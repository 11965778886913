import { useState } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import { lightTheme, darkTheme } from "./theme";
import Router from "./routes";
import { NotificationServiceProvider } from "./components/UIElements/notification";
import { AuthProvider } from "./hooks/useAuth";
import AuthGuard from "./hooks/useAuthGuard";
import { DetailProvider } from "./hooks/useDetails";
import Dashboard from "./pages/dashboard/Dashboard";
import HandleCookieData from "./components/auth/HandleCookieData";
import PageHeaderProvider from "./components/pageHeader";

function App() {
  // Detect user's preferred color scheme
  //  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: light)');
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <AuthProvider>
        <DetailProvider>
          <PageHeaderProvider>
            <NotificationServiceProvider>
              <Router />
            </NotificationServiceProvider>
          </PageHeaderProvider>
        </DetailProvider>
      </AuthProvider>
      {/* component to clear cookie data */}
      {/* <HandleCookieData /> */}
    </ThemeProvider>
  );
}

export default App;
