import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui

import { Box, List, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import NavConfig from "./config";
import useResponsive from "../../../hooks/useResponsive";
// ----------------------------------------------------------------------
const NAV_WIDTH = 250;

// ----------------------------------------------------------------------

interface NavItem {
  title: string;
  path: string;
  icon: React.ReactNode;
}

interface NavSectionProps {
  data: () => NavItem[]; // Define the type of the data prop
}

const NavStyle = styled("div")(({ theme }) => ({
  height: "100vh",

  flexShrink: 0,
  padding: "0 28px",
  [theme.breakpoints.up("lg")]: {
    paddingTop: 34,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));
const Navigation = () => {
  const isDesktop = useResponsive("down", "lg", "xl");
  const isMedium = useResponsive("down", "md", "xs");

  return (
    <NavStyle
      sx={{
        position:  "fixed",
        display: isDesktop ? "none" : "block",
      }}
    >
      <Box>
        <img
          src="/assets/images/kumo-logo.png"
          width="142"
          height="42"
          alt=""
        />
      </Box>
      <NavSection data={NavConfig} />
    </NavStyle>
  );
};

export default Navigation;

function NavSection({ data }: NavSectionProps) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const isCurrentPath = (path: string) => {
    return location.pathname.startsWith(path);
  };
  const handlePathNavigate = (path: string) => {
    navigate(path);
  };
  const newData = data();
  return (
    <Box sx={{ marginTop: "4rem" }}>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.secondary.main, fontWeight: 500 }}
      >
        Mainmenu
      </Typography>
      <List disablePadding sx={{ p: 1, my: 1 }}>
        {newData.map(
          (
            item: NavItem // Adjust mapping according to NavItem type
          ) => (
            <Box
              key={item.title}
              onClick={() => handlePathNavigate(item?.path)}
              sx={{
                display: "flex",
                gap: "20px",
                cursor: "pointer",
                my: 2,                          
                p: 3,
                background: isCurrentPath(item.path)
                  ? theme.palette.primary.main
                  : "",
                borderRadius: "20px",
                color: isCurrentPath(item.path)
                  ? theme.palette.common.white
                  : theme.palette.secondary.light,
              }}
            >
              {item.icon}
              <Typography
                variant="body1"
                sx={{
                  fontWeight: isCurrentPath(item.path) ? 700 : 400,
                }}
              >
                {item.title}
              </Typography>{" "}
              {/* Remove extra curly braces */}
            </Box>
          )
        )}
      </List>
    </Box>
  );
}
