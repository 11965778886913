// components/ProtectedRoute.js
import { FC, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { useAuth } from "./useAuth";
import { PATH_AUTH } from "../routes/path";
import { useDetails } from "./useDetails";
import { Box } from "@mui/material";
import LoadingSpinner from "../components/UIElements/loadingSpinner";

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { user, token, loadData } = useAuth();
  const { loadUserData } = useDetails();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadData();
        await loadUserData();
        setIsLoading(false);
      } catch (error) {
        console.error("Error loading data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <>
      {!!token && !!user ? (
        // If user and token exist, render children
        children
      ) : (
        // If not authenticated, render login page
        <Navigate to={PATH_AUTH.login} />
      )}
    </>
  );
};

export default AuthGuard;
