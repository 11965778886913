import { Box, Button, Typography, Stack } from "@mui/material";
import {
  ConfirmPasswordInput,
  EmailInput,
  FullNameInput,
  PasswordInput,
  PhonenumberInput,
  ReferralInput,
} from "../UIElements/input";
import { Link, useNavigate } from "react-router-dom";
import { PATH_AUTH } from "../../routes/path";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../../hooks/useResponsive";
import {
  SignupPasswordPropType,
  SignupValidateType,
} from "../../interface/auth";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  passwordSchema,
  signUpSchema,
} from "../../validate/input";
import { LoadingButton } from "@mui/lab";
import { FC, useState } from "react";
import { apiUserSignUp } from "../../api/auth";
import { useNotificationService } from "../UIElements/notification";
// import { ApiUserSignUpPropType } from "../../interface/api_request";

export const SignupDetailsOne = ({
  pageHandler,
  values,
  changeValues,
}: SignupValidateType) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xs");
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(signUpSchema),
  });

  const handleOnSubmit = async (values: any) => {
    console.log(values);
    changeValues((prev) => ({ ...prev, ...values }));
    pageHandler();
  };

  return (
    <Box sx={{ width: isMedium ? "100%" : "40%" }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <FullNameInput errors={errors} register={register} />

        <EmailInput
          errors={errors}
          placeholder="Email Address"
          register={register}
        />
        <ReferralInput
          placeholder="Referral ID (Optional)"
          errors={errors}
          register={register}
        />
        <PhonenumberInput
          errors={errors}
          register={register}
          placeholder="Enter Number"
        />

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ my: 3 }}
        >
          <Link style={{ textDecorationLine: "none" }} to={PATH_AUTH.login}>
            <Typography color={theme.palette.primary.main}>Login</Typography>
          </Link>
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "160px",
              borderRadius: "10px",
            }}
          >
            Continue
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export const SignupDetailsTwo = ({
  changeValues,
  values,
  prevPage,
  pageHandler,
}: SignupPasswordPropType) => {
  const isMedium = useResponsive("down", "md", "xs");
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotificationService();

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(passwordSchema),
  });

  // submit handler
  const handleOnSubmit = async (value: any) => {
    const { password } = value;

    setIsLoading(true);
    const data = {
      ...values,
      phone_number: "+" + values.country_code + values.phone_number,
      password: password,
    };

    const result = await apiUserSignUp(data);
    console.log(result);
    if ("msg" in result) {
      showNotification(result.msg, "error");
      setIsLoading(false);
    } else if (result.status == 201) {
      showNotification("Account Created. Kindly verify email!", "success");
      setIsLoading(false);
      pageHandler();
    } else {
      showNotification("Account Creation Failed!", "error");
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: isMedium ? "100%" : "40%" }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <PasswordInput
          errors={errors}
          placeholder="Password"
          register={register}
        />
        <ConfirmPasswordInput
          errors={errors}
          placeholder="Re-enter Password"
          register={register}
        />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ my: 3 }}
        >
          <Button onClick={prevPage} color="primary">
            Back
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            sx={{
              width: "160px",
              borderRadius: "10px",
            }}
          >
            Continue
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};
