import React, { FC, useEffect, useState } from "react";

//
import { Box, Typography, Stack } from "@mui/material";

import {
  MaxAmountValue,
  TextIconContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { SearchInput } from "../UIElements/searchInput";
import { useTheme } from "@mui/material/styles";
import { ButtonContainedMain } from "../UIElements/button";
import { CryptoTransaction } from "./modal/dashboardModals";
import { PasswordModal } from "../passwordModal";
import {
  TransactionResult,
  TransactionResultFailed,
} from "../transactionResult";
import { OTPModal } from "../otpModal";
import useResponsive from "../../hooks/useResponsive";
import {
  buyCrypto,
  buyCryptoCheckMinMax,
  buyCryptoVerifier,
  getCryptoToBaseCurrencyForBuy,
} from "../../api/transaction";
import { useAuth } from "../../hooks/useAuth";
import { useDetails } from "../../hooks/useDetails";
import { WalletBalanceType } from "../../interface/useDetail";
import { useNotificationService } from "../UIElements/notification";
import { requestOtp, validateOtp, verifyPin } from "../../api/users";
import {
  addCommasToNumbers,
  convertAndCheckAmountValue,
  formatNumberWithCommas,
  isAmountAboveMax,
  parseFormattedNumber,
} from "../../utils/basic";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../../routes/path";
import { TransactionIcon } from "../UIElements/svgIcons";
//
const BuyCryptoContent: FC<{ tokenList: any[] }> = ({ tokenList }) => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");
  const { base, token } = useAuth();
  const navigate = useNavigate();
  const { showNotification } = useNotificationService();
  const { wallet } = useDetails();
  const [assetWallet, setAssetWallet] = useState<{
    switch: boolean;
    max_amount: boolean;
  }>({ switch: false, max_amount: false });
  const [baseWallet, setBaseWallet] = useState<WalletBalanceType>();
  const [filteredData, setFilteredData] = useState<{
    coin_list: any[];
    search_value: string;
  }>({
    coin_list: tokenList ?? [],
    search_value: "",
  });
  const [modalContainer, setModalContainer] = useState({
    modalDetails: false,
    pinModal: false,
    otpModal: false,
    resultModal: false,
    failedModal: false,
    charges: "",
    converted_amount_value: "",
    pin: "",
  });
  const [currency, setCurrency] = useState<{
    token: any;
    exchange: boolean;
    amount_value: string | number;
    token_amount_value: number;
    base_token_value: number;
  }>({
    token: {},
    exchange: false,
    amount_value: "",
    token_amount_value: 0,
    base_token_value: 0,
  });

  // get baseWallet
  useEffect(() => {
    getBaseWallet();
  }, []);

  // Update token_amount_value whenever amount_value or base_token_value changes
  useEffect(() => {
    if (!assetWallet?.switch) {
      // Calculate token_amount_value based on amount_value and base_token_value
      const tokenAmount =
        parseFormattedNumber(currency.amount_value?.toString()) /
        currency.base_token_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        token_amount_value: tokenAmount,
      }));
    } else {
      if (assetWallet?.max_amount) {
        // Calculate token_amount_value based on amount_value and base_token_value
        const tokenAmount =
          parseFormattedNumber(currency.amount_value?.toString()) /
          currency.base_token_value;
        setCurrency((prevCurrency) => ({
          ...prevCurrency,
          token_amount_value: tokenAmount,
        }));
        setAssetWallet((prev) => ({ ...prev, max_amount: false }));
        return;
      }
      const tokenAmount =
        Number(currency?.token_amount_value) * currency?.base_token_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        amount_value: tokenAmount,
      }));
    }
  }, [
    currency.amount_value,
    currency.base_token_value,
    currency?.token_amount_value,
  ]);

  // get the value of the base from wallet
  const getBaseWallet = () => {
    const walletBalance = wallet?.find(
      (currency: WalletBalanceType) =>
        currency.code === base?.code || currency.name === base?.name
    );
    setBaseWallet(walletBalance);
  };

  // handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilteredData((prev) => ({ ...prev, search_value: value }));

    if (value.length === 0) {
      setFilteredData((prev) => ({ ...prev, coin_list: tokenList })); // Reset to original list if search input is empty
    } else {
      const filteredTokens = filteredData?.coin_list?.filter((token) =>
        token?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredData((prev) => ({ ...prev, coin_list: filteredTokens }));
    }
  };

  // hamdle modal function and send request
  const handleModal = async () => {
    const token_key = token ?? "";

    // check if amount is empty
    if (
      !convertAndCheckAmountValue(
        parseFormattedNumber(currency?.amount_value?.toString())
      )
    ) {
      showNotification("Invalid Amount", "error");
      return;
    }
    showNotification("Verifying Transaction", "info");
    const data = {
      currency_name: currency?.token?.name,
      amount: parseFormattedNumber(currency?.amount_value?.toString()),
      rate: currency?.base_token_value,
    };
    const result = await buyCryptoCheckMinMax(data, token_key);

    if ("msg" in result) {
      showNotification(result.msg, "error");
    } else if (result.status == 200) {
      const verify_data = {
        currency_name: currency?.token?.name,
        amount: parseFormattedNumber(currency?.amount_value?.toString()),
        rate: currency?.base_token_value,
      };

      const verify_result = await buyCryptoVerifier(verify_data, token_key);
      if ("msg" in verify_result) {
        showNotification(verify_result.msg, "error");
      } else if (verify_result.status == 200) {
        setModalContainer((prev) => ({
          ...prev,
          modalDetails: !prev.modalDetails,
          charges: verify_result?.data?.data?.fee,
          converted_amount_value: verify_result?.data?.data?.value,
        }));
      } else {
        showNotification("An error occurred", "error");
      }
    } else {
      showNotification("Transaction not approved", "error");
    }
  };
  const handlePinModal = async () => {
    setModalContainer((prev) => ({ ...prev, pinModal: !prev.pinModal }));
  };
  const handleOtpModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // send otp request
      const send_request = await requestOtp(token_key);
      console.log(send_request?.data?.msg);
      if (send_request?.status === 200) {
        showNotification(send_request?.data?.msg, "success");
      } else {
        showNotification("OTP code not sent", "error");
      }
      // update state
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        otpModal: !prev.otpModal,
        pin: pin_value,
      }));
    } else {
      showNotification("Invalid Pin", "error");
    }
  };
  const handleResultModal = async (otp: string[]) => {
    const token_key = token ?? "";

    // verify otp and invoke buy crypto api
    const otp_value = otp.join("");
    const data = {
      currency_name: currency?.token?.name,
      amount: parseFormattedNumber(currency?.amount_value?.toString()),
      rate: currency?.base_token_value,
      converted: currency?.token_amount_value,
      action: "buy",
      code: base?.code,
      destination_address: "",
      pin: modalContainer?.pin,
    };
    const otp_verify = await validateOtp(otp_value, token_key);
    if (otp_verify?.status === 200) {
      const buy_crypto = await buyCrypto(data, token_key);
      console.log(buy_crypto, "buy_crypto");
      if (buy_crypto?.status === 200) {
        showNotification("Crypto bought successfully", "success");
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          failedModal: !prev.failedModal,
        }));
        showNotification("Transaction Failed", "error");
      }
    } else {
      showNotification("OTP Verification Failed", "error");
    }
  };

  // currency function
  const handleCurrency = async (tokenObj: any) => {
    const token_key = token ?? "";
    const query = {
      amount: "1",
      crypto: tokenObj?.name,
      currency: base?.code,
    };
    const result = await getCryptoToBaseCurrencyForBuy(query, token_key);
    if (result?.status === 200) {
      setCurrency((prev) => ({
        ...prev,
        exchange: true,
        token: tokenObj,
        base_token_value: result?.data?.data?.value,
      }));
    } else {
      showNotification("Error fetching currency exchange rate", "error");
    }
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    const value = e.target.value.replace(/,/g, "");
    if (assetWallet?.switch) {
      setCurrency((prev) => ({ ...prev, token_amount_value: e.target.value }));
    } else {
      setCurrency((prev) => ({ ...prev, amount_value: value }));
    }
  };
  const handleMaxAmount = (num: number) => {
    setAssetWallet((prev) => ({ ...prev, max_amount: true }));
    setCurrency((prev) => ({ ...prev, amount_value: num }));
  };

  const handleAddComma = () => {
    if (!assetWallet?.switch) {
      const plainNumber = currency.amount_value || "";
      const formattedNumber = addCommasToNumbers(+plainNumber);
      setCurrency((prev) => ({ ...prev, amount_value: formattedNumber }));
    } else {
      return;
    }
  };

  const handleFocus = () => {
    if (!assetWallet?.switch && currency.amount_value) {
      const plainNumber = parseFormattedNumber(
        currency.amount_value?.toString()
      );
      setCurrency((prev) => ({ ...prev, amount_value: plainNumber }));
    }
  };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      otpModal: false,
      resultModal: false,
      failedModal: false,
    }));
  };
  return (
    <>
      <CryptoTransaction
        onClose={closeAllModal}
        charges={modalContainer?.charges}
        currency={currency?.token?.code?.toUpperCase()}
        open={modalContainer?.modalDetails}
        amount={parseFormattedNumber(currency?.amount_value?.toString())}
        onclick={handlePinModal}
        currencyValue={modalContainer?.converted_amount_value}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleOtpModal}
        onClose={closeAllModal}
      />
      <OTPModal
        open={modalContainer.otpModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}></Typography>
      </TransactionResult>
      <TransactionResultFailed
        onClose={closeAllModal}
        openResult={modalContainer?.failedModal}
        reason="Transaction Failed"
      />

      <Box sx={{ mt: isSmall ? 0 : 4 }}>
        {currency?.exchange && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Balance{" "}
            </Typography>
            {!!baseWallet && (
              <MaxAmountValue
                onclick={handleMaxAmount}
                currency={base?.code}
                amount={baseWallet?.value}
              />
            )}{" "}
            <TextValueContainer
              text="You Pay"
              onFocusHandler={handleFocus}
              onBlurHandler={handleAddComma}
              onchange={handleAmountChange}
              currency={
                assetWallet?.switch
                  ? currency?.token?.code?.toUpperCase()
                  : base?.symbol
              }
              amount={
                assetWallet?.switch
                  ? currency?.token_amount_value
                  : currency?.amount_value
              }
              icon={assetWallet?.switch ? currency?.token?.image : base?.image}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                my: 2,
              }}
            >
              <span
                onClick={() => {
                  setAssetWallet((prev) => ({ ...prev, switch: !prev.switch }));
                }}
                style={{
                  color: theme.palette.secondary.light,
                  cursor: "pointer",
                }}
              >
                <TransactionIcon />
              </span>
            </Box>
            <TextValueContainer
              text="Value"
              onchange={() => {}}
              onFocusHandler={() => {}}
              onBlurHandler={() => {}}
              currency={
                !assetWallet?.switch
                  ? currency?.token?.code?.toUpperCase()
                  : base?.symbol
              }
              amount={
                !assetWallet?.switch
                  ? currency?.token_amount_value
                  : currency?.amount_value
              }
              icon={!assetWallet?.switch ? currency?.token?.image : base?.image}
            />
            <Stack direction="column" alignItems={"center"}>
              <Typography
                variant="body2"
                sx={{ my: 2, color: theme.palette.secondary.main }}
              >
                1 {currency?.token?.code?.toUpperCase()} ={" "}
                {base?.code?.toUpperCase()}{" "}
                {formatNumberWithCommas(currency?.base_token_value, 4)}
              </Typography>
              <ButtonContainedMain sx={{}} onclick={handleModal}>
                Next
              </ButtonContainedMain>
            </Stack>
          </Box>
        )}
        {!currency?.exchange && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Select Your Preferred coin
            </Typography>
            <Box sx={{ my: 2 }}>
              <SearchInput
                width="100%"
                text="Search Coin"
                handler={handleSearchChange}
                value={filteredData?.search_value}
              />
              {filteredData?.coin_list?.map((token, id) => (
                <Box key={id}>
                  <TextIconContainer
                    image={token?.image}
                    onclick={() => handleCurrency(token)}
                  >
                    {token?.name?.toUpperCase()}
                  </TextIconContainer>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BuyCryptoContent;
