import React, { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ModalContainer from "./UIElements/modal";
import { ButtonContainedMain } from "./UIElements/button";
import { useAuth } from "../hooks/useAuth";
import { getBaseCurrency } from "../api/settings";
import { useDetails } from "../hooks/useDetails";
import { setUsersCurrentBaseCurrency } from "../api/users";
import { useNotificationService } from "./UIElements/notification";
import LoadingSpinner from "./UIElements/loadingSpinner";

export const ModalBaseCurrencySelector: React.FC<{
  open: boolean;
  onclose: () => void;
}> = ({ open, onclose }) => {
  const theme = useTheme();
  const [getBase, setGetBase] = useState<any[]>([]);
  const [isBaseSet, setIsBaseSet] = useState(false);
  const { token, base } = useAuth();
  const { fetchUserData } = useDetails();

  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = async () => {
    const token_key = token ?? "";
    const data = await fetchUserData(token_key);
    if (!!data) {
      setIsBaseSet(data?.is_base_currency_set);
      if (!data?.is_base_currency_set) {
        // fetch base data list from server
        fetchUserBaseCurrency();
      }
    }
  };

  const fetchUserBaseCurrency = async () => {
    const token_key = token ?? "";
    const result = await getBaseCurrency(token_key);
    if (result?.status === "error") {
    } else if (result?.status === 200) {
      setGetBase(result?.data?.data);
    }
  };
  return (
    <ModalContainer
      sx={{}}
      size={"xs"}
      openModal={open}
      fullWidth={true}
      handleModal={onclose}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: "10px",
          padding: 3,
        }}
      >
        {!!getBase && (
          <BaseCurrencySelector
            isBaseSet={isBaseSet}
            handleClose={onclose}
            baseValue={base?.name}
            baseCurrencies={getBase}
          />
        )}
      </Box>
    </ModalContainer>
  );
};

const BaseCurrencySelector: FC<{
  handleClose: () => void;
  baseCurrencies: any[];
  isBaseSet: boolean;
  baseValue: string;
}> = ({ handleClose, baseCurrencies, isBaseSet, baseValue }) => {
  const theme = useTheme();
  const [base_value, setBase_Value] = useState(baseValue ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const { loadData, token } = useAuth();
  const { refetchData } = useDetails();
  const { showNotification } = useNotificationService();

  // handle base selector
  const handleBaseSeletor = async () => {
    const token_key = token ?? "";
    if (isBaseSet) {
      handleClose();
      return;
    }
    if (base_value?.length >= 1) {
      setIsLoading(true);
      //pass currency_id as props
      const set_base_request = await setUsersCurrentBaseCurrency(
        base_value,
        token_key
      );
      if ("msg" in set_base_request) {
        showNotification(set_base_request.msg, "error");
        setIsLoading(false);
      } else if (set_base_request.status == 200) {
        showNotification("Base Currency set!", "success");
        setIsLoading(false);
        refetchData();
        loadData();
        handleClose();
      } else {
        showNotification("An error occured while fetching data", "error");
        setIsLoading(false);
      }
    }
    handleClose();
  };

  const handleChange = (event: SelectChangeEvent) => {
    setBase_Value(event.target.value as string);
  };
  return (
    <Box>
      <Stack sx={{ color: theme.palette.text.primary, mb: 3 }}>
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 600 }}>
          Set Base Currency
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, textAlign: "center" }}
        >
          Your base currency will be used for trasnsaction
        </Typography>
      </Stack>
      {isBaseSet && (
        <Typography
          variant="body2"
          color={"primary"}
          sx={{ fontWeight: 500, textAlign: "center" }}
        >
          Base Currency has been set
        </Typography>
      )}
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <LoadingSpinner />{" "}
        </Box>
      )}
      <InputLabel
        sx={{
          color: theme.palette.text.primary,
          fontSize: "12px",
        }}
      >
        Select Currency
      </InputLabel>
      <FormControl
        sx={{ width: "100%", my: 2, position: "relative", zIndex: 2 }}
      >
        {!isBaseSet ? (
          <Select
            value={base_value}
            onChange={handleChange}
            sx={{
              background: theme.palette.common.white,
            }}
            disabled={isBaseSet}
          >
            {baseCurrencies?.map((currency, id) => (
              <MenuItem
                sx={{
                  background: theme.palette.common.white,
                  color: theme.palette.common.black,
                }}
                key={id}
                value={currency?.code}
              >
                {currency?.name?.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <TextField disabled={true} value={baseValue?.toUpperCase()} />
        )}
      </FormControl>

      <Typography variant="caption" sx={{ my: 2, textAlign: "center" }}>
        This selection cannot be undone when base currency have been choosen
      </Typography>
      <Stack direction={"row"} justifyContent={"center"} sx={{ my: 2 }}>
        <ButtonContainedMain sx={{}} onclick={handleBaseSeletor}>
          Done
        </ButtonContainedMain>
      </Stack>
    </Box>
  );
};
