import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  Stack,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  ButtonContainedMain,
  LoadingButtonContainer,
} from "../UIElements/button";
import { SelectContainer } from "../UIElements/inputElements";
import { SwitchContainer } from "../UIElements/switchInput";
import useResponsive from "../../hooks/useResponsive";
import { apiUpdateUser } from "../../api/profile";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../UIElements/notification";
import BVNVerification from "./BVNVerification";

interface AccountProps {
  inputState: Record<string, any>;
  changeHandler: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}
export const AccountSetting = ({ inputState, changeHandler }: AccountProps) => {
  const theme = useTheme();
  const { user, token } = useAuth();
  
  const { showNotification } = useNotificationService();
  const [isLoading, setIsLoading] = useState(false);
  const isMedium = useResponsive("down", "md", "xs");
  const bgStyle = {
    background: theme.palette.common.white,
    borderRadius: "20px",
    paddingX: 4,
    paddingY: 2,
  };
console.log(inputState)
  const handleUpdate = async () => {
    const data = {
      first_name: inputState?.first_name,
      last_name: inputState?.last_name,
      phone_number: inputState?.phone_number,
    };
    setIsLoading(true);
    const token_key = token ?? "";
    const result = await apiUpdateUser(data, token_key);
    if (result.status == 200) {
      showNotification(result?.data?.msg, "success");
      setIsLoading(false);
    } else {
      showNotification(result?.data?.msg, "error");
      setIsLoading(false);
    }
  };
  return (
    <Grid container gap={3}>
      <Grid item xs={isMedium ? 12 : 5}>
        <Box sx={bgStyle}>
          <Stack sx={{ mb: 2, width: "100%" }}>
            <InputLabel
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "31.72px",
                
              }}
            >
              Language
            </InputLabel>

            <SelectContainer
              selectData={["English"]}
              value={"English"}
              onChange={() => {}}
            />
          </Stack>
          <SwitchContainer
            label={"Verify Email"}
            text={`${user?.email}`}
            value={inputState?.is_verified}
            onChange={()=>{}}
          />
          <SwitchContainer
            label={"Verify Phone number"}
            text={`${inputState?.phone_number}`}
            value={inputState?.is_verified}
            onChange={() => {}}
          />
         <BVNVerification inputState={inputState} />
          <SwitchContainer
            label={"Two Factor Verification"}
            text={"Off"}
            value={false}
            onChange={() => {}}
          />
          <SwitchContainer
            label={"Notifications"}
            text={"Off"}
            value={false}
            onChange={() => {}}
          />

          <Stack>
            <LoadingButtonContainer
              type="submit"
              loading={isLoading}
              onclick={handleUpdate}
            >
              Save
            </LoadingButtonContainer>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
