import React, { useEffect, useState } from "react";
import {
  TransactionResult,
  TransactionResultFailed,
} from "../../../components/transactionResult";
import {
  KumoAgentFirstStep,
  KumoAgentFourthStep,
  KumoAgentSecondStep,
  KumoAgentThirdStep,
} from "../../../components/dashboard/fundWallet/KumoAgentContent";
import { PATH_DASHBOARD } from "../../../routes/path";
import { useNavigate } from "react-router-dom";
import { useNotificationService } from "../../../components/UIElements/notification";
import { useAuth } from "../../../hooks/useAuth";
import { getKumoAgents } from "../../../api/agents";
import { KumoAgentStateType } from "../../../interface/dashboard";
import { parseFormattedNumber } from "../../../utils/basic";
import { fundWallet } from "../../../api/transaction";

const KumoAgent = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotificationService();
  const { base, token } = useAuth();
  const [kumoAgent, setKumoAgent] = useState<KumoAgentStateType>({
    amount: "",
    agentSelected: {},
    agentList: [],
  });
  const [modalContainer, setModalContainer] = useState({
    firstInfo: true,
    secondInfo: false,
    thirdInfo: false,
    fourthInfo: false,
    fifthInfo: false,
    failed: false,
  });

  useEffect(() => {
    fetchKumoAgent();
  }, []);

  const fetchKumoAgent = async () => {
    const token_key = token ?? "";
    const result = await getKumoAgents(token_key);

    if ("msg" in result) {
      console.log("Error fetching coin list", result.msg);
      showNotification(result.msg, "error");
    } else if (result.status == 200) {
      setKumoAgent((prev) => ({ ...prev, agentList: result?.data?.data }));
    } else {
      showNotification("An error occured while fetching data", "error");
    }
  };

  const handleSecondInfo = () =>
    setModalContainer((prev) => ({ ...prev, secondInfo: !prev.secondInfo }));
  const handleThirdInfo = () =>
    setModalContainer((prev) => ({ ...prev, thirdInfo: !prev.thirdInfo }));
  const handleFourthInfo = () =>
    setModalContainer((prev) => ({ ...prev, fourthInfo: !prev.fourthInfo }));

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      firstInfo: false,
      secondInfo: false,
      thirdInfo: false,
      fourthInfo: false,
      fifthInfo: false,
      failed: false,
    }));
  };
  const navigateHome = () => {
    navigate(PATH_DASHBOARD.fundWallet);
  };

  const handleFundWallet = async () => {
    const token_key = token ?? "";
    showNotification("Saving your funding details", "info");
    const data = {
      payment_method: "kumo",
      naira_amount_paid: parseFormattedNumber(kumoAgent?.amount?.toString()),
      method: "kumo-agent",
      currency_name: base?.name,
    };
    const submit_result = await fundWallet(data, token_key);
    console.log(submit_result);
    if ("msg" in submit_result) {
      showNotification(submit_result.msg, "error");
      setModalContainer((prev) => ({
        ...prev,
        fourthInfo: false,
        failed: true,
      }));
    } else if (submit_result.status == 200) {
      setModalContainer((prev) => ({
        ...prev,
        fourthInfo: false,
        fifthInfo: true,
      }));
    } else {
      showNotification("An error occured while fetching data", "error");
      setModalContainer((prev) => ({
        ...prev,
        fourthInfo: false,
        failed: true,
      }));
    }
  };
  return (
    <>
      <KumoAgentFirstStep
        open={modalContainer.firstInfo}
        onclick={handleSecondInfo}
        onclose={closeAllModal}
        navigate={navigateHome}
        kumoAgent={kumoAgent}
        setKumoAgent={setKumoAgent}
      />
      <KumoAgentSecondStep
        open={modalContainer.secondInfo}
        onclick={handleThirdInfo}
        onclose={closeAllModal}
        navigate={navigateHome}
      />
      <KumoAgentThirdStep
        open={modalContainer.thirdInfo}
        onclick={handleFourthInfo}
        onclose={closeAllModal}
        navigate={navigateHome}
        agentList={kumoAgent?.agentList}
        setKumoAgent={setKumoAgent}
      />
      <KumoAgentFourthStep
        open={modalContainer.fourthInfo}
        onclick={handleFundWallet}
        onclose={navigateHome}
        kumoAgent={kumoAgent}
      />

      <TransactionResult
        txId={""}
        onclose={closeAllModal}
        openResult={modalContainer.fifthInfo}
      >
        {""}
      </TransactionResult>
      <TransactionResultFailed
        onClose={navigateHome}
        openResult={modalContainer?.failed}
        reason={"Transaction Failed"}
      />
    </>
  );
};

export default KumoAgent;
