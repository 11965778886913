export const lightGeneralColors = {
  primary: {
    main: "#6E25CC",
    light: "#6E25CC1A",
  },
  secondary: {
    main: "#3E3E3B7D",
    light: "#B8B8B8",
  },
  error: {
    main: "#E43F40",
  },
  
  success: {
    main: "#6CA125",
  },
  warning: {
    main: "#F48140",
  },
  text: {
    primary: "#2C393F",
    secondary: "#B8B8B8",
  },
};

export const darkGeneralColors = {
  primary: {
    main: "#6E25CC",
    light: "#6E25CC1A",
  },
  secondary: {
    main: "#32BBCD",
  },
  error: {
    main: "#E43F40",
  },
  success: {
    main: "#00BA88",
  },
  warning: {
    main: "#F48140",
  },
  text: {
    primary: "#2C393F",
    secondary: "#B8B8B8",
  },
};
