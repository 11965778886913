import React, { useEffect, useState } from "react";

//
import { Box, Typography, Stack } from "@mui/material";
import Decimal from "decimal.js";

import {
  MaxAmountValue,
  TextIconContainer,
  TextInputContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { SearchInput } from "../UIElements/searchInput";
import { useTheme } from "@mui/material/styles";
import { ButtonContainedMain } from "../UIElements/button";
import {
  TransactionResult,
  TransactionResultFailed,
} from "../transactionResult";
import { OTPModal } from "../otpModal";
import { PasswordModal } from "../passwordModal";
import { useAuth } from "../../hooks/useAuth";
import { useDetails } from "../../hooks/useDetails";
import { useNotificationService } from "../UIElements/notification";
import KumoWalletBaseTransfer, {
  KumoWalletTransaction,
} from "./KumoWalletBaseTransfer";
import { WalletBalanceType } from "../../interface/useDetail";
import {
  getCryptoToBaseCurrencyForSend,
  transferToKumoAccount,
  transferToKumoAccountVerifier,
} from "../../api/transaction";
import { TransactionIcon } from "../UIElements/svgIcons";
import useResponsive from "../../hooks/useResponsive";
import { verifyPin } from "../../api/profile";
import { requestOtp, validateOtp } from "../../api/users";
import {
  addCommasToNumbers,
  formatNumberWithCommas,
  parseFormattedNumber,
} from "../../utils/basic";

//
const KumoWalletTransferContents: React.FC<{ coinList: any[] }> = ({
  coinList,
}) => {
  const theme = useTheme();
  const { user, base, token } = useAuth();
  const { wallet } = useDetails();
  const isSmall = useResponsive("down", "sm", "xl");
  const { showNotification } = useNotificationService();
  const [assetWallet, setAssetWallet] = useState<{
    baseWallet: any;
    cryptoWallet: any;
    switch: boolean;
  }>({
    baseWallet: {},
    cryptoWallet: {},
    switch: true,
  });
  const [filteredData, setFilteredData] = useState<{
    coin_list: any[];
    search_value: string;
  }>({
    coin_list: coinList ?? [],
    search_value: "",
  });
  const [modalContainer, setModalContainer] = useState({
    modalDetails: false,
    pinModal: false,
    otpModal: false,
    resultModal: false,
    failedModal: false,
    charges: "",
    pin: "",
  });

  const [currency, setCurrency] = useState<{
    token: any;
    exchange: string;
    amount_value: number | string;
    token_amount_value: number | string;
    base_token_value: number;
    email: string;
  }>({
    token: {},
    exchange: "coin_list",
    amount_value: 1,
    token_amount_value: "",
    base_token_value: 0,
    email: "",
  });

  // Update token_amount_value whenever amount_value or base_token_value changes
  useEffect(() => {
    if (!assetWallet?.switch) {
      // Calculate token_amount_value based on amount_value and base_token_value
      const tokenAmount =
        parseFormattedNumber(currency?.amount_value?.toString()) /
        currency?.base_token_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        token_amount_value: tokenAmount,
      }));
    } else {
      const tokenAmount =
        Number(currency?.token_amount_value) * currency?.base_token_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        amount_value: tokenAmount,
      }));
    }
  }, [
    currency.amount_value,
    currency.base_token_value,
    currency?.token_amount_value,
  ]);

  // get the value of the base from wallet
  const getAssetWallet = (tokenObj: any) => {
    const cryptoBalance = wallet?.find(
      (wallet: WalletBalanceType) =>
        wallet.code === tokenObj?.code || wallet.name === tokenObj?.name
    );
    const walletBalance = wallet?.find(
      (currency: WalletBalanceType) =>
        currency.code === base?.code || currency.name === base?.name
    );
    setAssetWallet((prev) => ({
      ...prev,
      baseWallet: walletBalance,
      cryptoWallet: cryptoBalance,
    }));
  };

  // handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilteredData((prev) => ({ ...prev, search_value: value }));

    if (value.length === 0) {
      setFilteredData((prev) => ({ ...prev, coin_list: coinList })); // Reset to original list if search input is empty
    } else {
      const filteredTokens = filteredData?.coin_list?.filter((token) =>
        token?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredData((prev) => ({ ...prev, coin_list: filteredTokens }));
    }
  };

  const handleModal = async () => {
    const base_value = assetWallet?.baseWallet?.value ?? 0;
    const token_key = token ?? "";

    // check if email is empty
    if (currency?.email?.length < 6) {
      showNotification("Email is empty", "error");
      return;
    }
    const data = {
      currency_name: currency?.token?.name,
      user_id: user?.user_id ?? "",
      naira_amount_paid: parseFormattedNumber(
        currency?.amount_value?.toString()
      ),
      email: currency?.email,
      pin: "random-pin",
    };
    const amount_exceed_check =
      parseFormattedNumber(currency?.amount_value?.toString()) > base_value ||
      parseFormattedNumber(currency?.amount_value?.toString()) === 0;

    // Check if currency.amount_value is more than base_value
    if (!amount_exceed_check) {
      const verify_transfer = await transferToKumoAccountVerifier(
        data,
        token_key
      );
      console.log(verify_transfer, "Verified transfer");
      if (
        verify_transfer?.status === 200 &&
        verify_transfer?.data?.status === "success"
      ) {
        setModalContainer((prev) => ({
          ...prev,
          modalDetails: !prev.modalDetails,
          charges: verify_transfer?.data?.data?.fee,
        }));
      }
    } else {
      showNotification("Amount exceeds base amount", "error");
    }
  };
  const handlePinModal = () =>
    setModalContainer((prev) => ({ ...prev, pinModal: !prev.pinModal }));

  const handleOtpModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");
    console.log(user?.user_id);

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // send otp request
      const send_request = await requestOtp(token_key);
      if (send_request?.status === 200) {
        showNotification(send_request?.data?.msg, "success");
      } else {
        showNotification("OTP code not sent", "error");
      }
      // update state
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        otpModal: !prev.otpModal,
        pin: pin_value,
      }));
    } else {
      showNotification("Invalid Pin", "error");
    }
  };

  const handleResultModal = async (otp: string[]) => {
    const token_key = token ?? "";
    // verify otp and invoke buy crypto api
    const otp_value = otp.join("");
    const data = {
      currency_name: currency?.token?.name,
      user_id: user?.user_id ?? "",
      naira_amount_paid: Number(currency?.token_amount_value),
      email: currency?.email,
      pin: modalContainer?.pin,
    };

    const otp_verify = await validateOtp(otp_value, token_key);
    if (otp_verify?.status === 200) {
      const transfer = await transferToKumoAccount(data, token_key);
      console.log(transfer);
      if ("msg" in transfer) {
        showNotification(transfer?.msg, "error");
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          failedModal: !prev.failedModal,
        }));
      } else if (transfer.status == 200) {
        showNotification("Crypto Transfer successfully", "success");
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        showNotification("Transaction Failed", "error");
      }
    } else {
      showNotification("OTP Verification Failed", "error");
      setModalContainer((prev) => ({
        ...prev,
        modalDetails: false,
        otpModal: false,
        failedModal: true,
      }));
    }
  };

  //
  const fetchCoinBaseConversion = async (
    tokenObj: any,
    amount_value: string
  ) => {
    const token_key = token ?? "";
    const query = {
      amount: amount_value,
      crypto: tokenObj?.name,
      currency: base?.code,
    };
    const result = await getCryptoToBaseCurrencyForSend(query, token_key);
    console.log(result);
    if (result?.status === 200) {
      setCurrency((prev) => ({
        ...prev,
        exchange: "coin_selected",
        token: tokenObj,
        base_token_value: result?.data?.data?.value,
      }));
    } else {
      showNotification("Error converting Coin and Base Amount", "error");
    }
  };

  //
  const handleCurrency = async (tokenObj: any) => {
    if (tokenObj?.name === base?.name) {
      setCurrency((prev) => ({
        ...prev,
        exchange: "coin_base",
        token: tokenObj,
      }));
      return;
    }

    await getAssetWallet(tokenObj);
    await fetchCoinBaseConversion(tokenObj, "1");
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    if (assetWallet?.switch) {
      setCurrency((prev) => ({ ...prev, token_amount_value: e.target.value }));
    } else {
      setCurrency((prev) => ({ ...prev, amount_value: e.target.value }));
    }
  };
  const handleMaxAmount = (num: number) => {
    if (assetWallet?.switch) {
      setCurrency((prev) => ({ ...prev, token_amount_value: num }));
    } else {
      setCurrency((prev) => ({ ...prev, amount_value: num }));
    }
  };

  const checkMaxAmount = async () => {
    const amount_exceed_check = new Decimal(currency?.token_amount_value).gt(
      new Decimal(assetWallet?.cryptoWallet?.value)
    );
    if (amount_exceed_check) {
      showNotification("Amount exceeds Balance", "error");
    } else {
    }
  };
  const handleAddComma = () => {
    if (!assetWallet?.switch) {
      const plainNumber = currency.amount_value || "";
      const formattedNumber = addCommasToNumbers(+plainNumber);
      setCurrency((prev) => ({ ...prev, amount_value: formattedNumber }));
    } else {
      return;
    }
  };

  const handleFocus = () => {
    if (!assetWallet?.switch && currency.amount_value) {
      const plainNumber = parseFormattedNumber(
        currency.amount_value?.toString()
      );
      setCurrency((prev) => ({ ...prev, amount_value: plainNumber }));
    }
  };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      otpModal: false,
      resultModal: false,
    }));
  };
  return (
    <>
      <KumoWalletTransaction
        email={currency?.email}
        amount={parseFormattedNumber(currency?.amount_value?.toString())}
        charges={modalContainer?.charges}
        onClose={closeAllModal}
        open={modalContainer?.modalDetails}
        onclick={handlePinModal}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleOtpModal}
        onClose={closeAllModal}
      />
      <OTPModal
        open={modalContainer.otpModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          You have <span style={{ fontWeight: 700 }}>successfully</span>{" "}
          Transferred <span style={{ fontWeight: 700 }}>0.013149 ETH </span> to{" "}
          <span style={{ fontWeight: 700 }}>Johndoe@gmail.com</span>
        </Typography>
      </TransactionResult>
      <TransactionResultFailed
        onClose={closeAllModal}
        openResult={modalContainer?.failedModal}
        reason="Transaction could not be completed"
      />

      <Box sx={{ mt: 4 }}>
        {currency?.exchange === "coin_selected" && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Balance{" "}
            </Typography>
            {!!assetWallet && (
              <MaxAmountValue
                onclick={handleMaxAmount}
                currency={
                  assetWallet?.switch
                    ? assetWallet?.cryptoWallet?.code
                    : base?.code
                }
                amount={
                  assetWallet?.switch
                    ? Number(assetWallet?.cryptoWallet?.value)
                    : Number(assetWallet?.baseWallet?.value)
                }
              />
            )}
            <TextValueContainer
              text="You Pay"
              onFocusHandler={handleFocus}
              onBlurHandler={handleAddComma}
              onchange={handleAmountChange}
              currency={
                assetWallet?.switch
                  ? currency?.token?.code?.toUpperCase()
                  : base?.code.toUpperCase()
              }
              amount={
                assetWallet?.switch
                  ? currency?.token_amount_value
                  : currency?.amount_value
              }
              icon={assetWallet?.switch ? currency?.token?.image : base?.image}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                my: 2,
              }}
            >
              <span
                onClick={() => {
                  setAssetWallet((prev) => ({ ...prev, switch: !prev.switch }));
                }}
                style={{
                  color: theme.palette.secondary.light,
                  cursor: "pointer",
                }}
              >
                <TransactionIcon />
              </span>
            </Box>
            {/* exchnge icon */}
            <TextValueContainer
              text="Value"
              onFocusHandler={() => {}}
              onBlurHandler={() => {}}
              onchange={() => {}}
              currency={
                !assetWallet?.switch
                  ? currency?.token?.code?.toUpperCase()
                  : base?.code.toUpperCase()
              }
              amount={
                !assetWallet?.switch
                  ? currency?.token_amount_value
                  : currency?.amount_value
              }
              icon={!assetWallet?.switch ? currency?.token?.image : base?.image}
            />
            <Stack direction="column" alignItems={"center"}>
              <Typography
                variant="body2"
                sx={{ my: 2, color: theme.palette.secondary.main }}
              >
                1 {currency?.token?.code?.toUpperCase()} ={" "}
                {base?.code?.toUpperCase()}{" "}
                {formatNumberWithCommas(currency?.base_token_value)}
              </Typography>
            </Stack>

            <TextInputContainer
              text="Email"
              placeholder="Enter email Address ..."
              value={currency?.email}
              onclick={(e) =>
                setCurrency((prev) => ({ ...prev, email: e.target.value }))
              }
            />

            <Stack direction="column" alignItems={"center"}>
              <ButtonContainedMain
                sx={{ width: "150px", fontWeight: 500, fontSize: "16px" }}
                onclick={handleModal}
              >
                Send
              </ButtonContainedMain>
            </Stack>
          </Box>
        )}

        {currency?.exchange === "coin_list" && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Select Your Preferred coin
            </Typography>
            <Box sx={{ my: 2 }}>
              <SearchInput
                width="100%"
                text="Search Coin"
                handler={handleSearchChange}
                value={filteredData?.search_value}
              />

              {filteredData?.coin_list?.map((token, id) => (
                <Box key={id}>
                  <TextIconContainer
                    image={token?.image}
                    onclick={() => handleCurrency(token)}
                  >
                    {token?.name?.toUpperCase()}
                  </TextIconContainer>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {currency?.exchange === "coin_base" && <KumoWalletBaseTransfer />}
      </Box>
    </>
  );
};

export default KumoWalletTransferContents;
