import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import SendCryptoContent from "../../components/dashboard/SendCryptoContent";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../../components/UIElements/notification";
import { getAllListedCoinToken } from "../../api/settings";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";

const SendCrypto: React.FC = () => {
  const theme = useTheme();
  const {user, token } = useAuth();
  const { showNotification } = useNotificationService();
  const [tokenList, setTokenList] = useState([]);

  useEffect(() => {
    fetchCrypto();
  }, []);
  const fetchCrypto = async () => {
    const token_key = token ?? "";
    const result = await getAllListedCoinToken(token_key);
    console.log(result?.data, user)
    if (result?.status === 200) {
      setTokenList(result?.data?.data);
    } else {
      showNotification("Error while fetching token list", "error");
    }
  };  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: "#2C393F" }}>
            Send Crypto
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
      {tokenList?.length >= 1 && <SendCryptoContent tokenList={tokenList} />}
      {tokenList?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: 350,
              justifyContent: "center", 
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
      </DashboardGridContainer>
    </>
  );
};

export default SendCrypto;
