import React, { FC, ReactNode, createContext, useState } from "react";
import config from "../utils/config";
import Cookies from "js-cookie";
import { getUserDetails } from "../api/profile";
import {
  AuthContextData,
  AuthProviderProps,
  BaseData,
  UserData,
} from "../interface/useAuth";
import {
  ClearDataFunction,
  LoadDataFunction,
  RefetchDataFunction,
  SetDataFunction,
} from "../types/useAuth";
import { useDetails } from "./useDetails";
import { useNotificationService } from "../components/UIElements/notification";
import { getBaseCurrency } from "../api/settings";
import { mergeCurrencies } from "../utils/useAuth";

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be called within a AuthProvider");
  }
  return context;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [base, setBase] = useState<BaseData | null>(null);

  const set_data: SetDataFunction = async (data) => {
    Cookies.set(config.key.user, JSON?.stringify(data));
    Cookies.set(config.key.token, JSON?.stringify(data.token));
    setUser(data);
    await handleBaseCurrency(data?.token);
    setToken(data?.token);
  };

  const loadData: LoadDataFunction = async () => {
    const tokenCookie = Cookies.get(config.key.token);
    if (tokenCookie) {
      setToken(JSON.parse(tokenCookie));
    }
    const userCookie = Cookies.get(config.key.user);
    if (userCookie) {
      setUser(JSON.parse(userCookie));
    }
    const baseCookie = Cookies.get(config.key.base);
    if (baseCookie) {
      setBase(JSON.parse(baseCookie));
    } else {
      const token_value = tokenCookie ?? "";
      handleBaseCurrency(JSON.parse(token_value));
    }
  };

  const refetchData: RefetchDataFunction = () => {
    let temp = Cookies.get(config.key.token);

    if (temp?.toString() !== "undefined" && temp !== undefined) {
      fetchData(JSON.parse(temp));
    }
    loadData();
  };
  const clear_data: ClearDataFunction = () => {
    Cookies.remove(config.key.user);
    Cookies.remove(config.key.token);
    Cookies.remove(config.key.base);
    setUser(null);
    setBase(null);
    setToken(null);
  };

  const fetchData = async (token: string | undefined) => {
    if (!token) {
      return;
    }
    const result = await getUserDetails(token);
    if (result?.status === "error") {
      setUser(null);
      setToken(null);
    } else if (result?.status === 200) {
      Cookies.set(config.key.user, JSON.stringify(result?.data?.data));
      setUser(result?.data?.data);
    }
  };

  // handle base
  const handleBaseCurrency = async (token: string) => {
    // fetch base value
    const resultBase = await getBaseCurrency(token);
    if (resultBase?.status === "error") {
      return;
    }
    const result = await getUserDetails(token);
    if (result?.status === 200) {
      if (result?.data?.data?.is_base_currency_set) {
        //check if base currency is set and merge the data
        const baseData = await mergeCurrencies(
          result?.data?.data?.current_base_currency,
          resultBase?.data?.data
        );
        Cookies.set(config.key.base, JSON.stringify(baseData));
        setBase(baseData);
      } else {
        setBase(null);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        set_data,
        token,
        base,
        user,
        loadData,
        refetchData,
        clear_data,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
