import * as React from "react";
import Switch from "@mui/material/Switch";
import { useTheme } from "@mui/material/styles";
import { Box, InputLabel, Stack, Typography } from "@mui/material";

interface SwitchInputProps {
  checked: boolean;
}
export function SwitchInput({ checked }: SwitchInputProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setChecked(event.target.checked);
    console.log(checked);
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}

interface SwitchContainerProps {
  label: string;
  text: string;
  value: boolean;
  onChange: () => void;
}

export const SwitchContainer = ({
  label,
  text,
  value,
  onChange,
}: SwitchContainerProps) => {
  const theme = useTheme();
  return (
    <Stack sx={{}}>
      <InputLabel
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "31.72px",
        }}
      >
        {label}
      </InputLabel>
      <Box
        sx={{
          color: theme.palette.primary.main,
          marginBottom: 3,
          gap: "4px",
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
          paddingLeft: "14px",
          height: "3rem",
          border: `1px solid ${theme.palette.secondary.light}`,
          cursor: "pointer",
          background: theme.palette.common.white,
          justifyContent: "space-between",
          "&:hover": {
            border: `1px solid ${theme.palette.common.black}`,
          },
        }}
      >
        <Typography
          sx={{
            color: theme.palette.common.black,
            fontSize: "13px",
            fontWeight: 500,
            lineHeight: "15.64px",
          }}
        >
          {text}
        </Typography>
        <SwitchInput checked={value} />
      </Box>
    </Stack>
  );
};
