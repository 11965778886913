import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {  TableInfoData, TransactionPropType } from "../../data/transactionData";

const headerSty = {
  color: "#B8B8B8",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
};
const textSty = {
  color: "#000000",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  textAlign: "left",
};

export const TransactionsTable = ({ data, handleClick }: TransactionPropType) => {
  const theme = useTheme();

  function transactionType(value: string) {
    let icon = "";
    if (value === "SWAP") {
      icon = "swap-icon.svg";
    } else if (value === "CREDIT") {
      icon = "credit-icon.svg";
    } else {
      icon = "debit-icon.svg";
    }
    return icon;
  }

  // Parse the timestamp string into a Date object

  function parseDate(date: string) {
    const timestamp = new Date(date);

    // Format the Date object into the desired output format
    const formattedDate = timestamp.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  }

  return (
    <>
      <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            boxShadow: "none",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={headerSty}></TableCell>
              <TableCell sx={headerSty}>Type</TableCell>
              <TableCell sx={headerSty}>Description </TableCell>
              <TableCell sx={headerSty}>Date</TableCell>
              <TableCell sx={headerSty}>Status</TableCell>
              <TableCell sx={headerSty}>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: TableInfoData, id: number) => (
              <TableRow
                onClick={() => handleClick(row)}
                key={id}
                sx={{
                  cursor: "pointer",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell sx={{}} component="th" scope="row">
                  <Stack direction={"row"} alignItems={"cente"}>
                    <img
                      src={`/assets/icons/${transactionType(row?.mode)}`}
                      alt=""
                      width={34}
                      height={34}
                    />
                  </Stack>
                </TableCell>
                <TableCell
                  sx={{ ...textSty, color: theme.palette.primary.main }}
                >
                  {row?.type?.toUpperCase()}
                </TableCell>
                <TableCell sx={textSty}>{row?.description}</TableCell>
                <TableCell sx={textSty}>{parseDate(row?.updated_at)}</TableCell>

                <TableCell sx={textSty}>
                  <span
                    style={{
                      background: theme.palette.primary.light,
                      color: theme.palette.primary.main,
                      padding: "9px 16px",
                      borderRadius: "100px",
                    }}
                  >
                    {row?.status}
                  </span>
                </TableCell>
                <TableCell sx={{ ...textSty, fontWeight: 700 }}>
                  {row?.amount_paid}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data.length === 0 && (
        <Box
          padding={2}
          display="flex"
          flexDirection="column"
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.common.black,
              textAlign: "center",
              fontSize: "17px",
              my: 2,
              fontWeight: 600,
            }}
          >
            No transactions record
          </Typography>
        </Box>
      )}
    </>
  );
};
