import { useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Divider,
  MenuItem,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { VisibilityOff } from "@mui/icons-material";
import { Theme, useTheme } from "@mui/material/styles";

import {
  FullnamePropType,
  InputAttributeType,
  InputPropType,
  InputValidateNamePropType,
  InputValidatePropType,
  PhonenumberPropType,
  SelectAttributeType,
} from "../../interface/input";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { CirclelockIcon, ProfileIcon, ReferralInputIcon } from "./svgIcons";
import { codes } from "country-calling-code";
import Referral from "../../pages/referral";

const styles = {
  input: {
    padding: "15px",
    paddingLeft: "4px",
    fontSize: "14px",
    fontFamily: "Outfit",
    fontWeight: 400,
    borderRadius: "10px",
    color: "#292925",
  },
};
const inputSty = {
  border: "none",
  outline: "none",
  background: "white",
  height: "2.5rem",
  borderRadius: "10px",
  color: "#292925",
  fontSize: "13px",
  padding: "0 14px",
};

const EmailInput = ({
  errors,
  register,
  placeholder,
}: InputValidatePropType) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        sx={{ color: "#292925" }}
        placeholder={placeholder ?? "Enter your email address or phone number"}
        type="email"
        autoComplete="username"
        error={!!errors.email}
        helperText={errors.email ? errors.email.message : ""} // Render error message only when there's an error
        {...register("email")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon
                style={{
                  width: "24px",
                  height: "24px",
                  color: !!errors.email
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                }}
              />
            </InputAdornment>
          ),
          inputProps: {
            style: styles.input,
          },
        }}
      ></TextField>
    </Stack>
  );
};
const ReferralInput = ({
  placeholder,
  register,
  errors,
}: InputValidatePropType) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        {...register("referral_id")}
        sx={{ color: "#292925" }}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ReferralInputIcon />
            </InputAdornment>
          ),
          inputProps: {
            style: styles.input,
          },
        }}
      ></TextField>
    </Stack>
  );
};
const OTPInput = ({ placeholder, register, errors }: InputValidatePropType) => {
  const theme = useTheme();
  console.log(errors);

  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        error={!!errors.code}
        helperText={errors.code ? errors.code.message : ""} // Render error message only when there's an error
        {...register("code")}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CirclelockIcon
                style={{
                  color: !!errors.code
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                }}
              />
            </InputAdornment>
          ),
          inputProps: {
            style: styles.input,
          },
        }}
      ></TextField>
    </Stack>
  );
};
const NameInput = ({ placeholder, value, onChange }: InputAttributeType) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        placeholder={placeholder ?? ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleIcon
                style={{
                  width: "30px",
                  height: "30px",
                  color: theme.palette.primary.main,
                }}
              />
            </InputAdornment>
          ),
          inputProps: {
            style: styles.input,
          },
        }}
      ></TextField>
    </Stack>
  );
};
const FullNameInput = ({ errors, register }: InputValidateNamePropType) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  console.log(!!errors);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const getBorderColor = (errors: any, isFocused: boolean, theme: Theme) => {
    if (errors?.first_name || errors?.last_name) {
      return `1px solid ${theme.palette.error.main}`;
    } else if (isFocused) {
      return `2px solid ${theme.palette.primary.main}`;
    } else {
      return `1px solid ${theme.palette.secondary.light}`;
    }
  };

  return (
    <Box
      sx={{
        color:
          !!errors?.first_name || !!errors?.last_name
            ? theme.palette.error.main
            : theme.palette.primary.main,
        marginBottom: 3,
        gap: "4px",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        paddingLeft: "14px",
        height: "3rem",
        border: getBorderColor(errors, isFocused, theme),
        cursor: "pointer",
        background: theme.palette.common.white,
        justifyContent: "space-between",
        "&:hover": {
          border: isFocused ? "" : `1px solid ${theme.palette.common.black}`,
        },
      }}
    >
      <ProfileIcon />
      <input
        {...register("first_name")}
        style={{ ...inputSty, width: "35%" }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={"First Name"}
      />
      <Divider
        orientation="vertical"
        sx={{
          background: "white",
          width: "1px",
          height: "20px",
          marginRight: "1rem",
        }}
      />
      <input
        {...register("last_name")}
        style={{ ...inputSty, width: "40%" }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={"Last Name"}
      />
    </Box>
  );
};
const PasswordInput = ({
  errors,
  register,
  placeholder,
}: InputValidatePropType) => {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        error={!!errors.password}
        helperText={errors.password ? errors.password.message : ""} // Render error message only when there's an error
        {...register("password")}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        autoComplete="current-password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon
                style={{
                  color: !!errors.password
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
const ConfirmPasswordInput = ({
  errors,
  register,
  placeholder,
}: InputValidatePropType) => {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        error={!!errors.passwordConfirm}
        helperText={
          errors.passwordConfirm ? errors.passwordConfirm.message : ""
        } // Render error message only when there's an error
        {...register("passwordConfirm")}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon
                style={{
                  color: !!errors.passwordConfirm
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

// 
const PinInput = ({
  errors,
  register,
  placeholder,
}: InputValidatePropType) => {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        error={!!errors.password}
        helperText={errors.password ? errors.password.message : ""} // Render error message only when there's an error
        {...register("password")}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon
                style={{
                  color: !!errors.password
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
const ReEnterPinInput = ({
  errors,
  register,
  placeholder,
}: InputValidatePropType) => {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        error={!!errors.passwordConfirm}
        helperText={
          errors.passwordConfirm ? errors.passwordConfirm.message : ""
        } // Render error message only when there's an error
        {...register("passwordConfirm")}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon
                style={{
                  color: !!errors.passwordConfirm
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};


const PhonenumberInput = ({
  errors,
  register,
  placeholder,
}: InputValidatePropType) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const getBorderColor = (errors: any, isFocused: boolean, theme: Theme) => {
    if (errors?.country_code || errors?.phone_number) {
      return `1px solid ${theme.palette.error.main}`;
    } else if (isFocused) {
      return `2px solid ${theme.palette.primary.main}`;
    } else {
      return `1px solid ${theme.palette.secondary.light}`;
    }
  };

  return (
    <Box
      sx={{
        color:
          !!errors?.first_name || !!errors?.last_name
            ? theme.palette.error.main
            : theme.palette.primary.main,
        marginBottom: 3,
        gap: "4px",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        paddingLeft: "14px",
        height: "3rem",
        border: getBorderColor(errors, isFocused, theme),
        cursor: "pointer",
        background: theme.palette.common.white,
        justifyContent: "space-between",
        "&:hover": {
          border: isFocused ? "" : `1px solid ${theme.palette.common.black}`,
        },
      }}
    >
      <PhoneInTalkIcon />
      <select
        style={{ ...inputSty, width: "80px", color: "#645f5f", padding: 0 }}
        id="countryCode"
        {...register("country_code")}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {codes?.map((val, id) => (
          <option
            style={{ width: "150px" }}
            key={id}
            value={val.countryCodes[0]}
          >
            +{val.countryCodes[0]} {val.isoCode2} {val.country}
          </option>
        ))}
      </select>
      <input
        {...register("phone_number")}
        id="phoneNumber"
        type={"tel"}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ ...inputSty, width: "100%", padding: "0 4px" }}
        placeholder={placeholder}
        maxLength={12}
      />
    </Box>
  );
};
export {
  PasswordInput,
  EmailInput,
  OTPInput,
  ConfirmPasswordInput,
  NameInput,
  FullNameInput,
  ReferralInput,
  PhonenumberInput,
  PinInput, 
  ReEnterPinInput
};
