import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TextContainer } from "../../components/UIElements/textContainer";
import { PageMainTextSummary } from "../../components/pageMainSummary";
import Header from "../../layouts/dashboard/header";
import { PATH_KUMOSTAKING } from "../../routes/path";
import { ButtonContainedLight } from "../../components/UIElements/button";
import useResponsive from "../../hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import { getAllPackages } from "../../api/investments";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../../components/UIElements/notification";
import { PlanDetailPropType } from "../../interface/kumo-stacking";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";
import { Helmet } from "react-helmet-async";

const KumoStacking = () => {
  const isSmall = useResponsive("down", "sm", "xl");
  const theme = useTheme();
  const navigate = useNavigate();
  const { showNotification } = useNotificationService();
  const [planList, setPlanList] = useState<PlanDetailPropType[]>([]);
  const { token } = useAuth();
  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    const token_key = token ?? "";
    const data = {
      query: "",
      item_per_page: "",
      q: "",
      component: "",
    };
    const fetch_plans = await getAllPackages(data, token_key);
    if ("msg" in fetch_plans) {
      console.log("Error fetching currency data:", fetch_plans.msg);
      showNotification(fetch_plans.msg, "error");
    } else if (fetch_plans.status == 200) {
      // arrange data in ascending order
      const fetched_plan_list = fetch_plans?.data?.data?.sort(
        (a: PlanDetailPropType, b: PlanDetailPropType) => {
          const nameA = a.package_name.toUpperCase();
          const nameB = b.package_name.toUpperCase();

          if (nameA < nameB) {
            return -1; // a should come before b
          }
          if (nameA > nameB) {
            return 1; // a should come after b
          }
          return 0; // names are equal
        }
      );
      setPlanList(fetched_plan_list);
    } else {
      showNotification("An error occured while fetching data", "error");
    }
  };

  const handleStackHistory = () => {
    navigate(PATH_KUMOSTAKING.history);
  };
  return (
    <>
      <Helmet>
        <title>Kumo Staking</title>
      </Helmet>
      <Header>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: isSmall ? "column" : "row",
            gap: 3,
            justifyContent:'space-between'
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Kumo Staking
          </Typography>

          <ButtonContainedLight
            sx={{ borderRadius: isSmall ? "10px" : "20px", width: 150 }}
            onclick={handleStackHistory}
          >
            Staking History
          </ButtonContainedLight>
        </Box>
      </Header>
      <Box sx={{ mt: 4 }}>
        <PageMainTextSummary referralCode={false} code="" text="Kumo Staking" />
      </Box>

      <DashboardGridContainer>
        <Box sx={{}}>
          <Typography variant="h4" sx={{ fontWeight: 700, color: "#3E3E3B" }}>
            Select A package
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
          >
            Choose an investment plan that suits you
          </Typography>
          {planList?.map((plan: PlanDetailPropType) => (
            <Box key={plan?._id}>
              <TextContainer path={`${PATH_KUMOSTAKING.root}/${plan._id}`}>
                {" "}
                {plan?.package_name}
              </TextContainer>
            </Box>
          ))}{" "}
          {planList?.length === 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: 200, 
                justifyContent: "center",
              }}
            >
              <LoadingSpinner />{" "}
            </Box>
          )}
        </Box>
      </DashboardGridContainer>
    </>
  );
};

export default KumoStacking;
