import React, { useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import { AlarmIcon } from "../../../components/UIElements/svgIcons";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../../../hooks/useResponsive";
const NotificationContainer = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");


  const handleNotificationClick = () => {
    setOpen(!open); // Toggle the state when the notification icon is clicked
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Box
        onClick={handleNotificationClick}
        sx={{
          borderRadius: "20px",
          width: "42px",
          height: "42px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme.palette.primary.main,
          background: "white",
        }}
      >
        <AlarmIcon />
      </Box>
      {/* Show backdrop if notification container is open */}
      {open && (
        <Box
          sx={{
            position: "absolute",
            top: "50px",
            right:isSmall ? "0px" : "10px",
            background: "#fff",
            border: "1px solid #ccc",
            padding: "14px",
            borderRadius: "14px",
            zIndex: 20,
            minWidth: isSmall ? "220px" : "300px",
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              Notifications
            </Typography>
            <CloseIcon
              onClick={handleNotificationClick}
              sx={{ cursor: "pointer" }}
            />
          </Stack>
        </Box>
      )}
    </div>
  );
};

export default NotificationContainer;
