import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import SwapCoinContents from "../../components/dashboard/SwapCoinContents";
import { getSwapCoinList } from "../../api/settings";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../../components/UIElements/notification";
import { useDetails } from "../../hooks/useDetails";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";

const SwapCoin: React.FC = () => {
  const theme = useTheme();
  const { token } = useAuth();
  const { fetchUserData } = useDetails();
  const { showNotification } = useNotificationService();
  const [swapCoinList, setSwapCoinList] = useState([]);
  const [walletList, setWalletList] = useState<any>([]);
  const [userPin, setUserPin] = useState("");

  const { wallet } = useDetails();
  useEffect(() => {
    fetchCrypto();
  }, []);
  const fetchCrypto = async () => {
    const token_key = token ?? "";
    const result = await getSwapCoinList(token_key);
    const getUserPin = await fetchUserData(token_key);
    setUserPin(getUserPin?.pin ?? "");
    if (!!wallet) {
      const wallet_list =
        Array.isArray(wallet) && wallet.length > 1 ? wallet : [];
      setSwapCoinList(result?.data?.data);
      setWalletList(wallet_list);
    } else {
      showNotification("Error fetching wallet list", "error");
    }
  };
  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Swap coin
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        {swapCoinList?.length >= 1 && (
          <SwapCoinContents userPin={userPin}
            swapCoinList={swapCoinList}
            walletList={walletList}
          />
        )}
        {swapCoinList?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: 350, 
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
      </DashboardGridContainer>
    </>
  );
};

export default SwapCoin;
