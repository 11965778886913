import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import AssetsContent from "../../components/dashboard/AssetsContent";
import { useAuth } from "../../hooks/useAuth";
import { getAllCurrency } from "../../api/settings";
import { getCurrencyConversion } from "../../api/users";
import { WrongLocationSharp } from "@mui/icons-material";
import { useNotificationService } from "../../components/UIElements/notification";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";

const Assets = () => {
  const theme = useTheme();
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [assetList, setAssetList] = useState<any[]>([]);
  const { showNotification } = useNotificationService();
  useEffect(() => {
    fetchCurrencyData();
  }, []);

  // fetch currency and balance
  const fetchCurrencyData = async () => {
    const token_key = token ?? "";
    setIsLoading(true);
    const resultOne = await getAllCurrency(token_key);
    const resultTwo = await getCurrencyConversion(token_key);
    console.log(resultOne?.data?.data, resultTwo);
    if ("msg" in resultOne) {
      console.log("Error fetching currency data:", resultOne.msg);
      showNotification(resultOne.msg, "error");
      setIsLoading(false);
    } else if ("msg" in resultTwo) {
      console.log("Error fetching currency conversion:", resultTwo.msg);
      showNotification(resultTwo.msg, "error");
      setIsLoading(false);
    } else if (resultOne?.status === 200 && resultTwo?.status === 200) {
      const assetData = combineData(
        resultOne?.data?.data,
        resultTwo?.data?.data
      );
      setAssetList(assetData);
      setIsLoading(false);
    } else {
      showNotification("Something went wrong, kindly try again", "error");
      setIsLoading(false);
    }
  };

  // combile both result
  const combineData = (arr1: any[], obj2: any) => {
    return arr1.map((item1) => {
      // Find matching item in obj2 based on name or code
      const matchingItem = obj2[item1.name] || obj2[item1.code];
      return { ...item1, ...matchingItem };
    });
  };

  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            My Asset
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        {assetList?.length > 1 && <AssetsContent assetList={assetList} />}
        {assetList?.length === 0 && !isLoading && (
          <Box  sx={{
            display: "flex",
            alignItems: "center",
            height: 400,
            justifyContent: "center",
          }}>
            <Typography variant="h4">Asset List is Empty</Typography>
          </Box>
        )}
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: 400,
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
      </DashboardGridContainer>
    </>
  );
};

export default Assets;
