import React, { useEffect, useState } from "react";
import {
  CryptoFirstStep,
  CryptoSecondStep,
  CryptoThirdStep,
} from "../../../components/dashboard/fundWallet/CryptoContent";
import {
  TransactionResult,
  TransactionResultFailed,
} from "../../../components/transactionResult";
import { PATH_DASHBOARD } from "../../../routes/path";
import { useNavigate } from "react-router-dom";
import { CryptoStateType } from "../../../interface/dashboard";
import { fundWallet, getWalletAddress } from "../../../api/transaction";
import { useAuth } from "../../../hooks/useAuth";
import { useNotificationService } from "../../../components/UIElements/notification";
import { getAllListedCoinToken } from "../../../api/settings";
import { WalletAddressesType } from "../../../interface/useDetail";
import { parseFormattedNumber } from "../../../utils/basic";

const Crypto = () => {
  const navigate = useNavigate();
  const { base, token } = useAuth();
  const { showNotification } = useNotificationService();
  const [coinToken, setCoinToken] = useState<{
    walletAddressList: any[];
    coin_list: any[];
  }>({
    walletAddressList: [],
    coin_list: [],
  });
  const [cryptoDetails, setCryptoDetails] = useState<CryptoStateType>({
    amount: "",
    crypto: "",
    cryptoWallet: {} as WalletAddressesType,
  });
  const [modalContainer, setModalContainer] = useState({
    firstInfo: true,
    secondInfo: false,
    thirdInfo: false,
    fourthInfo: false,
    failed: false,
  });

  useEffect(() => {
    fetchWalledAddress();
  }, []);

  const fetchWalledAddress = async () => {
    const token_key = token ?? "";
    const walletResult = await getWalletAddress(token_key);
    const result = await getAllListedCoinToken(token_key);
    if ("msg" in result) {
      showNotification(result.msg, "error");
    } else if (result.status == 200) {
      if ("msg" in walletResult) {
        showNotification(walletResult.msg, "error");
        return;
      }
      setCoinToken({
        walletAddressList:
          walletResult?.data?.data?.length > 0 ? walletResult?.data?.data : [],
        coin_list: result?.data?.data,
      });
    } else {
      showNotification("An error occured while fetching data", "error");
    }
  };

  const handleSecondInfo = () =>
    setModalContainer((prev) => ({ ...prev, secondInfo: !prev.secondInfo }));
  const handleThirdInfo = () => {
    if(!!cryptoDetails?.cryptoWallet) {
      showNotification("Wallet Address does not exist", "error");
      navigate(PATH_DASHBOARD.root)
      return;
    }
    setModalContainer((prev) => ({ ...prev, thirdInfo: !prev.thirdInfo }));}
  const handleFourthInfo = () =>
    setModalContainer((prev) => ({ ...prev, fourthInfo: !prev.fourthInfo }));

  const handleSubmitFunds = async () => {
    const token_key = token ?? "";
    showNotification("Saving your funding details", "info");
    const data = {
      payment_method: "crypto",
      naira_amount_paid: parseFormattedNumber(
        cryptoDetails?.amount?.toString()
      ),
      method: "crypto",
      currency_name: base?.name,
    };
    const submit_result = await fundWallet(data, token_key);
    if ("msg" in submit_result) {
      showNotification(submit_result.msg, "error");
    } else if (submit_result.status == 200) {
      setModalContainer((prev) => ({ ...prev, fourthInfo: true }));
    } else {
      showNotification("An error occured while fetching data", "error");
      setModalContainer((prev) => ({ ...prev, failed: true }));
    }
  };
  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      firstInfo: false,
      secondInfo: false,
      thirdInfo: false,
      fourthInfo: false,
    }));
  };
  const navigateHome = () => {
    navigate(PATH_DASHBOARD.fundWallet);
  };
  return (
    <>
      <CryptoFirstStep
        open={modalContainer.firstInfo}
        onclick={handleSecondInfo}
        navigate={navigateHome}
        onclose={closeAllModal}
        cryptoDetails={cryptoDetails}
        setCryptoDetails={setCryptoDetails}
      />
      <CryptoSecondStep
        open={modalContainer.secondInfo}
        onclick={handleThirdInfo}
        navigate={navigateHome}
        coinList={coinToken?.coin_list}
        walletList={coinToken?.walletAddressList}
        onclose={closeAllModal}
        setCryptoDetails={setCryptoDetails}
      />
      <CryptoThirdStep
        open={modalContainer.thirdInfo}
        onclick={handleFourthInfo}
        navigate={navigateHome}
        cryptoDetails={cryptoDetails}
        onclose={closeAllModal}
        submit={handleSubmitFunds}
      />
      <TransactionResult
        txId={""}
        onclose={navigateHome}
        openResult={modalContainer.fourthInfo}
      >
        {""}
      </TransactionResult>
      <TransactionResultFailed
        onClose={navigateHome}
        openResult={modalContainer?.failed}
        reason={"Transaction Failed"}
      />
    </>
  );
};

export default Crypto;
