import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { Box, Typography } from "@mui/material";
import Header from "../../layouts/dashboard/header";
import { useAuth } from "../../hooks/useAuth";
import { getSinglePackage } from "../../api/investments";
import { useNotificationService } from "../../components/UIElements/notification";
import { PlanDetailPropType } from "../../interface/kumo-stacking";
import { useLocation } from "react-router-dom";
import { hasValues } from "../../utils/basic";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";
import PlanDetailsContent from "../../components/kumo-stacking/PlanDetailsContent";

const PlanDetails = () => {
  const { showNotification } = useNotificationService();
  const [planDetails, setPlanDetails] = useState<PlanDetailPropType>(
    {} as PlanDetailPropType
  );
  const { token } = useAuth();
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname.split("/");
    const planId = path[path?.length - 1];
    if (planId?.length > 6) {
      fetchPlanInfo(planId);
    }
  }, []);

  const fetchPlanInfo = async (id: string) => {
    const token_key = token ?? "";
    const fetch_plan = await getSinglePackage(id, token_key);
    if ("msg" in fetch_plan) {
      showNotification(fetch_plan.msg, "error");
    } else if (fetch_plan.status == 200) {
      setPlanDetails(fetch_plan?.data?.data);
    } else {
      showNotification("An error occured while fetching data", "error");
    }
  };

  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: "#2C393F" }}>
            {planDetails?.package_name ?? ""}
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        {hasValues(planDetails) ? (
          <PlanDetailsContent planDetails={planDetails} />
        ) : (
          <Box
            sx={{ 
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
      </DashboardGridContainer>
    </>
  );
};

export default PlanDetails;
