import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { MenuDropDown } from "./dropDownMenu";
import NotificationContainer from "./notification";
import useResponsive from "../../../hooks/useResponsive";
import { SmallScreenDrawer } from "../drawer";

const Header: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const isMedium = useResponsive("down", "md", "xl");
  const isDesktop = useResponsive("down", "lg", "xl");

  const handleModal = () => setOpen(!open);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: isMedium ? "4px" : "10px",
          alignItems: isMedium ? "flex-start" : "center",
          paddingX: 2,
        }}
      >
        {children}
        <Stack direction={isMedium ? "column" : "row"}>
          <Stack direction={'row'} alignItems={'center'} >
          <NotificationContainer />
          <MenuDropDown handleModal={handleModal} />
          </Stack>
          {isDesktop && <SmallScreenDrawer />}
        </Stack>
      </Box>
    </>
  );
};

export default Header;
