import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { PATH_TRANSACTION } from "../../routes/path";
import { transactionData } from "../../data/transactionData";
import { getAllTransactions } from "../../api/transaction";
import { useAuth } from "../../hooks/useAuth";
import LoadingSpinner from "../UIElements/loadingSpinner";

const TransactionInformation = () => {
  const theme = useTheme();
  const [tableData, setTableData] = useState<any[]>([]);
  const { token } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const query = {
        page: currentPage.toString(),
        item_per_page: "10",
        q: "",
        component: "",
      };
      // Fetch data for the current page
      const token_key = token ?? "";
      const result = await getAllTransactions(query, token_key);
      setTableData(result?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //

  function parseDate(date: string) {
    const timestamp = new Date(date);

    // Format the Date object into the desired output format
    const formattedDate = timestamp.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  }

  return (
    <Box
      sx={{
        padding: "1rem",
        background: theme.palette.common.white,
        minHeight: 500,
        borderRadius: "20px",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.common.black,
            fontWeight: 700,
          }}
        >
          Transaction
        </Typography>
        <Link
          style={{
            textDecorationLine: "none",
            color: theme.palette.primary.main,
          }}
          to={PATH_TRANSACTION.root}
        >
          <Typography variant="body2">View All</Typography>
        </Link>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ my: 2 }}>
        {/* <Typography variant="body2" sx={{color: theme.palette.text.secondary, fontWeight: 500}}>Today</Typography> */}
        {tableData?.map((row, id) => (
          <Box key={id}>
            <InfoBox
              type={row?.type?.toUpperCase()}
              date={parseDate(row?.updated_at)}
              amount={row?.amount_paid}
              mode={row?.mode}
            />
          </Box>
        ))}
        {!isLoading && tableData?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              minHeight: 400,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" color={"primary"} sx={{ my: "auto" }}>
              No Transaction Found
            </Typography>
          </Box>
        )}
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              minHeight: 400,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
      </Box>
      <Divider />
      {/* <Box sx={{my: 2}}>
        <Typography variant="body2" sx={{color: theme.palette.text.secondary, fontWeight: 500}}>Yesterday</Typography>
        <InfoBox type={transactionData[2].type} amount={transactionData[2].amount} desc={transactionData[2].desc} date={transactionData[2].date}/>
        <InfoBox type={transactionData[3].type} amount={transactionData[3].amount} desc={transactionData[3].desc} date={transactionData[3].date}/>
        <InfoBox type={transactionData[4].type} amount={transactionData[4].amount} desc={transactionData[4].desc} date={transactionData[4].date}/>
        <InfoBox type={transactionData[5].type} amount={transactionData[5].amount} desc={transactionData[5].desc} date={transactionData[5].date}/>
        <InfoBox type={transactionData[6].type} amount={transactionData[6].amount} desc={transactionData[6].desc} date={transactionData[6].date}/>
        <InfoBox type={transactionData[7].type} amount={transactionData[7].amount} desc={transactionData[7].desc} date={transactionData[7].date}/>

      </Box> */}
    </Box>
  );
};

export default TransactionInformation;

const InfoBox: React.FC<{
  type: string;
  mode: string;
  date: string;
  amount: string;
}> = ({ type = "debit", date, amount, mode }) => {
  //  best to use a function to arrange the data

  const theme = useTheme();

  function transactionType(value: string) {
    let icon = "";
    if (value === "SWAP") {
      icon = "swap-icon.svg";
    } else if (value === "CREDIT") {
      icon = "credit-icon.svg";
    } else {
      icon = "debit-icon.svg";
    }
    return icon;
  }

  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ color: theme.palette.common.black, my: 2 }}
    >
      <Stack direction={"row"} alignItems={"center"} sx={{width: "65%"}}>
        <img
          src={`/assets/icons/${transactionType(mode)}`}
          alt=""
          width={28}
          height={28}
        />
        <Stack sx={{ marginLeft: 2,  }}>
          <Typography variant="body2">{type}</Typography>
          <Typography
            variant="caption"
            sx={{ color: theme.palette.secondary.main }}
          >
            {date}
          </Typography>
        </Stack>
      </Stack>
      <Typography sx={{ fontWeight: 700, fontSize: "13px" }}>
        {amount}
      </Typography>
    </Stack>
  );
};
