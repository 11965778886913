import { Box, Typography, Stack } from "@mui/material";
import React, { FC, useEffect, useReducer, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { ProfileSetting } from "./ProfileSetting";
import { AccountSetting } from "./AccountSetting";
import { ButtonContainedMain } from "../UIElements/button";
import { InputState, ProfileContentProps } from "../../interface/profile";
import { InputAction } from "../../types/profile";
import { useAuth } from "../../hooks/useAuth";
import { useDetails } from "../../hooks/useDetails";
import { TransactionChangePin, TransactionCreatePin } from "./TransactionPin";
import { PasswordModal } from "../passwordModal";
import { OTPModal } from "../otpModal";
import { setPin } from "../../api/users";
import { useNotificationService } from "../UIElements/notification";
import { checkPinCode, requestPinUpdate, updatePin } from "../../api/profile";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../../routes/path";
import useResponsive from "../../hooks/useResponsive";
//  useReducer setup
const inputReducer = (state: any, action: any) => {
  switch (action.type) {
    case "HANDLE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "HANDLE_SELECT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    default:
      return state;
  }
};

const ProfileContent: FC<ProfileContentProps> = ({ userData }) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xl");
  const [setting, setSettings] = useState("profile");
  const { userDetail } = useDetails();
  const { showNotification } = useNotificationService();
  const { user, token } = useAuth();
  const navigate = useNavigate();
  const [modalContainer, setModalContainer] = useState({
    createPin: false,
    createPinModal: false,
    changePin: false,
    changePinModal: false,
    otpModal: false,
    pin_code: 1,
  });
  const [inputState, dispatch] = useReducer(inputReducer, {
    first_name: userData?.first_name ?? "",
    last_name: userData?.last_name ?? "",
    phone_number: userData?.phone_number ?? "",
    language: userData?.language ?? "",
    bvn: userData?.bvn ?? "",
    is_verified: userData?.is_verified ?? "",
  });

  const handleSetting = (value: string) => {
    setSettings(value);
  };

  // const changeHandler = (e: Event) => {
  //   dispatch({
  //     type: 'HANDLE_INPUT',
  //     field: e.target.name,
  //     payload: e.target.value,
  //   });
  // };
  // Define the action creator functions

  const handleInput = (
    field: keyof InputState,
    payload: string
  ): InputAction => ({
    type: "HANDLE_INPUT",
    field,
    payload,
  });

  const handleSelect = (
    field: keyof InputState,
    payload: string
  ): InputAction => ({
    type: "HANDLE_SELECT",
    field,
    payload,
  });
  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const actionCreator =
      e.target.name === "selectedOption" ? handleSelect : handleInput;
    dispatch(actionCreator(e.target.name as keyof InputState, e.target.value));
  };

  // modal state handlers
  const handleCreatePin = () => {
    setModalContainer((prev) => ({ ...prev, createPin: true }));
  };
  const handleChangePin = async (otp: string[]) => {
    const token_key = token ?? "";
    const pinCode = otp.join("");
    const check_pin_code = await checkPinCode(pinCode, token_key);
    if ("msg" in check_pin_code) {
      showNotification(check_pin_code.msg, "error");
    } else if (check_pin_code.status == 200) {
      setModalContainer((prev) => ({
        ...prev,
        otpModal: false,
        changePin: true,
        pin_code: Number(pinCode),
      }));
    } else {
      showNotification("An error occured while verifying pin code", "error");
    }
  };

  const handleCreatePinModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");
    // sent create pin request
    const create_pin = await setPin(pin_value, token_key);
    if ("msg" in create_pin) {
      showNotification(create_pin.msg, "error");
    } else if (create_pin.status == 200) {
      setModalContainer((prev) => ({
        ...prev,
        createPin: false,
        createPinModal: true,
      }));
      showNotification("Pin set success", "success");
    } else {
      showNotification("An error occured while setting pin", "error");
    }
  };
  const handleChangePinModal = () => {
    setModalContainer((prev) => ({ ...prev, changePinModal: true }));
  };
  const handleOTP = async () => {
    const token_key = token ?? "";
    const create_pin = await requestPinUpdate(token_key);

    if ("msg" in create_pin) {
      showNotification(create_pin.msg, "error");
    } else if (create_pin.status == 200) {
      setModalContainer((prev) => ({ ...prev, otpModal: true }));
      showNotification("Request has been sent to Admin", "info");
    } else {
      showNotification("An error occured while sending otp", "error");
    }
  };

  const naviagteHomePage = () => {
    navigate(PATH_DASHBOARD.root);
  };

  //

  const handleUpdatePin = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");
    const data = {
      pin: pin_value,
      pin_code: modalContainer?.pin_code?.toString(),
      user_id: user?.user_id ?? "",
    };
    const update_pin = await updatePin(data, token_key);
    if ("msg" in update_pin) {
      showNotification(update_pin.msg, "error");
    } else if (update_pin.status == 200) {
      showNotification("Pin update success", "success");
      naviagteHomePage();
    } else {
      showNotification("An error occured while updating pin", "error");
    }
  };
  //
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      pinModal: false,
      createPinModal: false,
      changePinModal: false,
      otpModal: false,
      createPin: false,
      changePin: false,
      pin_code: 1,
    }));
  };
  return (
    <>
      <TransactionCreatePin
        open={modalContainer?.createPinModal}
        onClose={closeAllModal}
        onclick={naviagteHomePage}
      />
      <TransactionChangePin
        open={modalContainer?.changePinModal}
        onClose={closeAllModal}
        onclick={handleOTP}
      />
      <OTPModal
        open={modalContainer?.otpModal}
        onClose={closeAllModal}
        onclick={handleChangePin}
      />

      <PasswordModal
        open={modalContainer?.createPin}
        onClose={closeAllModal}
        onclick={handleCreatePinModal}
      />
      <PasswordModal
        open={modalContainer?.changePin}
        onClose={closeAllModal}
        onclick={handleUpdatePin}
      />
      <Box sx={{ mt: 3 }}>
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{
            background: theme.palette.primary.light,
            padding: 2,
            gap: "10px",
            borderRadius: "20px",
            width: "280px",
          }}
        >
          <Stack
            onClick={() => handleSetting("profile")}
            sx={{
              padding: 2,
              paddingX: 3,
              cursor: "pointer",
              color:
                setting === "profile"
                  ? theme.palette.common.white
                  : theme.palette.primary.main,
              borderRadius: "12px",
              background:
                setting === "profile" ? theme.palette.primary.main : "",
            }}
          >
            <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
              Profile setting
            </Typography>
          </Stack>
          <Stack
            onClick={() => handleSetting("account")}
            sx={{
              padding: 2,
              paddingX: 3,
              cursor: "pointer",
              color:
                setting === "account"
                  ? theme.palette.common.white
                  : theme.palette.primary.main,
              borderRadius: "12px",
              background:
                setting === "account" ? theme.palette.primary.main : "",
            }}
          >
            <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
              Account setting
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ padding: 2, my: 3 }}>
          {setting === "profile" && (
            <ProfileSetting
              changeHandler={changeHandler}
              inputState={inputState}
              nextSection={handleSetting}
            />
          )}
          {setting === "account" && (
            <AccountSetting
              changeHandler={changeHandler}
              inputState={inputState}
            />
          )}
        </Box>
        {setting === "profile" && (
          <Stack
            direction="column"
            sx={{
              gap: "20px",
              width: isMedium ? "94%" : "470px",
              padding: 2,
              my: 2,
              mx: "auto",
            }}
          >
            {!!userData?.pin ? (
              <ButtonContainedMain
                onclick={handleChangePinModal}
                sx={{ width: "100%" }}
              >
                Change Pin
              </ButtonContainedMain>
            ) : (
              <ButtonContainedMain
                onclick={handleCreatePin}
                sx={{ width: "100%" }}
              >
                Create Pin
              </ButtonContainedMain>
            )}
          </Stack>
        )}
      </Box>
    </>
  );
};

export default ProfileContent;
