import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import CableTvContent from "../../components/utilities/CableTv";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";
import { WalletBalanceType } from "../../interface/useDetail";
import { getUtilitiesList } from "../../api/utilities";
import { useDetails } from "../../hooks/useDetails";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../../components/UIElements/notification";
import { getFeeCost } from "../../api/settings";

const CableTv: React.FC = () => {
  const theme = useTheme();
  const { showNotification } = useNotificationService();
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [costBalance, setCostBalance] = useState<{
    baseWallet: WalletBalanceType;
    fee_cost: string;
  }>({
    baseWallet: {} as WalletBalanceType,
    fee_cost: "",
  });
  const { base, token } = useAuth();
  const { wallet, fetchUserData } = useDetails();

  useEffect(() => {
    fetchUtilities();
  }, []);

  const fetchUtilities = async () => {
    const token_key = token ?? "";
    setIsLoading(true);
    const fetch_data = await getUtilitiesList("tv-subscription", token_key);

    // const fetch_cost = await getFeeCost("CABLE_TV_FEE", token_key);
    if ("msg" in fetch_data) {
      showNotification(fetch_data.msg, "error");
      setIsLoading(false);
    } else if (fetch_data.status == 200) {
      setData(fetch_data?.data?.data);
      getBaseWallet();
      const user_data = await fetchUserData(token_key);
      const phone_number = user_data?.phone_number ?? "";
      setUserPhoneNumber(phone_number);
      setIsLoading(false);
    } else {
      showNotification("An error occured while fetching data", "error");
      setIsLoading(false);
    }
  };
  // get the value of the base from wallet
  const getBaseWallet = () => {
    const walletBalance = wallet?.find(
      (currency: WalletBalanceType) =>
        currency.code === base?.code || currency.name === base?.name
    );
    setCostBalance((prev) => ({ ...prev, baseWallet: walletBalance }));
  };
  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Cable TV
          </Typography>
        </Box>
      </Header>

      {data?.length > 1 ? (
        <DashboardGridContainer>
          <CableTvContent
            provider_data={data}
            baseWallet={costBalance?.baseWallet}
            userPhoneNumber={userPhoneNumber}
          />
        </DashboardGridContainer>
      ) : isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 350,
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />{" "}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 350,
            justifyContent: "center",
          }}
        >
          <Typography variant="h4">Cable List is Empty</Typography>
        </Box>
      )}
    </>
  );
};

export default CableTv;
