import React from "react";
import ModalContainer from "../../UIElements/modal";
import { Box, Stack, Typography, Divider } from "@mui/material";
import { ButtonContainedMain } from "../../UIElements/button";
import { useTheme } from "@mui/material/styles";

import { CloseIcon } from "../../UIElements/svgIcons";
import { FundWalletInput, FundWalletTextValueContainer } from "./contentStyle";
import { BankStateType } from "../../../interface/dashboard";
import { bankData } from "../../../data/bankData";
import { addCommasToNumbers, parseFormattedNumber } from "../../../utils/basic";
import { useAuth } from "../../../hooks/useAuth";

// ----------------------------------

export const BankTransferFirstStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  open: boolean;
  navigate: () => void;
  setBankDetails: React.Dispatch<React.SetStateAction<BankStateType>>;
  bankDetails: BankStateType;
}> = ({ onclick, onclose, open, navigate, bankDetails, setBankDetails }) => {
  const theme = useTheme();
  const { base } = useAuth();

  const handleNextContent = () => {
    onclose();
    onclick();
  };
  const handleAddComma = () => {
    const plainNumber = bankDetails?.amount || "";
    const formattedNumber = addCommasToNumbers(+plainNumber);
    setBankDetails((prev) => ({ ...prev, amount: formattedNumber }));
  };

  const handleFocus = () => {
    if (bankDetails?.amount) {
      const plainNumber = parseFormattedNumber(bankDetails?.amount?.toString());
      setBankDetails((prev) => ({ ...prev, amount: plainNumber }));
    }
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    const value = e.target.value.replace(/,/g, "");
    setBankDetails((prev) => ({ ...prev, amount: value }));
  };

  return (
    <>
      <ModalContainer
        sx={{}}
        size={"sm"}
        openModal={open}
        fullWidth={true}
        handleModal={() => {}}
      >
        <Box
          sx={{
            background: theme.palette.common.white,
            padding: 4,
            borderRadius: "20px",
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ color: theme.palette.text.primary }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Fund Wallet{" "}
            </Typography>
            <span onClick={navigate} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </span>
          </Stack>
          <Typography variant="body2">
            Enter the amount to add, click “Continue”, and complete payment to
            update your wallet balance
          </Typography>
          <Divider sx={{ my: 3 }} />
          <FundWalletTextValueContainer
            text="You Pay"
            onBlurHandler={handleAddComma}
            onFocusHandler={handleFocus}
            onchange={handleAmountChange}
            currency={base?.code?.toUpperCase()}
            amount={bankDetails?.amount}
            icon={base?.image}
          />
          <Divider sx={{ my: 3 }} />
          <Stack direction={"row"} justifyContent={"center"}>
            <ButtonContainedMain
              onclick={handleNextContent}
              sx={{ width: "150px" }}
            >
              Continue
            </ButtonContainedMain>
          </Stack>
        </Box>
      </ModalContainer>
    </>
  );
};

export const BankTransferSecondStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  open: boolean;
  submit: () => void;
  navigate: () => void;
}> = ({ onclick, onclose, open, navigate, submit }) => {
  const theme = useTheme();
  // const handleNextContent = () => {
  //   onclose();
  //   onclick();
  // };
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ color: theme.palette.text.primary }}
        >
          <Typography>Bank Transfer</Typography>
          <span onClick={navigate} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Typography variant="caption">
          Kidly note that this option is manual and your wallet will be credited
          when value is received fron your bank, Crediting your wallet might
          take upto 5 miniutes - 2 hours depending on bank network, Kindly
          excercise patience while the process is ongoin.
        </Typography>
        <Divider sx={{ my: 3 }} />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            my: 3,
            border: `1px solid  ${theme.palette.secondary.main}`,
            borderRadius: "8px",
            padding: "0.8rem",
          }}
        >
          <Typography variant="body1">Zenith Bank</Typography>
          <span>
            <img
              src="/assets/icons/zenith-icon.jpeg"
              width={18}
              height={18}
              alt=""
            />
          </span>
        </Stack>
        <FundWalletInput value="Kurefunds consult Ltd" label="Account Name" />{" "}
        <FundWalletInput value="1015130375" label="Account Number" />
        <FundWalletInput value="*901*10000*8261543291#" label="USSD code" />
        <Divider sx={{ my: 3 }} />
        <Typography variant="caption">
          ‣ After transferring funds, send a WhatsApp message to{" "}
          <span style={{ fontWeight: 700 }}>07086337628</span>
          with proof of payment, amount sent and Kumo email address to be
          credited
          <br />
          Kindly Note:
        </Typography>
        <ol style={{ fontWeight: 400, fontSize: "12px", fontFamily: "Outfit" }}>
          <li>
            Only transfer funds from an account that matches your verified name
            on your profile.
          </li>
          <li>
            ⁠Funds transferred from an unverified source or account that doesn't
            match your profile may result to loss of funds
          </li>
        </ol>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          sx={{ marginTop: "15px" }}
        >
          <ButtonContainedMain onclick={submit} sx={{ width: "150px" }}>
            Confirm Payment
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};
