import Axios from "../utils/http-request";
import { getRequestConfiguration } from "../utils/headerHelper";
import { AxiosErrorResponse } from "../interface/api_request";

export const getCurrencyConversion = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/users/get-currency-conversion`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};


export const getBalanceCurrencyConversion = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/users/get-base-balance-conversion`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const setUsersCurrentBaseCurrency = async (
  currency_id: string,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `/app/users/set-current-base-currency?currency_id=${currency_id}`;
    return await Axios.put(uri, {}, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const getUserOverallStats = async (userId: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/users/get-user-overall-stats?user_id=${userId}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: errorMessage,
    };
  }
};

export const validateOtp = async (code: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);

    const uri = `/app/users/verify-otp?code=${code}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const requestOtp = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);

    const uri = `/app/users/request-otp`;
    return await Axios.post(uri, {}, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const verifyPin = async (pin: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);

    const uri = `/app/users/verify-pin?pin=${pin}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const setPin = async (pin: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/users/set-pin`;
    const response = await Axios.put(uri, { pin }, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};
