import React, { FC, useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { Box, Stack, Typography } from "@mui/material";
import Header from "../../layouts/dashboard/header";
import { useNotificationService } from "../../components/UIElements/notification";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { getAllInvestments } from "../../api/investments";
import { useTheme } from "@mui/material/styles";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";
import { InvestmentDetailPropType } from "../../interface/kumo-stacking";
import useResponsive from "../../hooks/useResponsive";
import { PATH_KUMOSTAKING } from "../../routes/path";

const StackingHistory = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotificationService();
  const [isLoading, setIsloading] = useState(false);
  const [investmentList, setInvestmentList] = useState<
    InvestmentDetailPropType[]
  >([]);
  const { token, user } = useAuth();
  useEffect(() => {
    fetchInvestments();
  }, []);

  const fetchInvestments = async () => {
    const token_key = token ?? "";
    setIsloading(true);
    const user_id = user?.user_id ?? "";
    const fetch_investments = await getAllInvestments(user_id, token_key);
    if ("msg" in fetch_investments) {
      showNotification(fetch_investments.msg, "error");
    } else if (fetch_investments.status == 200) {
      console.log(fetch_investments);
      setIsloading(false);
      setInvestmentList(fetch_investments?.data?.data);
    } else {
      setIsloading(false);
      showNotification("An error occured while fetching data", "error");
    }
  };
  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: "#2C393F" }}>
            Kumo Staking History
          </Typography>
        </Box>
      </Header>
      {investmentList?.length !== 0 && (
        <DashboardGridContainer>
          {investmentList?.map((invest, id) => (
            <Box key={id}>
              <InvestmentDetailsContainer invest={invest} />
            </Box>
          ))}
        </DashboardGridContainer>
      )}
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 500,
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />{" "}
        </Box>
      )}
      {investmentList?.length === 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 500,
            justifyContent: "center",
          }}
        >
          <Typography variant="h4">No Investment History Found!</Typography>
        </Box>
      )}
    </>
  );
};

export default StackingHistory;

const InvestmentDetailsContainer: FC<{
  invest: InvestmentDetailPropType;
}> = ({ invest }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmall = useResponsive("down", "sm", "xl");
  const handleInvestmentDetails = (id: string) => {
    navigate(`${PATH_KUMOSTAKING.history}/${id}`);
  };
  return (
    <Box
      onClick={(e) => handleInvestmentDetails(invest?._id)}
      sx={{
        padding: 3,
        background: theme.palette.common.white,
        borderRadius: "20px",
        display: "flex",
        flexDirection: isSmall ? "column" : "row",
        justifyContent: "space-between",
        alignItems: isSmall ? "flex-start" : "center",
        my: 3,
        cursor: "pointer",
      }}
    >
      <Stack direction="row" alignItems={"center"} sx={{ gap: "10px" }}>
        <img src="/assets/icons/piggy-icon.svg" width={58} height={58} alt="" />
        <Typography variant="h6" sx={{ fontWeight: 700, marginLeft: 3 }}>
          {invest?.investment_token?.toUpperCase()}
        </Typography>{" "}
        -
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {invest?.investment_amount}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"center"}
        sx={{ gap: "3px", marginLeft: 3 }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: 400, color: theme.palette.common.black }}
        >
          {invest?.investment_duration} months
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 400, color: theme.palette.common.black }}
        >
          ({invest?.investment_status})
        </Typography>
      </Stack>
    </Box>
  );
};
