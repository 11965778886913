import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PaypalFirstStep,
  PaypalSecondStep,
} from "../../../components/dashboard/fundWallet/PaypalContent";
import { TransactionResult } from "../../../components/transactionResult";
import { PATH_DASHBOARD } from "../../../routes/path";
import { useNotificationService } from "../../../components/UIElements/notification";
const Paypal = () => {
  const navigate = useNavigate();
  const {showNotification}  = useNotificationService()
  const [paypalDetails, setPaypalDetails] = useState<{
    amount: string | number;
    paypal_email: string;
  }>({
    amount: "",
    paypal_email: "",
  });
  const [modalContainer, setModalContainer] = useState({
    firstInfo: true,
    secondInfo: false,
    thirdInfo: false,
  });

  const handleSecondInfo = () =>
    setModalContainer((prev) => ({ ...prev, secondInfo: !prev.secondInfo }));
  const handleThirdInfo = () =>{
showNotification("This service is not available", "info")
    // setModalContainer((prev) => ({ ...prev, thirdInfo: !prev.thirdInfo }));
  }
  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      firstInfo: false,
      secondInfo: false,
      thirdInfo: false,
    }));
  };
  const navigateHome = () => {
    navigate(PATH_DASHBOARD.fundWallet);
  };
  return (
    <>
      <PaypalFirstStep
        open={modalContainer.firstInfo}
        onclick={handleSecondInfo}
        onclose={closeAllModal}
        paypalDetails={paypalDetails}
        setPaypalDetails={setPaypalDetails}
        navigate={navigateHome}
      />
      <PaypalSecondStep
        open={modalContainer.secondInfo}
        onclick={handleThirdInfo}
        onclose={closeAllModal}
        navigate={navigateHome}
      />
      {/* <TransactionResult txId={""} onclose={closeAllModal} openResult={modalContainer.thirdInfo}>
        {""}
      </TransactionResult> */}
    </>
  );
};

export default Paypal;
