import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ArrowRightIcon, CloseIcon } from "./UIElements/svgIcons";
import { useTheme } from "@mui/material/styles";
import ModalContainer from "./UIElements/modal";
import useResponsive from "../hooks/useResponsive";
import { ModalPasswordPropType } from "../interface/pin";
import LoadingSpinner from "./UIElements/loadingSpinner";

export const PasswordModal = ({
  open,
  onClose,
  onclick,
}: ModalPasswordPropType) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xl");
  const [inputs, setInputs] = useState(["", "", "", ""]); // State to hold input values
  const [isLoading, setIsLoading] = useState(false);
  const numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, "", 0, ""];

  useEffect(() => {
    // set input to false
    setIsLoading(false);
  }, [open]);

  // Function to update input box on number click
  const updateInput = (index: number, value: string) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
    checkLastInput(newInputs); // Call checkLastInput function after updating input
  };

  // function to get the last index of the array
  const findLastIndex = (arr: string[]) => {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i] !== "") {
        return i;
      }
    }
    return -1; // Return -1 if no non-empty string is found
  };

  // Function to delete last entered input value or clear last non-empty input box
  const deleteLastInput = () => {
    const lastInputIndex = findLastIndex(inputs);

    if (lastInputIndex >= 0) {
      const lastInputValue = inputs[lastInputIndex];
      if (!isNaN(parseFloat(lastInputValue))) {
        // If last entered value is numeric, delete it
        const newInputs = [...inputs];
        newInputs[lastInputIndex] = "";
        setInputs(newInputs);
      } else {
        // If last entered value is non-numeric, clear last non-empty input box
        const newInputs = [...inputs];
        newInputs[lastInputIndex] = "";
        setInputs(newInputs);
      }
    }
  };

  // Function to handle key press events
  const handleKeyPress = (event: any) => {
    const { key } = event;
    if (key === "Backspace") {
      deleteLastInput();
      return;
    }
    const activeInputIndex = inputs.findIndex((input) => input === "");
    if (activeInputIndex !== -1 && !isNaN(key)) {
      updateInput(activeInputIndex, key);
    }
  };

  // Function to check if the last input has been entered
  const checkLastInput = (inputValue: string[]) => {
    if (inputValue.every((input) => input !== "")) {
      onclick(inputValue);
      setIsLoading(true);
      setInputs(["", "", "", ""]);
    }
  };

  // handle close modal
  const handleCloseModal = () => {
    onClose();
    setInputs(["", "", "", ""]);
  };
  return (
    <ModalContainer
      sx={{}}
      openModal={open}
      handleModal={() => {}}
      size="sm"
      fullWidth={true}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: "20px",
          padding: isMedium ? 3 : 4,
        }}
      >
        <Stack direction="row" justifyContent={"space-between"} sx={{ my: 2 }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary, fontWeight: 500 }}
          >
            Enter Transaction Pin
          </Typography>
          <span style={{ cursor: "pointer" }} onClick={handleCloseModal}>
            <CloseIcon />
          </span>
        </Stack>
        <Box>
          <div onKeyDown={handleKeyPress} tabIndex={0}>
            {/* Render input boxes */}
            {!isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: isMedium ? "5px" : "15px",
                  my: 3,
                }}
              >
                {inputs.map((input, index) => (
                  <Box key={index} sx={{}}>
                    <input
                      type="text"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "10px",
                        fontWeight: 600,
                        fontSize: "22px",
                        textAlign: "center",
                        color: theme.palette.primary.main,
                        background: theme.palette.background.default,
                        outline: "none",
                        fontFamily: "Outfit",
                        border: `1px solid #6E25CC80`,
                      }}
                      value={input}
                      readOnly
                    />
                  </Box>
                ))}
              </Box>
            )}
            {isLoading && (
              <Stack direction={"row"} justifyContent={"center"}>
                {" "}
                <LoadingSpinner />
              </Stack>
            )}

            {/* Render number keys */}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: isMedium ? "10px" : "20px",

                  justifyContent: "center",
                }}
              >
                {numberArray?.map((number, index) => (
                  <Box key={index} sx={{ width: "30%" }}>
                    <button
                      style={{
                        cursor:
                          index === numberArray.length - 3 ? "none" : "pointer",
                        color: theme.palette.primary.main,
                        margin: "4px auto",
                        fontFamily: "Outfit",
                        fontWeight: 500,
                        fontSize: "22px",
                        background: "#D9D9D94D",
                        width: "100%",
                        height: "50px",
                        borderRadius: "10px",
                        outline: "none",
                        border: "0",
                      }}
                      onClick={() => {
                        if (index === numberArray.length - 1) {
                          deleteLastInput();
                          return;
                        }
                        const activeInputIndex = inputs.findIndex(
                          (input) => input === ""
                        );
                        if (activeInputIndex !== -1) {
                          updateInput(activeInputIndex, number.toString());
                        }
                      }}
                    >
                      {index === numberArray.length - 1 ? (
                        <ArrowRightIcon />
                      ) : (
                        number
                      )}
                    </button>
                  </Box>
                ))}
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
    </ModalContainer>
  );
};
