import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Breakpoint } from "@mui/material/styles";
import { CSSProperties } from '@mui/styles';

interface ModalPropsType {
  sx: CSSProperties;
  size: Breakpoint;
  openModal: boolean;
  fullWidth: boolean;
  handleModal: () => void;
  children: React.ReactNode;
}

export default function ModalContainer({
  sx,
  size = "sm",
  openModal,
  fullWidth = false,
  handleModal,
  children,
}: ModalPropsType) {
  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        sx={{ borderRadius: "20px", ...sx }}
        maxWidth={size}
        open={openModal}
        scroll="paper"
        onClose={handleModal}
      >
        {children}
      </Dialog>
    </React.Fragment>
  );
}
