import React, { FC, useEffect, useState } from "react";
import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Iconwallet } from "../UIElements/svgIcons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useResponsive from "../../hooks/useResponsive";
import { useAuth } from "../../hooks/useAuth";
import { getUserOverallStats } from "../../api/users";
import { useNotificationService } from "../UIElements/notification";
import { AccountInfoProp } from "../../interface/dashboard";
import { addCommasToNumbers, formatNumberWithCommas, hasValues, parseFormattedNumber } from "../../utils/basic";
import { useDetails } from "../../hooks/useDetails";
import { WalletBalanceType } from "../../interface/useDetail";

const AccountInfo: React.FC = () => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xl");
  const { base, user, token } = useAuth();
  const { wallet, fetchUserData } = useDetails();
  const { showNotification } = useNotificationService();
  const [data, setData] = useState<AccountInfoProp>();
  const [balance, setBalance] = useState(true);

  useEffect(() => {
    fetchBalance();
    setTimeout(checkBaseValue, 3000);
  }, []);
  const fetchBalance = async () => {
    const token_key = token ?? "";
    const userId = user?.user_id ?? "";
    const result = await getUserOverallStats(userId, token_key);
    if ("msg" in result) {
      console.log("Error fetching currency data:", result.msg);
      showNotification(result.message, "error");
    } else if (result.status == 201) {
      setData(result?.data?.data);
    } else {
      showNotification("An error occured while fetching data", "error");
    }
  };

  const checkBaseValue = async () => {
    const token_key = token ?? "";
    const data = await fetchUserData(token_key);
    if (!!data) {
      if (!data?.is_base_currency_set) {
        showNotification("Kindly set base currency value", "info");
      }
    } else {
      showNotification("An error occured while fetching user data", "error");
    }
  };
  return (
    <>
      {" "}
      {!!data ? (
        <Box
          sx={{
            width: "100%",
            minHeight: "150px",
            position: "relative",
            borderRadius: "20px",
            zIndex: 5,
            background: theme.palette.primary.main,
          }}
        >
          <Box
            sx={{
              width: "100%",
              position: "absolute",
              right: 0,
              bottom: 0,
              top: 0,
              zIndex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              src="/assets/images/logo-mid.png"
              width={"70%"}
              height={"auto"}
              style={{ borderRadius: "20px" }}
              alt=""
            />
          </Box>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ position: "relative", zIndex: 2 }}
          >
            <Stack
              direction="column"
              sx={{ padding: 3, color: theme.palette.common.white }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ gap: "20px", mb: 2 }}
              >
                <Iconwallet />
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                  {balance ? "Total Balance " : "Estimate Balance"}
                </Typography>
              </Stack>
              {!balance ? (
                <Stack
                  direction="row"
                  sx={{ gap: "20px", flexGrow: 1, flexWrap: "wrap" }}
                  alignItems={"center"}
                >
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    sx={{ gap: "10px" }}
                  >
                    <Typography variant="body1" sx={{}}>
                      {data?.total_ngn?.name}
                    </Typography>
                    <Typography variant="h3" sx={{ fontWeight: 700 }}>
                      {formatNumberWithCommas(+data?.total_ngn?.total)}
                    </Typography>
                  </Stack>
                  {!isMedium && (
                    <Divider
                      orientation="vertical"
                      sx={{
                        background: "white",
                        width: "1px",
                        height: "20px",
                      }}
                    />
                  )}
                  <Stack
                    direction="row"
                    alignItems={"flex-end"}
                    sx={{ gap: "10px" }}
                  >
                    <Typography sx={{ fontSize: "10px" }}>
                      Value in {data?.total_usd?.name}{" "}
                    </Typography>
                    <Typography variant="body1">
                      {formatNumberWithCommas(+data?.total_usd?.total)}
                    </Typography>
                  </Stack>{" "}
                  {!isMedium && (
                    <Divider
                      orientation="vertical"
                      sx={{
                        background: "white",
                        width: "1px",
                        height: "20px",
                      }}
                    />
                  )}
                  <Stack
                    direction="row"
                    alignItems={"flex-end"}
                    sx={{ gap: "10px" }}
                  >
                    <Typography sx={{ fontSize: "10px" }}>
                      Value in {data?.total_btc?.name}{" "}
                    </Typography>
                    <Typography>
                      {" "}
                      {formatNumberWithCommas(+data?.total_btc?.total, 6)}
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <BalanceInfo data={data} walletBalance={wallet} />
              )}
            </Stack>
            <Stack sx={{ padding: 1, color: "white", width: "30px" }}>
              <span onClick={() => setBalance(!balance)}>
                <ArrowForwardIosIcon style={{ cursor: "pointer" }} />
              </span>
            </Stack>
          </Stack>{" "}
        </Box>
      ) : (
        <Skeleton
          sx={{
            borderRadius: "12px",
            marginX: "auto",
            marginY: 1,
          }}
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={150}
        />
      )}
    </>
  );
};

export default AccountInfo;

const BalanceInfo: FC<{
  data: AccountInfoProp;
  walletBalance: WalletBalanceType[];
}> = ({ data, walletBalance }) => {
  const isMedium = useResponsive("down", "md", "xl");
  const { base } = useAuth();
  const getWalletName = (text: string, balance: boolean) => {
    if (balance) {
      const wallet_item = walletBalance?.find(
        (wallet: WalletBalanceType) => wallet?.name === text?.toLowerCase()
      );
      return wallet_item?.value;
    } else {
      const wallet_item = walletBalance?.find(
        (wallet: WalletBalanceType) => wallet?.name === text?.toLowerCase()
      );
      return wallet_item?.name;
    }
  };
  const convertToNumber = (amount: any) => {
    if (isNaN(amount)) return 0;
    if (amount === undefined) return 0;
    const number = Math.round(Number(amount));
    const amount_balance = addCommasToNumbers(number)
    return amount_balance;
  };
  return (
    <Stack
      direction="row"
      sx={{ gap: "20px", flexGrow: 1, flexWrap: "wrap" }}
      alignItems={"center"}
    >
      <Stack direction="row" alignItems={"center"} sx={{ gap: "10px" }}>
        <Typography variant="body1" sx={{}}>
          {base?.code?.toString()?.toUpperCase() ?? "Set Base"}
        </Typography>
        <Typography variant="h3" sx={{ fontWeight: 700 }}>
          {convertToNumber(getWalletName(base?.name, true))}
        </Typography>
      </Stack>
      {!isMedium && (
        <Divider
          orientation="vertical"
          sx={{
            background: "white",
            width: "1px",
            height: "20px",
          }}
        />
      )}
      <Stack direction="row" alignItems={"center"} sx={{ gap: "10px" }}>
        <Typography sx={{ fontSize: "10px" }}>
          {getWalletName("bitcoin", false)?.toString()?.toUpperCase()}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {Number(getWalletName("bitcoin", true)).toFixed(6)}
        </Typography>
      </Stack>{" "}
      {!isMedium && (
        <Divider
          orientation="vertical"
          sx={{
            background: "white",
            width: "1px",
            height: "20px",
          }}
        />
      )}
      <Stack direction="row" alignItems={"center"} sx={{ gap: "10px" }}>
        <Typography sx={{ fontSize: "10px" }}>
          {getWalletName("ethereum", false)?.toString()?.toUpperCase()}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {" "}
          {Number(getWalletName("ethereum", true)).toFixed(6)}
        </Typography>
      </Stack>
    </Stack>
  );
};
