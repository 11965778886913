import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import PinDetails from "../../components/auth/PinDetails";

const Pin: React.FC = () => {
  const isMedium = useResponsive("down", "md", "xs");
  const isSmall = useResponsive("down", "sm", "xs");

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "36px",
        width: isMedium ? (isSmall ? "90%" : "450px") : "80%",

        marginX: "auto",
        padding: isMedium ? 3 : 4,
      }}
    >
      <Stack
        direction={isMedium ? "column" : "row"}
        justifyContent={"space-between"}
        sx={{ margin: 3, gap: 4 }}
      >
        <Box sx={{ width: isMedium ? "100%" : "40%" }}>
          <img
            src="/assets/images/kumo-logo.png"
            width="140"
            height="40"
            alt=""
          />
          <Typography
            sx={{
              fontWeight: 300,
              lineHeight: isMedium ? "50px" : "56px",
              fontSize: isMedium ? "40px" : "52px",
              my: 2,
            }}
          >
            Create Transaction Pin
          </Typography>
          <Typography variant="h5">Secure your transactions</Typography>
        </Box>
        <PinDetails />
      </Stack>
    </Box>
  );
};

export default Pin;
