import { Stack, Typography } from "@mui/material";
import React from "react";
import { CopyIcon } from "../UIElements/svgIcons";
import { useTheme } from "@mui/material/styles";
import { useNotificationService } from "../UIElements/notification";
import useResponsive from "../../hooks/useResponsive";

const ProfileTopImage = ({ userData }: any) => {
  const theme = useTheme();
  const { showNotification } = useNotificationService();
  const isMedium = useResponsive("down", "md", "xl");
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    showNotification("Copied to clipboard", "success");
  };
  return (
    <Stack direction="row" sx={{ my: 4, gap: "20px" }} alignItems={"center"}>
      <img
        src="/assets/icons/avatar.png"
        width={isMedium ? 100 : 130}
        style={{ borderRadius: "150px" }}
        height={ isMedium ? 100 : 130}
        alt=""
      />
      <Stack>
        <Typography
          variant="h3"
          sx={{ fontWeight: 600, color: theme.palette.text.primary }}
        >
          {userData?.first_name} {userData?.last_name}
        </Typography>
        <Stack direction="row" sx={{ gap: "10px" }}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
            <span style={{ color: theme.palette.primary.main }}>User ID:</span>{" "}
            {userData?.email}{" "}
          </Typography>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleCopy(userData?.email)}
          >
            <CopyIcon />
          </span>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileTopImage;
