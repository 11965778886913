import React, { useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
import LoginDetails from "../../components/auth/LoginDetails";
import useResponsive from "../../hooks/useResponsive";
import { Helmet } from "react-helmet-async";
import EmailVerification from "../../components/auth/EmailVerification";

const Login: React.FC = () => {
  const isMedium = useResponsive("down", "md", "xs");
  const isSmall = useResponsive("down", "sm", "xs");
  const [verify, setVerify] = useState(false);

  const handleVerify = () => {
    setVerify(true);
  };

  const handlePrev = () => {
    setVerify(false);
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          background: "white",
          borderRadius: "36px",
          width: isMedium ? (isSmall ? "90%" : "450px") : "80%",

          marginX: "auto",
          padding: isMedium ? 3 : 4,
        }}
      >
        {!verify && (
          <Stack
            direction={isMedium ? "column" : "row"}
            justifyContent={"space-between"}
            sx={{ margin: 3, gap: 4 }}
          >
            <Box sx={{ width: isMedium ? "100%" : "35%" }}>
              <img
                src="/assets/images/kumo-logo.png"
                width="140"
                height="40"
                alt=""
              />
              <Typography
                sx={{
                  fontWeight: 300,
                  lineHeight: isMedium ? "50px" : "56px",
                  fontSize: isMedium ? "40px" : "52px",
                  my: 2,
                }}
              >
                Login to your Account
              </Typography>
              <Typography variant="h5">
                Join over 1000 users to enjoy our seamless payment platform.
              </Typography>
            </Box>
            <LoginDetails handleVerify={handleVerify} />
          </Stack>
        )}
        {verify && <EmailVerification prevPage={handlePrev} email="" />}
      </Box>
    </>
  );
};

export default Login;
