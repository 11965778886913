import {
  Box,
  Stack,
  TextField,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ModalContainer from "./modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SearchInput } from "./searchInput";
import useResponsive from "../../hooks/useResponsive";
import { formatNumberWithCommas } from "../../utils/basic";
import {
  MaxValueProps,
  ValuTextNumberInputPropType,
  ValueInputPropType,
  ValuePropTypeUI,
  ValueSelectPropType,
} from "../../interface/ui-elements";

export const TextContainer: React.FC<{
  children: React.ReactNode;
  path: string;
}> = ({ children, path }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const handleNavigation = () => {
    navigate(path);
  };
  return (
    <Box
      onClick={handleNavigation}
      sx={{
        padding: 3,
        my: 3,
        cursor: "pointer",
        background: theme.palette.common.white,
        borderRadius: "20px",
      }}
    >
      <Typography
        variant="body1"
        sx={{ fontWeight: 700, color: theme.palette.common.black, px: 2 }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export const TextIconContainer: React.FC<{
  children: React.ReactNode;
  image: string;
  onclick: () => void;
}> = ({ children, onclick, image }) => {
  const theme = useTheme();

  return (
    <Box
      onClick={onclick}
      sx={{
        padding: 3,
        my: 3,
        cursor: "pointer",
        background: theme.palette.common.white,
        borderRadius: "20px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "32px",
          height: "32px",
          padding: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "40px",
        }}
      >
        <img src={image} width={26} height={22} alt="" />
      </Box>
      <Typography
        variant="body1"
        sx={{ fontWeight: 700, color: theme.palette.common.black, px: 2 }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export const TextIconContainerSelect: React.FC<{
  children: React.ReactNode;
  onclick: () => void;
  image: string;
}> = ({ children, onclick, image }) => {
  const theme = useTheme();

  return (
    <Box
      onClick={onclick}
      sx={{
        padding: 3,
        my: 3,
        cursor: "pointer",
        background: "#F9F9F9",
        borderRadius: "20px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      {!!image && (
        <Box
          sx={{
            width: "42px",
            height: "42px",
            padding: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "40px",
          }}
        >
          <img src={image} width={24} height={24} alt="" />
        </Box>
      )}
      <Typography
        variant="body1"
        sx={{ fontWeight: 700, color: theme.palette.common.black, px: 2 }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export const TextValueContainer = ({
  text,
  currency,
  onchange,
  amount,
  onBlurHandler,
  onFocusHandler,
  icon,
}: ValuePropTypeUI) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xs");
  const isSmall = useResponsive("down", "sm", "xl");

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow only numbers, decimal point, comma, backspace, and delete
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      ",",
    ];
    const backspaceKey = e.keyCode === 8; // Backspace key code
    const deleteKey = e.keyCode === 46; // Delete key code

    if (!allowedKeys.includes(e.key) && !backspaceKey && !deleteKey) {
      e.preventDefault();
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: isMedium ? "10px" : "20px",
        my: 2,
      }}
    >
      <Stack
        alignItems={"center"}
        direction={"column"}
        sx={{
          gap: "10px",
          background: theme.palette.common.white,
          borderRadius: "20px",
          minWidth: isSmall ? "25px" : "40px",
          padding: isMedium ? " 0.6rem" : "1rem",
        }}
      >
        <Typography
          variant={isMedium ? "caption" : "body2"}
          sx={{ color: theme.palette.secondary.main }}
        >
          {currency}
        </Typography>
        <span>
          <img src={icon} alt="" width={20} height={18} />
        </span>
      </Stack>
      <Stack
        direction={"column"}
        sx={{
          gap: "5px",
          background: theme.palette.common.white,
          borderRadius: "20px",
          padding: isMedium ? " 0.7rem" : "1rem",
          flexGrow: 1,
        }}
      >
        <Typography
          variant={isMedium ? "caption" : "body2"}
          sx={{ color: theme.palette.secondary.main }}
        >
          {text}
        </Typography>
        <input
          value={typeof amount === "number" ? amount : amount?.toString()}
          type="text"
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          placeholder="0"
          onChange={onchange}
          onKeyDown={handleKeyDown}
          style={{
            color: theme.palette.common.black,
            fontFamily: "Outfit",
            fontSize: isMedium ? "20px" : "24px",
            fontWeight: 700,
            border: 0,
            outline: "none",
          }}
        />
      </Stack>
    </Box>
  );
};

//

const styles = {
  input: {
    paddingLeft: "15px",
    fontSize: "15px",
    fontWeight: 400,
    padding: "10px",
    borderRadius: "10px",
    background: "#fff"
  },
};

export const TextInputContainer = ({
  text,
  placeholder,
  value,
  onclick,
}: ValueInputPropType) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xl");
  return (
    <Stack
      direction={isMedium ? "column" : "row"}
      justifyContent={"space-between"}
      alignItems={isMedium ? "flex-start" : "center"}
      sx={{
        gap: isMedium ? "10px" : "20px",
        borderRadius: "20px",
        flexGrow: 1,
        paddingY: "0.5rem",
        paddingLeft: "1rem",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.secondary.main,
          minWidth: isMedium ? "80px" : "150px",
        }}
      >
        {text}
      </Typography>
      <TextField
        placeholder={placeholder}
        value={value}
        size="medium"
        onChange={(e) => onclick(e)}
        sx={{ width: isMedium ? "90%" : "100%" }}
        InputProps={{
          inputProps: {
            style: { ...styles.input, color: theme.palette.common.black },
          },
        }}
      />
    </Stack>
  );
};

export const TextNumberInputContainer = ({
  text,
  placeholder,
  value,
  onFocusHandler,
  onBlurHandler,
  onclick,
}: ValuTextNumberInputPropType) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xl");
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow only numbers, decimal point, comma, backspace, and delete
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      ",",
    ];
    const backspaceKey = e.keyCode === 8; // Backspace key code
    const deleteKey = e.keyCode === 46; // Delete key code

    if (!allowedKeys.includes(e.key) && !backspaceKey && !deleteKey) {
      e.preventDefault();
    }
  };
  return (
    <Stack
      direction={isMedium ? "column" : "row"}
      justifyContent={"space-between"}
      alignItems={isMedium ? "flex-start" : "center"}
      sx={{
        gap: isMedium ? "10px" : "20px",
        borderRadius: "20px",
        flexGrow: 1,
        paddingY: "0.5rem",
        paddingLeft: "1rem",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.secondary.main,
          minWidth: isMedium ? "80px" : "150px",
        }}
      >
        {text}
      </Typography>
      <TextField
        placeholder={placeholder}
        value={value}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        onKeyDown={handleKeyDown}
        size="medium"
        onChange={(e) => onclick(e)}
        sx={{ width: isMedium ? "90%" : "100%" }}
        InputProps={{
          inputProps: {
            style: { ...styles.input, color: theme.palette.secondary.main },
          },
        }}
      />
    </Stack>
  );
};

export const MaxAmountValue: FC<MaxValueProps> = ({
  amount,
  onclick,
  currency,
}) => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");
  const [amount_point, setAmount_Point] = useState(2);

  useEffect(() => {
    if (currency.toLowerCase() === "usd" || currency.toLowerCase() === "ngn") {
      setAmount_Point(2);
    } else {
      setAmount_Point(6);
    }
  }, [currency]);
  const handleMaxValue = () => {
    onclick(Number(amount));
  };
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      sx={{
        color: theme.palette.text.primary,
        padding: "1rem",
        borderRadius: "20px",
        my: isSmall ? 0 : 3,
        background: theme.palette.primary.light,
      }}
    >
      <Typography variant="body1">{currency?.toUpperCase()}</Typography>
      <Typography variant="h4" sx={{ fontWeight: 700, ml: 3 }}>
        {formatNumberWithCommas(+amount, amount_point)}
      </Typography>
      <Button
        onClick={handleMaxValue}
        sx={{
          ml: "auto",
          fontSize: "16px",
          background: theme.palette.common.white,
          color: theme.palette.primary.main,
          borderRadius: "20px",
          padding: "15px 30px",
        }}
      >
        Max
      </Button>
    </Stack>
  );
};

export const TextSelectContainer = ({
  text,
  data,
  value,
  onclick,
}: ValueSelectPropType) => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");
  const isMedium = useResponsive("down", "md", "xl");

  const [modalContents, setModalContents] = useState(false);
  const [filteredData, setFilteredData] = useState<{
    items_list: any[];
    search_value: string;
  }>({
    items_list: data ?? [],
    search_value: "",
  });
  const handleChange = (name: string) => {
    handleModalContents();
    onclick(name);
  };
  const handleModalContents = () => {
    setModalContents((prev) => !prev);
  };

  // handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilteredData((prev) => ({ ...prev, search_value: value }));

    if (value.length === 0) {
      setFilteredData((prev) => ({ ...prev, items_list: data })); // Reset to original list if search input is empty
    } else {
      const filteredTokens = filteredData?.items_list?.filter((token) =>
        token?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredData((prev) => ({ ...prev, items_list: filteredTokens }));
    }
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        gap: "20px",
        borderRadius: "20px",
        flexGrow: 1,
        paddingY: "1rem",
        paddingLeft: "1rem",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.common.black,
          fontWeight: 600,
          minWidth: isSmall ? "80px" : "200px",
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          padding: "14px",
          borderRadius: "10px",
          border: "1px solid #E0E0E0",
          cursor: "pointer",
          background: theme.palette.common.white,
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={handleModalContents}
      >
        <Typography
          variant="body2"
          sx={{ color: theme.palette.secondary.main }}
        >
          {!!value ? value?.toUpperCase() : "Select Coin"}
        </Typography>
        <span style={{ cursor: "pointer" }}>
          <KeyboardArrowDownIcon />
        </span>
      </Box>
      <ModalContainer
        openModal={modalContents}
        sx={{}}
        fullWidth={true}
        size="sm"
        handleModal={handleModalContents}
      >
        <Box
          sx={{
            background: theme.palette.common.white,
            padding: 3,
            borderRadius: "20px",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, color: theme.palette.text.primary }}
          >
            Select Coin
          </Typography>
          <Divider sx={{ my: 3 }} />
          <SearchInput
            text={""}
            handler={handleSearchChange}
            width={"100%"}
            value={filteredData?.search_value}
          />
           <Box
            sx={{
              overflowY: "auto",
              height: 400,
              background: theme.palette.common.white,
              borderRadius: "20px",
            }}
          >
          {filteredData?.items_list?.map((data, index) => (
            <TextIconContainerSelect
              key={index}
              image={data?.image}
              onclick={() => handleChange(data?.name)}
            >
              {data?.name?.toUpperCase()}
            </TextIconContainerSelect>
          ))}
          </Box>
        </Box>
      </ModalContainer>
    </Stack>
  );
};
