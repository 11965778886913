import React, { FC } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Stack, Typography } from "@mui/material";
import { assetData } from "../../data/asset";
import useResponsive from "../../hooks/useResponsive";

const AssetsContent: FC<{ assetList: any[] }> = ({ assetList }) => {
  return (
    <>
      {assetList?.map((val, id) => (
        <AssetBoxContainer key={id} data={val}>
          {val.name?.toUpperCase()}
        </AssetBoxContainer>
      ))}
    </>
  );
};

export default AssetsContent;

export const AssetBoxContainer: React.FC<{
  children: React.ReactNode;
  data: any;
}> = ({ children, data }) => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xs");

  return (
    <Box
      sx={{
        padding: 3,
        my: 3,
        background: theme.palette.common.white,
        borderRadius: "20px",
        display: "flex",
        flexDirection: isSmall ? "column" : "row",
        justifyContent: "space-between",
        gap: "10px",
        flexGrow: 1,
        alignItems: isSmall ? "flex-start" : "center",
      }}
    >
      <Stack
        sx={{ color: theme.palette.common.black }}
        direction={"row"}
        alignItems={"center"}
      >
        <Box
          sx={{
            // background: "#D9D9D9",
            width: "32px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            borderRadius: "40px",
          }}
        >
          <img src={data.image} alt="" width="90%" height={""} />
        </Box>
        <Typography variant="body1" sx={{ fontWeight: 700, px: 2 }}>
          {children}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 700, px: 2 }}>
          {data.rate?.toFixed(2)}
        </Typography>
        <img
          src="/assets/icons/wave.svg"
          alt=""
          width={"40px"}
          height={"20px"}
        />
      </Stack>
      <Stack direction={"row"} sx={{ gap: "10px" }}>
        <Typography>{data.balance?.toFixed(6)}</Typography> -
        <Typography>{data.to_base_currency.toFixed(2)}</Typography>
      </Stack>
    </Box>
  );
};
