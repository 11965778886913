import { FC, useEffect, useState } from "react";
import {
  MaxAmountValue,
  TextSelectContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { Typography, Stack, Box } from "@mui/material";
import Decimal from "decimal.js";

import { ButtonContainedMain } from "../UIElements/button";
import { useTheme } from "@mui/material/styles";
import { SwapCryptoTransaction } from "./modal/dashboardModals";
import { PasswordModal } from "../passwordModal";
import { TransactionResult } from "../transactionResult";
import { TransactionIcon } from "../UIElements/svgIcons";
import { OTPModal } from "../otpModal";
import { useAuth } from "../../hooks/useAuth";
import {
  getSwapConversion,
  swapCoins,
  swapCryptoVerifier,
} from "../../api/transaction";
import { useNotificationService } from "../UIElements/notification";
import { convertSwapTokens } from "../../utils/currency";
import { WalletBalanceType } from "../../interface/useDetail";
import { requestOtp, validateOtp, verifyPin } from "../../api/users";
import { hasValues } from "../../utils/basic";

const SwapCoinContents: FC<{
  swapCoinList: any[];
  userPin: string;
  walletList: WalletBalanceType[];
}> = ({ swapCoinList, walletList, userPin }) => {
  const theme = useTheme();
  const { token } = useAuth();
  const { showNotification } = useNotificationService();
  const [modalContainer, setModalContainer] = useState({
    modalDetails: false,
    pinModal: false,
    otpModal: false,
    resultModal: false,
    failedModal: false,
    converted_charged_amount: "",
    charges: "",
    pin: "",
  });
  const [currency, setCurrency] = useState<{
    token_from: any;
    token_to: any;
    exchange: boolean;
    token_from_value: number | string;
    token_to_value: number | string;
    token_exchange_value: number;
    token_from_wallet_balance: number;
    token_to_wallet_balance: number;
  }>({
    token_from: {},
    token_to: {},
    exchange: false,
    token_from_value: "",
    token_to_value: "",
    token_exchange_value: 0,
    token_from_wallet_balance: 0,
    token_to_wallet_balance: 0,
  });
  //
  useEffect(() => {
    if (currency?.exchange) {
      // Fetch exchange data from the backend server
      const tokenAmount =
        Number(currency?.token_to_value) * currency?.token_exchange_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        token_from_value: tokenAmount,
      }));
    } else {
      const tokenAmount =
        Number(currency?.token_from_value) * currency?.token_exchange_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        token_to_value: tokenAmount,
      }));
    }
  }, [
    currency?.token_from_value,
    currency?.token_to_value,
    currency?.token_exchange_value,
  ]);

  // Update token_amount_value whenever amount_value or base_token_value changes
  useEffect(() => {
    if (hasValues(currency?.token_to)) {
      fetchSwapConversion("1");
    }
  }, [currency?.token_to, currency?.exchange, currency?.token_from]);

  //get swap conversion
  const fetchSwapConversion = async (from_amt: string) => {
    const token_key = token ?? "";
    const query = {
      to_name: currency?.exchange
        ? currency?.token_from?.name
        : currency?.token_to?.name,
      from_name: currency?.exchange
        ? currency?.token_to?.name
        : currency?.token_from?.name,
      from_amount: from_amt,
    };
    const swap_result = await getSwapConversion(query, token_key);

    if (swap_result?.status === 200) {
      setCurrency((prev) => ({
        ...prev,
        token_exchange_value: swap_result?.data?.data?.to_amount,
      }));
    } else {
      showNotification("Server Error", "error");
    }
  };

  const fetchConversion = async (from_amt: string) => {
    const token_key = token ?? "";
    const query = {
      to_name: currency?.exchange
        ? currency?.token_from?.name
        : currency?.token_to?.name,
      from_name: currency?.exchange
        ? currency?.token_to?.name
        : currency?.token_from?.name,
      from_amount: from_amt,
    };
    const swap_result = await getSwapConversion(query, token_key);
    if (swap_result?.status === 200) {
      setCurrency((prev) => ({
        ...prev,
         token_to_value: swap_result?.data?.data?.to_amount,
      }));
    } else {
      showNotification("Server Error", "error");
    }
  };

  const handleModal = async () => {
    await fetchConversion(currency?.token_from_value?.toString());
    const token_value_check = currency?.exchange
      ? currency?.token_to_value
      : currency?.token_from_value;
    const coin_wallet_max_value = currency?.exchange
      ? currency?.token_to_wallet_balance
      : currency?.token_from_wallet_balance;

    // check if amount is empty
    if (Number(token_value_check) <= 0) {
      showNotification("Amount is empty", "error");
      return;
    }
    const amount_exceed_check = new Decimal(token_value_check).gt(
      new Decimal(coin_wallet_max_value)
    );

    // Check if currency.amount_value is more than base_value
    if (!amount_exceed_check) {
      showNotification("Verifying Transaction", "info");
      const token_key = token ?? "";
      const verify_data = {
        to_name: currency?.exchange
          ? currency?.token_from?.name
          : currency?.token_to?.name,
        from_name: currency?.exchange
          ? currency?.token_to?.name
          : currency?.token_from?.name,
        from_amount: currency?.exchange
          ? Number(currency?.token_to_value).toString()
          : Number(currency?.token_from_value).toString(),
        to_amount: currency?.exchange
          ? Number(currency?.token_from_value).toString()
          : Number(currency?.token_to_value).toString(),
        pin: "",
      };

      const verify_transaction = await swapCryptoVerifier(
        verify_data,
        token_key
      );
      if ("msg" in verify_transaction) {
        showNotification(verify_transaction.msg, "error");
      } else if (verify_transaction.status == 200) {
        setModalContainer((prev) => ({
          ...prev,
          modalDetails: !prev.modalDetails,
          charges: verify_transaction?.data?.data?.fee,
          converted_charged_amount: verify_transaction?.data?.data?.value,
        }));
      } else {
        showNotification("An error occured", "error");
      }
    } else {
      showNotification("Amount exceeds base amount", "error");
    }
  };

  const handlePinModal = () => {
    setModalContainer((prev) => ({ ...prev, pinModal: !prev.pinModal }));
  };

  const handleOtpModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // send otp request
      const send_request = await requestOtp(token_key);
      console.log(send_request?.data?.msg);
      if (send_request?.status === 200) {
        showNotification(send_request?.data?.msg, "success");
      } else {
        showNotification("OTP code not sent", "error");
      }
      // update state
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        otpModal: !prev.otpModal,
        pin: pin_value,
      }));
    } else {
      showNotification("Invalid Pin", "error");
    }
  };

  const handleResultModal = async (otp: string[]) => {
    const token_key = token ?? "";

    // verify otp and invoke buy crypto api
    const otp_value = otp.join("");
    const data = {
      to_name: currency?.exchange
        ? currency?.token_from?.name
        : currency?.token_to?.name,
      from_name: currency?.exchange
        ? currency?.token_to?.name
        : currency?.token_from?.name,
      from_amount: currency?.exchange
        ? Number(currency?.token_to_value)
        : Number(currency?.token_from_value),
      to_amount: currency?.exchange
        ? Number(currency?.token_from_value)
        : Number(currency?.token_to_value),
      pin: modalContainer?.pin,
    };
    const otp_verify = await validateOtp(otp_value, token_key);
    if (otp_verify?.status === 200) {
      const swap_crypto = await swapCoins(data, token_key);
      console.log(swap_crypto, "swap_crypto");
      if ("msg" in swap_crypto) {
        showNotification(swap_crypto.msg, "error");
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          failedModal: !prev.failedModal,
        }));
      } else if (swap_crypto.status == 200) {
        showNotification("Crypto Swap successfully", "success");
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        showNotification("Transaction Failed", "error");
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          failedModal: !prev.failedModal,
        }));
      }
    } else {
      showNotification("OTP Verification Failed", "error");
      setModalContainer((prev) => ({
        ...prev,
        otpModal: !prev.otpModal,
        failedModal: !prev.failedModal,
      }));
    }
  };
  // get token name
  const getTokenFromName = (name: string) => {
    const findToken = swapCoinList?.find((val) => val.name === name);
    const wallet_balance =
      walletList?.find((token) => token?.name === name)?.value ?? 0;
    if (currency?.exchange) {
      setCurrency((prev) => ({
        ...prev,
        token_to: findToken,
        token_to_value: "",
        token_to_wallet_balance: wallet_balance,
      }));
    } else {
      setCurrency((prev) => ({
        ...prev,
        token_from: findToken,
        token_from_value: "",
        token_from_wallet_balance: wallet_balance,
      }));
    }
  };

  const getTokenToName = (name: string) => {
    const findToken = swapCoinList?.find((val) => val.name === name);
    const wallet_balance =
      walletList?.find((token) => token?.name === name)?.value ?? 0;

    if (!currency?.exchange) {
      setCurrency((prev) => ({
        ...prev,
        token_to: findToken,
        token_to_value: "",
        token_to_wallet_balance: wallet_balance,
      }));
    } else {
      setCurrency((prev) => ({
        ...prev,
        token_from: findToken,
        token_from_value: "",
        token_from_wallet_balance: wallet_balance,
      }));
    }
  };

  const handleMaxAmount = (num: number) => {
    if (currency?.exchange) {
      setCurrency((prev) => ({ ...prev, token_to_value: num }));
    } else {
      setCurrency((prev) => ({ ...prev, token_from_value: num }));
    }
  };

  // handle amount change
  const handleAmountChange = (e: { target: { value: any } }) => {
    if (currency?.exchange) {
      setCurrency((prev) => ({ ...prev, token_to_value: e.target.value }));
    } else {
      setCurrency((prev) => ({ ...prev, token_from_value: e.target.value }));
    }
  };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      otpModal: false,
      resultModal: false,
    }));
  };

  return (
    <>
      <SwapCryptoTransaction
        charges={modalContainer?.charges}
        onClose={closeAllModal}
        currency={currency?.token_from?.code?.toUpperCase()}
        open={modalContainer.modalDetails}
        recieve={modalContainer?.converted_charged_amount}
        onclick={handlePinModal}
        currencyValue={currency?.token_from_value}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleOtpModal}
        onClose={closeAllModal}
      />
      <OTPModal
        open={modalContainer?.otpModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          You have <span style={{ fontWeight: 700 }}>successfully</span> Swapped
          coins
        </Typography>
      </TransactionResult>
      {hasValues(currency?.token_from) && (
        <MaxAmountValue
          onclick={handleMaxAmount}
          currency={
            currency?.exchange
              ? currency?.token_to?.code
              : currency?.token_from?.code
          }
          amount={
            currency?.exchange
              ? currency?.token_to_wallet_balance
              : currency?.token_from_wallet_balance
          }
        />
      )}
      <TextSelectContainer
        text="Select Coin"
        value={
          currency?.exchange
            ? currency?.token_to?.name?.toUpperCase()
            : currency?.token_from?.name?.toUpperCase()
        }
        data={swapCoinList}
        onclick={getTokenFromName}
      />
      {hasValues(currency?.token_from) && (
        <TextValueContainer
          text={currency?.exchange ? "Value" : "You Pay"}
          onBlurHandler={() => {}}
          onFocusHandler={() => {}}
          onchange={handleAmountChange}
          currency={
            currency?.exchange
              ? currency?.token_to?.code?.toUpperCase()
              : currency?.token_from?.code?.toUpperCase()
          }
          amount={
            currency?.exchange
              ? currency?.token_to_value
              : currency?.token_from_value
          }
          icon={
            currency?.exchange
              ? currency?.token_to?.image
              : currency?.token_from?.image
          }
        />
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          my: 3,
        }}
      >
        <span
          onClick={() => {
            setCurrency((prev) => ({ ...prev, exchange: !prev.exchange }));
          }}
          style={{
            color: theme.palette.secondary.light,
            cursor: "pointer",
          }}
        >
          <TransactionIcon />
        </span>
      </Box>
      <TextSelectContainer
        text="Select Coin"
        value={
          currency?.exchange
            ? currency?.token_from?.name?.toUpperCase()
            : currency?.token_to?.name?.toUpperCase()
        }
        data={swapCoinList}
        onclick={getTokenToName}
      />
      {hasValues(currency?.token_to) && (
        <TextValueContainer
          onFocusHandler={() => {}}
          text={currency?.exchange ? "You Get" : "Value"}
          onBlurHandler={() => {}}
          currency={
            currency?.exchange
              ? currency?.token_from?.code?.toUpperCase()
              : currency?.token_to?.code?.toUpperCase()
          }
          onchange={() => {}}
          amount={
            currency?.exchange
              ? currency?.token_from_value
              : currency?.token_to_value
          }
          icon={
            currency?.exchange
              ? currency?.token_from?.image
              : currency?.token_to?.image
          }
        />
      )}
      <Stack direction="column" alignItems={"center"}>
        <Typography
          variant="body2"
          sx={{ my: 2, color: theme.palette.secondary.main }}
        >
          1{" "}
          {currency?.exchange
            ? currency?.token_to?.code?.toUpperCase()
            : currency?.token_from?.code?.toUpperCase()}{" "}
          = {currency?.token_exchange_value?.toFixed(6)}{" "}
          {!currency?.exchange
            ? currency?.token_to?.code?.toUpperCase()
            : currency?.token_from?.code?.toUpperCase()}
        </Typography>
        <ButtonContainedMain
          sx={{ width: "150px", fontWeight: 500, fontSize: "16px" }}
          onclick={handleModal}
        >
          Proceed
        </ButtonContainedMain>
      </Stack>
    </>
  );
};

export default SwapCoinContents;
