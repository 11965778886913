import { Box, Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ArrowLIcon, ArrowRIcon } from "./UIElements/svgIcons";
import { FC } from "react";

const Pagination: FC<{
  pageNumber: number;
  handleChange: (value: number) => void;
}> = ({ pageNumber, handleChange }) => {
  const theme = useTheme();
  const handlePrev = () => {
    if (pageNumber === 1) {
      return;
    }
    handleChange(pageNumber - 1);
  };
  const handleNext = () => {
    handleChange(pageNumber + 1);
  };
  // let totalPages = 4;
  // // Create an array with page numbers from 1 to totalPages
  // const pageNumbers = Array.from(
  //   { length: totalPages },
  //   (_, index) => index + 1
  // );
  return (
    <Box
      sx={{
        display: "flex",
        marginY: 2,
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.dark,
      }}
    >
      <span onClick={handlePrev}>
        <ArrowLIcon />
      </span>
      <Stack direction="row" gap="10px" marginX={2}>
        <Button
          sx={{
            border: `1px solid ${theme.palette.primary.light}`,
            minWidth: "10px",
            padding: "6px 10px",
            borderRadius: "100px",
            fontWeight: 500,
          }}
        >
          {pageNumber}
        </Button>
      </Stack>
      <span onClick={handleNext}>
        <ArrowRIcon />
      </span>
    </Box>
  );
};

export default Pagination;
