import React, { useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
import {
  SignupDetailsOne,
  SignupDetailsTwo,
} from "../../components/auth/SignupDetails";
import useResponsive from "../../hooks/useResponsive";
import { Helmet } from "react-helmet-async";
import EmailVerification from "../../components/auth/EmailVerification";

const SignUp: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const isMedium = useResponsive("down", "md", "xs");
  const isSmall = useResponsive("down", "md", "xs");

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    referral_id: "",
    email: "",
    phone_number: "",
    password: "",
    country_code: "",
  });
  const handlePage = () => {
    setPageNumber((prev) => prev + 1);
  };
  const handlePrevPage = () => {
    setPageNumber((prev) => prev - 1);
  };
  let header = "";
  let text = "";
  if (pageNumber === 1) {
    header = "Create an Account";
    text = "Join over 1000 users to enjoy our seamless payment platform.";
  } else if (pageNumber === 2) {
    header = "Create Password";
    text = "Secure your Account";
  }

  console.log(values);
  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <Box
        sx={{
          background: "white",
          borderRadius: "36px",
          width: isMedium ? (isSmall ? "90%" : "450px") : "80%",

          marginX: "auto",
          padding: isMedium ? 3 : 4,
        }}
      >
        {pageNumber !== 3 && (
          <Stack
            direction={isMedium ? "column" : "row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ margin: 3, gap: 4 }}
          >
            <Box sx={{ width: isMedium ? "100%" : "35%" }}>
              <img
                src="/assets/images/kumo-logo.png"
                width="140"
                height="40"
                alt=""
              />
              <Typography
                sx={{
                  fontWeight: 300,
                  lineHeight: isMedium ? "45px" : "56px",
                  fontSize: isMedium ? "40px" : "52px",
                  my: 2,
                }}
              >
                {header}
              </Typography>
              <Typography variant="h5">{text}</Typography>
            </Box>

            {pageNumber === 1 && (
              <SignupDetailsOne
                values={values}
                changeValues={setValues}
                pageHandler={handlePage}
              />
            )}
            {pageNumber === 2 && (
              <SignupDetailsTwo
                values={values}
                changeValues={setValues}
                pageHandler={handlePage}
                prevPage={handlePrevPage}
              />
            )}
          </Stack>
        )}
        {pageNumber === 3 && <EmailVerification prevPage={handlePrevPage} email={values?.email} />}
      </Box>
    </>
  );
};

export default SignUp;
