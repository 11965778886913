import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  
  
  PinInput,
  ReEnterPinInput,
} from "../UIElements/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {  PATH_DASHBOARD } from "../../routes/path";
import { useTheme } from "@mui/material/styles";
import { useNotificationService } from "../UIElements/notification";
import useResponsive from "../../hooks/useResponsive";
import { apiUserLogin } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import { loginSchema } from "../../validate/input";
import { useAuth } from "../../hooks/useAuth";

//
const PinDetails: React.FC = () => {
  const { showNotification } = useNotificationService();
  const isMedium = useResponsive("down", "md", "xs");
  const navigate = useNavigate();
  const { set_data } = useAuth();

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(loginSchema),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any): Promise<void> => {
    const { email, password } = values;

    // setIsLoading(true);
    const data = { password: password, name_field: email };
    setIsLoading(true);
    const result = await apiUserLogin(data);

    if (result.status == 201) {
      showNotification("Login Success", "success");
      console.log(result?.data?.data);
      set_data(result?.data?.data);
      setIsLoading(false);
      navigate(PATH_DASHBOARD.root);
    } else {
      showNotification("Invalid Credentials", "error");
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: isMedium ? "100%" : "40%" }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <PinInput errors={errors} placeholder="Pin" register={register} />
        <ReEnterPinInput
          errors={errors}
          placeholder="Re-Enter Pin"
          register={register}
        />

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"end"}
          sx={{ my: 3 }}
        >
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            sx={{
              width: "120px",
              borderRadius: "10px",
            }}
          >
            Continue
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default PinDetails;
