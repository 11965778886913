import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../hooks/useResponsive";
import QRCode from "react-qr-code";
import { useNotificationService } from "./UIElements/notification";

export const QrCodeContainer: React.FC<{ text: string; address: string }> = ({
  text,
  address,
}) => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");
  const { showNotification } = useNotificationService();


  const handleCopy = () => {
    if (!address) {
      return;
    }
    navigator.clipboard
      .writeText(address)
      .then(() => {
        showNotification("Address Copied", "success");
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };
  return (
    <Box
      sx={{
        background: theme.palette.common.white,
        padding: 4,
        my: 3,
        borderRadius: "20px",
      }}
    >
      <Typography variant="body2" sx={{ color: theme.palette.secondary.main }}>
        {text}
      </Typography>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ gap: "20px", width: "100%" }}
      >
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.common.black,
            fontWeight: 700,
            overflowX: "auto",
          }}
        >
          {address}
        </Typography>
        <Box             onClick={handleCopy}

          sx={{
            // whiteSpace: 'nowrap',
            width: "100px",
            color: theme.palette.primary.main,
            fontSize: "14px",
            cursor: "pointer",
            fontWeight: 400,
            fontFamily: "Outfit",
            display: "flex",
            alignItems: " center",
            gap: "5px",
          }}
        >
          {" "}
          <img
            src="/assets/icons/copyIcon.svg"
            alt=""
            width="24"
            height={"24"}
          />
          Copy Address{" "}
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
      
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "300px", width: "200px", borderRadius: '10px' }}
          viewBox={`0 0 226 226`}
          value={address}
        />
      </Box>
    </Box>
  );
};
