import React, { FC, useState } from "react";
import {
  MaxAmountValue,
  TextInputContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { Box, Typography, Stack } from "@mui/material";
import {
  ButtonContainedLight,
  ButtonContainedMain,
} from "../UIElements/button";
import { useTheme } from "@mui/material/styles";
import { CustomTextSelectContainer, UserInfo } from "../textInput";
import { TransactionDetailsPropType } from "../../interface/transaction";
import CustomTransactionDetailsModal from "../customTransactionDetailsModal";
import { PasswordModal } from "../passwordModal";
import {
  TransactionResult,
  TransactionResultFailed,
} from "../transactionResult";
import RadioContainer from "../UIElements/radioContainer";
import {
  addCommasToNumbers,
  hasValues,
  parseFormattedNumber,
} from "../../utils/basic";
import { buyElectricity, verifyMerchantNumber } from "../../api/utilities";
import { useAuth } from "../../hooks/useAuth";
import LoadingSpinner from "../UIElements/loadingSpinner";
import { useNotificationService } from "../UIElements/notification";
import { WalletBalanceType } from "../../interface/useDetail";
import { ProviderDetailsProps } from "../../interface/cable";
import { verifyPin } from "../../api/users";

//

const radioValues = [
  { name: "Prepaid", id: 1 },
  { name: "Post Paid", id: 2 },
];
//

const BuyElectricityContent: FC<{
  provider_data: any[];
  userPhoneNumber: string;

  baseWallet: WalletBalanceType | undefined;
}> = ({ provider_data, baseWallet, userPhoneNumber }) => {
  const theme = useTheme();
  const { token, base } = useAuth();
  const { showNotification } = useNotificationService();
  const [electricDetails, setElectricDetails] = useState<{
    provider: ProviderDetailsProps;
    user_info: any;
    meter_number: string;
    type: string;
    amount: number | string;
    validate: boolean;
  }>({
    provider: {} as ProviderDetailsProps,
    user_info: {
      Address: "",
      Customer_Account_Type: "",
      Customer_Name: " ",
      MeterNumber: "",
      Meter_Type: "",
      Min_Purchase_Amount: 500,
    },
    meter_number: "",
    type: "prepaid",
    amount: "",
    validate: false,
  });
  const [modalContainer, setModalContainer] = useState<{
    modalDetails: boolean;
    pinModal: boolean;
    isLoading: boolean;
    resultModal: boolean;
    failedModal: boolean;
  }>({
    modalDetails: false,
    pinModal: false,
    isLoading: false,
    resultModal: false,
    failedModal: false,
  });

  const handleModal = () => {
// check if it's a dollar account
if (base?.code === "usd" || base?.name === "dollars") {
  showNotification(
    "Conversion for dollar account has not been implemented",
    "info"
  );
  return;
}    // check amout
    if (
      parseFormattedNumber(electricDetails?.amount?.toString()) <
      electricDetails?.user_info?.Min_Purchase_Amount
    ) {
      showNotification(
        `Minimum purchase amount is ${electricDetails?.user_info?.Min_Purchase_Amount}`,
        "error"
      );
      return;
    }
    // check if amount is more than base amount
    if (
      parseFormattedNumber(electricDetails?.amount?.toString()) >
      (baseWallet?.value ?? 0)
    ) {
      showNotification(`Amount is greater than base amount`, "error");
      return;
    }
    setModalContainer((prev) => ({
      ...prev,

      modalDetails: !prev.modalDetails,
    }));
  };
  const handlePinModal = () =>
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: !prev.modalDetails,
      pinModal: !prev.pinModal,
    }));
  // verify pin then send confirmation
  const handleResultModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");

    const buy_electricity = {
      amount: parseFormattedNumber(electricDetails?.amount?.toString()),
      service_id: electricDetails?.provider?.serviceID,
      billers_code: electricDetails?.meter_number.toString(),
      type: electricDetails?.type?.toLowerCase(),
      variation_code: electricDetails?.type?.toLowerCase(),
      phone_number: userPhoneNumber,
      pin: pin_value,
    };

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // sent final request to server
      const buy_electric = await buyElectricity(buy_electricity, token_key);
      if ("msg" in buy_electric) {
        showNotification(buy_electric.msg, "error");
        setModalContainer((prev) => ({
          ...prev,
          pinModal: !prev.pinModal,
          failedModal: !prev.failedModal,
        }));
      } else if (buy_electric.status == 200) {
        showNotification("Electricity Recharged", "success");
        setModalContainer((prev) => ({
          ...prev,
          pinModal: !prev.pinModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        showNotification("An error occured while recharging electricity", "error");
      }
    } else {
      showNotification("Invalid Pin", "error");
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        failedModal: !prev.failedModal,
      }));
    }
  };
  // handle provider selection
  const handleProviderSelection = (obj: any) => {
    if (hasValues(obj)) {
      // update state
      setElectricDetails((prev) => ({
        ...prev,
        provider: obj,
      }));
    }
  };

  // handle prepaid / post paid type
  const handleRadioValue = (value: string) => {
    setElectricDetails((prev) => ({ ...prev, type: value?.toLowerCase() }));
  };

  // handleValidation
  const handleValidation = async () => {
    const token_key = token ?? "";
    const data = {
      billers_code: electricDetails?.meter_number,
      service_id: electricDetails?.provider?.serviceID,
      type: electricDetails?.type?.toLowerCase(),
    };
    console.log(data);
    setModalContainer((prev) => ({ ...prev, isLoading: true }));
    const validate_meter = await verifyMerchantNumber(data, token_key);
    console.log(validate_meter);
    if ("msg" in validate_meter) {
      console.log("Error fetching currency data:", validate_meter.msg);
      showNotification(validate_meter.msg, "error");
      setModalContainer((prev) => ({ ...prev, isLoading: false }));
    } else if (validate_meter.status == 200) {
      showNotification("Meter User details Fetched", "success");
      setModalContainer((prev) => ({ ...prev, isLoading: false }));
      setElectricDetails((prev) => ({
        ...prev,
        user_info: validate_meter?.data?.data,
        validate: true,
      }));
    } else {
      showNotification("An error occured while validating number", "error");
      setModalContainer((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const handleMaxAmount = (num: number) => {
    setElectricDetails((prev) => ({ ...prev, amount: num }));
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    const value = e.target.value.replace(/,/g, "");
    setElectricDetails((prev) => ({ ...prev, amount: value }));
  };

  const handleAddComma = () => {
    const plainNumber = electricDetails?.amount || "";
    const formattedNumber = addCommasToNumbers(+plainNumber);
    setElectricDetails((prev) => ({ ...prev, amount: formattedNumber }));
  };

  const handleFocus = () => {
    if (electricDetails?.amount) {
      const plainNumber = parseFormattedNumber(
        electricDetails?.amount?.toString()
      );
      setElectricDetails((prev) => ({ ...prev, amount: plainNumber }));
    }
  };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      resultModal: false,
    }));
  };

  return (
    <>
      <BuyElectricityTransaction
        data={electricDetails}
        charges={""}
        onClose={closeAllModal}
        open={modalContainer?.modalDetails}
        onclick={handlePinModal}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />

      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {/* You have <span style={{ fontWeight: 700 }}>successfully</span> bought{" "}
          <span style={{ fontWeight: 700 }}> 95.9 Kwh </span> to{" "}
          <span style={{ fontWeight: 700 }}>Joseph Oloruntoba</span> */}
        </Typography>
      </TransactionResult>
      <TransactionResultFailed
        onClose={closeAllModal}
        openResult={modalContainer?.failedModal}
        reason="Transaction Failed"
      />

      <Box>
        <RadioContainer data={radioValues} handleValue={handleRadioValue} />
        <CustomTextSelectContainer
          text={"Electricity Provider"}
          data={provider_data}
          placeHolder="Select Provider"
          modalText={"Select Provider"}
          onclick={handleProviderSelection}
          value={""}
        />
        <TextInputContainer
          text="Meter Number"
          value={electricDetails?.meter_number}
          onclick={(e) =>
            setElectricDetails((prev) => ({
              ...prev,
              meter_number: e.target.value,
            }))
          }
          placeholder="Enter number here..."
        />
        <Stack direction={"column"} alignItems={"center"} sx={{ my: 2 }}>
          <ButtonContainedLight sx={{}} onclick={handleValidation}>
            Validate Meter Number
          </ButtonContainedLight>
        </Stack>{" "}
        {modalContainer?.isLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: 200,
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
        {electricDetails?.validate && (
          <>
            <UserInfo
              firstlabel="Name"
              secondlabel="Address"
              value1={electricDetails?.user_info?.Customer_Name}
              value2={electricDetails?.user_info?.Address}
            />
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary, my: 2 }}
            >
              Your Balance{" "}
            </Typography>
            {!!baseWallet && (
              <MaxAmountValue
                onclick={handleMaxAmount}
                currency={base?.code}
                amount={baseWallet?.value}
              />
            )}{" "}
            <TextValueContainer
              text="Value"
              onFocusHandler={handleFocus}
              onBlurHandler={handleAddComma}
              onchange={handleAmountChange}
              currency={base?.code?.toUpperCase()}
              amount={electricDetails?.amount}
              icon={base?.image}
            />
            <Stack direction="column" alignItems={"center"}>
              <ButtonContainedMain
                sx={{
                  width: "150px",
                  fontWeight: 500,
                  fontSize: "16px",
                  marginLeft: "auto",
                  my: 2,
                }}
                onclick={handleModal}
              >
                Send
              </ButtonContainedMain>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default BuyElectricityContent;

const BuyElectricityTransaction = ({
  onClose,
  onclick,
  open,
  data,
}: TransactionDetailsPropType) => {
  const theme = useTheme();
  const { base } = useAuth();
  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
    fontWeight: 700,
  };
  return (
    <CustomTransactionDetailsModal
      open={open}
      onClose={onClose}
      onclick={onclick}
    >
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {base?.symbol} {data?.amount}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Disco
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.provider?.name}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Type
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.type}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Account name
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.user_info?.Customer_Name}
          </Typography>
        </Stack>
        {/* <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Address
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.address}
          </Typography>
        </Stack> */}
      </Box>
    </CustomTransactionDetailsModal>
  );
};
