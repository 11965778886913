import { Box, Typography, Stack, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import * as React from "react";
import { Outlet } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";

const AuthLayout: React.FC = () => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xs");
  const isSmall = useResponsive("down", "sm", "xs");

  return (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        justifyContent: " center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          background: "url(/assets/icons/kumoLine.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          width: "100%",
          height: "100%",
          right: 0,
          zIndex: -1,
        }}
      />
      <Container>
        <Outlet />
      </Container>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          bottom: 1,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ padding: isMedium ? 2 : 5, color: "white" }}
        >
          <Typography variant={isMedium ? "body1" : "h5"}>
            Privacy Policy
          </Typography>
          <Typography variant={isMedium ? "body1" : "h5"}>
            Copyright@kumo 2024
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
export default AuthLayout;
