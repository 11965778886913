import { Box, Button, InputLabel, Stack, Typography } from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { SwitchInput } from "../UIElements/switchInput";
import { useNotificationService } from "../UIElements/notification";
import { requestBVNVerification } from "../../api/profile";
import { useAuth } from "../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../routes/path";

const BVNVerification: FC<{ inputState: Record<string, any> }> = ({
  inputState,
}) => {
  const theme = useTheme();
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const { showNotification } = useNotificationService();
  const [bvn_state, setBvn_State] = useState({
    bvn_num: inputState?.bvn?.bvn,
    verifying: false,
  });
  const handleBVN = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputState?.bvn?.verified) {
      return;
    }
    setBvn_State((prev) => ({ ...prev, bvn_num: e.target.value }));
  };

  const verifyBVNNumber = async () => {
    const token_key = token ?? "";
    if (bvn_state?.bvn_num?.length === 11) {
      showNotification("Kindly Innput valid BVN number", "error");
      return;
    }
    showNotification("Submitting BVN for verification", "info");

    // send verification request
    const data = {
      first_name: inputState?.first_name?.toString(),
      last_name: inputState?.last_name?.toString(),
      phone_number: inputState?.phone_number?.toString(),
      email: user?.email ?? "",
      bvn: bvn_state?.bvn_num?.toString(),
    };
    const validate_bvn = await requestBVNVerification(data, token_key);
    if ("msg" in validate_bvn) {
      showNotification(validate_bvn.msg, "error");
    } else if (validate_bvn.status == 200) {
      showNotification("BVN verification request created", "success");
      setBvn_State((prev) => ({ ...prev, verifying: true }));
      navigate(PATH_DASHBOARD.root)
    } else {
      showNotification("BVN failed", "error");
    }
  };
  return (
    <Box>
      <InputLabel
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "31.72px",
        }}
      >
        BVN Verification
      </InputLabel>
      <Box
        sx={{
          color: theme.palette.primary.main,
          marginBottom: 3,
          gap: "4px",
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
          paddingLeft: "14px",
          height: "3rem",
          border: `1px solid ${theme.palette.secondary.light}`,
          cursor: "pointer",
          background: theme.palette.common.white,
          justifyContent: "space-between",
          "&:hover": {
            border: `1px solid ${theme.palette.common.black}`,
          },
        }}
      >
        <input
          style={{
            width: "80%",
            height: "2rem",
            border: "none",
            outline: "none",
          }}
          disabled={inputState?.bvn?.admin_reply === "pending" ? true : false}
          placeholder="Enter BVN number"
          value={bvn_state?.bvn_num}
          onChange={handleBVN}
        />
        <SwitchInput checked={inputState?.bvn?.verified} />
      </Box>
      {!inputState?.bvn?.verified && (
        <Stack direction="row" justifyContent="space-between" alignItems={'center'} >
          {inputState?.bvn?.admin_reply === "unverified" || inputState?.bvn?.admin_reply === "failed"  && (
            <Button onClick={verifyBVNNumber}>Verify BVN</Button>
          )}

          {inputState?.bvn?.admin_reply === "pending" && (
            <Typography variant="body2" color={"primary"}>
              Verifying (BVN under review, check back later)
            </Typography>
          )}
           {inputState?.bvn?.admin_reply === "failed" && (
            <Typography variant="body2" color={"error"}>
               BVN verification failed
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default BVNVerification;
