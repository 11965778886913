import { AxiosErrorResponse } from "../interface/api_request";
import { getRequestConfiguration } from "../utils/headerHelper";
import Axios from "../utils/http-request";

export const getKumoAgents = async (
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/agents/get-kumo-agents`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};
