import React, { ChangeEventHandler, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../../../hooks/useResponsive";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ModalContainer from "../../UIElements/modal";
import { SearchInput } from "../../UIElements/searchInput";
import { TextIconContainerSelect } from "../../UIElements/textContainer";
// import { assetData } from "../../../data/asset";
import { CopyMainIcon } from "../../UIElements/svgIcons";
import { useNotificationService } from "../../UIElements/notification";

interface FundValuePropType {
  text: string;
  currency: string;
  amount: string | number;
  icon: any;
  onFocusHandler: () => void;
  onBlurHandler: () => void;
  onchange: ChangeEventHandler<HTMLInputElement>;
}
export const FundWalletTextValueContainer = ({
  text,
  currency,
  amount,
  onBlurHandler,
  onFocusHandler,
  onchange,
  icon,
}: FundValuePropType) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xs");
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow only numbers, decimal point, comma, backspace, and delete
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      ",",
    ];
    const backspaceKey = e.keyCode === 8; // Backspace key code
    const deleteKey = e.keyCode === 46; // Delete key code

    if (!allowedKeys.includes(e.key) && !backspaceKey && !deleteKey) {
      e.preventDefault();
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: isMedium ? "10px" : "20px",
        my: 2,
      }}
    >
      <Stack
        direction={"column"}
        justifyContent={"space-around"}
        sx={{
          gap: "20px",
          background: theme.palette.common.white,
          borderRadius: "20px",
          border: `1px solid ${theme.palette.secondary.light}`,
          width: "26px",
          padding: isMedium ? " 0.6rem" : "1rem",
        }}
      >
        <Typography
          variant={isMedium ? "caption" : "body2"}
          sx={{ color: theme.palette.secondary.main }}
        >
          {currency}
        </Typography>
        <img src={icon} alt="" width={24} height={23} />
      </Stack>
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        sx={{
          gap: "5px",
          background: theme.palette.common.white,
          borderRadius: "20px",
          border: `1px solid ${theme.palette.secondary.light}`,
          padding: isMedium ? " 0.7rem" : "1rem",
          flexGrow: 1,
        }}
      >
        <Typography
          variant={isMedium ? "caption" : "body2"}
          sx={{ color: theme.palette.secondary.main }}
        >
          {text}
        </Typography>
        <input
          value={amount}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
          placeholder="0"
          onChange={onchange}
          onKeyDown={handleKeyDown}
          style={{
            color: theme.palette.common.black,
            fontFamily: "Outfit",
            fontSize: isMedium ? "20px" : "24px",
            fontWeight: 700,
            border: 0,
            outline: "none",
          }}
        />
      </Stack>
    </Box>
  );
};

interface FundWalletType {
  text: string;
  data: any;
  placeholder: string;
  value: string | number;
  onclick: (obj: any) => void;
}

export const FundWalletTextSelectContainer = ({
  text,
  data,
  placeholder,
  value,
  onclick,
}: FundWalletType) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xl");
  const [select, setSelect] = useState("");
  const [modalContents, setModalContents] = useState(false);
  const [filteredData, setFilteredData] = useState<{
    items_list: any[];
    search_value: string;
  }>({
    items_list: [],
    search_value: "",
  });

  useEffect(() => {
    setFilteredData((prev) => ({ ...prev, items_list: [...data] }));
  }, [data]);
  const handleChange = (details: any) => {
    setSelect(details?.name as string);
    onclick(details);
    handleModalContents();
  };
  const handleModalContents = () => {
    setModalContents((prev) => !prev);
  };

  // handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setFilteredData((prev) => ({ ...prev, search_value: value }));

    if (value.length === 0) {
      setFilteredData((prev) => ({ ...prev, items_list: data })); // Reset to original list if search input is empty
    } else {
      const filteredTokens = filteredData?.items_list?.filter((token) =>
        token?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredData((prev) => ({ ...prev, items_list: filteredTokens }));
    }
  };
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        gap: "20px",
        borderRadius: "20px",
        flexGrow: 1,
        paddingY: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          padding: "14px",
          borderRadius: "10px",
          border: "1px solid #E0E0E0",
          cursor: "pointer",
          background: theme.palette.common.white,
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={handleModalContents}
      >
        <Typography
          variant="body2"
          sx={{ color: theme.palette.secondary.main }}
        >
          {select.length >= 2 ? select : placeholder}
        </Typography>
        <span style={{ cursor: "pointer" }}>
          <KeyboardArrowDownIcon />
        </span>
      </Box>
      <ModalContainer
        openModal={modalContents}
        sx={{}}
        fullWidth={true}
        size="sm"
        handleModal={handleModalContents}
      >
        <Box
          sx={{
            background: theme.palette.common.white,
            padding: 3,
            borderRadius: "20px",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, color: theme.palette.text.primary }}
          >
            Select Coin
          </Typography>
          <Divider sx={{ my: 3 }} />
          <SearchInput
            text={"Search..."}
            handler={handleSearchChange}
            width={"100%"}
            value={filteredData?.search_value}
          />
          {data?.map((data: any, index: number) => (
            <TextIconContainerSelect
              key={index}
              image={data?.image ?? ""}
              onclick={() => handleChange(data)}
            >
              {data.name?.toUpperCase()}
            </TextIconContainerSelect>
          ))}
        </Box>
      </ModalContainer>
    </Stack>
  );
};

export const FundWalletInput: React.FC<{ value: string; label: string }> = ({
  value,
  label,
}) => {
  const theme = useTheme();
  const { showNotification } = useNotificationService();
  const handleCopy = (address: string) => {
    if (!address) {
      return;
    }
    navigator.clipboard
      .writeText(address)
      .then(() => {
        showNotification("Copied", "success");
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  return (
    <Stack
      sx={{
        my: 3,
      }}
    >
      <InputLabel
        sx={{
          my: 1,
          color: theme.palette.secondary.main,
        }}
      >
        {label}
      </InputLabel>{" "}
      <TextField
        type={"text"}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => handleCopy(value)}>
                <CopyMainIcon />
              </IconButton>
            </InputAdornment>
          ),
          inputProps: {
            style: {},
          },
        }}
      />
    </Stack>
  );
};
