import React, { useState } from "react";
import {
  BankTransferFirstStep,
  BankTransferSecondStep,
} from "../../../components/dashboard/fundWallet/BankTransferContent";
import { TransactionResult, TransactionResultFailed } from "../../../components/transactionResult";
import { PATH_DASHBOARD } from "../../../routes/path";
import { useNavigate } from "react-router-dom";
import { BankStateType } from "../../../interface/dashboard";
import { useAuth } from "../../../hooks/useAuth";
import { useNotificationService } from "../../../components/UIElements/notification";
import { parseFormattedNumber } from "../../../utils/basic";
import { fundWallet } from "../../../api/transaction";

const BankTransfer = () => {
  const navigate = useNavigate();
  const { base, token } = useAuth();
  const { showNotification } = useNotificationService();
  const [bankDetails, setBankDetails] = useState<BankStateType>({
    amount: "",
    bank: "",
  });
  const [modalContainer, setModalContainer] = useState({
    firstInfo: true,
    secondInfo: false,
    thirdInfo: false,
    failed: false,
  });

  const handleSecondInfo = () =>
    setModalContainer((prev) => ({ ...prev, secondInfo: !prev.secondInfo }));
  const handleThirdInfo = () =>
    setModalContainer((prev) => ({ ...prev, thirdInfo: !prev.thirdInfo }));

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      firstInfo: false,
      secondInfo: false,
      thirdInfo: false,
      failed: false,
    }));
  };

  const handleSubmitFunds = async () => {
    const token_key = token ?? "";
    showNotification("Saving your funding details", "info");
    const data = {
      payment_method: "bank",
      naira_amount_paid: parseFormattedNumber(bankDetails?.amount?.toString()),
      method: "bank",
      currency_name: base?.name,
    };
    const submit_result = await fundWallet(data, token_key);
    if ("msg" in submit_result) {
      showNotification(submit_result.msg, "error");
    } else if (submit_result.status == 200) {
      setModalContainer((prev) => ({ ...prev, thirdInfo: true }));
    } else {
      showNotification("An error occured while fetching data", "error");
      setModalContainer((prev) => ({ ...prev, failed: true }));
    }
  };

  const navigateHome = () => {
    navigate(PATH_DASHBOARD.fundWallet);
  };
  return (
    <>
      <BankTransferFirstStep
        bankDetails={bankDetails}
        setBankDetails={setBankDetails}
        open={modalContainer.firstInfo}
        onclick={handleSecondInfo}
        navigate={navigateHome}
        onclose={closeAllModal}
      />
      <BankTransferSecondStep
        open={modalContainer.secondInfo}
        onclick={handleThirdInfo}
        onclose={closeAllModal}
        navigate={navigateHome}
        submit={handleSubmitFunds}
      />
      <TransactionResult
        txId={""}
        onclose={navigateHome}
        openResult={modalContainer.thirdInfo}
      >
        {""}
      </TransactionResult>
      <TransactionResultFailed
        onClose={navigateHome}
        openResult={modalContainer?.failed}
        reason={"Transaction Failed"}
      />
    </>
  );
};

export default BankTransfer;
