import React from "react";
import ModalContainer from "../../UIElements/modal";
import { Box, Stack, Typography, Divider } from "@mui/material";
import { ButtonContainedMain } from "../../UIElements/button";
import { useTheme } from "@mui/material/styles";

import { CloseIcon } from "../../UIElements/svgIcons";
import { FundWalletInput, FundWalletTextValueContainer } from "./contentStyle";
import { addCommasToNumbers, parseFormattedNumber } from "../../../utils/basic";
import { useAuth } from "../../../hooks/useAuth";

// ----------------------------------

export const PaypalFirstStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  open: boolean;
  navigate: () => void;
  setPaypalDetails: React.Dispatch<React.SetStateAction<{paypal_email: string, amount: string | number}>>;
  paypalDetails: any;
}> = ({ onclick, onclose, open, paypalDetails, navigate, setPaypalDetails, }) => {
  const theme = useTheme();
  const { base } = useAuth();

  const handleNextContent = () => {
    onclose()
    onclick();
  };

  const handleAddComma = () => {
    const plainNumber = paypalDetails?.amount || "";
    const formattedNumber = addCommasToNumbers(+plainNumber);
    setPaypalDetails((prev) => ({ ...prev, amount: formattedNumber }));
  };

  const handleFocus = () => {
    if (paypalDetails?.amount) {
      const plainNumber = parseFormattedNumber(paypalDetails?.amount?.toString());
      setPaypalDetails((prev) => ({ ...prev, amount: plainNumber }));
    }
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    const value = e.target.value.replace(/,/g, "");
    setPaypalDetails((prev) => ({ ...prev, amount: value }));
  };
  return (
    <>
      <ModalContainer
        sx={{}}
        size={"sm"}
        openModal={open}
        fullWidth={true}
        handleModal={() => {}}
      >
        <Box
          sx={{
            background: theme.palette.common.white,
            padding: 4,
            borderRadius: "20px",
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ color: theme.palette.text.primary }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Fund Wallet
            </Typography>
            <span onClick={navigate} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </span>
          </Stack>
          <Typography variant="body2">
            Enter the amount to add, click “Continue”, and complete payment to
            update your wallet balance
          </Typography>
          <Divider sx={{ my: 3 }} />
          <FundWalletTextValueContainer
            text="You Pay"
            onBlurHandler={handleAddComma}
            onFocusHandler={handleFocus}
            onchange={handleAmountChange}
            currency={base?.code?.toUpperCase()}
            amount={paypalDetails?.amount}
            icon={base?.image}
          />
          <Divider sx={{ my: 3 }} />
          <Stack direction={"row"} justifyContent={"center"}>
            <ButtonContainedMain
              onclick={handleNextContent}
              sx={{ width: "150px" }}
            >
              Continue
            </ButtonContainedMain>
          </Stack>
        </Box>
      </ModalContainer>
    </>
  );
};

export const PaypalSecondStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  open: boolean;
  navigate: () => void;

}> = ({ onclick, onclose, open, navigate }) => {
  const theme = useTheme();
  const handleNextContent = () => {
    onclick();
  };
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ color: theme.palette.text.primary }}
        >
          <Typography>Paypal</Typography>
          <span onClick={navigate} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Typography variant="caption">Fund your wallet via paypal </Typography>
        <Divider sx={{ my: 3 }} />
        <FundWalletInput
          label="Account Username"
          value="Kumo.payment@gmail.com"
        />
        <Divider sx={{ my: 3 }} />
        <Typography variant="caption" >
        Kidly note that this option is manual and your wallet will be credited when value is received fron your bank, Crediting your wallet might take upto 5 miniutes - 2 hours depending on bank network, Kindly excercise patience while the process is ongoin.
        </Typography>
        <Stack direction={"row"} justifyContent={"center"} sx={{marginTop: 3}}>
          <ButtonContainedMain onclick={handleNextContent} sx={{ width: "150px" }}>
            Continue
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};
