import { FC, ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const PageHeaderProvider:FC<{children: ReactNode}> = ({ children }) => {
  return (
    <HelmetProvider>
      <Helmet
        defaultTitle="Kumo Web App"
        titleTemplate="Kumo | %s Page"
        defer={false}
      >
        <meta name="description" content="Kumo Web App" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default PageHeaderProvider;