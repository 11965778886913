import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import BuyElectricityContent from "../../components/utilities/BuyElectricityContent";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../../components/UIElements/notification";
import { getUtilitiesList } from "../../api/utilities";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";
import { useDetails } from "../../hooks/useDetails";
import { WalletBalanceType } from "../../interface/useDetail";
import { hasValues } from "../../utils/basic";

const BuyElectricity: React.FC = () => {
  const theme = useTheme();
  const { showNotification } = useNotificationService();
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [baseWallet, setBaseWallet] = useState<WalletBalanceType>();
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const { base, token } = useAuth();
  const { wallet, fetchUserData } = useDetails();

  useEffect(() => {
    fetchUtilities();
    getBaseWallet();
  }, []);

  const fetchUtilities = async () => {
    const token_key = token ?? "";
    setIsLoading(true);

    const fetch_data = await getUtilitiesList("electricity-bill", token_key);
    if ("msg" in fetch_data) {
      console.log("Error fetching currency data:", fetch_data.msg);
      showNotification(fetch_data.msg, "error");
      setIsLoading(false);
    } else if (fetch_data.status == 200) {
      setData(fetch_data?.data?.data);
      const user_data = await fetchUserData(token_key);
      const phone_number = user_data?.phone_number ?? "";
      setUserPhoneNumber(phone_number);
      setIsLoading(false);

    } else {
      showNotification("An error occured while fetching data", "error");
      setIsLoading(false);

    }
  };
  // get the value of the base from wallet
  const getBaseWallet = () => {
    const walletBalance = wallet?.find(
      (currency: WalletBalanceType) =>
      currency.code === base?.code || currency.name === base?.name
      );
    setBaseWallet(walletBalance);
  };
  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Buy Electricity
          </Typography>
        </Box>
      </Header>
      {data.length > 1 ? (
        <DashboardGridContainer>
          <BuyElectricityContent
            provider_data={data}
            userPhoneNumber={userPhoneNumber}
            baseWallet={baseWallet}
          />
        </DashboardGridContainer> 
      )  : isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 350,
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />{" "}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 350,
            justifyContent: "center",
          }}
        >
          <Typography variant="h4">List is Empty</Typography>
        </Box>
      )}
    </>
  );
};

export default BuyElectricity;
