import { Button } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { useTheme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

interface ButtonPropsType {
  sx: CSSProperties;
  children: React.ReactNode;
  onclick: () => void;
}

export const ButtonContainedLight = ({
  sx,
  children,
  onclick,
}: ButtonPropsType) => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        padding: "8px 16px",
        borderRadius: "20px",
        fontWeight: 400,
        fontSixe: "14px",
        color: theme.palette.primary.main,
        background: theme.palette.primary.light,
        ...sx,
      }}
      onClick={onclick}
    >
      {children}
    </Button>
  );
};

export const ButtonContainedMain = ({
  sx,
  children,
  onclick,
}: ButtonPropsType) => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        padding: " 14px, 24px, 14px, 24px",
        borderRadius: "10px",
        width: "150px",
        fontWeight: 500,
        fontSize: "16px",
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        ...sx,
      }}
      onClick={onclick}
    >
      {children}
    </Button>
  );
};

export const LoadingButtonContainer: FC<{ type: string; loading: boolean, children: ReactNode, onclick: () => void; }> = ({
  type,
  loading,
  children,
  onclick
}) => {
  const theme = useTheme();

  return (
    <LoadingButton onClick={onclick}
      sx={{
        marginLeft: "auto",
        padding: " 14px, 24px, 14px, 24px",
        borderRadius: "10px",
        width: "150px",
        fontWeight: 500,
        fontSize: "16px",
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
      }}
    >
      {children}
    </LoadingButton>
  );
};
