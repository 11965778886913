


export const getRequestConfiguration = (token: string, extraHeader: any) => {
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`,
            ...extraHeader
        }
    }
    return header
}
