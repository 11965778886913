import React from "react";
import ModalContainer from "./UIElements/modal";
import { Box, Stack, Typography, Divider } from "@mui/material";
import { ButtonContainedMain } from "./UIElements/button";
import { useTheme } from "@mui/material/styles";

import { CloseIcon } from "./UIElements/svgIcons";

interface CustomPropsType {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onclick: () => void;
}

// ----------------------------------
const CustomTransactionDetailsModal = ({
  open,
  onClose,
  onclick,
  children,
}: CustomPropsType) => {
  const theme = useTheme();
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography>Transaction Details</Typography>
          <span onClick={onClose} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Divider sx={{ my: 3 }} />
        {children}
        <Divider sx={{ my: 3 }} />
        <Stack direction={"row"} justifyContent={"center"}>
          <ButtonContainedMain onclick={onclick} sx={{ width: "150px" }}>
            Proceed
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};

export default CustomTransactionDetailsModal;
