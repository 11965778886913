import React, { useState } from "react";
import ModalContainer from "../UIElements/modal";
import { Box, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  ButtonContainedLight,
  ButtonContainedMain,
} from "../UIElements/button";

const TermsAndCondition: React.FC<{
  open: boolean;
  onClose: () => void;
  onclick: () => void;
}> = ({ open, onClose, onclick }) => {
  const theme = useTheme();
  const [fullDetails, setFullDetails] = useState(true);

  const handleFullDetails = () => setFullDetails((prev) => !prev);

  const handleTermsAndCondition = () => {
    onclick();
    onClose()
  };
  return (
    <>
      <ModalContainer
        sx={{}}
        size={"md"}
        openModal={open}
        fullWidth={true}
        handleModal={onClose}
      >
        <Box
          sx={{
            color: theme.palette.text.primary,
            background: theme.palette.common.white,
            padding: "1.5rem",
            borderRadius: "20px",
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: 700, textAlign: "center" }}
          >
            Kumo Staking
          </Typography>
          <Typography variant="body1" sx={{ marginY: 2 }}>
            Welcome to Kumo Staking, an initiative designed to help our users
            generate passive income while reducing the impact of cryptocurrency
            market volatility.
            <br />
            <br />
            By joining the pool of Kumo users, you'll have the opportunity to
            stake cryptocurrencies/tokens and receive additional rewards and
            incentives.
            <br /> <br />
            We offer various staking packages tailored to meet your financial
            needs:
            <br /> <br />
            MASTER KUMO (ERC20) is a minimum amount of $5000 investment with 30%
            ROI <br />
            MASTER KUMO (TRC20) is a minimum amount of $5000 investment with 30%
            ROI <br />
            PREMIUM KUMO is a minimum amount of $1000 investment with 30% ROI{" "}
            <br />
            BASIC KUMO is a minimum amount of $ 10 investment with 6% ROI
            <br /> <br />
          </Typography>
          {fullDetails ? (
            <Typography>
              <span style={{ display: "block", fontWeight: 700 }}>Rules:</span>{" "}
              1.⁠ ⁠All investments are made from your wallet, and repayments
              will be credited to your Kumo wallet. 2.⁠ ⁠Referral rewards are
              paid when your referee successfully completes a staking
              transaction...
            </Typography>
          ) : (
            <FullInformation />
          )}
          <Typography
            onClick={() => {}}
            sx={{
              color: theme.palette.primary.main,
              textDecorationLine: "underline",
              my: 3,
            }}
          >
            Terms and Conditon apply{" "}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              textDecorationLine: "underline",
              my: 3,
            }}
          >
            Accept Terms and Conditions{" "}
          </Typography>
          <Stack direction="column" alignItems={"center"} sx={{ gap: "20px" }}>
            <ButtonContainedLight sx={{}} onclick={handleFullDetails}>
              {fullDetails ? " See more" : "Collapse"}
            </ButtonContainedLight>
            <ButtonContainedMain
              sx={{ width: 200 }}
              onclick={handleTermsAndCondition}
            >
              Agree And Continue
            </ButtonContainedMain>
          </Stack>
        </Box>
      </ModalContainer>
    </>
  );
};

export default TermsAndCondition;

const FullInformation = () => {
  return (
    <Box>
      <Typography sx={{ mb: 2 }}>
        <span style={{ display: "block", fontWeight: 700 }}>Rules:</span>
        1.⁠ ⁠All investments are made from your wallet, and repayments will be
        credited to your Kumo wallet.
        <br />
        2.⁠ ⁠Referral rewards are paid when your referee successfully completes
        a staking transaction
        <br />
        3.⁠ ⁠Both referrals and referees receive payments on the due date of
        investment
        <br />
        4.⁠ ⁠Interest is automatically funded into your wallet on a monthly
        basis.
        <br />
        5.⁠ ⁠The principal amount staked is credited back to your wallet at the
        end of each cycle.
        <br />
        6.⁠ ⁠Monthly interest can be withdrawn.
        <br />
        7.⁠ ⁠The staking date is extended to the top-up date when a to
        investment is initiated to an active staking
        <br />
        8.⁠ ⁠Termination of an investment incurs a termination fee, resulting in
        a deduction of 10% from the principal amount
        <br />
        9.⁠ ⁠If no instruction is provided by the user, the system will
        automatically roll over funds whose cycle has completed after
        approximately 30 days or more
      </Typography>
      <Typography sx={{ mt: 3 }}>
        <span style={{ fontWeight: 700 }}>Eligibility:</span> To be eligible for
        Kumo Staking, you must have a Kumo account. <br />
        <br />
        Duration: All staking packages run for a cycle of 12 months, but users
        can request termination by contacting our support team at
        info@kumo.africa. Please note that all termination requests will be
        processed and approved by the Kumo Team before disbursement.
        <br /> <br />
        NB: Please be aware that the terms of the Kumo Staking programs are
        subject to change, and referral payments are only active during ongoing
        promotions. Terms and Conditions apply.
        <br />
        <br />
        We hope you find the Kumo Staking program beneficial and encourage you
        to reach out to our support team if you have any further questions or
        require assistance.
      </Typography>
    </Box>
  );
};
