import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { useTheme } from "@mui/material/styles";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";

export const headerSty = {
  color: "#B8B8B8",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "10.2px",
};

export const bodySty = {
  color: "#000000",
  fontSize: "13px",
  fontWeight: 500,
  lineHeight: "19.2px",
  borderBottom: "none",
};

interface ReferralProps {
  data: any[];
}

export default function ReferralTable({ data }: ReferralProps) {
  const theme = useTheme();
  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
        <Table
          sx={{ border: "none", boxShadow: "0" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ ...headerSty, background: theme.palette.common.white }}
              >
                S/N
              </TableCell>
              <TableCell
                sx={{ ...headerSty, background: theme.palette.common.white }}
                align="right"
              >
                Name
              </TableCell>
              <TableCell
                sx={{ ...headerSty, background: theme.palette.common.white }}
                align="right"
              >
                Commission Form
              </TableCell>
              <TableCell
                sx={{ ...headerSty, background: theme.palette.common.white }}
                align="right"
              >
                Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((value) => (
              <TableRow
                key={value.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={bodySty} component="th" scope="row">
                  {value.name}
                </TableCell>
                <TableCell sx={bodySty} align="right">
                  {value.calories}
                </TableCell>
                <TableCell sx={bodySty} align="right">
                  {value.fat}
                </TableCell>
                <TableCell sx={bodySty} align="right">
                  {value.protein}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 && (
        <Box
          padding={2}
          display="flex"
          flexDirection="column"
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            color: theme.palette.primary.main,
            height: 150,
          }}
        >
          <Typography
            sx={{
              color: "#000",
              textAlign: "center",
              fontSize: "17px",
              my: 2,
              fontWeight: 600,
            }}
          >
            No referrals yet.
          </Typography>
        </Box>
      )}
    </>
  );
}
