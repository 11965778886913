import { FC, useState } from "react";
//
import { Box, Typography, Stack } from "@mui/material";
import {
  TextInputContainer,
  TextNumberInputContainer,
} from "../UIElements/textContainer";
import { useTheme } from "@mui/material/styles";
import {
  ButtonContainedLight,
  ButtonContainedMain,
} from "../UIElements/button";
import {
  TransactionResult,
  TransactionResultFailed,
} from "../transactionResult";
import { TextLabelContainer } from "../textInput";
import TermsAndCondition from "./TermsAndCondition";
import useResponsive from "../../hooks/useResponsive";
import { PlanDetailPropType } from "../../interface/kumo-stacking";
import CheckboxUI from "../UIElements/checkbox";
import { addCommasToNumbers, parseFormattedNumber } from "../../utils/basic";
import { createInvestment } from "../../api/investments";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../UIElements/notification";

//

const PlanDetailsContent: FC<{
  planDetails: PlanDetailPropType | undefined;
}> = ({ planDetails }) => {
  const theme = useTheme();
  const { token, user } = useAuth();
  const isMedium = useResponsive("down", "md", "xl");
  const [terms, setTerms] = useState(false);
  const [checked, setChecked] = useState(false);
  const { showNotification } = useNotificationService();
  const [modalContainer, setModalContainer] = useState<{
    resultModal: boolean;
    failedModal: boolean;
    token_amount_value: string | number;
  }>({
    resultModal: false,
    failedModal: false,
    token_amount_value: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleModal = () => {
    // check term
    if (!checked) {
      showNotification("Kindly Accept Terms and Conditions", "error");
      return;
    }
    // check amount
    const token_amount = parseFormattedNumber(
      modalContainer?.token_amount_value?.toString()
    );
    const min_amount = planDetails?.min_amount ?? 0;
    const max_amount = planDetails?.max_amount ?? 0;

    if (token_amount < min_amount || token_amount > max_amount) {
      showNotification(
        `Amount must be between ${addCommasToNumbers(
          min_amount
        )} and ${addCommasToNumbers(max_amount)}`,
        "error"
      );
      return;
    }

    handleResultModal();
  };

  const handleResultModal = async () => {
    const tokey_key = token ?? "";
    const body = {
      package_id: planDetails?._id ?? "",
      user_id: user?.user_id ?? "",
      user_name: `${user?.first_name ?? ""} + ${user?.last_name ?? ""}`,
      investment_token: planDetails?.package_token[0] ?? "",
      investment_duration: planDetails?.package_duration[0] ?? 0,
      investment_apr: planDetails?.package_apr ?? 0,
      investment_amount: parseFormattedNumber(
        modalContainer?.token_amount_value.toString()
      ),
      referral_id: user?.user_referral_id ?? "",
    };
    const create_investment = await createInvestment(body, tokey_key);
    console.log(create_investment);
    if ("msg" in create_investment) {
      showNotification(create_investment.msg, "error");
    } else if (create_investment.status == 201) {
      showNotification("Investment Created", "success");
      setModalContainer((prev) => ({
        ...prev,
        resultModal: !prev.resultModal,
      }));
    } else {
      showNotification("An error occured", "error");
      setModalContainer((prev) => ({
        ...prev,
        failedModal: !prev.failedModal,
      }));
    }
  };
  const handleAmountChange = (e: { target: { value: any } }) => {
    const value = e.target.value.replace(/,/g, "");
    setModalContainer((prev) => ({ ...prev, token_amount_value: value }));
  };

  const handleAddComma = () => {
    const plainNumber = modalContainer?.token_amount_value || "";
    const formattedNumber = addCommasToNumbers(+plainNumber);
    setModalContainer((prev) => ({
      ...prev,
      token_amount_value: formattedNumber,
    }));
  };

  const handleFocus = () => {
    if (modalContainer?.token_amount_value) {
      const plainNumber = parseFormattedNumber(
        modalContainer?.token_amount_value?.toString()
      );
      setModalContainer((prev) => ({
        ...prev,
        token_amount_value: plainNumber,
      }));
    }
  };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      resultModal: false,
      failedModal: false,
    }));
  };

  const handleTermsAndCondition = () => {
    setTerms((prev) => !prev);
  };
  console.log(planDetails);
  return (
    <>
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Transaction Successful
        </Typography>
      </TransactionResult>
      <TransactionResultFailed
        onClose={closeAllModal}
        openResult={modalContainer?.failedModal}
        reason={"Investment Plan failed"}
      />
      <TermsAndCondition
        open={terms}
        onclick={handleChecked}
        onClose={handleTermsAndCondition}
      />
      <Box sx={{ mt: 4 }}>
        <Box>
          <TextLabelContainer
            label="Investment token"
            text={planDetails?.package_token[0] ?? ""}
          />
          <TextLabelContainer
            label="Investment type"
            text={planDetails?.is_fixed ? "Fixed" : "Non-Fixed"}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ gap: "20px", padding: 2 }}
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.text.secondary,
                width: isMedium ? "80px" : "150px",
              }}
            >
              Duration
            </Typography>
            <ButtonContainedLight
              sx={{ borderRadius: "8px" }}
              onclick={() => {}}
            >
              {planDetails?.package_duration[0]} Month <span style={{textTransform: "lowercase"}}>(s)</span>  
            </ButtonContainedLight>
          </Stack>
          <TextInputContainer
            text={"APR"}
            onclick={() => {}}
            value={planDetails?.package_apr + "%"}
            placeholder={"Text Here..."}
          />
          <TextNumberInputContainer
            onBlurHandler={handleAddComma}
            onFocusHandler={handleFocus}
            text={planDetails?.package_token_code[0]?.toUpperCase() ?? ""}
            onclick={handleAmountChange}
            value={modalContainer?.token_amount_value}
            placeholder={"00"}
          />
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Typography sx={{ marginRight: "2rem" }} variant="caption">
              {" "}
              {planDetails?.min_amount} - {planDetails?.max_amount}{" "}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{ gap: "15px", marginBottom: 2 }}
            justifyContent={"center"}
          >
            <Typography variant="body2">
              <CheckboxUI checked={checked} onCheck={handleChange} /> I agree
              with{" "}
              <span
                onClick={handleTermsAndCondition}
                style={{
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                  textDecorationLine: "underline",
                  fontWeight: 600,
                }}
              >
                Terms and conditon{" "}
              </span>
            </Typography>
          </Stack>
          <Stack direction="column" alignItems={"center"}>
            <ButtonContainedMain
              sx={{
                width: "150px",
                fontWeight: 500,
                marginLeft: isMedium ? "0.6rem" : "auto",
                fontSize: "16px",
              }}
              onclick={handleModal}
            >
              Confirm
            </ButtonContainedMain>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default PlanDetailsContent;

// const PlanContentTransaction = ({
//   onClose,
//   onclick,
//   open,
//   data,
// }: TransactionDetailsPropType) => {
//   const theme = useTheme();
//   const textStyle = {
//     color: theme.palette.secondary.main,
//   };
//   const valueStyle = {
//     color: theme.palette.common.black,
//     fontWeight: 700,
//   };
//   return (
//     <CustomTransactionDetailsModal
//       open={open}
//       onClose={onClose}
//       onclick={onclick}
//     >
//       <Box>
//         <Stack
//           direction={"row"}
//           justifyContent={"space-between"}
//           sx={{ my: 2 }}
//         >
//           <Typography variant="body2" sx={textStyle}>
//             Amount
//           </Typography>
//           <Typography variant="body2" sx={valueStyle}>
//             {data?.amount}
//           </Typography>
//         </Stack>
//         <Stack
//           direction={"row"}
//           justifyContent={"space-between"}
//           sx={{ my: 2 }}
//         >
//           <Typography variant="body2" sx={textStyle}>
//             Fee
//           </Typography>
//           <Typography variant="body2" sx={valueStyle}>
//             {data?.fee}
//           </Typography>
//         </Stack>
//         <Stack
//           direction={"row"}
//           justifyContent={"space-between"}
//           sx={{ my: 2 }}
//         >
//           <Typography variant="body2" sx={textStyle}>
//             {""} Value
//           </Typography>
//           <Typography variant="body2" sx={valueStyle}>
//             {data?.value}
//           </Typography>
//         </Stack>
//         <Stack
//           direction={"row"}
//           justifyContent={"space-between"}
//           sx={{ my: 2 }}
//         >
//           <Typography variant="body2" sx={textStyle}>
//             Email
//           </Typography>
//           <Typography variant="body2" sx={valueStyle}>
//             {data?.email}
//           </Typography>
//         </Stack>
//       </Box>
//     </CustomTransactionDetailsModal>
//   );
// };
