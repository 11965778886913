import React, { useState } from "react";
import {
  ResetPasswordDetailsOne,
  ResetPasswordDetailsTwo,
  ResetPasswordDetailsThree,
} from "../../components/auth/ResetPasswordDetails";
import { Box, Typography, Stack } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Helmet } from "react-helmet-async";

const ResetPassword: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [values, setValues] = useState({
    email: "",
    code: "",
    password: "",
  });
  const handlePage = () => {
    setPageNumber((prev) => prev + 1);
  };
  const handlePrevPage = () => {
    if (pageNumber === 1) {
      return;
    }
    setPageNumber((prev) => prev - 1);
  };
  const isMedium = useResponsive("down", "md", "xs");
  const isSmall = useResponsive("down", "sm", "xl");
  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Box
        sx={{
          background: "white",
          borderRadius: "36px",
          width: isMedium ? (isSmall ? "90%" : "450px") : "80%",

          marginX: "auto",
          padding: isMedium ? 3 : 4,
        }}
      >
        <Stack
          direction={isMedium ? "column" : "row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ margin: 3, gap: 4 }}
        >
          <Box sx={{ width: isMedium ? "100%" : "35%" }}>
            <img
              src="/assets/images/kumo-logo.png"
              width="140"
              height="40"
              alt=""
            />
            <Typography
              sx={{
                fontWeight: 300,
                lineHeight: isMedium ? "50px" : "56px",
                fontSize: isMedium ? "40px" : "52px",
                my: 2,
              }}
            >
              {pageNumber === 3 ? "Create Password" : "Account Recovery"}
            </Typography>
            <Typography variant="h5">
              Lets help you reset your password{" "}
            </Typography>
          </Box>
          {pageNumber === 1 && (
            <ResetPasswordDetailsOne
              values={values}
              changeValues={setValues}
              pageHandler={handlePage}
              prevPage={handlePrevPage}
            />
          )}
          {pageNumber === 2 && (
            <ResetPasswordDetailsTwo
              values={values}
              changeValues={setValues}
              pageHandler={handlePage}
              prevPage={handlePrevPage}
            />
          )}
          {pageNumber === 3 && (
            <ResetPasswordDetailsThree
              values={values}
              prevPage={handlePrevPage}
            />
          )}
        </Stack>
      </Box>
    </>
  );
};

export default ResetPassword;
