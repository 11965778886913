import * as React from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Typography, useMediaQuery, Box } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import useResponsive from "../../../hooks/useResponsive";
import { PATH_AUTH, PATH_PROFILE } from "../../../routes/path";
import { useAuth } from "../../../hooks/useAuth";
import { useDetails } from "../../../hooks/useDetails";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    background: theme.palette.common.white,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: 2,
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const MenuDropDown: React.FC<{ handleModal: () => void }> = ({
  handleModal,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const isMedium = useResponsive("down", "md", "lg");
  const theme = useTheme();
  const { clear_data } = useAuth();
  const { clear_userData } = useDetails();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigateTo = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    clear_data();
    clear_userData();
    navigateTo(PATH_AUTH.login);
  };

  return (
    <div>
      <Button
        sx={{
          textTransform: "none",
          fontSize: "16px",
          height: "3rem",
          paddingX: !isMedium ? 3 : 0,
          gap: "6px",
          color: theme.palette.primary.main,
          borderRadius: isMedium ? "10px" : "100px",
        }}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={!isMedium && <KeyboardArrowDownIcon />}
      >
        <img
          src="/assets/icons/avatar.png"
          alt="icon"
          width={40}
          height={40}
          style={{ borderRadius: "100px" }}
        />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigateTo(PATH_PROFILE.root)} disableRipple>
          <EditIcon style={{ margin: "0 10px" }} />
          Profile
        </MenuItem>
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon style={{ margin: "0 10px" }} />
          Logout
        </MenuItem>
      </StyledMenu>
    </div>
  );
};
