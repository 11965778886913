import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import TransferLocalMoneyContents from "../../components/dashboard/TransferLocalMoneyContents";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../../components/UIElements/notification";
import { useDetails } from "../../hooks/useDetails";
import { WalletBalanceType } from "../../interface/useDetail";
import { getBankList } from "../../api/utilities";
import { getFeeCost } from "../../api/settings";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";

const TransferLocalMoney: React.FC = () => {
  const theme = useTheme();
  const { token, base } = useAuth();
  const { showNotification } = useNotificationService();
  const [bankList, setBankList] = useState<any[]>([]);
  const [baseWallet, setBaseWallet] = useState<WalletBalanceType>({
    name: "",
    code: "",
    coin_id: "",
    value: 0,
  });

  const { wallet } = useDetails();
  useEffect(() => {
    fetchCrypto();
  }, []);
  const fetchCrypto = async () => {
    const token_key = token ?? "";
    const result = await getBankList(token_key);

    if (result?.status != 200) return;

    if (!wallet) return;
    const list = Array.isArray(wallet) && wallet.length > 1 ? wallet : [];
    const baseWallet = list?.find(
      (baseObj) => baseObj?.code === base?.code || baseObj?.name === base?.name
    );

    if (!baseWallet) return;
    setBaseWallet(baseWallet);
    setBankList(result?.data?.data);
  };
  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Transfer Money
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        {bankList?.length >= 1 && (
          <TransferLocalMoneyContents
            baseWallet={baseWallet}
            bankList={bankList}
          />
        )}{" "}
        {bankList?.length === 0 && (
          <Box
            sx={{ 
              display: "flex",
              height: "60vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
      </DashboardGridContainer>
    </>
  );
};

export default TransferLocalMoney;
