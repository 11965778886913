import { Box, Typography } from "@mui/material";
import React from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import AccountInfo from "../../components/dashboard/AccountInfo";
import { useTheme } from "@mui/material/styles";
import { TextContainer } from "../../components/UIElements/textContainer";
import { PATH_DASHBOARD } from "../../routes/path";
import Header from "../../layouts/dashboard/header";

const SendRecieve: React.FC = () => {
  const theme = useTheme();
  return (
    <>
      <Header>
        <Box sx={{flexGrow: 1}}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: "#2C393F" }}>
          Send/Receive Crypto
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        <AccountInfo />
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
          >
            Action
          </Typography>
          <TextContainer path={PATH_DASHBOARD.sendCrypto}>
            Send Crypto
          </TextContainer>
          <TextContainer path={PATH_DASHBOARD.receiveCrypto}>
            Recieve Crypto
          </TextContainer>
        </Box>
      </DashboardGridContainer>
    </>
  );
};

export default SendRecieve;
