import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  TableData,
  TableInfoData,
  transactionData,
} from "../../data/transactionData";
import { useTheme } from "@mui/material/styles";
import { TransactionsTable } from "../table/transaction";
import { getAllTransactions } from "../../api/transaction";
import { useNotificationService } from "../UIElements/notification";
import { useAuth } from "../../hooks/useAuth";
import Pagination from "../pagination";
import TransactionDetailsContent from "./TransactionDetailsContent";
import useResponsive from "../../hooks/useResponsive";
import LoadingSpinner from "../UIElements/loadingSpinner";

const data_type = ["All", "Credit", "Debit", "Swap"];

const TransactionContent = ({}) => {
  const [viewTable, setViewTable] = useState<string>("All");
  const [tableData, setTableData] = useState<any[]>([]);
  const [modalTransactionData, setModalTransactionData] = useState({
    modalalView: false,
    transactionData: {} as TableInfoData,
  });
  const [filteredData, setFilteredData] = useState<TableInfoData[]>([]); // Initialize filteredData state
  const { token } = useAuth();
  const isSmall = useResponsive("down", "sm", "xl");
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const query = {
        page: currentPage.toString(),
        item_per_page: "20",
        q: "",
        component: "",
      };
      // Fetch data for the current page
      const token_key = token ?? "";
      const result = await getAllTransactions(query, token_key);
      console.log(result);
      setTableData(result?.data?.data);
      setFilteredData(result?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewTable = (value: string) => {
    if (value === "All") {
      setFilteredData(tableData);
    } else {
      const filteredTransactions = tableData?.filter(
        (val) => val.mode === value.toUpperCase()
      );
      setFilteredData(filteredTransactions);
    }
    setViewTable(value);
  };

  const countTransaction = (data: any[], mode: string) => {
    return data?.filter((val) => val?.mode === mode).length;
  };

  const handleChange = (value: number) => {
    setCurrentPage(value);
  };

  const handleClick = (info: TableInfoData) => {
    setModalTransactionData(() => ({
      transactionData: info,
      modalalView: true,
    }));
  };

  const handleClose = () => {
    setModalTransactionData((prev) => ({ ...prev, modalalView: false }));
  };

  //
  return (
    <>
      <TransactionDetailsContent
        open={modalTransactionData?.modalalView}
        onClose={handleClose}
        onclick={() => {}}
        data={modalTransactionData?.transactionData}
      />
      <Box sx={{ padding: 3 }}>
        <Stack direction={"row"} sx={{ marginTop: "2rem", gap: "20px" }}>
          <TransactionSummary
            type="credit"
            text="Credit"
            amount={countTransaction(tableData, "CREDIT")}
          />
          <TransactionSummary
            type="debit"
            text="Debit"
            amount={countTransaction(tableData, "DEBIT")}
          />
        </Stack>
        <Stack sx={{ width: isSmall ? "100%" : "350px" }}>
          <Box
            sx={{
              display: "inline-flex",
              gap: "10px",
              background: theme.palette.primary.light,
              padding: 2,
              borderRadius: "20px",
              my: "2rem",
              width: "auto",
            }}
          >
            {data_type?.map((data, id) => (
              <Typography
                variant="body1"
                key={id}
                sx={{
                  color:
                    viewTable === data
                      ? theme.palette.common.white
                      : theme.palette.primary.main,
                  padding: isSmall ? "5px 10px" : "7px 20px",
                  borderRadius: "12px",
                  background:
                    viewTable === data ? theme.palette.primary.main : "",
                  width: "40px",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => handleViewTable(data)}
              >
                {data}
              </Typography>
            ))}
          </Box>
        </Stack>

        {/*  */}
        <Box
          sx={{
            background: theme.palette.common.white,
            borderRadius: "20px",
            padding: 3,
          }}
        >
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: 200,
                justifyContent: "center",
              }}
            >
              <LoadingSpinner />{" "}
            </Box>
          )}

          {!isLoading && (
            <TransactionsTable data={filteredData} handleClick={handleClick} />
          )}
          <Divider sx={{ my: 2 }} />
          {filteredData?.length > 1 && (
            <Pagination pageNumber={currentPage} handleChange={handleChange} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default TransactionContent;

// return (
//   <div>
//     {isLoading ? (
//       <div>Loading...</div>
//     ) : (
//       <div>
//         {/* Render your data here */}
//         <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
//         <button onClick={nextPage}>Next</button>
//       </div>
//     )}
//   </div>
// );

const TransactionSummary: React.FC<{
  type: string;
  text: string;
  amount: number;
}> = ({ type, text, amount }) => {
  const theme = useTheme();
  let icon = "";
  if (type === "credit") {
    icon = "credit-icon.svg";
  } else {
    icon = "debit-icon.svg";
  }
  return (
    <Box
      sx={{
        padding: "1.5rem",
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        width: "250px",
        borderRadius: "20px",
        my: 2,
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{ gap: "20px", mb: 2 }}
      >
        <img src={`/assets/icons/${icon}`} alt="" width={34} height={34} />

        <Typography variant="body1">{text}</Typography>
      </Stack>
      <Typography variant="h3" sx={{ fontWeight: 700, textAlign: "center" }}>
        {amount}
      </Typography>
    </Box>
  );
};
