import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useTheme } from "@mui/material/styles";
import NavConfig from "../nav/config";
import { useLocation, useNavigate } from "react-router-dom";
import { SettingIcon } from "../../../components/UIElements/svgIcons";
import { Stack, Typography } from "@mui/material";
import {
  ButtonContainedLight,
  ButtonContainedMain,
} from "../../../components/UIElements/button";
import { PATH_DASHBOARD } from "../../../routes/path";
import { ModalBaseCurrencySelector } from "../../../components/baseCurrencySelector";

export function SmallScreenDrawer() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [openBase, setOpenBase] = React.useState(false);

  const handleOpenBase = () => {
    setOpenBase(!openBase);
  };
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const location = useLocation();
  const navigate = useNavigate();

  const isCurrentPath = (path: string) => {
    return location.pathname.startsWith(path);
  };
  const handlePathNavigate = (path: string) => {
    navigate(path);
  };
  const navdata = NavConfig();

  const handleAssets = () => {
    navigate(PATH_DASHBOARD.assets);
  };
  const handleWallet = () => {
    navigate(PATH_DASHBOARD.fundWallet);
  };
  const DrawerList = (
    <Box
      sx={{
        width: 220,
        minHeight: '100vh',
        background: theme.palette.common.white,
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Box
        sx={{ padding: 1, my: 4, display: "flex", justifyContent: "center" }}
      >
        <img src="/assets/icons/kumo-icon.svg" width="90" height="30" alt="" />
      </Box>
      <List
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {navdata?.map((item, index) => (
          <Box
            onClick={() => handlePathNavigate(item.path)}
            key={index}
            sx={{
              background: isCurrentPath(item.path)
                ? theme.palette.primary.main
                : "",
              borderRadius: "20px",
              color: isCurrentPath(item.path)
                ? theme.palette.common.white
                : theme.palette.secondary.light,
              width: 180,
              my: 2,
              padding: 2,
              display: "flex",
              alignItems: "center",
cursor:'pointer',
              gap: "10px",
            }}
          >
            <span>{item.icon}</span>
            <Typography>{item.title}</Typography>
          </Box>
        ))}
      </List>
      <Divider />
      <Stack sx={{background: theme.palette.common.white}}>
        <ButtonContainedLight
          sx={{ borderRadius: "10px", width: 200, my: 2, mx: "auto" }}
          onclick={handleOpenBase}
        >
          Select Base Currency
        </ButtonContainedLight>
        <ButtonContainedLight
          sx={{ borderRadius: "10px", width: 200, my: 2, mx: "auto" }}
          onclick={handleAssets}
        >
          View Assets
        </ButtonContainedLight>
        <ButtonContainedLight
          sx={{ borderRadius: "10px", width: 200, mx: "auto", my: 2 }}
          onclick={handleWallet}
        >
          Fund Wallet
        </ButtonContainedLight>
      </Stack>
    </Box>
  );

  return (
    <>
      <ModalBaseCurrencySelector onclose={handleOpenBase} open={openBase} />
      <Button
        onClick={toggleDrawer(true)}
        sx={{ padding: 0, margin: 0, color: theme.palette.primary.main }}
      >
        <SettingIcon />
      </Button>
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>
    </>
  );
}
