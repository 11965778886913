import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import Header from "../../layouts/dashboard/header";
import { PageMainTextSummary } from "../../components/pageMainSummary";
import { useNotificationService } from "../../components/UIElements/notification";
import { useAuth } from "../../hooks/useAuth";
import { getUserReferralList } from "../../api/profile";
import ReferralTable from "../../components/table/referral";
import { Helmet } from "react-helmet-async";

const Referral: React.FC = () => {
  const theme = useTheme();
  const { showNotification } = useNotificationService();

  const { user, token } = useAuth();
  const [userReferralData, setUserReferralData] = useState([]);

  // fetch user  data
  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) {
        return;
      }
      const result = await getUserReferralList("", token);
      console.log(result);
      if (result?.status === "error") {
      } else if (result?.status === 200) {
        setUserReferralData(result?.data?.data ?? []);
      }
    };
    fetchUserData();
  }, []);

  return (
    <>
     <Helmet>
        <title>Referral</title>
      </Helmet>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Referral
          </Typography>
        </Box>
      </Header>
      <Box sx={{ mt: 4 }}>
        <PageMainTextSummary
          code={user?.user_referral_id}
          referralCode={true}
          text="Refer your Friends and Win!"
        />
      </Box>

      <DashboardGridContainer>
        <Box sx={{}}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
          >
            My Referral List
          </Typography>
        </Box>

        {/* set up  referal table */}
        <Box sx={{ background: theme.palette.common.white, borderRadius: '20px', paddingX: 3, my: 2, minHeight: 200 }}>
          <ReferralTable data={userReferralData} />
        </Box>
      </DashboardGridContainer>
    </>
  );
};

export default Referral;
