import React, { useState } from "react";
import ModalContainer from "../../UIElements/modal";
import { Box, Stack, Typography, Divider } from "@mui/material";
import { ButtonContainedMain } from "../../UIElements/button";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

import { CloseIcon } from "../../UIElements/svgIcons";
import {
  CombinedInterface,
  ModalPropType,
  SwapModalPropType,
} from "../../../interface/dashboard";
import { useAuth } from "../../../hooks/useAuth";
//

export const CryptoTransaction = ({
  currency,
  open,
  charges,
  onClose,
  amount,
  onclick,
  currencyValue,
}: ModalPropType) => {
  const theme = useTheme();
  const { base } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
  };
  const handleClick = () => {
    setIsLoading(true);
    onclick();
    setIsLoading(false);
  };
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography>Transaction Details</Typography>
          <span onClick={onClose} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {base?.symbol} {Number(amount).toFixed(2)}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          {" "}
          <Typography variant="body2" sx={textStyle}>
            Fee
          </Typography>{" "}
          <Typography variant="body2" sx={valueStyle}>
            {charges}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          {" "}
          <Typography variant="body2" sx={textStyle}>
            {currency} Value
          </Typography>{" "}
          <Typography variant="body2" sx={valueStyle}>
            {currencyValue}
          </Typography>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack direction={"row"} justifyContent={"center"}>
            
        <LoadingButton
            loading={isLoading}
            type="button"
            onClick={handleClick}
            variant="contained"
            sx={{
              width: "120px",
              borderRadius: "10px",
            }}
          >
            Proceed
          </LoadingButton>
        </Stack>
      </Box>
    </ModalContainer>
  );
};

export const SellCryptoTransaction = ({
  currency,
  open,
  onClose,
  amount,
  charges,
  onclick,
  currencyValue,
}: ModalPropType) => {
  const theme = useTheme();
  const { base } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
  };

  const handleClick = () => {
    setIsLoading(true);
    onclick();
    setIsLoading(false);
  };
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography>Transaction Details</Typography>
          <span onClick={onClose} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          {" "}
          <Typography variant="body2" sx={textStyle}>
            {currency} Value
          </Typography>{" "}
          <Typography variant="body2" sx={valueStyle}>
            {currencyValue}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          {" "}
          <Typography variant="body2" sx={textStyle}>
            Fee
          </Typography>{" "}
          <Typography variant="body2" sx={valueStyle}>
            {charges}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {base?.symbol} {amount}
          </Typography>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack direction={"row"} justifyContent={"center"}>
         
          <LoadingButton
            loading={isLoading}
            type="button"
            onClick={handleClick}
            variant="contained"
            sx={{
              width: "120px",
              borderRadius: "10px",
            }}
          >
            Proceed
          </LoadingButton>
        </Stack>
      </Box>
    </ModalContainer>
  );
};

export const SendCryptoTransaction = ({
  currency,
  open,
  onClose,
  amount,
  address,
  charges,
  onclick,
  currencyValue,
}: CombinedInterface) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
    fontWeight: 600,
  };

  const handleClick = () => {
    setIsLoading(true);
    onclick();
    setIsLoading(false);
  };
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography>Transaction Details</Typography>
          <span onClick={onClose} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {amount}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          {" "}
          <Typography variant="body2" sx={textStyle}>
            Fee
          </Typography>{" "}
          <Typography variant="body2" sx={valueStyle}>
           {charges}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          {" "}
          <Typography variant="body2" sx={textStyle}>
            {currency} Value
          </Typography>{" "}
          <Typography variant="body2" sx={valueStyle}>
            {currencyValue}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2, gap: "10px" }}
        >
          {" "}
          <Typography variant="body2" sx={{ ...textStyle }}>
            Wallet Address
          </Typography>{" "}
          <Typography variant="body2" sx={{ ...valueStyle, overflowX: "auto" }}>
            {address}
          </Typography>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack direction={"row"} justifyContent={"center"}>
        <LoadingButton
            loading={isLoading}
            type="button"
            onClick={handleClick}
            variant="contained"
            sx={{
              width: "120px",
              borderRadius: "10px",
            }}
          >
            Proceed
          </LoadingButton>
        </Stack>
      </Box>
    </ModalContainer>
  );
};

export const SwapCryptoTransaction = ({
  currency,
  open,
  charges,
  onClose,
  recieve,
  onclick,
  currencyValue,
}: SwapModalPropType) => {
  const theme = useTheme();
  const { base } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
  };

  const handleClick = () => {
    setIsLoading(true);
    onclick();
    setIsLoading(false);
  };
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography>Transaction Details</Typography>
          <span onClick={onClose} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            To receive
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {recieve}{" "}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          {" "}
          <Typography variant="body2" sx={textStyle}>
            Fee
          </Typography>{" "}
          <Typography variant="body2" sx={valueStyle}>
            {charges}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          {" "}
          <Typography variant="body2" sx={textStyle}>
            {currency} Value
          </Typography>{" "}
          <Typography variant="body2" sx={valueStyle}>
            {currencyValue}
          </Typography>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack direction={"row"} justifyContent={"center"}>
        <LoadingButton
            loading={isLoading}
            type="button"
            onClick={handleClick}
            variant="contained"
            sx={{
              width: "120px",
              borderRadius: "10px",
            }}
          >
            Proceed
          </LoadingButton>
        </Stack>
      </Box>
    </ModalContainer>
  );
};
