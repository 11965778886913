import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Navigation from "./nav";
import { styled, useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;
// const NAV_WIDTH = 250;

const Main = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    paddingTop: 16,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const isMedium = useResponsive("down", "md", "xs");
  const isDesktop = useResponsive("down", "lg", "xs");

  const theme = useTheme();
  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid
        item
        xs={isDesktop ? 0 : 2.5}
        style={{
          background: theme.palette.common.white,
        }}
      >
          <Navigation />
      </Grid>
      <Grid item xs={isDesktop ? 12 : 9.5} sx={{
          background: theme.palette.background.default,
          overflowY: "auto",
      }}>
        <Main
          sx={{
            padding: isDesktop ? "0.6rem" : 24,
            paddingY: "1rem",
          }}
        >
          <Outlet />
        </Main>
      </Grid>
    </Grid>
  );
}
