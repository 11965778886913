import Axios from "../utils/http-request";
import { getRequestConfiguration } from "../utils/headerHelper";
import {
  AxiosErrorResponse,
  BuyElectricityProps,
  RechargeAirtimeProps,
  RechargeCableTvProps,
  RechargeDataProps,
  RechargeElectricityProps,
} from "../interface/api_request";

export const getBankList = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/utilities/get-bank-list`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getAccountNameFromAccountNumber = async (
  query: { account_number: string; bank_code: string },
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/utilities/get-account-name-of-account-number?account_number=${query.account_number}&bank_code=${query.bank_code}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getUtilitiesList = async (identifier: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/utilities/get-utilities-list?identifier=${identifier}`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const verifyMerchantNumber = async (
  data: { billers_code: string; service_id: string; type: string },
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);

    const uri = `/app/utilities/verify-merchant-number`;
    const response = await Axios.post(uri, data, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const rechargeElectricity = async (
  data: RechargeElectricityProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/utilities/recharge-electricity-vt`;
    const response = await Axios.post(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const getUtilitiesVariation = async (
  service_id: string,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/utilities/get-utilities-variation?serviceID=${service_id}`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const rechargeCableTV = async (
  data: RechargeCableTvProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/utilities/recharge-cable-tv`;
    const response = await Axios.post(uri, data, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const rechargeData = async (
  data: RechargeDataProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/utilities/recharge-data-vt`;
    const response = await Axios.post(uri, data, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const buyElectricity = async (
  data: BuyElectricityProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/utilities/recharge-electricity-vt`;
    const response = await Axios.post(uri, data, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const rechargeAirtime = async (
  data: RechargeAirtimeProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/utilities/airtime-recharge-vt`;
    const response = await Axios.post(uri, data, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};