import React  from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TextContainer } from "../../UIElements/textContainer";
import { PATH_DASHBOARD } from "../../../routes/path";

const FundWalletContent: React.FC = () => {
  const theme = useTheme();
  
  return (
    <>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
        >
          Action
        </Typography>
        <TextContainer path="">
          Debit Card
        </TextContainer>
        <TextContainer path={PATH_DASHBOARD.fundWalletCrypto}>
          Crypto
        </TextContainer>
        <TextContainer path={PATH_DASHBOARD.fundWalletBankTransfer}>
          Bank Transfer
        </TextContainer>
        <TextContainer path={PATH_DASHBOARD.fundWalletPaypal}>
          Paypal
        </TextContainer>
        <TextContainer path={PATH_DASHBOARD.fundWalletKumoAgent}>
          Kumo Agents
        </TextContainer>
        <TextContainer path="">
          Pay Link
        </TextContainer>
      </Box>
    
    </>
  );
};

export default FundWalletContent;

const FundTextContainer: React.FC<{
  children: React.ReactNode;
  onclick: () => void;
}> = ({ children, onclick }) => {
  const theme = useTheme();

  return (
    <Box
      onClick={onclick}
      sx={{
        padding: 3,
        my: 3,
        cursor: "pointer",
        background: theme.palette.common.white,
        borderRadius: "20px",
      }}
    >
      <Typography
        variant="body1"
        sx={{ fontWeight: 700, color: theme.palette.common.black, px: 2 }}
      >
        {children}
      </Typography>
    </Box>
  );
};
