import React, { useEffect, useState } from "react";

//
import { Box, Typography, Stack } from "@mui/material";
import Decimal from "decimal.js";

import {
  MaxAmountValue,
  TextIconContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { SearchInput } from "../UIElements/searchInput";
import { useTheme } from "@mui/material/styles";
import { ButtonContainedMain } from "../UIElements/button";
import { SellCryptoTransaction } from "./modal/dashboardModals";
import { PasswordModal } from "../passwordModal";
import {
  TransactionResult,
  TransactionResultFailed,
} from "../transactionResult";
import { OTPModal } from "../otpModal";
import useResponsive from "../../hooks/useResponsive";
import { useAuth } from "../../hooks/useAuth";
import { useDetails } from "../../hooks/useDetails";
import { WalletBalanceType } from "../../interface/useDetail";
import {
  getCryptoToBaseCurrencyForSell,
  sellCrypto,
  sellCryptoCheckMinMax,
  sellCryptoVerifier,
} from "../../api/transaction";
import { TransactionIcon } from "../UIElements/svgIcons";
import { useNotificationService } from "../UIElements/notification";
import { requestOtp, validateOtp, verifyPin } from "../../api/users";
import {
  addCommasToNumbers,
  convertAndCheckAmountValue,
  formatNumberWithCommas,
  isAmountAboveMax,
  parseFormattedNumber,
} from "../../utils/basic";

//
const SellCryptoContent: React.FC<{ tokenList: any[] }> = ({ tokenList }) => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");
  const { base, token } = useAuth();
  const { wallet } = useDetails();
  const { showNotification } = useNotificationService();
  const [assetWallet, setAssetWallet] = useState<{
    baseWallet: any;
    cryptoWallet: any;
    switch: boolean;
  }>({
    baseWallet: {},
    cryptoWallet: {},
    switch: true,
  });
  const [filteredData, setFilteredData] = useState<{
    coin_list: any[];
    search_value: string;
  }>({
    coin_list: tokenList ?? [],
    search_value: "",
  });
  const [modalContainer, setModalContainer] = useState({
    modalDetails: false,
    pinModal: false,
    otpModal: false,
    resultModal: false,
    failedModal: false,
    converted_amount_value: "",
    charges: "",
    pin: "",
  });

  const [currency, setCurrency] = useState<{
    token: any;
    exchange: boolean;
    amount_value: number | string;
    token_amount_value: number | string;
    base_token_value: number;
  }>({
    token: {},
    exchange: false,
    amount_value: 1,
    token_amount_value: "",
    base_token_value: 0,
  });

  // Update token_amount_value whenever amount_value or base_token_value changes
  useEffect(() => {
    if (!assetWallet?.switch) {
      // Calculate token_amount_value based on amount_value and base_token_value
      const tokenAmount =
        parseFormattedNumber(currency?.amount_value?.toString()) /
        currency?.base_token_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        token_amount_value: tokenAmount,
      }));
    } else {
      const tokenAmount =
        Number(currency?.token_amount_value) * currency?.base_token_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        amount_value: tokenAmount,
      }));
    }
  }, [
    currency.amount_value,
    currency.base_token_value,
    currency?.token_amount_value,
  ]);

  // get the value of the base from wallet
  const getAssetWallet = (tokenObj: any) => {
    const cryptoBalance = wallet?.find(
      (wallet: WalletBalanceType) =>
        wallet.code === tokenObj?.code || wallet.name === tokenObj?.name
    );
    const walletBalance = wallet?.find(
      (currency: WalletBalanceType) =>
        currency.code === base?.code || currency.name === base?.name
    );
    setAssetWallet((prev) => ({
      ...prev,
      baseWallet: walletBalance,
      cryptoWallet: cryptoBalance,
    }));
  };

  // handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilteredData((prev) => ({ ...prev, search_value: value }));

    if (value.length === 0) {
      setFilteredData((prev) => ({ ...prev, coin_list: tokenList })); // Reset to original list if search input is empty
    } else {
      const filteredTokens = filteredData?.coin_list?.filter((token) =>
        token?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredData((prev) => ({ ...prev, coin_list: filteredTokens }));
    }
  };

  // hamdle modal function and send request
  const handleModal = async () => {
    const token_key = token ?? "";

    // check if amount is empty
    if (!convertAndCheckAmountValue(+currency?.token_amount_value)) {
      showNotification("Invalid Amount", "error");
      return;
    }
    showNotification("Verifying Transaction", "info");
    const data = {
      currency_name: currency?.token?.name,
      amount: parseFormattedNumber(currency?.amount_value?.toString()),
    };
    const check_transaction = await sellCryptoCheckMinMax(data, token_key);
    console.log(check_transaction);
    if ("msg" in check_transaction) {
      showNotification(check_transaction.msg, "error");
    } else if (check_transaction.status == 200) {
      const verify_data = {
        currency_name: currency?.token?.name,
        amount: parseFormattedNumber(currency?.amount_value?.toString()),
        rate: currency?.base_token_value,
      };
      const verify_transaction = await sellCryptoVerifier(
        verify_data,
        token_key
      );
      console.log(verify_transaction);
      if ("msg" in verify_transaction) {
        showNotification(verify_transaction.msg, "error");
      } else if (verify_transaction.status == 200) {
        setModalContainer((prev) => ({
          ...prev,
          modalDetails: !prev.modalDetails,
          converted_amount_value: verify_transaction?.data?.data?.value ?? "0",
          charges: verify_transaction?.data?.data?.fee,
        }));
      } else {
        showNotification("An error occured", "error");
      }
    } else {
      showNotification("An error occured", "error");
    }
  };

  const handlePinModal = async () => {
    setModalContainer((prev) => ({ ...prev, pinModal: !prev.pinModal }));
  };

  const handleOtpModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // send otp request
      const send_request = await requestOtp(token_key);
      console.log(send_request?.data?.msg);
      if (send_request?.status === 200) {
        showNotification(send_request?.data?.msg, "success");
      } else {
        showNotification("OTP code not sent", "error");
      }
      // update state
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        otpModal: !prev.otpModal,
        pin: pin_value,
      }));
    } else {
      showNotification("Invalid Pin", "error");
    }
  };

  const handleResultModal = async (otp: string[]) => {
    const token_key = token ?? "";
    // verify otp and invoke buy crypto api
    const otp_value = otp.join("");
    const data = {
      currency_name: currency?.token?.name,
      amount: parseFormattedNumber(currency?.amount_value?.toString()),
      rate: Number(currency?.base_token_value),
      converted: Number(currency?.token_amount_value),
      action: "sell",
      code: base?.code,
      destination_address: "",
      pin: modalContainer?.pin,
    };
    const otp_verify = await validateOtp(otp_value, token_key);
    if (otp_verify?.status === 200) {
      const sell_crypto = await sellCrypto(data, token_key);
      if (sell_crypto?.status === 200) {
        showNotification("Crypto Sold successfully", "success");
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          failedModal: !prev.failedModal,
        }));
        showNotification("Transaction Failed", "error");
      }
    } else {
      showNotification("OTP Verification Failed", "error");
    }
  };
  const handleCurrency = async (tokenObj: any) => {
    const token_key = token ?? "";
    const query = {
      amount: "1",
      crypto: tokenObj?.name,
      currency: base?.code,
    };

    await getAssetWallet(tokenObj);

    const result = await getCryptoToBaseCurrencyForSell(query, token_key);
    if (result?.status === 200) {
      setCurrency((prev) => ({
        ...prev,
        exchange: true,
        token: tokenObj,
        base_token_value: result?.data?.data?.value,
      }));
    } else {
      showNotification("Error converting Coin and Base Amount", "error");
    }
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    if (assetWallet?.switch) {
      setCurrency((prev) => ({ ...prev, token_amount_value: e.target.value }));
    } else {
      setCurrency((prev) => ({ ...prev, amount_value: e.target.value }));
    }
  };
  const handleMaxAmount = (num: number) => {
    if (assetWallet?.switch) {
      setCurrency((prev) => ({ ...prev, token_amount_value: num }));
    } else {
      setCurrency((prev) => ({ ...prev, amount_value: num }));
    }
  };

  const checkMaxAmount = () => {
    const amount_exceed_check = new Decimal(currency?.token_amount_value).gt(
      new Decimal(assetWallet?.cryptoWallet?.value)
    );
    if (amount_exceed_check) {
      showNotification("Amount exceeds Balance", "error");
    }
  };

  const handleAddComma = () => {
    if (!assetWallet?.switch) {
      const plainNumber = currency.amount_value || "";
      const formattedNumber = addCommasToNumbers(+plainNumber);
      setCurrency((prev) => ({ ...prev, amount_value: formattedNumber }));
    } else {
      return;
    }
  };

  const handleFocus = () => {
    if (!assetWallet?.switch && currency.amount_value) {
      const plainNumber = parseFormattedNumber(
        currency.amount_value?.toString()
      );
      setCurrency((prev) => ({ ...prev, amount_value: plainNumber }));
    }
  };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      otpModal: false,
      resultModal: false,
    }));
  };
  return (
    <>
      <SellCryptoTransaction
        onClose={closeAllModal}
        charges={modalContainer?.charges}
        currency={currency?.token?.code?.toUpperCase()}
        open={modalContainer.modalDetails}
        amount={parseFormattedNumber(currency?.amount_value?.toString())}
        onclick={handlePinModal}
        currencyValue={modalContainer?.converted_amount_value}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleOtpModal}
        onClose={closeAllModal}
      />
      <OTPModal
        open={modalContainer.otpModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          You have <span style={{ fontWeight: 700 }}>successfully</span> Sold{" "}
          <span style={{ fontWeight: 700 }}>0.013149 ETH </span>
        </Typography>
      </TransactionResult>
      <TransactionResultFailed
        onClose={closeAllModal}
        openResult={modalContainer?.failedModal}
        reason="Transaction could not be completed"
      />

      <Box sx={{ mt: isSmall ? 0 : 4 }}>
        {currency?.exchange && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Balance{" "}
            </Typography>
            {!!assetWallet && (
              <MaxAmountValue
                onclick={handleMaxAmount}
                currency={
                  assetWallet?.switch
                    ? assetWallet?.cryptoWallet?.code
                    : base?.code
                }
                amount={
                  assetWallet?.switch
                    ? Number(assetWallet?.cryptoWallet?.value)
                    : Number(assetWallet?.baseWallet?.value)
                }
              />
            )}
            <TextValueContainer
              text="You Pay"
              onFocusHandler={handleFocus}
              onBlurHandler={handleAddComma}
              onchange={handleAmountChange}
              currency={
                assetWallet?.switch
                  ? currency?.token?.code?.toUpperCase()
                  : base?.code.toUpperCase()
              }
              amount={
                assetWallet?.switch
                  ? currency?.token_amount_value
                  : currency?.amount_value
              }
              icon={assetWallet?.switch ? currency?.token?.image : base?.image}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                my: 2,
              }}
            >
              <span
                onClick={() => {
                  setAssetWallet((prev) => ({ ...prev, switch: !prev.switch }));
                }}
                style={{
                  color: theme.palette.secondary.light,
                  cursor: "pointer",
                }}
              >
                <TransactionIcon />
              </span>
            </Box>
            {/* exchnge icon */}
            <TextValueContainer
              text="Value"
              onFocusHandler={() => {}}
              onBlurHandler={() => {}}
              onchange={() => {}}
              currency={
                !assetWallet?.switch
                  ? currency?.token?.code?.toUpperCase()
                  : base?.code.toUpperCase()
              }
              amount={
                !assetWallet?.switch
                  ? currency?.token_amount_value
                  : currency?.amount_value
              }
              icon={!assetWallet?.switch ? currency?.token?.image : base?.image}
            />
            <Stack direction="column" alignItems={"center"}>
              <Typography
                variant="body2"
                sx={{ my: 2, color: theme.palette.secondary.main }}
              >
                1 {currency?.token?.code?.toUpperCase()} ={" "}
                {base?.code?.toUpperCase()}{" "}
                {formatNumberWithCommas(+currency?.base_token_value, 4)}
              </Typography>
              <ButtonContainedMain
                sx={{ width: "150px", fontWeight: 500, fontSize: "16px" }}
                onclick={handleModal}
              >
                Next
              </ButtonContainedMain>
            </Stack>
          </Box>
        )}
        {!currency?.exchange && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Select Your Preferred coin
            </Typography>
            <Box sx={{ my: 2 }}>
              <SearchInput
                width="100%"
                text="Search Crypto"
                handler={handleSearchChange}
                value={filteredData?.search_value}
              />
              {filteredData?.coin_list?.map((token, id) => (
                <Box key={id}>
                  <TextIconContainer
                    image={token?.image}
                    onclick={() => handleCurrency(token)}
                  >
                    {token?.name?.toUpperCase()}
                  </TextIconContainer>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SellCryptoContent;
