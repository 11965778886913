import { Box, Typography } from "@mui/material";
import React from "react";
import DashboardGridContainer from "../../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../../layouts/dashboard/header";
import FundWalletContent from "../../../components/dashboard/fundWallet/FundWalletContent";

const FundWallet: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Fund Wallet
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        <FundWalletContent />
      </DashboardGridContainer>
    </>
  );
};

export default FundWallet;
