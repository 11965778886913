import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { Box, Typography } from "@mui/material";
import Header from "../../layouts/dashboard/header";
import { useAuth } from "../../hooks/useAuth";
import { getSingleInvestment, getSinglePackage } from "../../api/investments";
import { useNotificationService } from "../../components/UIElements/notification";
import { InvestmentDetailPropType } from "../../interface/kumo-stacking";
import { useLocation } from "react-router-dom";
import { hasValues } from "../../utils/basic";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";
import PlanDetailsContent from "../../components/kumo-stacking/PlanDetailsContent";
import HistoryDetailsContent from "../../components/kumo-stacking/HistoryDetailsContent";

const StackingHistoryDetails = () => {
  const { showNotification } = useNotificationService();
  const [historyDetails, setHistoryDetails] =
    useState<InvestmentDetailPropType>({} as InvestmentDetailPropType);

  const { user, token } = useAuth();
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname.split("/");
    const planId = path[path?.length - 1];
    if (planId?.length > 6) {
      fetchPlanInfo(planId);
    }
  }, []);

  const fetchPlanInfo = async (id: string) => {
    const token_key = token ?? "";
    const user_id = user?.user_id ?? "";
    const fetch_package = await getSinglePackage(id, token_key);
    const fetch_plan = await getSingleInvestment(
      { user_id: user_id, queryId: id },
      token_key
    );
    if ("msg" in fetch_plan) {
      console.log("Error fetching currency data:", fetch_plan.msg);
      showNotification(fetch_plan.msg, "error");
    } else if (fetch_plan.status == 200) {
      setHistoryDetails(fetch_plan?.data?.data);
    } else {
      showNotification("An error occured while fetching data", "error");
    }
  };
  console.log(historyDetails);
  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: "#2C393F" }}>
            Investment Details
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        {hasValues(historyDetails) ? (
          <HistoryDetailsContent historyDetails={historyDetails} />
        ) : (
          <Box
            sx={{
              display: "flex", 
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
      </DashboardGridContainer>
    </>
  );
};

export default StackingHistoryDetails;
