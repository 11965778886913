// NotificationService.js

import React, { createContext, useContext, useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

interface NotificationState {
  open: boolean;
  message: string;
  severity?: AlertProps["severity"];
}

interface NotificationContextType {
  showNotification: (
    message: string,
    severity?: AlertProps["severity"]
  ) => void;
  closeNotification: () => void;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
});

const NotificationServiceContext = createContext<
  NotificationContextType | undefined
>(undefined);

export const useNotificationService = () => {
  const context = useContext(NotificationServiceContext);
  if (!context) {
    throw new Error(
      "useNotificationService must be used within a NotificationServiceContext.Provider"
    );
  }
  return context;
};

export const NotificationServiceProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [notification, setNotification] = useState<NotificationState>({
    open: false,
    message: "",
    severity: undefined,
  });
  useEffect(() => {
    if (notification.open) {
      const timer = setTimeout(() => {
        closeNotification();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification.open]);

  const showNotification = (
    message: string,
    severity?: AlertProps["severity"]
  ) => {
    setNotification({ open: true, message, severity });
  };

  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <NotificationServiceContext.Provider
      value={{ showNotification, closeNotification }}
    >
      {children}
      <Snackbar
        open={notification.open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        onClose={closeNotification}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={notification.severity}
          onClose={closeNotification}
          sx={{ color: "white" }}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </NotificationServiceContext.Provider>
  );
};
