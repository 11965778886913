import { FC, useState } from "react";
//
import { Box, Typography, Stack } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import {
  ButtonContainedLight,
  ButtonContainedMain,
} from "../UIElements/button";

import useResponsive from "../../hooks/useResponsive";
import {
  InvestmentDetailPropType,
  PlanDetailPropType,
} from "../../interface/kumo-stacking";

import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../UIElements/notification";
import TopUpInvestment from "./TopUpInvestment";
import { PATH_KUMOSTAKING } from "../../routes/path";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../UIElements/modal";
import { terminateInvestment } from "../../api/investments";
import { parseDate, parseDateToString } from "../../utils/basic";

//

const HistoryDetailsContent: FC<{
  historyDetails: InvestmentDetailPropType;
}> = ({ historyDetails }) => {
  const theme = useTheme();
  const { token, user } = useAuth();
  const [topUp, setTopUp] = useState(false);
  const isMedium = useResponsive("down", "md", "xl");
  const { showNotification } = useNotificationService();
  const [modalTerminate, setModalTerminate] = useState(false);



  const handleTerminate = () => {
    setModalTerminate(true);
  };
  const handleModal = () => setModalTerminate(!modalTerminate);

  function multiplyAndAddPercentage(num: number, multiplier: number) {
    // Calculate the percentage of the original number
    const percentageToAdd = num / 100;

    // Add the percentage to the multiplied result
    const finalResult = percentageToAdd * multiplier;

    return finalResult;
  }

  return (
    <>
      {!topUp && (
        <Box sx={{ mt: 4 }}>
          <TerminateInvestmentCointainer
            onclose={handleModal}
            openResult={modalTerminate}
            id={historyDetails?._id}
          />
          <HistoryTextContainer
            text={multiplyAndAddPercentage(
              historyDetails?.investment_apr,
              historyDetails?.investment_amount
            )}
            label="Est.ROI"
          />
          <HistoryTextContainer
            text={historyDetails?.investment_amount}
            label="Initial Investment"
          />
          <HistoryTextContainer
            text={`${historyDetails?.investment_apr}%`}
            label="% increase Rate"
          />
          <HistoryTextContainer
            text={historyDetails?.investment_status}
            label="Status"
          />
          <Stack
            direction={"column"}
            sx={{
              color: theme.palette.secondary.main,
              background: theme.palette.common.white,
              borderRadius: "20px",
              padding: 3,
              gap: "15px",
            }}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.secondary.main, fontWeight: 400 }}
              >
                Start Date
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.secondary.main, fontWeight: 400 }}
              >
                {parseDateToString(historyDetails?.investment_start_date)}{" "}
              </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.secondary.main, fontWeight: 400 }}
              >
                Redemption Date
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.secondary.main, fontWeight: 400 }}
              >
                {parseDate(historyDetails?.investment_redemption_date)}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ marginY: 4 }}
          >
            <ButtonContainedLight sx={{}} onclick={handleTerminate}>
              Terminate
            </ButtonContainedLight>
            <ButtonContainedMain
              sx={{
                width: "200px",
                fontWeight: 500,
                marginLeft: "auto",
                fontSize: "16px",
              }}
              onclick={() => setTopUp(true)}
            >
              + Top up investment
            </ButtonContainedMain>
          </Stack>
        </Box>
      )}
      {topUp && (
        <TopUpInvestment
          planDetails={historyDetails?.package_object}
          investmentData={historyDetails}
        />
      )}
    </>
  );
};

export default HistoryDetailsContent;

const HistoryTextContainer: FC<{ text: string | number; label: string }> = ({
  text,
  label,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        backgroundColor: "white",
        padding: 3,
        borderRadius: "20px",
        flexGrow: 1,
        marginY: "1rem",
      }}
    >
      <Typography
        variant="body2"
        sx={{ color: theme.palette.secondary.main, fontWeight: 400 }}
      >
        {label}
      </Typography>
      <Typography
        variant="h6"
        sx={{ color: theme.palette.common.black, fontWeight: 700 }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

interface TerminateInvestmentProps {
  onclose: () => void;
  openResult: boolean;
  id: string;
}
export const TerminateInvestmentCointainer = ({
  onclose,
  openResult,
  id,
}: TerminateInvestmentProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, token } = useAuth();
  const { showNotification } = useNotificationService();
  const handleTerminate = async () => {
    // sent terminate request
    const token_key = token ?? "";
    const user_id = user?.user_id ?? "";
    const data = { user_id, id };
    const terminate_request = await terminateInvestment(data, token_key);
    console.log(terminate_request, data);
    if ("msg" in terminate_request) {
      console.log("Error fetching currency data:", terminate_request.msg);
      showNotification(terminate_request.msg, "error");
    } else if (terminate_request.status == 201) {
      showNotification("Investment Terminated", "success");
    } else {
      showNotification(
        "An error occured while terminating investment",
        "error"
      );
    }
    onclose();
  };

  return (
    <ModalContainer
      sx={{}}
      openModal={openResult}
      handleModal={() => {}}
      size="xs"
      fullWidth={true}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: "20px",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack
          direction={"column"}
          sx={{
            textAlign: "center",
            color: theme.palette.text.primary,
            my: 2,
            height: 80,
          }}
        >
          <Typography>Are you sure you want to terminate? </Typography>
        </Stack>

        <Stack direction={"row"} justifyContent={"center"} sx={{ gap: "20px" }}>
          <ButtonContainedLight
            sx={{ width: "150px" }}
            onclick={() => onclose()}
          >
            Cancel
          </ButtonContainedLight>
          <ButtonContainedMain
            onclick={handleTerminate}
            sx={{ width: "150px" }}
          >
            Terminate
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};
