import {
  AxiosErrorResponse,
  CreateInvestmentProps,
  GetPakagesProps,
  TopupInvestmentProps,
} from "../interface/api_request";
import { getRequestConfiguration } from "../utils/headerHelper";
import Axios from "../utils/http-request";

export const createInvestment = async (
  body: CreateInvestmentProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/investment/create-investment`;
    const response = await Axios.post(uri, body, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const getAllPackages = async (data: GetPakagesProps, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/investment/get-packages?query=${data.query}&item_per_page=${data.item_per_page}&q=${data.q}&component=${data.component}`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const getSinglePackage = async (id: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/investment/get-package/${id}`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};
  
export const getAllInvestments = async (user_id: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/investment/get-investments?user_id=${user_id}`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const getSingleInvestment = async (
  data: { user_id: string; queryId: string },
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/investment/get-investment?_id=${data.queryId}&user_id=${data.user_id}`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const getTokenRate = async (investment_token: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/investment/get-token-rate?investment_token=${investment_token}`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const terminateInvestment = async (
  data: { id: string; user_id: string },
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/investment/close-investment?_id=${data?.id}&user_id=${data?.user_id}`;
    const response = await Axios.put(uri, {}, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const topupInvestment = async (
  data: TopupInvestmentProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/investment/topup-investment?_id=${data._id}`;
    const response = await Axios.put(uri, data, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

