import Decimal from "decimal.js";

export function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

export const updateDateSelectorByMonth = (months: number) => {
  // Logic to update the date selectors by the specified number of months
  // For example, you can use the JavaScript Date object to manipulate dates
  // Here's a simple example that adds months to the current date
  const currentDate = new Date();
  const newStartDate = new Date(currentDate);
  newStartDate.setMonth(newStartDate.getMonth() + months);
  // setStartDate(newStartDate);
  return newStartDate;

  // Similarly, update the endDate if needed
  // const newEndDate = new Date(currentDate);
  // newEndDate.setMonth(newEndDate.getMonth() + months);
  // setEndDate(newEndDate);
};

// function to validate users amount inout, if it's below the thresshold limit
type Currency = "usd" | "ngn";

export function isAmountBelowThreshold(
  amount: number,
  currency: Currency
): boolean {
  const thresholds: { [key in Currency]: number } = {
    usd: 50,
    ngn: 10000,
  };

  if (currency in thresholds) {
    return amount > thresholds[currency];
  } else {
    return false;
  }
}

export function isAmountAboveMax(
  amount: number,
  maxBaseAmount: number
): boolean {
  return new Decimal(amount).gt(new Decimal(maxBaseAmount));
}

export function convertAndCheckAmountValue(value: string | number): boolean {
  const num = Number(value);
  return !isNaN(num) && typeof num === "number" && num > 0;
}

// function that check if an object has values, it returns a boolean value
export const hasValues = (obj: any) => Object.keys(obj).length > 0;

export function formatNumberWithCommas(
  num: number,
  decimalPlaces: number = 2
): string {
  // Ensure the number has the desired decimal places
  const fixedNumber = num.toFixed(decimalPlaces);

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = fixedNumber.split(".");

  // Format the integer part with commas
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // Reassemble the formatted integer part with the decimal part
  return `${formattedIntegerPart}.${decimalPart}`;
}

export function addCommasToNumbers(num: number, decimalPlaces = 2) {
  // Ensure the number has the desired decimal places without rounding
  const factor = Math.pow(10, decimalPlaces);
  const fixedNumber = (Math.trunc(num * factor) / factor).toFixed(
    decimalPlaces
  );

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = fixedNumber.split(".");

  // Format the integer part with commas
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // Reassemble the formatted integer part with the decimal part
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
}

export function parseFormattedNumber(formattedValue: string): number {
  // Remove commas and convert the string back to a number
  const numberValue = parseFloat(formattedValue.replace(/,/g, ""));
  return isNaN(numberValue) ? 0 : numberValue;
}


// funtion to convert date to a readable string
export  function parseDateToString(date: string) {
  const timestamp = new Date(date);
  const currentDate = new Date();
  const timeDiff = currentDate.getTime() - timestamp.getTime();

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30.44); // Approximate number of days in a month
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return "Just now";
  }
}

export function parseDate(date: string) {
  const timestamp = new Date(date);

  // Format the Date object into the desired output format
  const formattedDate = timestamp.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return formattedDate;
}