import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Stack, Typography } from "@mui/material";
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import useResponsive from "../hooks/useResponsive";

export const DateSelector: React.FC<{
  text: string;
  selectedDate: Dayjs | null;
  onChange:
    | ((
        value: any,
        context: PickerChangeHandlerContext<DateValidationError>
      ) => void)
    | undefined;
}> = ({ text, selectedDate, onChange }) => {
  const isSmall = useResponsive("down", "sm", "xl");
  
  return (
    <>
      <Stack
        direction="row"
        sx={{ gap: "20px", my: 2 }}
        justifyContent={!isSmall ? "space-between" : "flex-start"}
        alignItems={"center"}
      >
        <Stack sx={{width: '60px'}}>
          <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
            {text}
          </Typography>
        </Stack>
        <Box sx={{}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={onChange}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Stack>
    </>
  );
};
