import { Box, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { dashboardData } from "../../data/dashboard";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import { PATH_DASHBOARD } from "../../routes/path";
import { useNotificationService } from "../UIElements/notification";
import { useDetails } from "../../hooks/useDetails";
import { useAuth } from "../../hooks/useAuth";

const QuickAction = () => {
  const theme = useTheme();

  return (
    <Box sx={{ mt: 4 }}>
      <Typography
        variant="body2"
        sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
      >
        Quick Action
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: "2px",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {dashboardData?.map((data, id) => (
          <ActionBox
            key={id}
            image={data?.image}
            header={data?.main}
            text={data?.subMain}
            path={data?.link}
          />
        ))}
      </Box>
    </Box>
  );
};

export default QuickAction;

const ActionBox: React.FC<{
  header: string;
  text: string;
  path: string;
  image: string;
}> = ({ header, text, image, path }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showNotification } = useNotificationService();
  const isMedium = useResponsive("down", "md", "xl");
  const isSmall = useResponsive("down", "sm", "xl");
  const {userSettings} = useDetails();

  // function to check if pin is set
  const handlePath = (path: string) => {
    console.log(userSettings)
    if (userSettings?.pin) {
      navigate(path);
    } else {
      showNotification(
        "You currently do not have a pin to carry out transaction. Navigate to Profile and set pin",
        "info"
      );
      return PATH_DASHBOARD.root;
    }
  };

  return (
    <Box
      onClick={() => handlePath(path)}
      sx={{
        background: theme.palette.common.white,
        padding: "1.5rem",
        my: 2,
        borderRadius: "20px",
        width: isSmall ? "100%" : isMedium ? "35%" : "25%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <img src={image} alt="" width={26} height={22} />
      </Box>
      <Stack sx={{ color: theme.palette.common.black }}>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          {header}
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: theme.palette.secondary.main }}
        >
          {text}
        </Typography>
      </Stack>
    </Box>
  );
};
