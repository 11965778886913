import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

interface CheckboxUIPropsType {
  checked: boolean;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function CheckboxUI({ checked, onCheck }: CheckboxUIPropsType) {
  return (
    <Checkbox
      checked={checked}
      onChange={onCheck}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}
