import React, { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import Header from "../../layouts/dashboard/header";
import { useTheme } from "@mui/material/styles";
import { ButtonContainedMain } from "../../components/UIElements/button";
import TransactionContent from "../../components/transaction/TransactionContent";
import { SearchInput } from "../../components/UIElements/searchInput";

import { DateSelector } from "../../components/datePicker";
import { CustomDropDownModal } from "../../components/customDropDownModal";
import dayjs, { Dayjs } from "dayjs";
import { useAuth } from "../../hooks/useAuth";
import { getTransactionHistory } from "../../api/transaction";
import { useNotificationService } from "../../components/UIElements/notification";
import useResponsive from "../../hooks/useResponsive";
import { Helmet } from "react-helmet-async";

const Transaction: React.FC = () => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");

  return (
    <>
     <Helmet>
        <title>Transactions</title>
      </Helmet>
      {/* Header Menu */}
      <Header>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            gap: "18px",
            flexDirection: isSmall ? "column" : "row",
            justifyContent: "space-between",
            marginRight: "1rem",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 600, color: "#2C393F" }}>
            Transactions
          </Typography>

          <Stack direction="row" alignItems={"center"} sx={{ gap: "0" }}>
            <CustomDropDownModal
              handleModal={() => {}}
              onSubmit={() => {}}
              content={<GenerateStatement />}
              btn={"Proceed"}
            >
              Generate Statement
            </CustomDropDownModal>
          </Stack>
        </Box>
      </Header>
    
      <TransactionContent />
    </>
  );
};

export default Transaction;

export const GenerateStatement = () => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");
  const { user, token } = useAuth();
  const { showNotification } = useNotificationService();
  const [isActive, setIsActive] = useState({
    oneMonth: false,
    twoMonth: false,
    custom: true,
  });
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null); // Use dayjs.Dayjs type for startDate
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null); // Use dayjs.Dayjs type for endDate

  const updateDateSelectorByMonth = (months: number) => {
    const currentDate = dayjs();
    const newStartDate = currentDate.add(months, "month");
    setStartDate(newStartDate);

    // Update endDate if needed
    let newEndDate;
    if (months === -1) {
      // When "Last Month" button is clicked, end date is one month ahead of start date
      newEndDate = newStartDate.add(1, "month");
      setEndDate(newEndDate);
    } else if (months === -2) {
      // When "Last 2 Months" button is clicked, end date is two months ahead of start date
      newEndDate = newStartDate.add(2, "month");
      setEndDate(newEndDate);
    }
  };

  const handleLastMonthClick = () => {
    setIsActive({
      oneMonth: true,
      twoMonth: false,
      custom: false,
    });
    updateDateSelectorByMonth(-1);
  };

  const handleLast2MonthsClick = () => {
    setIsActive({
      oneMonth: false,
      twoMonth: true,
      custom: false,
    });
    updateDateSelectorByMonth(-2);
  };

  const handleCustomClick = () => {
    setIsActive({
      oneMonth: false,
      twoMonth: false,
      custom: true,
    });
    // Implement logic to handle custom date range selection
    setStartDate(null);
    setEndDate(null);
  };

  const handleSubmit = async () => {
    // typescript check for values

    const data = {
      from: startDate ? startDate.format("YYYY-MM-DD") : "",
      to: endDate ? endDate.format("YYYY-MM-DD") : "",
      format: "csv",
      user_id: user?.user_id ?? "",
    };
    const token_key = token ?? "";
    const result = await getTransactionHistory(data, token_key);
    if (result.status == 200) {
      showNotification(result?.data?.msg, "success");
    } else {
      showNotification(result?.data?.msg, "error");
    }
  };
  return (
    <Box sx={{ width: 400 }}>
      <Stack sx={{ color: theme.palette.text.primary, mb: 3 }}>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", fontWeight: 600 }}
        >
          Generate Statement
        </Typography>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          onClick={handleLastMonthClick}
          sx={{
            background: isActive?.oneMonth ? theme.palette.primary.main : "",
            color: isActive?.oneMonth ? theme.palette.common.white : "",
          }}
        >
          Last Month
        </Button>
        <Button
          onClick={handleLast2MonthsClick}
          sx={{
            background: isActive?.twoMonth ? theme.palette.primary.main : "",
            color: isActive?.twoMonth ? theme.palette.common.white : "",
          }}
        >
          Last 2 Months
        </Button>
        <Button
          onClick={handleCustomClick}
          sx={{
            background: isActive?.custom ? theme.palette.primary.main : "",
            color: isActive?.custom ? theme.palette.common.white : "",
          }}
        >
          Custom
        </Button>
      </Stack>
      <DateSelector
        text="Start Date"
        onChange={setStartDate}
        selectedDate={startDate}
      />
      <DateSelector
        text="End Date"
        selectedDate={endDate}
        onChange={setEndDate}
      />

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={!isSmall ? "space-between" : "flex-start"}
        sx={{ gap: "20px" }}
      >
        <Typography sx={{ width: "60px", color: "#292925" }} variant="body2">
          Email
        </Typography>
        <TextField
          sx={{ width: 230 }}
          placeholder="johndeo@gmail.com"
          value={user?.email}
        />
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack sx={{ my: 2 }}>
        <ButtonContainedMain sx={{ marginX: "auto" }} onclick={handleSubmit}>
          Proceed
        </ButtonContainedMain>
      </Stack>
    </Box>
  );
};
