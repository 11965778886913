import Axios from "../utils/http-request";
import { getRequestConfiguration } from "../utils/headerHelper";

export const getAllCurrency = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/settings/get-all-currency`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getBaseCurrency = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/settings/get-base-currency`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getAllListedCoinToken = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/settings/get-all-listed-coin-token`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getMarkupRate = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/settings/get-markup-rate`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getSwapCoinList = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/settings/get-swap-coin-list`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getFeeCost = async ( fee_name: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null); 

    const uri = `/app/settings/get-fees-cost?fee_name=${fee_name}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getAllCoinListing = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/settings/get-all-coin-listings`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};