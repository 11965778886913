import React, { useEffect } from "react";
import { useNotificationService } from "../../components/UIElements/notification";
import { Navigate } from "react-router-dom";
import { PATH_AUTH } from "../../routes/path";

const Logout: React.FC = () => {
  const { showNotification } = useNotificationService();

  useEffect(() => {
    // Show notification after component has been rendered
    showNotification("Session has expired", "error");
  }, [showNotification]);

  return <Navigate to={PATH_AUTH.login} />;
};

export default Logout;
