import Axios from "../utils/http-request";
import {
  ApiResetPassword,
  ApiUserLoginPropType,
  ApiUserSignUpPropType,
  ApiVerifyAccount,
  AxiosErrorResponse,
} from "../interface/api_request";

export const apiUserLogin = async (values: ApiUserLoginPropType) => {
  try {
    const uri = `/app/auth/login`;
    return await Axios.post(uri, values, {});
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const apiUserSignUp = async (values: ApiUserSignUpPropType) => {
  try {
    const uri = `/app/auth/register/`;
    return await Axios.post(uri, values, {});
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const apiForgotPassword = async (value: string) => {
  let uri = "";
  try {
    const uri = `/app/auth/forgotten-password?email=${value}`;
    return await Axios.put(uri, {});
  } catch (e: any) {
    return {
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const apiVerifyAccount = async (value: ApiVerifyAccount) => {
  try {
    const uri = `/app/auth/verify-account?email=${value.email}`;
    const response = await Axios.put(
      uri,
      {
        code: value.code,
      },
      {}
    );
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const apiResetPassword = async (value: ApiResetPassword) => {
  try {
    const uri = `/app/auth/reset-password?email=${value.email}`;
    return await Axios.put(uri, value, {});
  } catch (e: any) {
    return {
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const apiResendAccountCode = async (value: string) => {
  try {
    const uri = `/app/auth/resend-account-code?email=${value}`;
    return await Axios.put(uri, value, {});
  } catch (e: any) {
    return {
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};
