import React, { FC, useState } from "react";
import {
  MaxAmountValue,
  TextInputContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { Box, Typography, Stack } from "@mui/material";
import {
  ButtonContainedLight,
  ButtonContainedMain,
} from "../UIElements/button";
import { useTheme } from "@mui/material/styles";
import { CustomTextSelectContainer, UserInfo } from "../textInput";
import { TransactionDetailsPropType } from "../../interface/transaction";
import CustomTransactionDetailsModal from "../customTransactionDetailsModal";
import { PasswordModal } from "../passwordModal";
import {
  TransactionResult,
  TransactionResultFailed,
} from "../transactionResult";
import RadioContainer from "../UIElements/radioContainer";
import {
  addCommasToNumbers,
  hasValues,
  parseFormattedNumber,
} from "../../utils/basic";
import {
  getUtilitiesVariation,
  rechargeCableTV,
  verifyMerchantNumber,
} from "../../api/utilities";
import { useAuth } from "../../hooks/useAuth";
import LoadingSpinner from "../UIElements/loadingSpinner";
import { useNotificationService } from "../UIElements/notification";
import { WalletBalanceType } from "../../interface/useDetail";
import { ProviderDetailsProps, VariationSelectedProps } from "../../interface/cable";
import { verifyPin } from "../../api/users";

//


const radioValues = [
  { name: "Change", id: 1 },
  { name: "Renew", id: 2 },
];
//

const CableTvContent: FC<{
  provider_data: any[];
  userPhoneNumber: string;
  baseWallet: WalletBalanceType | undefined;
}> = ({ provider_data, baseWallet, userPhoneNumber }) => {
  const theme = useTheme();
  const { token, base } = useAuth();
  const { showNotification } = useNotificationService();
  const [cableDetails, setCableDetails] = useState<{
    provider: ProviderDetailsProps;
    variations: VariationSelectedProps[];
    variation_selected: VariationSelectedProps;
    user_info: any;
    smartcard_number: string;
    type: string;
    amount: number | string;
    validate: boolean;
  }>({
    provider: {} as ProviderDetailsProps,
    user_info: {
      Address: "",
      Customer_Account_Type: "",
      Customer_Name: " ",
      MeterNumber: "",
      Meter_Type: "",
      Min_Purchase_Amount: 500,
    },
    variations: [],
    variation_selected: {} as VariationSelectedProps,
    smartcard_number: "",
    type: "renew",
    amount: "",
    validate: false,
  });
  const [modalContainer, setModalContainer] = useState<{
    modalDetails: boolean;
    pinModal: boolean;
    isLoading: boolean;
    resultModal: boolean;
    failedModal: boolean;
  }>({
    modalDetails: false,
    pinModal: false,
    isLoading: false,
    resultModal: false,
    failedModal: false,
  });
  const handleModal = () => {
    // check if it's a dollar account
    if (base?.code === "usd" || base?.name === "dollars") {
      showNotification(
        "Conversion for dollar account has not been implemented",
        "info"
      );
      return;
    }
    // check if amount is more than base amount
    if (
      parseFormattedNumber(cableDetails?.amount?.toString()) >
      (baseWallet?.value ?? 0)
    ) {
      showNotification(`Amount is greater than base amount`, "error");
      return;
    }

    // check amout is equal to renewal amount
    if (
      parseFormattedNumber(cableDetails?.amount?.toString()) !==
      cableDetails?.user_info?.Renewal_Amount
    ) {
      showNotification(
        `Purchase amount is ${base?.symbol} ${cableDetails?.user_info?.Renewal_Amount}`,
        "error"
      );
      return;
    }
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: !prev.modalDetails,
    }));
  };
  const handlePinModal = () =>
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: !prev.modalDetails,
      pinModal: !prev.pinModal,
    }));

  // verify pin then send confirmation
  const handleResultModal = async (pin: string[]) => {
    console.log(cableDetails);
    const token_key = token ?? "";
    const pin_value = pin.join("");

    const recharge_data = {
      amount: cableDetails?.amount.toString(),
      service_id: cableDetails?.provider?.serviceID,
      billers_code: cableDetails?.smartcard_number,
      quantity: 1,
      subscription_type: cableDetails?.type?.toLowerCase(),
      variation_code: cableDetails?.variation_selected?.variation_code,
      phone_number: userPhoneNumber,
      pin: pin_value
    };

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // sent final request to server
      const cable_recharge = await rechargeCableTV(recharge_data, token_key);
      if ("msg" in cable_recharge) {
        showNotification(cable_recharge.msg, "error");
        setModalContainer((prev) => ({
          ...prev,
          pinModal: !prev.pinModal,
          failedModal: !prev.failedModal,
        }));
      } else if (cable_recharge.status == 200) {
        showNotification("Cable Recharged", "success");
        setModalContainer((prev) => ({
          ...prev,
          pinModal: !prev.pinModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        showNotification("An error occured while recharging cable", "error");
      }
    } else {
      showNotification("Invalid Pin", "error");
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        failedModal: !prev.failedModal,
      }));
    }
  };

  // handle provider selection
  const handleProviderSelection = async (obj: ProviderDetailsProps) => {
    const token_key = token ?? "";
    setCableDetails((prev) => ({
      ...prev,
      provider: obj,
    }));
    showNotification("Fetching variations, kindly hold on", "info");

    if (hasValues(obj)) {
      // update state
      const variation_fetch = await getUtilitiesVariation(
        obj?.serviceID,
        token_key
      );
      if (variation_fetch.status !== 200) {
        showNotification(`${obj.name} variations Failed`, "error");
        return;
      } else {
        const variation =
          variation_fetch?.data?.data?.variations ||
          variation_fetch?.data?.data?.varations ||
          [];
        showNotification("Variations Fetched", "success");
        setCableDetails((prev) => ({
          ...prev,
          variations: variation,
        }));
      }
    }
  };

  const handleVarationSelection = async (obj: VariationSelectedProps) => {
    if (hasValues(obj)) {
      // update state
      setCableDetails((prev) => ({
        ...prev,
        variation_selected: obj,
        amount: obj.variation_amount,
      }));
    }
  };

  // handle prepaid / post paid type
  const handleRadioValue = (value: string) => {
    setCableDetails((prev) => ({ ...prev, type: value }));
  };

  // handleValidation
  const handleValidation = async () => {
    const token_key = token ?? "";
    showNotification("Fetching smartcard user, kindly hold on", "info");
    const values = {
      service_id: cableDetails?.provider?.serviceID,
      billers_code: cableDetails?.smartcard_number,
      type: cableDetails?.type,
    };

    setModalContainer((prev) => ({ ...prev, isLoading: true }));
    const validate_number = await verifyMerchantNumber(values, token_key);
    console.log(validate_number);
    if ("msg" in validate_number) {
      showNotification(validate_number.msg, "error");
      setModalContainer((prev) => ({ ...prev, isLoading: false }));
    } else if (validate_number.status == 200) {
      showNotification("Smartcard User details Fetched", "success");
      setModalContainer((prev) => ({ ...prev, isLoading: false }));
      setCableDetails((prev) => ({
        ...prev,
        user_info: validate_number?.data?.data,
        validate: true,
        amount: validate_number?.data?.data?.Renewal_Amount,
      }));
    } else {
      showNotification("An error occured while validating number", "error");
      setModalContainer((prev) => ({ ...prev, isLoading: false }));
    }
  };
  const handleMaxAmount = (num: number) => {
    // setCableDetails((prev) => ({ ...prev, amount: num }));
  };

  // const handleAmountChange = (e: { target: { value: any } }) => {
  //   const value = e.target.value.replace(/,/g, "");
  //   setCableDetails((prev) => ({ ...prev, amount: value }));
  // };

  // const handleAddComma = () => {
  //   const plainNumber = cableDetails?.amount || "";
  //   const formattedNumber = addCommasToNumbers(+plainNumber);
  //   setCableDetails((prev) => ({ ...prev, amount: formattedNumber }));
  // };

  // const handleFocus = () => {
  //   if (cableDetails?.amount) {
  //     const plainNumber = parseFormattedNumber(
  //       cableDetails?.amount?.toString()
  //     );
  //     setCableDetails((prev) => ({ ...prev, amount: plainNumber }));
  //   }
  // };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      resultModal: false,
    }));
  };

  return (
    <>
      <CableTransaction
        data={cableDetails}
        charges={""}
        onClose={closeAllModal}
        open={modalContainer?.modalDetails}
        onclick={handlePinModal}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />

      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {/* You have <span style={{ fontWeight: 700 }}>successfully</span> bought{" "}
          <span style={{ fontWeight: 700 }}> 95.9 Kwh </span> to{" "}
          <span style={{ fontWeight: 700 }}>Joseph Oloruntoba</span> */}
        </Typography>
      </TransactionResult>
      <TransactionResultFailed
        onClose={closeAllModal}
        openResult={modalContainer?.failedModal}
        reason="Transaction Failed"
      />

      <Box>
        <RadioContainer data={radioValues} handleValue={handleRadioValue} />
        <CustomTextSelectContainer
          text={"Subscription Provider"}
          data={provider_data}
          placeHolder="Select Provider"
          modalText={"Select Provider"}
          onclick={handleProviderSelection}
          value={""}
        />
        <CustomTextSelectContainer
          text={"Variation"}
          data={
            cableDetails?.variations?.length > 1 ? cableDetails.variations : []
          }
          placeHolder="Select"
          modalText={"Select Variation"}
          onclick={handleVarationSelection}
          value={""}
        />
        <TextInputContainer
          text="Smartcard Number"
          value={cableDetails?.smartcard_number}
          onclick={(e) =>
            setCableDetails((prev) => ({
              ...prev,
              smartcard_number: e.target.value,
            }))
          }
          placeholder="Enter number here..."
        />
        <Stack direction={"column"} alignItems={"center"} sx={{ my: 2 }}>
          <ButtonContainedLight sx={{}} onclick={handleValidation}>
            Validate Meter Number
          </ButtonContainedLight>
        </Stack>{" "}
        {modalContainer?.isLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: 200,
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
        {cableDetails?.validate && (
          <>
            <UserInfo
              firstlabel="Name:"
              secondlabel="Amount:"
              value1={cableDetails?.user_info?.Customer_Name}
              value2={`₦${cableDetails?.user_info?.Renewal_Amount}`}
            />
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary, my: 2 }}
            >
              Your Balance{" "}
            </Typography>
            {!!baseWallet && (
              <MaxAmountValue
                onclick={handleMaxAmount}
                currency={base?.code}
                amount={baseWallet?.value}
              />
            )}{" "}
            {/* <TextValueContainer
              text="Value"
              onFocusHandler={handleFocus}
              onBlurHandler={handleAddComma}
              onchange={handleAmountChange}
              currency={base?.code?.toUpperCase()}
              amount={cableDetails?.amount}
              icon={base?.image}
            /> */}
            <Stack direction="column" alignItems={"center"}>
              <ButtonContainedMain
                sx={{
                  width: "150px",
                  fontWeight: 500,
                  fontSize: "16px",
                  
                  my: 2,
                }}
                onclick={handleModal}
              >
                Send
              </ButtonContainedMain>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default CableTvContent;

const CableTransaction = ({
  onClose,
  onclick,
  open,
  data,
}: TransactionDetailsPropType) => {
  const theme = useTheme();
  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
    fontWeight: 700,
  };
  return (
    <CustomTransactionDetailsModal
      open={open}
      onClose={onClose}
      onclick={onclick}
    >
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            ₦{data?.amount}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Cable
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.provider?.name}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Variation
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.variation_selected?.name}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            cost
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            ₦{data?.user_info?.Renewal_Amount}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Customer name
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.user_info?.Customer_Name}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Smartcard number
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.smartcard_number}
          </Typography>
        </Stack>
      </Box>
    </CustomTransactionDetailsModal>
  );
};
