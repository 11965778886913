import React, { FC, useEffect, useState } from "react";

//
import { Box, Typography, Stack } from "@mui/material";

import {
  MaxAmountValue,
  TextIconContainer,
  TextInputContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { SearchInput } from "../UIElements/searchInput";
import { useTheme } from "@mui/material/styles";
import {
  ButtonContainedLight,
  ButtonContainedMain,
} from "../UIElements/button";
import { SendCryptoTransaction } from "./modal/dashboardModals";
import { PasswordModal } from "../passwordModal";
import { TransactionResult } from "../transactionResult";
import { OTPModal } from "../otpModal";
import useResponsive from "../../hooks/useResponsive";
import { TransactionIcon } from "../UIElements/svgIcons";
import { useDetails } from "../../hooks/useDetails";
import { useAuth } from "../../hooks/useAuth";
import { WalletBalanceType } from "../../interface/useDetail";
import {
  getCryptoToBaseCurrencyForSend,
  sendCrypto,
  sendCryptoMinMaxCheck,
  sendCryptoVerifier,
} from "../../api/transaction";
import { useNotificationService } from "../UIElements/notification";
import { addCommasToNumbers, parseFormattedNumber } from "../../utils/basic";
import { verifyPin } from "../../api/profile";
import { requestOtp, validateOtp } from "../../api/users";

//
const SendCryptoContent: FC<{ tokenList: any[] }> = ({ tokenList }) => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");
  const { base, token } = useAuth();
  const { wallet } = useDetails();
  const { showNotification } = useNotificationService();
  const [assetWallet, setAssetWallet] = useState<{
    baseWallet: any;
    cryptoWallet: any;
    switch: boolean;
  }>({
    baseWallet: {},
    cryptoWallet: {},
    switch: true,
  });
  const [filteredData, setFilteredData] = useState<{
    coin_list: any[];
    search_value: string;
  }>({
    coin_list: tokenList ?? [],
    search_value: "",
  });
  const [modalContainer, setModalContainer] = useState({
    modalDetails: false,
    pinModal: false,
    otpModal: false,
    resultModal: false,
    failedModal: false,
    charges: "",
    converted_amount_value: "",
    pin: "",
  });

  const [currency, setCurrency] = useState<{
    token: any;
    exchange: boolean;
    amount_value: number | string;
    token_amount_value: number;
    base_token_value: number;
    receiver_wallet_address: string;
    memo: string;
  }>({
    token: {},
    exchange: false,
    amount_value: "",
    token_amount_value: 0,
    base_token_value: 0,
    receiver_wallet_address: "",
    memo: "",
  });

  // Update token_amount_value whenever amount_value or base_token_value changes
  useEffect(() => {
    if (!assetWallet?.switch) {
      // Calculate token_amount_value based on amount_value and base_token_value
      const tokenAmount =
        parseFormattedNumber(currency.amount_value?.toString()) /
        currency.base_token_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        token_amount_value: tokenAmount,
      }));
    } else {
      const tokenAmount =
        currency.token_amount_value * currency.base_token_value;
      setCurrency((prevCurrency) => ({
        ...prevCurrency,
        amount_value: tokenAmount,
      }));
    }
  }, [
    currency.amount_value,
    currency.base_token_value,
    currency?.token_amount_value,
  ]);

  // get the value of the base from wallet
  const getAssetWallet = (tokenObj: any) => {
    const cryptoBalance = wallet?.find(
      (wallet: WalletBalanceType) =>
        wallet.code === tokenObj?.code || wallet.name === tokenObj?.name
    );
    const walletBalance = wallet?.find(
      (currency: WalletBalanceType) =>
        currency.code === base?.code || currency.name === base?.name
    );
    setAssetWallet((prev) => ({
      ...prev,
      baseWallet: walletBalance,
      cryptoWallet: cryptoBalance,
    }));
  };

  // handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilteredData((prev) => ({ ...prev, search_value: value }));

    if (value.length === 0) {
      setFilteredData((prev) => ({ ...prev, coin_list: tokenList })); // Reset to original list if search input is empty
    } else {
      const filteredTokens = filteredData?.coin_list?.filter((token) =>
        token?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredData((prev) => ({ ...prev, coin_list: filteredTokens }));
    }
  };

  const handleModal = async () => {
    const token_key = token ?? "";
    if (currency?.receiver_wallet_address?.length < 6) {
      showNotification("Wallet Address or Memo must be filled", "error");
      return;
    }
    showNotification("Verifying Transaction", "info");
    const data = {
      currency_name: currency?.token?.name,
      amount: Number(currency?.token_amount_value),
      rate: currency?.base_token_value?.toString(),
      base_currency_amount: parseFormattedNumber(
        currency?.amount_value?.toString()
      ).toString(),
    };
    const check_request = await sendCryptoMinMaxCheck(data, token_key);
    console.log(check_request);
    if ("msg" in check_request) {
      showNotification(check_request.msg, "error");
    } else if (check_request.status == 200) {
      // call send crypto verifier
      const sendData = {
        currency_name: currency?.token?.name,
        amount: Number(currency?.token_amount_value),
        destination_address: currency?.receiver_wallet_address,
        memo: currency?.memo,
        rate: Number(currency?.base_token_value),
        pin: "",
      };
      const send_result = await sendCryptoVerifier(sendData, token_key);
      if ("msg" in send_result) {
        showNotification(send_result.msg, "error");
      } else if (send_result.status == 200) {
        // update charges and amount value
        setModalContainer((prev) => ({
          ...prev,
          modalDetails: !prev.modalDetails,
          charges: send_result?.data?.data?.fee,
          converted_amount_value: send_result?.data?.data?.value,
        }));
      } else {
        showNotification("An error occured", "error");
      }
    } else {
      showNotification("An error occured", "error");
    }
  };
  const handlePinModal = () => {
    setModalContainer((prev) => ({ ...prev, pinModal: !prev.pinModal }));
  };

  const handleOtpModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // send otp request
      const send_request = await requestOtp(token_key);
      console.log(send_request?.data?.msg);
      if (send_request?.status === 200) {
        showNotification(send_request?.data?.msg, "success");
      } else {
        showNotification("OTP code not sent", "error");
      }
      // update state
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        otpModal: !prev.otpModal,
        pin: pin_value,
      }));
    } else {
      showNotification("Invalid Pin", "error");
    }
  };
  const handleResultModal = async (otp: string[]) => {
    const token_key = token ?? "";
    // verify otp and invoke buy crypto api
    const otp_value = otp.join("");
    const data = {
      currency_name: currency?.token?.name,
      amount: Number(currency?.token_amount_value),
      destination_address: currency?.receiver_wallet_address,
      memo: currency?.memo,
      rate: Number(currency?.base_token_value),
      base_currency_amount: parseFormattedNumber(
        currency?.amount_value?.toString()
      )?.toString(),
      pin: modalContainer?.pin,
    };
    const otp_verify = await validateOtp(otp_value, token_key);
    if (otp_verify?.status === 200) {
      const send_crypto = await sendCrypto(data, token_key);
console.log(send_crypto)
      if ("msg" in send_crypto) {
        showNotification(
          send_crypto?.msg,
          "error"
        );
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          failedModal: !prev.failedModal,
        }));
      } else if (send_crypto.status == 200) {
        showNotification(send_crypto?.data?.msg ?? "Transaction", "success");

        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        showNotification("An error occured", "error");
      }
    } else {
      showNotification("OTP Verification Failed", "error");
      setModalContainer((prev) => ({
        ...prev,
        otpModal: !prev.otpModal,
        failedModal: !prev.failedModal,
      }));
    }
  };
  const handleCurrency = async (tokenObj: any) => {
    const token_key = token ?? "";
    const query = {
      amount: "1",
      crypto: tokenObj?.name,
      currency: base?.code,
    };

    await getAssetWallet(tokenObj);

    const result = await getCryptoToBaseCurrencyForSend(query, token_key);
    if (result?.status === 200) {
      setCurrency((prev) => ({
        ...prev,
        exchange: true,
        token: tokenObj,
        base_token_value: result?.data?.data?.value,
      }));
    }
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    if (assetWallet?.switch) {
      setCurrency((prev) => ({ ...prev, token_amount_value: e.target.value }));
    } else {
      setCurrency((prev) => ({ ...prev, amount_value: e.target.value }));
    }
  };
  const handleMaxAmount = (num: number) => {
    if (assetWallet?.switch) {
      setCurrency((prev) => ({ ...prev, token_amount_value: num }));
    } else {
      setCurrency((prev) => ({ ...prev, amount_value: num }));
    }
  };

  const checkMaxAmount = async () => {
    if (assetWallet?.switch) {
      const booleanValue =
        currency?.token_amount_value > assetWallet?.cryptoWallet?.value;
      if (booleanValue) {
        // await showNotification("Insuficient Funds", "error");
      }
    } else {
      const booleanValue =
        currency?.amount_value > assetWallet?.baseWallet?.value;
      if (booleanValue) {
        // await showNotification("Insuficient Funds", "error");
      }
    }
  };

  const handleAddComma = () => {
    if (!assetWallet?.switch) {
      const plainNumber = currency.amount_value || "";
      const formattedNumber = addCommasToNumbers(+plainNumber);
      setCurrency((prev) => ({ ...prev, amount_value: formattedNumber }));
    } else {
      return;
    }
  };

  const handleFocus = () => {
    if (!assetWallet?.switch && currency.amount_value) {
      const plainNumber = parseFormattedNumber(
        currency.amount_value?.toString()
      );
      setCurrency((prev) => ({ ...prev, amount_value: plainNumber }));
    }
  };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      otpModal: false,
      resultModal: false,
    }));
  };

  return (
    <>
      <SendCryptoTransaction
        address={currency?.receiver_wallet_address}
        onClose={closeAllModal}
        charges={modalContainer?.charges}
        currency={currency?.token?.code?.toUpperCase()}
        open={modalContainer.modalDetails}
        amount={Number(currency?.amount_value).toFixed(2)}
        onclick={handlePinModal}
        currencyValue={modalContainer?.converted_amount_value}
      />

      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleOtpModal}
        onClose={closeAllModal}
      />
      <OTPModal
        open={modalContainer.otpModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          You have <span style={{ fontWeight: 700 }}>successfully</span> sent{" "}
          <span style={{ fontWeight: 700 }}>
            {currency?.amount_value} {currency?.token?.code?.toUpperCase()}{" "}
          </span>
        </Typography>
      </TransactionResult>
      <Box sx={{ mt: isSmall ? 0 : 4 }}>
        {currency?.exchange && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Balance{" "}
            </Typography>
            {!!assetWallet && (
              <MaxAmountValue
                onclick={handleMaxAmount}
                currency={
                  assetWallet?.switch
                    ? assetWallet?.cryptoWallet?.code
                    : base?.code
                }
                amount={
                  assetWallet?.switch
                    ? assetWallet?.cryptoWallet?.value
                    : assetWallet?.baseWallet?.value
                }
              />
            )}{" "}
            <TextValueContainer
              text="You Pay"
              onFocusHandler={handleFocus}
              onchange={handleAmountChange}
              currency={
                assetWallet?.switch
                  ? currency?.token?.code?.toUpperCase()
                  : base?.code.toUpperCase()
              }
              onBlurHandler={handleAddComma}
              amount={
                assetWallet?.switch
                  ? currency?.token_amount_value
                  : currency?.amount_value
              }
              icon={assetWallet?.switch ? currency?.token?.image : base?.image}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                my: 2,
              }}
            >
              <span
                onClick={() => {
                  setAssetWallet((prev) => ({ ...prev, switch: !prev.switch }));
                }}
                style={{
                  color: theme.palette.secondary.light,
                  cursor: "pointer",
                }}
              >
                <TransactionIcon />
              </span>
            </Box>
            <TextValueContainer
              text="Value"
              onBlurHandler={() => {}}
              onFocusHandler={() => {}}
              onchange={() => {}}
              currency={
                !assetWallet?.switch
                  ? currency?.token?.code?.toUpperCase()
                  : base?.code.toUpperCase()
              }
              amount={
                !assetWallet?.switch
                  ? currency?.token_amount_value
                  : currency?.amount_value
              }
              icon={!assetWallet?.switch ? currency?.token?.image : base?.image}
            />
            <Stack direction="column" alignItems={"center"}>
              <Typography
                variant="body2"
                sx={{ my: 2, color: theme.palette.secondary.main }}
              >
                1 {currency?.token?.code?.toUpperCase()} ={" "}
                {base?.code?.toUpperCase()} {currency?.base_token_value}
              </Typography>
            </Stack>
            <TextInputContainer
              onclick={(e) =>
                setCurrency((prev) => ({
                  ...prev,
                  receiver_wallet_address: e.target.value,
                }))
              }
              value={currency?.receiver_wallet_address}
              placeholder="Address here..."
              text="Wallet Address"
            />
            <TextInputContainer
              onclick={(e) =>
                setCurrency((prev) => ({ ...prev, memo: e.target.value }))
              }
              value={currency?.memo}
              placeholder="Text here..."
              text="Memo (optional)"
            />
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ my: 2 }}
            >
              <ButtonContainedLight
                sx={{}}
                onclick={() => {
                  showNotification(
                    "Scan QR is currently not available at the moment",
                    "info"
                  );
                }}
              >
                Scan QR - Code
              </ButtonContainedLight>
              <ButtonContainedMain sx={{}} onclick={handleModal}>
                Send
              </ButtonContainedMain>
            </Stack>
          </Box>
        )}
        {!currency?.exchange && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Select Your Preferred coin
            </Typography>
            <Box sx={{ my: 2 }}>
              <SearchInput
                width="100%"
                text="Search Crypto"
                handler={handleSearchChange}
                value={filteredData?.search_value}
              />
              {filteredData?.coin_list?.map((token, id) => (
                <Box key={id}>
                  <TextIconContainer
                    image={token?.image}
                    onclick={() => handleCurrency(token)}
                  >
                    {token?.name?.toUpperCase()}
                  </TextIconContainer>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SendCryptoContent;
