import React, { FC, ReactNode, createContext, useState } from "react";
import config from "../utils/config";
import Cookies from "js-cookie";
import { getUserDetails } from "../api/profile";
import {
  DetailContextData,
  DetailsData,
  UserSettingsProps,
  WalletBalanceType,
} from "../interface/useDetail";
import {
  ClearDataFunction,
  LoadDataFunction,
  RefetchDataFunction,
  SetDataFunction,
} from "../types/useDetails";
import { useAuth } from "./useAuth";
import { getWalletBalance } from "../api/transaction";

const DetailContext = createContext<DetailContextData | undefined>(undefined);

export function useDetails() {
  const context = React.useContext(DetailContext);
  if (context === undefined) {
    throw new Error("useAuth must be called within a AuthProvider");
  }
  return context;
}

interface BaseProviderProps {
  children: ReactNode;
}
export const DetailProvider: FC<BaseProviderProps> = ({ children }) => {
  const [userDetail, setUserDetail] = useState<DetailsData>();
  const [userSettings, setUserSettings] = useState<UserSettingsProps | null>(
    null
  );
  const [wallet, setWallet] = useState<WalletBalanceType[]>([]);
  const { token } = useAuth();

  const loadUserData: LoadDataFunction = async () => {
    const walletCookie = Cookies.get(config.key.wallet);
    if (walletCookie) {
      setWallet(JSON.parse(walletCookie));
    }
    const userSettingCookie = Cookies.get(config.key.userSetting);
    if (userSettingCookie) {
      setUserSettings(JSON.parse(userSettingCookie));
    }
  };

  const refetchData: RefetchDataFunction = async () => {
    // call a refresh function to get the latest data from the database
    const token_key = token ?? "";
    set_user_wallet(token_key);
  };

  const clear_userData: ClearDataFunction = async () => {
    Cookies.remove(config.key.wallet);
    Cookies.remove(config.key.userSetting);
    setUserDetail(undefined);
    setUserSettings(null);
    setWallet([]);
  };

  const fetchUserData = async (
    token_key: string
  ): Promise<DetailsData | undefined> => {
    const result = await getUserDetails(token_key);
    if (result?.status === "error") {
      return;
    } else if (result?.status === 200) {
      return result?.data?.data;
    }
  };

  const set_user_wallet: SetDataFunction = async (data_token) => {
    try {
      const userData = await fetchUserData(data_token);
      if (!userData) {
        return;
      }
      const walletBalance = userData?.wallet_balance
      Cookies.set(config.key.wallet, JSON?.stringify(walletBalance));
      setWallet(walletBalance);
      setUserDetail(userData);
      const settings = {
        bvn: userData.bvn,
        pin: userData.pin ?? "",
        is_base_currency_set: userData.is_base_currency_set ?? false,
      };
      Cookies.set(config.key.userSetting, JSON?.stringify(settings));
      setUserSettings(settings);
    } catch (e) {
      throw new Error("Server Error");
    }
  };

  // function to check userData and fetch
  // const loadUserData = async (
  //   token_key: string
  // ): Promise<DetailsData | undefined> => {
  //   if (!!userDetail) {
  //     return userDetail;
  //   } else {
  //     return await fetchUserData(token_key);
  //   }
  // };

  return (
    <DetailContext.Provider
      value={{
        fetchUserData,
        set_user_wallet,
        wallet,
        userDetail,
        loadUserData,
        userSettings,
        refetchData,
        clear_userData,
      }}
    >
      {children}
    </DetailContext.Provider>
  );
};
