import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { CopyIcon } from "./UIElements/svgIcons";
import useResponsive from "../hooks/useResponsive";
import { useNotificationService } from "./UIElements/notification";

export const PageMainTextSummary: React.FC<{
  text: string;
  referralCode: boolean;
  code: string | undefined;
}> = ({ text, referralCode, code }) => {
  const theme = useTheme();
  const {showNotification} = useNotificationService()
  const isSmall = useResponsive("down", "sm", "xl");
  const isMedium = useResponsive("down", "md", "xl");

  const handleCopy = () => {
    if (!code) {
      return;
    }
    navigator.clipboard
      .writeText(code)
      .then(() => {
        showNotification("Text copied successfully", "info");
      })
      .catch((error) => {
        showNotification("Error copying text: ", "error");
      });
  };
  return (
    <Box
      sx={{
        padding: 4,
        background: theme.palette.primary.light,
        flexGrow: 1,
        marginX: "10px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack>
        <Typography
          variant={ isSmall ? "h3" : "h2"}
          sx={{
            fontWeight: 700,
            whiteSpace: "wrap",
            color: theme.palette.primary.main,
            width: isSmall ? "100%" : "380px",
            paddingY: isSmall ? "2rem" : "1rem" 
          }}
        >
          {text}
        </Typography>
        {referralCode && (
          <Stack direction="row" sx={{ gap: "16px" }}>
            <Typography
              sx={{ color: theme.palette.secondary.main, fontWeight: 500 }}
            >
              Referral Code:
              <span
                style={{ marginLeft: 2, color: theme.palette.text.primary }}
              >
                {code}
              </span>
            </Typography>{" "}
            <span style={{ cursor: "pointer" }} onClick={handleCopy}>
              <CopyIcon />
            </span>
          </Stack>
        )}
      </Stack>
     {!isSmall && <img src="/assets/icons/bg-icon.svg" height={140} width={145} alt="" />}
    </Box>
  );
};
