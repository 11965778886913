import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../../hooks/useResponsive";

export default function RadioContainer({ data, handleValue }: any) {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xs");

  const [value, setValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    handleValue((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl
      sx={{
        background: theme.palette.common.white,
        padding: 3,
        width: isMedium ? "80%" : "96%",
        my: 2,
        borderRadius: "20px",
      }}
    >
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {data?.map((val: { name: string; id: number }, id: number) => (
          <FormControlLabel
            key={id}
            value={val.name}
            control={<Radio onChange={handleChange} />}
            label={val.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
