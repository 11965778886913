import { PATH_DASHBOARD, PATH_KUMOSTAKING } from "../routes/path";

export const dashboardData = [
  {
    id: 1,
    main: "Buy/Sell Crypto",
    subMain: "Trade BTC, ETH, DASH now!",
    link: PATH_DASHBOARD.buySell,
    image: "/assets/icons/dashboard/buy-sell.svg"
  },
  {
    id: 2,
    main: "Send/Receive Crypto",
    subMain: "Scan and send coin to address",
    link: PATH_DASHBOARD.sendReceive,
    image: "/assets/icons/dashboard/send-receive.svg"

  },
  {
    id: 3,
    main: "Swap Crypto",
    subMain: "Easily & Securely Swap your coins",
    link: PATH_DASHBOARD.swapCoin,
    image: "/assets/icons/dashboard/swap-crypto.svg"

  },
  {
    id: 4,
    main: "Transfer Money",
    subMain: "Transfer and send Paylink to friends",
    link: PATH_DASHBOARD.kumoTransfer,
    image: "/assets/icons/dashboard/transfer.svg"

  },
  {
    id: 5,
    main: "Kumo Staking",
    subMain: "Transfer and send Paylink to friends",
    link: PATH_KUMOSTAKING.root,
    image: "/assets/icons/dashboard/kumo-stacking.svg"

  },
  {
    id: 6,
    main: "Fund Wallet",
    subMain: "Need copy here",
    link: PATH_DASHBOARD.fundWallet,
    image: "/assets/icons/dashboard/fund-wallet.svg"

  },
];
