import React, { ChangeEvent, FC, useEffect, useState } from "react";
import {
  MaxAmountValue,
  TextInputContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { Box, Typography, Stack } from "@mui/material";
import { ButtonContainedMain } from "../UIElements/button";
import { useTheme } from "@mui/material/styles";
import { CustomTextSelectContainer } from "../textInput";
import { TransactionDetailsPropType } from "../../interface/transaction";
import CustomTransactionDetailsModal from "../customTransactionDetailsModal";
import { PasswordModal } from "../passwordModal";
import { OTPModal } from "../otpModal";
import { TransactionResult } from "../transactionResult";
import { WalletBalanceType } from "../../interface/useDetail";
import { useAuth } from "../../hooks/useAuth";
import { getAccountNameFromAccountNumber } from "../../api/utilities";
import { useNotificationService } from "../UIElements/notification";
import { getFeeCost } from "../../api/settings";
import { transferToBank, transferToBankMinMax } from "../../api/transaction";
import { verifyPin } from "../../api/profile";
import { requestOtp, validateOtp } from "../../api/users";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../../routes/path";
import { addCommasToNumbers, parseFormattedNumber } from "../../utils/basic";

const TransferLocalMoneyContents: FC<{
  baseWallet: WalletBalanceType;
  bankList: any[];
}> = ({ baseWallet, bankList }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { token, base } = useAuth();
  const { showNotification } = useNotificationService();
  const [transfer, setTransfer] = useState<{
    bank_name: string;
    account_name: string;
    account_number: string;
    bank_code: string;
    amount: string | number;
  }>({
    bank_code: "",
    account_name: "",
    bank_name: "",
    account_number: "",
    amount: "",
  });
  const [modalContainer, setModalContainer] = useState({
    modalDetails: false,
    pinModal: false,
    otpModal: false,
    resultModal: false,
    failedModal: false,
    charges: "",
    pin: "",
  });

  // fetch fee charge for bank transfer
  useEffect(() => {
    getCharges();
  }, []);
  // fetch account name
  useEffect(() => {
    if (
      transfer?.account_number?.length === 10 &&
      transfer?.bank_name?.length > 1
    ) {
      fetchAccountName();
    }
  }, [transfer?.bank_name, transfer?.account_number]);

  // get charges and fee cost
  const getCharges = async () => {
    const token_key = token ?? "";
    const fee_charge = await getFeeCost("TRANSFER_TO_BANK_FEE", token_key);
    if (fee_charge?.status === 200) {
      setModalContainer((prev) => ({
        ...prev,
        charges: fee_charge?.data?.data?.cost,
      }));
    }
  };

  // fetch account name from server
  const fetchAccountName = async () => {
    const token_key = token ?? "";
    showNotification("Fetching Account Name", "info");
    const data = {
      account_number: transfer?.account_number,
      bank_code: transfer?.bank_code,
    };
    const result = await getAccountNameFromAccountNumber(data, token_key);
    if (result?.status === 200) {
      showNotification("Account Name Fetched", "success");
      const name = result?.data?.data?.account_name ?? "";
      
      setTransfer((prev) => ({ ...prev, account_name: name }));
    }
  };

  //  modal functions
  const handleModal = async () => {
    const token_key = token ?? "";

    // check values
    if (parseFormattedNumber(transfer?.amount?.toString()) == 0) {
      showNotification("Amount is required, kindly enter amount", "error");
      return;
    }
    if (transfer?.account_name?.length < 2) {
      showNotification("Account Name is required", "error");
      return;
    }

    // check if base is usd
    if (base?.code === "usd") {
      showNotification("You are transferring from USD to NGN.", "error");
      return;
    }
    const data = {
      account_name: transfer?.account_name,
      account_number: transfer?.account_number,
      bank_code: transfer?.bank_code,
      bank_name: transfer?.bank_name,
      currency_name: "ngn",
      pin: "random-pin",
      naira_amount_paid: parseFormattedNumber(transfer?.amount?.toString()),
    };
    const check_amount = await transferToBankMinMax(data, token_key);
    if (check_amount?.data?.data === "OK") {
      setModalContainer((prev) => ({
        ...prev,
        modalDetails: !prev.modalDetails,
      }));
    } else {
      showNotification("Invalid Amount", "error");
    }
  };
  const handlePinModal = () => {
    setModalContainer((prev) => ({ ...prev, pinModal: !prev.pinModal }));
  };

  const handleOtpModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // send otp request
      const send_request = await requestOtp(token_key);
      if (send_request?.status === 200) {
        showNotification(send_request?.data?.msg, "success");
      } else {
        showNotification("OTP code not sent", "error");
      }
      // update state
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        otpModal: !prev.otpModal,
        pin: pin_value,
      }));
    } else {
      showNotification("Invalid Pin", "error");
      closeAllModal();
    }
  };

  const handleResultModal = async (otp: string[]) => {
    const token_key = token ?? "";
    // verify otp and invoke buy crypto api
    const otp_value = otp.join("");
    const data = {
      account_name: transfer?.account_name,
      account_number: transfer?.account_number,
      bank_code: transfer?.bank_code,
      bank_name: transfer?.bank_name,
      currency_name: "ngn",
      pin: modalContainer?.pin,
      naira_amount_paid: parseFormattedNumber(transfer?.amount?.toString()),
    };

    const otp_verify = await validateOtp(otp_value, token_key);
    if (otp_verify?.status === 200) {
      const transfer = await transferToBank(data, token_key);
      console.log(transfer, data);
      if (transfer?.status === 200) {
        showNotification("Money Transfered successfully", "success");
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          failedModal: !prev.failedModal,
        }));
        showNotification(
          "This operation is not available at the moment.",
          "error"
        );
        navigate(PATH_DASHBOARD.root);
      }
    } else {
      showNotification("OTP Verification Failed", "error");
      setModalContainer((prev) => ({
        ...prev,
        modalDetails: false,
        otpModal: false,
        failedModal: true,
      }));
    }
  };

  // account number handler
  const handleAccountNumber = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = e.target.value;
    // Update state with cleaned value
    setTransfer((prev) => ({ ...prev, account_number: e.target.value }));
  };

  //
  const handleAmountChange = (e: { target: { value: string } }) => {
    // Update state with cleaned value
    const value = e.target.value.replace(/,/g, "");
    setTransfer((prev) => ({ ...prev, amount: value }));

  };
  const handleMaxAmount = (num: number) => {
    setTransfer((prev) => ({ ...prev, amount: num }));
  };
 
  // bank details function
  const handleBankSelection = (obj: any) => {
    if (Object.keys(obj).length > 0) {
      // update state
      console.log(obj);
      setTransfer((prev) => ({
        ...prev,
        bank_code: obj?.code,
        bank_name: obj?.name,
        account_number: "",
      }));
    }
  };

  const handleAddComma = () => {
    const plainNumber = transfer.amount || "";
    const formattedNumber = addCommasToNumbers(+plainNumber);
    setTransfer((prev) => ({ ...prev, amount: formattedNumber }));
  };

  const handleFocus = () => {
    if (transfer.amount) {
      const plainNumber = parseFormattedNumber(transfer.amount?.toString());
      setTransfer((prev) => ({ ...prev, amount: plainNumber }));
    }
  };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      otpModal: false,
      resultModal: false,
    }));
  };
  return (
    <>
      <TransferMoneyTransaction
        data={transfer}
        charges={modalContainer?.charges}
        onClose={closeAllModal}
        open={modalContainer?.modalDetails}
        onclick={handlePinModal}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleOtpModal}
        onClose={closeAllModal}
      />
      <OTPModal
        open={modalContainer.otpModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          You have <span style={{ fontWeight: 700 }}>successfully</span>{" "}
          Transferred <span style={{ fontWeight: 700 }}>0.013149 ETH </span> to{" "}
          <span style={{ fontWeight: 700 }}>Johndoe@gmail.com</span>
        </Typography>
      </TransactionResult>

      <Box>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, color: theme.palette.text.primary }}
        >
          Your Balance{" "}
        </Typography>
        {!!baseWallet && (
          <MaxAmountValue
            onclick={handleMaxAmount}
            currency={base?.code}
            amount={Number(baseWallet?.value)}
          />
        )}

        <CustomTextSelectContainer
          placeHolder="Select Bank"
          text={"Suggested Bank"}
          data={bankList}
          modalText={"Select Bank"}
          onclick={handleBankSelection}
          value={""}
        />
        <TextInputContainer
          text="Account Number"
          value={transfer?.account_number}
          onclick={handleAccountNumber}
          placeholder="Enter Number"
        />
        <TextInputContainer
          text="Account Name"
          value={transfer?.account_name}
          onclick={() => {}}
          placeholder="Account name..."
        />

        <TextValueContainer
          text="Value"
          onFocusHandler={handleFocus}
          currency={base?.code?.toUpperCase()}
          onchange={handleAmountChange}
          onBlurHandler={handleAddComma}
          amount={transfer?.amount}
          icon={base?.image}
        />
        <Stack direction="column" alignItems={"center"}>
          <Typography
            variant="body2"
            sx={{ my: 2, color: theme.palette.secondary.main }}
          >
            Charges = {base?.symbol} {modalContainer?.charges}
          </Typography>
          <ButtonContainedMain
            sx={{ width: "150px", fontWeight: 500, fontSize: "16px" }}
            onclick={handleModal}
          >
            Proceed
          </ButtonContainedMain>
        </Stack>
      </Box>
    </>
  );
};

export default TransferLocalMoneyContents;

const TransferMoneyTransaction = ({
  onClose,
  onclick,
  open,
  charges,
  data,
}: TransactionDetailsPropType) => {
  const theme = useTheme();
  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
    fontWeight: 700,
  };
  const total_amount = parseFormattedNumber(data?.amount?.toString()) - Number(charges);
  const { base } = useAuth();
  return (
    <CustomTransactionDetailsModal
      open={open}
      onClose={onClose}
      onclick={onclick}
    >
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
          {base?.symbol} {data?.amount}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Bank
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.bank_name}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Account number
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.account_number}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Account name
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.account_name}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Charges
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {base?.symbol}
            {charges}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Total Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            &#8358; {total_amount}
          </Typography>
        </Stack>
      </Box>
    </CustomTransactionDetailsModal>
  );
};
