import React, { FC } from "react";
import { useTheme } from "@mui/material/styles";
import { useDetails } from "../../hooks/useDetails";
import ModalContainer from "../UIElements/modal";
import { Box, Button, Stack, Typography } from "@mui/material";
import { SettingsIcon } from "../UIElements/svgIcons";

export const TransactionChangePin: FC<{
  open: boolean;
  onClose: () => void;
  onclick: () => void;
}> = ({ open, onClose, onclick }) => {
  const theme = useTheme();
 

  return (
    <ModalContainer
      sx={{}}
      openModal={open}
      handleModal={() => {}}
      size="sm"
      fullWidth={true}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: "20px",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: theme.palette.primary.main,
        }}
      >
        <SettingsIcon />
        <Typography variant="body2" sx={{ fontWeight: 600 }} color="primary">
          PIN Request
        </Typography>
        <Stack
          direction={"column"}
          sx={{ textAlign: "center", color: theme.palette.text.primary, my: 2 }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            You will be unable to perform transactions in the next 24 hours if
            you continue with this process. To continue click the submit button
            below.
          </Typography>
        </Stack>

        <Stack direction={"row"} justifyContent={"center"}>
          <Button
            onClick={onclick}
            sx={{ width: "150px", fontWeight: 600 }}
          >
            Submit
          </Button>
          <Button onClick={onClose} sx={{ width: "150px", fontWeight: 600 }}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </ModalContainer>
  );
};

export const TransactionCreatePin: FC<{
  open: boolean;
  onClose: () => void;
  onclick: () => void;
}> = ({ open, onClose, onclick }) => {
  const theme = useTheme();
  const { refetchData } = useDetails();


  return (
    <ModalContainer
      sx={{}}
      openModal={open}
      handleModal={() => {}}
      size="sm"
      fullWidth={true}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: "20px",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: theme.palette.primary.main,
        }}
      >
        <SettingsIcon />
        <Typography variant="body2" sx={{ fontWeight: 600 }} color="primary">
          Setting PIN
        </Typography>
        <Stack
          direction={"column"}
          sx={{ textAlign: "center", color: theme.palette.text.primary, my: 2 }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Your transaction PIN is an additional security measure that verifies
            your identity and prevents unauthorized transactions on your
            account. Choose a secure PIN that is easy for you to remember but
            difficult for others to guess. It is crucial to keep your
            transaction PIN confidential and never share it with anyone. Your
            transaction PIN will be required for processing any transaction.
          </Typography>
        </Stack>

        <Stack direction={"row"} justifyContent={"center"}>
          <Button
            onClick={onclick}
            sx={{ width: "150px", fontWeight: 600 }}
          >
            Ok
          </Button>
          <Button onClick={onClose} sx={{ width: "150px", fontWeight: 600 }}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </ModalContainer>
  );
};
