import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import KumoWalletTransferContents from "../../components/dashboard/KumoWalletTransferContents";
import { Helmet } from "react-helmet-async";
import { getAllCoinListing } from "../../api/settings";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../../components/UIElements/notification";
import { useDetails } from "../../hooks/useDetails";

const KumoWalletTransfer: React.FC = () => {
  const theme = useTheme();
  const { user, base, token } = useAuth();
  const { showNotification } = useNotificationService();
  const [coinList, setCoinList] = useState<any[]>([]);

  useEffect(() => {
    fetchCoinListing();
  }, []);
  const fetchCoinListing = async () => {
    const token_key = token ?? "";
    const result = await getAllCoinListing(token_key);
    if (result?.status === 200) {
      const token_list = result?.data?.data 
      setCoinList([base, ...token_list ])
    } else {
      showNotification("Error while fetching coin list", "error");
    }
  };
  return (
    <>
      <Helmet>
        <title>Wallet Transfer</title>
      </Helmet>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: "#2C393F" }}>
            Kumo Wallet Transfer
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        {coinList?.length > 1 && (
          <KumoWalletTransferContents coinList={coinList} />
        )}
      </DashboardGridContainer>
    </>
  );
};

export default KumoWalletTransfer;
