import React from "react";
import { object, string } from "zod";

export const loginSchema = object({
  email: string({ required_error: "Email is required" }).email(
    "Email is invalid"
  ),
  password: string({ required_error: "Password is required" })
    .min(6, "Password must be more than 6 characters")
    .max(32, "Password must be less than 32 characters"),
});

export const forgotPasswordSchemaOne = object({
  email: string({ required_error: "Email is required" }).email(
    "Email is invalid"
  ),
});
export const forgotPasswordSchemaTwo = object({
  code: string({ required_error: "OTP code is required" }).min(
    6,
    "Enter complete OTP"
  ),
});

export const passwordSchema = object({
  password: string({ required_error: "Password is required" })
    .min(6, "Password must be more than 6 characters")
    .max(32, "Password must be less than 32 characters")
    .regex(/^(?=.*[A-Z])(?=.*\d).*$/, {
      message:
        "Password must contain at least one uppercase letter and one numeric digit",
    }),
  passwordConfirm: string({ required_error: "Please confirm your password" }),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Passwords do not match",
});

export const signUpSchema = object({
  first_name: string({ required_error: "First name is required" }).min(
    3,
    "First name is too short"
  ),
  last_name: string({ required_error: "Last name is required" }).min(
    3,
    "Last name is too short"
  ),
  email: string({ required_error: "Email is required" }).email(
    "Email is invalid"
  ),
  referral_id: string(),
  country_code: string({ required_error: "Please select an option" }),
  phone_number: string({ required_error: "Input numnber" }).min(
    5,
    "Numnber is too short"
  ),
});

// profile
export const changePasswordSchema = object({
  old_password: string({ required_error: "Please enter your old password" }),
  new_password: string({ required_error: "Password is required" })
    .min(6, "Password must be more than 6 characters")
    .max(32, "Password must be less than 32 characters")
    .regex(/^(?=.*[A-Z])(?=.*\d).*$/, {
      message:
        "Password must contain at least one uppercase letter and one numeric digit",
    }),
});
