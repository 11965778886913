import Axios from "../utils/http-request";
import { getRequestConfiguration } from "../utils/headerHelper";
import {
  AxiosErrorResponse,
  BvnVerificationProp,
  ChangeUserPassword,
  UpdatePinProps,
  UpdateUserProps,
} from "../interface/api_request";

export const getUserDetails = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/users/get-user`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const apiUpdateUser = async (values: UpdateUserProps, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/users/update-user/`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const apiChangeUserPassword = async (
  values: ChangeUserPassword,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `/app/users/update-user-password/?_id=${values?._id}`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const getSecuredURL = async (image_name: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `app/users/get-secure-url?image_name=${image_name}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return { status: "error", msg: e?.response?.data?.message || e?.message };
  }
};

export const updateImageURL = async (image_url: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `/app/users/update-image-url`;
    return await Axios.put(uri, image_url, cfg);
  } catch (e: any) {
    return { status: "error", msg: e?.response?.data?.message || e?.message };
  }
};

export const getUserReferralList = async (
  user_referral_id: string,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `app/users/get-referral-list?user_referral_id=${user_referral_id}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const verifyPin = async (pin: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `app/users/verify-pin?pin=${pin}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return { status: "error", msg: e?.response?.data?.message || e?.message };
  }
};

export const requestPinUpdate = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `app/users/request-pin-update`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const checkChangePin = async (pin_code: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `app/users/check-pin-code?pin_code=${pin_code}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return { status: "error", msg: e?.response?.data?.message || e?.message };
  }
};

export const checkPinCode = async (pin_code: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `app/users/check-pin-code?pin_code=${pin_code}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const updatePin = async (data: UpdatePinProps, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, "");

    const uri = `app/users/update-pin`;
    return await Axios.put(uri, data, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const verifyBVN = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/users/verify-bvn`;
    const response = await Axios.get(uri, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const requestBVNVerification = async (
  data: BvnVerificationProp,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/users/request-bvn-verification`;
    const response = await Axios.post(uri, data, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};
