const config = {
    key: {
        user: '___kumo_user',
        token: '___kumo_token',
        userSetting: '___kumo_setting',
        wallet: '___kumo_wallet',
        base: '___kumo_base',
    },

};


export default config;