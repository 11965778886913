import React, { useEffect, useState } from "react";

//
import { Box, Typography, Stack } from "@mui/material";

import {
  MaxAmountValue,
  TextInputContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { useTheme } from "@mui/material/styles";
import { ButtonContainedMain } from "../UIElements/button";
import {
  KumoWalletDetailsPropType,
  TransactionDetailsPropType,
} from "../../interface/transaction";
import CustomTransactionDetailsModal from "../customTransactionDetailsModal";
import { TransactionResult, TransactionResultFailed } from "../transactionResult";
import { OTPModal } from "../otpModal";
import { PasswordModal } from "../passwordModal";
import { WalletBalanceType } from "../../interface/useDetail";
import { useDetails } from "../../hooks/useDetails";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../UIElements/notification";
import { transferToKumoAccount, transferToKumoAccountVerifier } from "../../api/transaction";
import { verifyPin } from "../../api/profile";
import { requestOtp, validateOtp } from "../../api/users";
import { addCommasToNumbers, parseFormattedNumber } from "../../utils/basic";

const KumoWalletBaseTransfer: React.FC = () => {
  const theme = useTheme();
  const { showNotification } = useNotificationService();
  const { token, base, user } = useAuth();
  const { wallet } = useDetails();
  const [currency, setCurrency] = useState<{
    base_wallet: WalletBalanceType | any;
    amount_value: string | number;
    email: string;
  }>({
    base_wallet: {},
    amount_value: "",
    email: "",
  });
  const [modalContainer, setModalContainer] = useState({
    modalDetails: false,
    pinModal: false,
    otpModal: false,
    resultModal: false,
    failedModal: false,
    charges: "",
    pin: "",
  });

  // get baseWallet
  useEffect(() => {
    getBaseWallet();
  }, []);


  // get the value of the base from wallet
  const getBaseWallet = () => {
    const walletBalance = wallet?.find(
      (currency: WalletBalanceType) =>
      currency.code === base?.code || currency.name === base?.name
      );
    setCurrency((prev) => ({ ...prev, base_wallet: walletBalance }));
  };

  const handleModal = async () => {
    const base_value = currency?.base_wallet?.value ?? 0;
    const token_key = token ?? "";

    // check if email is empty
    if (currency?.email?.length < 6) {
      showNotification("Email is empty", "error");
      return;
    }
    const data = {
      currency_name: base?.name,
      user_id: user?.user_id ?? "",
      naira_amount_paid: parseFormattedNumber(currency?.amount_value?.toString()),
      email: currency?.email,
      pin: "random-pin",
    };

    const amount_exceed_check =
      parseFormattedNumber(currency?.amount_value?.toString()) > base_value || parseFormattedNumber(currency?.amount_value?.toString()) === 0;

    // Check if currency.amount_value is more than base_value
    if (!amount_exceed_check) {
      const verify_transfer = await transferToKumoAccountVerifier(
        data,
        token_key
      );
      if (
        verify_transfer?.status === 200 &&
        verify_transfer?.data?.status === "success"
      ) {
        setModalContainer((prev) => ({
          ...prev,
          modalDetails: !prev.modalDetails,
          charges: verify_transfer?.data?.data?.fee,
        }));
      } else {
        showNotification("Kumo account cannot be found", "error");

      }
    } else {
      showNotification("Amount exceeds base amount", "error");
    }
  };
  const handlePinModal = () =>
    setModalContainer((prev) => ({ ...prev, pinModal: !prev.pinModal }));

  const handleOtpModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");
    console.log(user?.user_id);

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // send otp request
      const send_request = await requestOtp(token_key);
      if (send_request?.status === 200) {
        showNotification(send_request?.data?.msg, "success");
      } else {
        showNotification("OTP code not sent", "error");
      }
      // update state
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        otpModal: !prev.otpModal,
        pin: pin_value,
      }));
    } else {
      showNotification("Invalid Pin", "error");
      closeAllModal()
    }
  };

  const handleResultModal = async (otp: string[]) => {
    const token_key = token ?? "";
    // verify otp and invoke buy crypto api
    const otp_value = otp.join("");
    const data = {
      currency_name:  base?.name,
      user_id: user?.user_id ?? "",
      naira_amount_paid: parseFormattedNumber(currency?.amount_value?.toString()),
      email: currency?.email,
      pin: modalContainer?.pin,
    };

    const otp_verify = await validateOtp(otp_value, token_key);
    if (otp_verify?.status === 200) {
      const transfer = await transferToKumoAccount(data, token_key);
      console.log(transfer);
      if (transfer?.status === 200) {
        showNotification("Crypto Transfer successfully", "success");
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        setModalContainer((prev) => ({
          ...prev,
          otpModal: !prev.otpModal,
          failedModal: !prev.failedModal,
        }));
        showNotification("Transaction Failed", "error");
      }
    } else {
      showNotification("OTP Verification Failed", "error");
      setModalContainer((prev) => ({
        ...prev,
        modalDetails: false,
        otpModal: false,
        failedModal: true,
      }));
    }
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    setCurrency((prev) => ({ ...prev, amount_value: e.target.value }));
  };

  const handleMaxAmount = (num: number) => {
    setCurrency((prev) => ({ ...prev, amount_value: num }));
  };


  // onblur function
  const handleAddComma = () => {
    const plainNumber = currency.amount_value || "";
    const formattedNumber = addCommasToNumbers(+plainNumber);
    setCurrency((prev) => ({ ...prev, amount_value: formattedNumber }));
  };
// onfocus function
  const handleFocus = () => {
    if (currency.amount_value) {
      const plainNumber = parseFormattedNumber(currency.amount_value?.toString());
      setCurrency((prev) => ({ ...prev, amount_value: plainNumber }));
    }
  };

   // close all open modal
   const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      otpModal: false,
      resultModal: false,
    }));
  };

  return (
    <>
    <KumoWalletTransaction
        email={currency?.email}
        amount={parseFormattedNumber(currency?.amount_value?.toString())}
        charges={modalContainer?.charges}
        onClose={closeAllModal}
        open={modalContainer?.modalDetails}
        onclick={handlePinModal}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleOtpModal}
        onClose={closeAllModal}
      />
      <OTPModal
        open={modalContainer.otpModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          You have <span style={{ fontWeight: 700 }}>successfully</span>{" "}
          Transferred <span style={{ fontWeight: 700 }}>0.013149 ETH </span> to{" "}
          <span style={{ fontWeight: 700 }}>Johndoe@gmail.com</span>
        </Typography>
      </TransactionResult>
      <TransactionResultFailed
        onClose={closeAllModal}
        openResult={modalContainer?.failedModal}
        reason="Transaction could not be completed"
      />
      <Box sx={{ mt: 4 }}>
        <Box>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.text.primary }}
          >
            Balance{" "}
          </Typography>
          {!!currency?.base_wallet && (
            <MaxAmountValue
              onclick={handleMaxAmount}
              currency={base?.code}
              amount={currency?.base_wallet?.value}
            />
          )}{" "}
          <TextValueContainer
            text="You Pay"
            currency="NGN"
            onFocusHandler={handleFocus}
            onBlurHandler={handleAddComma}
            onchange={handleAmountChange}
            amount={currency?.amount_value}
            icon={base?.image}
          />
          <TextInputContainer
            text="Email"
            placeholder="Enter email Address ..."
            value={currency?.email}
            onclick={(e) =>
              setCurrency((prev) => ({ ...prev, email: e.target.value }))
            }
          />
          <Stack direction="column" alignItems={"center"}>
            <ButtonContainedMain
              sx={{ width: "150px", fontWeight: 500, fontSize: "16px" }}
              onclick={handleModal}
            >
              Send
            </ButtonContainedMain>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default KumoWalletBaseTransfer;

export const KumoWalletTransaction = ({
  onClose,
  amount,
  onclick,
  charges,
  email,
  open,
}: KumoWalletDetailsPropType) => {
  const theme = useTheme();
  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
  };
  return (
    <CustomTransactionDetailsModal
      open={open}
      onClose={onClose}
      onclick={onclick}
    >
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            &#8358; {amount}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Email
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {email}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Charges
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {charges}
          </Typography>
        </Stack>
      </Box>
    </CustomTransactionDetailsModal>
  );
};
