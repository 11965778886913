import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import SellCryptoContent from "../../components/dashboard/SellCryptoContents";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../../components/UIElements/notification";
import { getAllListedCoinToken } from "../../api/settings";
import { Helmet } from "react-helmet-async";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";

const SellCrypto: React.FC = () => {
  const theme = useTheme();
  const { user, token } = useAuth();
  const { showNotification } = useNotificationService();
  const [tokenList, setTokenList] = useState([]);

  useEffect(() => {
    fetchCrypto();
  }, []);
  const fetchCrypto = async () => {
    const token_key = token ?? "";
    const result = await getAllListedCoinToken(token_key);
    console.log(result?.data, user);
    if (result?.status === 200) {
      setTokenList(result?.data?.data);
    } else {
      showNotification("Error while fetching token list", "error");
    }
  };
  return (
    <>
      <Helmet>
        <title>Sell Crypto</title>
      </Helmet>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: "#2C393F" }}>
            Sell Crypto
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        {tokenList?.length >= 1 && <SellCryptoContent tokenList={tokenList} />}
        {tokenList?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: 350, 
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />{" "}
          </Box>
        )}
      </DashboardGridContainer>
    </>
  );
};

export default SellCrypto;
