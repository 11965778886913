import React, { useEffect, useState } from "react";
import { Stack, Box, Typography, InputLabel } from "@mui/material";
import Header from "../../layouts/dashboard/header";
import { useTheme } from "@mui/material/styles";
import DashboardContent from "../../components/dashboard/DashboardContent";
import { ButtonContainedLight } from "../../components/UIElements/button";
import useResponsive from "../../hooks/useResponsive";
import { PATH_DASHBOARD } from "../../routes/path";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ModalBaseCurrencySelector } from "../../components/baseCurrencySelector";
import { useAuth } from "../../hooks/useAuth";
import { Helmet } from "react-helmet-async";
import { useDetails } from "../../hooks/useDetails";

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xl");
  const isSmall = useResponsive("down", "sm", "xl");
  const navigate = useNavigate();
  const { base, user, token } = useAuth();
  const { refetchData } = useDetails();
  const [currencySelector, setCurrencySelector] = useState(false);
  const token_key = token ?? "";

  useEffect(() => {
    if (!!token_key) {
      refetchData();
      console.log(base)
    }
  }, []);
  const handleAssets = () => {
    navigate(PATH_DASHBOARD.assets);
  };
  const handleWallet = () => {
    navigate(PATH_DASHBOARD.fundWallet);
  };
  const handleBaseCurrency = () => {
    setCurrencySelector(!currencySelector);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <ModalBaseCurrencySelector
        onclose={handleBaseCurrency}
        open={currencySelector}
      />
      {/* Header Menu */}
      <Header>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: isMedium ? "column" : "row",
            gap: "10px",
            justifyContent: "space-between",
            marginRight: "1rem",
          }}
        >
          <Stack>
            <Typography
              variant={isMedium ? "h5" : "h4"}
              sx={{ fontWeight: 600, color: theme.palette.text.primary }}
            >
              Good Day, {user?.first_name}
            </Typography>
            <Typography
              variant={isMedium ? "caption" : "body2"}
              sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
            >
              Welcome to Kumo, Lets Help you Invest
            </Typography>
          </Stack>
          {!isMedium && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ gap: "12px", flexWrap: "wrap" }}
            >
              <ButtonContainedLight
                sx={{
                  color: theme.palette.common.black,
                  paddingX: "1rem",
                  fontSize: "11px",
                  display: "flex",
                  gap: "10px",
                  fontWeight: 700,
                }}
                onclick={handleBaseCurrency}
              >
                Base Currency{" "}
                <span style={{ color: theme.palette.primary.main }}>
                  {base?.code?.toString()?.toUpperCase() ?? "SET"}
                </span>
                <KeyboardArrowDownIcon />
              </ButtonContainedLight>
              <ButtonContainedLight
                sx={{ borderRadius: isSmall ? "10px" : "20px" }}
                onclick={handleAssets}
              >
                View Assets
              </ButtonContainedLight>
              <ButtonContainedLight
                sx={{ borderRadius: isSmall ? "10px" : "20px" }}
                onclick={handleWallet}
              >
                Fund Wallet
              </ButtonContainedLight>
            </Stack>
          )}
        </Box>
      </Header>

      <DashboardContent />
    </>
  );
};

export default Dashboard;
