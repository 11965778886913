import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import Header from "../../layouts/dashboard/header";
import ProfileContent from "../../components/profile";
import ProfileTopImage from "../../components/profile/ProfileTopImage";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../../components/UIElements/notification";
import { getUserDetails, verifyBVN } from "../../api/profile";
import { UserData } from "../../interface/useAuth";
import { InputState } from "../../interface/profile";
import { useDetails } from "../../hooks/useDetails";
import LoadingSpinner from "../../components/UIElements/loadingSpinner";
import { Helmet } from "react-helmet-async";

const Profile = () => {
  const theme = useTheme();
  const { showNotification } = useNotificationService();
  const { token } = useAuth();
  const { fetchUserData } = useDetails();
  const [userData, setUserData] = useState();

  // fetch user  data
  useEffect(() => {
    fetchUserDataInfo();
  }, []);

  const fetchUserDataInfo = async () => {
    if (!token) {
      showNotification("Error fetching User Data. Kindly Login", "error");
      return;
    }
    // const fetch_bvn = await verifyBVN(token)
    // const user_data = await fetchUserData(token);
    // console.log(user_data)
    const result = await getUserDetails(token);
    if (result?.status === "error") {
    } else if (result?.status === 200) {
      setUserData(result?.data?.data);
    }
  };
  return (
    <>
     <Helmet>
        <title>Profile</title>
      </Helmet>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Profile
          </Typography>
        </Box>
      </Header>
      <ProfileTopImage userData={userData} />
      {!!userData ? <ProfileContent userData={userData} /> : 
      <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: 200,
        justifyContent: "center",
      }}
    >
      <LoadingSpinner />{" "}
    </Box> 
      }

    </>
  );
};

export default Profile;
