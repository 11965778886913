import { Box, Typography } from "@mui/material";
import React from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import SwapCoinContents from "../../components/dashboard/SwapCoinContents";
import AccountInfo from "../../components/dashboard/AccountInfo";
import { TextContainer } from "../../components/UIElements/textContainer";
import { PATH_DASHBOARD } from "../../routes/path";

const KumoTransfer: React.FC = () => {
  const theme = useTheme();
  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Kumo Transfer
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        <AccountInfo />
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
          >
            Action
          </Typography>
          <TextContainer path={PATH_DASHBOARD.kumoWallet}>
            To Kumo wallet
          </TextContainer>
          <TextContainer path={PATH_DASHBOARD.transferMoney}>
            To a Bank Account
          </TextContainer>
          <TextContainer path={PATH_DASHBOARD.transferCrypto}>
            To a Crypto Wallet
          </TextContainer>
        </Box>
      </DashboardGridContainer>
    </>
  );
};

export default KumoTransfer;
