import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import AccountInfo from "../../components/dashboard/AccountInfo";
import { useTheme } from "@mui/material/styles";
import { TextContainer } from "../../components/UIElements/textContainer";
import { PATH_DASHBOARD } from "../../routes/path";
import Header from "../../layouts/dashboard/header";
import { useDetails } from "../../hooks/useDetails";
import { useAuth } from "../../hooks/useAuth";
import { Helmet } from "react-helmet-async";

const BuySell: React.FC = () => {
  const theme = useTheme();


  return (
    <>
     <Helmet>
        <title>Buy/Sell</title>
      </Helmet>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Buy/Sell Crypto
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        <AccountInfo />
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
          >
            Action
          </Typography>
          <TextContainer path={PATH_DASHBOARD.buyCrypto}>
            Buy Crypto
          </TextContainer>
          <TextContainer path={PATH_DASHBOARD.sellCrypto}>Sell Crypto</TextContainer>
        </Box>
      </DashboardGridContainer>
    </>
  );
};

export default BuySell;
