import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

//
import AuthLayout from "../layouts/auth";
import Login from "../pages/authentication/Login";
import SignUp from "../pages/authentication/SignUp";
import ResetPassword from "../pages/authentication/ResetPassword";
import Onboarding from "../pages/authentication/Onboarding";
import Logout from "../pages/authentication/Logout";

//
import DashboardLayout from "../layouts/dashboard";
import Dashboard from "../pages/dashboard/Dashboard";
import BuySell from "../pages/dashboard/BuySell";
import BuyCrypto from "../pages/dashboard/BuyCrypto";

import SellCrypto from "../pages/dashboard/SellCrypto";
import SendCrypto from "../pages/dashboard/SendCrypto";
import ReceiveCrypto from "../pages/dashboard/ReceiveCrypto";
import SendRecieve from "../pages/dashboard/SendRecieve";
import SwapCoin from "../pages/dashboard/SwapCoin";
import KumoTransfer from "../pages/dashboard/KumoTransfer";
import KumoWalletTransfer from "../pages/dashboard/KumoWalletTransfer";
import TransferLocalMoney from "../pages/dashboard/TransferLocalMoney";
import TransferCryptoWallet from "../pages/dashboard/TransferCryptoWallet";

import Assets from "../pages/dashboard/Assets";
import FundWallet from "../pages/dashboard/fund-wallet";
import Crypto from "../pages/dashboard/fund-wallet/Crypto";

//
import Transaction from "../pages/transaction";

//
import Utilities from "../pages/utility";
import Recharge from "../pages/recharge";
import BuyData from "../pages/recharge/BuyData";
import CableTv from "../pages/utility/CableTv";
import BuyElectricity from "../pages/utility/BuyElectricity";
import BuyAirtime from "../pages/recharge/BuyAirtime";

//
import Referral from "../pages/referral";
//
import Profile from "../pages/profile";
//
import KumoStaking from "../pages/kumo-staking";
import BankTransfer from "../pages/dashboard/fund-wallet/BankTransfer";
import Paypal from "../pages/dashboard/fund-wallet/Paypal";
import KumoAgent from "../pages/dashboard/fund-wallet/KumoAgent";
import Pin from "../pages/authentication/Pin";
import AuthGuard from "../hooks/useAuthGuard";
import StackingHistory from "../pages/kumo-staking/StackingHistory";
import PlanDetails from "../pages/kumo-staking/PlanDetails";
import StackingHistoryDetails from "../pages/kumo-staking/StackingHistoryDetails";

//
export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to={"/auth/login"} />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        {
          path: "login",
          element: <Login />,
        },
        { path: "pin", element: <Pin /> },
        { path: "register", element: <SignUp /> },
        { path: "onboarding", element: <Onboarding /> },
        { path: "reset-password", element: <ResetPassword /> },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Dashboard /> },
        {
          path: "buy-sell",
          element: <BuySell />,
        },
        {
          path: "assets",
          element: <Assets />,
        },
        {
          path: "buy-crypto",
          element: <BuyCrypto />,
        },
        {
          path: "sell-crypto",
          element: <SellCrypto />,
        },
        {
          path: "send-receive",
          element: <SendRecieve />,
        },
        {
          path: "send-crypto",
          element: <SendCrypto />,
        },
        {
          path: "receive-crypto",
          element: <ReceiveCrypto />,
        },
        {
          path: "swap-coin",
          element: <SwapCoin />,
        },
        {
          path: "kumo-transfer",
          element: <KumoTransfer />,
        },
        {
          path: "kumo-wallet",
          element: <KumoWalletTransfer />,
        },
        {
          path: "transfer-money",
          element: <TransferLocalMoney />,
        },
        {
          path: "transfer-crypto",
          element: <TransferCryptoWallet />,
        },
        { path: "fund-wallet", element: <FundWallet /> },
        { path: "fund-wallet/crypto", element: <Crypto /> },
        { path: "fund-wallet/bank-transfer", element: <BankTransfer /> },
        { path: "fund-wallet/paypal", element: <Paypal /> },
        { path: "fund-wallet/kumo-agent", element: <KumoAgent /> },
      ],
    },
    {
      path: "utilities",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Utilities /> },
        {
          path: "buy-electricity",
          element: <BuyElectricity />,
        },
        {
          path: "cable-tv",
          element: <CableTv />,
        },
      ],
    },
    {
      path: "recharge",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Recharge /> },
        {
          path: "buy-airtime",
          element: <BuyAirtime />,
        },
        {
          path: "buy-data",
          element: <BuyData />,
        },
      ],
    },
    {
      path: "transaction",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Transaction />,
        },
      ],
    },
  
    {
      path: "referral",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "", element: <Referral /> }],
    },
    {
      path: "profile",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "", element: <Profile /> }],
    },
    {
      path: "kumo-staking",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <KumoStaking /> },
        { path: "history", element: <StackingHistory /> },
        {
          path: "history/:id",
          element: <StackingHistoryDetails />,
        },
        {
          path: ":id",
          element: <PlanDetails />,
        },
      
      ],
    },
  ]);
}
