import { Box, Grid, Typography, Stack, Button } from "@mui/material";
import React, { useReducer, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { EmailInput, NameInput, PasswordInput } from "../UIElements/input";
import { ButtonContainedMain } from "../UIElements/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { changePasswordSchema } from "../../validate/input";
import {
  FullNameMainInput,
  NewPasswordInput,
  OldPasswordInput,
  PhonenumberMainInput,
} from "../UIElements/inputElements";
import useResponsive from "../../hooks/useResponsive";
import { apiChangeUserPassword } from "../../api/profile";
import { useAuth } from "../../hooks/useAuth";
import { ProfileContentProps } from "../../interface/profile";
import { useNotificationService } from "../UIElements/notification";

interface SettingProps {
  inputState: Record<string, any>;
  changeHandler: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  nextSection: (e: string) => void;
}

export const ProfileSetting = ({
  changeHandler,
  nextSection,
  inputState,
}: SettingProps) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xs");
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();
  const [disable, setDisable] = useState(true);
  const { showNotification } = useNotificationService();
  const bgStyle = {
    background: theme.palette.common.white,
    borderRadius: "20px",
    padding: 4,
  };

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(changePasswordSchema),
  });

  const onSubmitHandler = async (values: any): Promise<void> => {
    setIsLoading(true);
    const token_key = token ? token : "";
    const result = await apiChangeUserPassword(values, token_key);
    if ("msg" in result) {
      showNotification(result.msg, "error");
    } else if (result.status == 200) {
      // update charges and amount value
      showNotification(result?.data?.msg, "success");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showNotification("An error occured", "error");
    }
    
  };
  const handleEdit = () => {
    if (disable) {
      setDisable(false);
    } else {
      nextSection("account");
    }
  };
  return (
    <Grid container gap={3}>
      <Grid item xs={isMedium ? 12 : 5}>
        <Box sx={bgStyle}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.text.primary }}
          >
            Profile Details
          </Typography>
          <Box sx={{ my: 4, display: "flex", flexDirection: "column" }}>
            <FullNameMainInput
              disable={disable}
              first_value={inputState?.first_name}
              last_value={inputState?.last_name}
              onChangeFirst={changeHandler}
              onChangeLast={changeHandler}
            />
            <PhonenumberMainInput
              placeholder={""}
              disable={disable}
              type={"tel"}
              value={inputState?.phone_number}
              onChange={() => {}}
            />
          </Box>
          <Stack>
            <ButtonContainedMain
              sx={{ marginLeft: "auto" }}
              onclick={handleEdit}
            >
              {disable ? "Edit Account" : "Proceed"}
            </ButtonContainedMain>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={isMedium ? 12 : 5}>
        <Box
          sx={bgStyle}
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.text.primary }}
          >
            Change Password{" "}
          </Typography>
          <Box sx={{ my: 4 }}>
            <OldPasswordInput
              errors={errors}
              placeholder="Old Password"
              register={register}
            />
            <NewPasswordInput
              errors={errors}
              placeholder="New Password"
              register={register}
            />
          </Box>
          <Stack>
            <LoadingButton
              type="submit"
              loading={isLoading}
              sx={{
                marginLeft: "auto",
                padding: " 14px, 24px, 14px, 24px",
                borderRadius: "10px",
                width: "150px",
                fontWeight: 500,
                fontSize: "16px",
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
              }}
            >
              Update Password
            </LoadingButton>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
