import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SearchIcon } from "./svgIcons";

interface SearchInputPropType {
  text: string;
  handler : (e: React.ChangeEvent<HTMLInputElement>) => void
  width: string;
  value: string | number;
}

export const SearchInput: React.FC<SearchInputPropType> = ({
  text,
  handler,
  value,
  width,
}) => {
  const theme = useTheme();
  const styles = {
    input: {
      borderRadius: "10px",
      paddingLeft: "6px",
      fontWeight: 400,
      fontSize: "15px",
      padding: "10px",
      color: theme.palette.text.primary
    },
  };

  return (
    <TextField
      onChange={handler}
      value={value}
      className="searchInput"
      style={{
        borderRadius: "10px",
        width: width, background: theme.palette.common.white,
      }}
      variant="outlined"
      placeholder={text}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              style={{
                paddingRight: "0px",
                paddingLeft: "0px",
              }}
            >
              <SearchIcon
                style={{
                  padding: "0px",
                  margin: "0px",
                }}
              />
            </IconButton>
          </InputAdornment>
        ),
        inputProps: {
          style: styles.input,
        },
      }}
    />
  );
};

export const SearchInputEnd: React.FC<SearchInputPropType> = ({
  text,
  handler,
  value,
  width,
}) => {
  const theme = useTheme();
  const styles = {
    input: {
      borderRadius: "5px",
      paddingLeft: "10px",
      fontWeight: 700,
      padding: "10px",       color: theme.palette.text.primary

    },
  };

  return (
    <TextField
      onChange={handler}
      value={value}
      className="searchInput"
      style={{
        // background: "rgba(62, 64, 149, 0.20)",
        borderRadius: "4px",
        width: width,
      }}
      variant="outlined"
      placeholder={text}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              style={{
                paddingRight: "0px",
                paddingLeft: "0px",
              }}
            >
              <SearchIcon
                style={{
                  padding: "0px",
                  margin: "0px",
                }}
              />
            </IconButton>
          </InputAdornment>
        ),
        inputProps: {
          style: styles.input,
        },
      }}
    />
  );
};
