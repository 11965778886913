import { FC, useState } from "react";

//
import { Box, Typography, Stack } from "@mui/material";
import {
  MaxAmountValue,
  TextInputContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { useTheme } from "@mui/material/styles";
import { ButtonContainedMain } from "../UIElements/button";
import { PasswordModal } from "../passwordModal";
import { TransactionResult, TransactionResultFailed } from "../transactionResult";
import { CustomTextSelectContainer } from "../textInput";
import CustomTransactionDetailsModal from "../customTransactionDetailsModal";
import { TransactionDetailsPropType } from "../../interface/transaction";
import { WalletBalanceType } from "../../interface/useDetail";
import { useNotificationService } from "../UIElements/notification";
import { useAuth } from "../../hooks/useAuth";
import { ProviderDetailsProps } from "../../interface/cable";
import {
  addCommasToNumbers,
  hasValues,
  parseFormattedNumber,
} from "../../utils/basic";
import { verifyPin } from "../../api/users";
import { rechargeAirtime } from "../../api/utilities";

//
const BuyAirtimeContent: FC<{
  airtime_info: any[];
  baseWallet: WalletBalanceType | undefined;
}> = ({ airtime_info, baseWallet }) => {
  const theme = useTheme();
  const { token, base } = useAuth();
  const { showNotification } = useNotificationService();
  const [airtimeDetails, setAirtimeDetails] = useState<{
    provider: ProviderDetailsProps;
    phone_number: string;
    amount: number | string;
  }>({
    provider: {} as ProviderDetailsProps,
    phone_number: "",
    amount: "",
  });
  const [modalContainer, setModalContainer] = useState({
    modalDetails: false,
    pinModal: false,
    resultModal: false,
    failedModal: false,
   
  });

  // handle provider selection
  const handleProviderSelection = async (obj: ProviderDetailsProps) => {
    if (hasValues(obj)) {
      setAirtimeDetails((prev) => ({
        ...prev,
        provider: obj,
      }));
    }
  };

  const handleMaxAmount = (num: number) => {
    setAirtimeDetails((prev) => ({ ...prev, amount: num }));
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    const value = e.target.value.replace(/,/g, "");
    setAirtimeDetails((prev) => ({ ...prev, amount: value }));
  };

  const handleModal = () => {
    console.log(airtimeDetails)
    // check if it's a dollar account
    if (base?.code === "usd" || base?.name === "dollars") {
      showNotification(
        "Conversion for dollar account has not been implemented",
        "info"
      );
      return;
    }
    // check amout
    if (
      parseFormattedNumber(airtimeDetails?.amount?.toString()) <
      Number(airtimeDetails?.provider?.minimium_amount)
    ) {
      showNotification(
        `Purchase amount is ${airtimeDetails?.provider?.maximum_amount}`,
        "error"
      );
      return;
    }
    // check if amount is more than base amount
    if (
      parseFormattedNumber(airtimeDetails?.amount?.toString()) >
      (baseWallet?.value ?? 0)
    ) {
      showNotification(`Amount is greater than base amount`, "error");
      return;
    }
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: !prev.modalDetails,
    }));
  };
  const handlePinModal = () =>
    setModalContainer((prev) => ({ ...prev,  modalDetails: !prev.modalDetails, pinModal: !prev.pinModal }));
      // verify pin then send confirmation
  const handleResultModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");

    const recharge_airtime = {
      amount: parseFormattedNumber(airtimeDetails?.amount?.toString()),
      service_id: airtimeDetails?.provider?.serviceID,
      billers_code: airtimeDetails?.phone_number.toString(),
      type: "airtime",
      variation_code: airtimeDetails?.provider?.name,
      pin: pin_value,
    };

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // sent final request to server
      const airtime_recharge = await rechargeAirtime(recharge_airtime, token_key);
      if ("msg" in airtime_recharge) {
        showNotification(airtime_recharge.msg, "error");
        setModalContainer((prev) => ({
          ...prev,
          pinModal: !prev.pinModal,
          failedModal: !prev.failedModal,
        }));
      } else if (airtime_recharge.status == 200) {
        showNotification("Electricity Recharged", "success");
        setModalContainer((prev) => ({
          ...prev,
          pinModal: !prev.pinModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        showNotification("An error occured while recharging electricity", "error");
      }
    } else {
      showNotification("Invalid Pin", "error");
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        failedModal: !prev.failedModal,
      }));
    }
  };

  const handleAddComma = () => {
    const plainNumber = airtimeDetails?.amount || "";
    const formattedNumber = addCommasToNumbers(+plainNumber);
    setAirtimeDetails((prev) => ({ ...prev, amount: formattedNumber }));
  };

  const handleFocus = () => {
    if (airtimeDetails?.amount) {
      const plainNumber = parseFormattedNumber(
        airtimeDetails?.amount?.toString()
      );
      setAirtimeDetails((prev) => ({ ...prev, amount: plainNumber }));
    }
  };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      resultModal: false,
    }));
  };
 
  return (
    <>
      <BuyAirtimeTransaction
        charges={""}
        open={modalContainer.modalDetails}
        onclick={handlePinModal}
        onClose={closeAllModal}
        data={airtimeDetails}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          You have <span style={{ fontWeight: 700 }}>successfully</span> bought{" "}
          <span style={{ fontWeight: 700 }}>Data </span>
        </Typography>
      </TransactionResult>
      <TransactionResultFailed
        onClose={closeAllModal}
        openResult={modalContainer?.failedModal}
        reason="Transaction Failed"
      />
      <Box>
        {!!baseWallet && (
          <MaxAmountValue
            onclick={handleMaxAmount}
            currency={base?.code}
            amount={baseWallet?.value}
          />
        )}{" "}
        <TextValueContainer
          text="You Pay"
          currency={base?.code?.toUpperCase()}
          onFocusHandler={handleFocus}
          onBlurHandler={handleAddComma}
          amount={airtimeDetails?.amount}
          onchange={handleAmountChange}
          icon={base?.image}
        />
        <Typography
          variant="body2"
          sx={{
            my: 2,
            color: theme.palette.secondary.main,
            textAlign: "center",
          }}
        >
          Charge = 0
        </Typography>
        <TextInputContainer
          text="Phone Number"
          onclick={(e) =>
            setAirtimeDetails((prev) => ({
              ...prev,
              phone_number: e.target.value,
            }))
          }
          placeholder="Enter phone number here..."
          value={airtimeDetails?.phone_number}
        />
        <CustomTextSelectContainer
          modalText="Select Network"
          placeHolder="Select Network"
          text="Select Network"
          data={airtime_info}
          value={""}
          onclick={handleProviderSelection}
        />
        <Stack direction="column" alignItems={"center"}>
          <ButtonContainedMain
            sx={{ marginLeft: "auto" }}
            onclick={handleModal}
          >
            Send
          </ButtonContainedMain>
        </Stack>
      </Box>
    </>
  );
};

export default BuyAirtimeContent;

const BuyAirtimeTransaction = ({
  onClose,
  onclick,
  open,
  data,
}: TransactionDetailsPropType) => {
  const theme = useTheme();
  const { base } = useAuth();
  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
    fontWeight: 700,
  };
  return (
    <CustomTransactionDetailsModal
      open={open}
      onClose={onClose}
      onclick={onclick}
    >
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {base?.symbol} {data?.amount}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Network Provider
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.provider?.name}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Phone number
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.phone_number}
          </Typography>
        </Stack>
      </Box>
    </CustomTransactionDetailsModal>
  );
};
