import { Grid } from "@mui/material";
import React from "react";
import useResponsive from "../../hooks/useResponsive";

const DashboardGridContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isDesktop = useResponsive("up", "lg", "xl");
  const isSmall = useResponsive("down", "sm", "xl");

  return (
    <Grid container sx={{ marginTop: isSmall ? "0" : "1.5rem" }}>
      <Grid xs={!isDesktop ? 12 : 8} item sx={{ padding: 2 }}>
        {children}
      </Grid>
      <Grid xs={!isDesktop ? 0 : 4} item sx={{ padding: 3 }}></Grid>
    </Grid>
  );
};

export default DashboardGridContainer;
