import { Box, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import Header from "../../layouts/dashboard/header";
import { PageMainTextSummary } from "../../components/pageMainSummary";
import { TextContainer } from "../../components/UIElements/textContainer";
import { PATH_RECHARGE } from "../../routes/path";
import { Helmet } from "react-helmet-async";

const Recharge: React.FC = () => {
  const theme = useTheme();
  return (
    <>
     <Helmet>
        <title>Recharge</title>
      </Helmet>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Airtime and Data
          </Typography>
        </Box>
      </Header>
      <Box sx={{ mt: 4 }}>
        <PageMainTextSummary code="" referralCode={false} text="Recharge your Phone" />
      </Box>

      <DashboardGridContainer>
        <Box sx={{}}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
          >
            Make Purchase
          </Typography>
          <TextContainer path={PATH_RECHARGE.buyData}>Buy Data </TextContainer>
          <TextContainer path={PATH_RECHARGE.buyAirtime}>
            Buy Airtime
          </TextContainer>{" "}
        </Box>
      </DashboardGridContainer>
    </>
  );
};

export default Recharge;
