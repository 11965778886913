import React, { useState } from "react";
import ModalContainer from "../../UIElements/modal";
import { Box, Stack, Typography, Divider, InputLabel } from "@mui/material";
import { ButtonContainedMain } from "../../UIElements/button";
import { useTheme } from "@mui/material/styles";

import { CloseIcon } from "../../UIElements/svgIcons";
import {
  FundWalletTextSelectContainer,
  FundWalletTextValueContainer,
} from "./contentStyle";
import { QrCodeContainer } from "../../qrCodeContainer";
import { useAuth } from "../../../hooks/useAuth";
import { CryptoStateType } from "../../../interface/dashboard";
import {
  addCommasToNumbers,
  hasValues,
  parseFormattedNumber,
} from "../../../utils/basic";
import { WalletAddressesType } from "../../../interface/useDetail";
import { useNotificationService } from "../../UIElements/notification";

// ----------------------------------

export const CryptoFirstStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  navigate: () => void;
  open: boolean;
  cryptoDetails: CryptoStateType;
  setCryptoDetails: React.Dispatch<React.SetStateAction<CryptoStateType>>;
}> = ({
  onclick,
  onclose,
  open,
  navigate,
  setCryptoDetails,
  cryptoDetails,
}) => {
  const theme = useTheme();
  const { base } = useAuth();
  const { showNotification } = useNotificationService();

  const handleNextContent = () => {
    const amount_value = parseFormattedNumber(
      cryptoDetails?.amount?.toString()
    );
    if (amount_value === 0) {
      showNotification("Amount value is invalid", "error");
      return;
    }
    onclose();
    onclick();
  };

  const handleAddComma = () => {
    const plainNumber = cryptoDetails?.amount || "";
    const formattedNumber = addCommasToNumbers(+plainNumber);
    setCryptoDetails((prev) => ({ ...prev, amount: formattedNumber }));
  };

  const handleFocus = () => {
    if (cryptoDetails?.amount) {
      const plainNumber = parseFormattedNumber(
        cryptoDetails?.amount?.toString()
      );
      setCryptoDetails((prev) => ({ ...prev, amount: plainNumber }));
    }
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    const value = e.target.value.replace(/,/g, "");
    setCryptoDetails((prev) => ({ ...prev, amount: value }));
  };

  return (
    <>
      <ModalContainer
        sx={{}}
        size={"sm"}
        openModal={open}
        fullWidth={true}
        handleModal={() => {}}
      >
        <Box
          sx={{
            background: theme.palette.common.white,
            padding: 4,
            borderRadius: "20px",
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ color: theme.palette.text.primary }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Fund Wallet{" "}
            </Typography>
            <span onClick={navigate} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </span>
          </Stack>
          <Typography variant="body2">
            Enter the amount to add, click “Continue”, and complete payment to
            update your wallet balance
          </Typography>
          <Divider sx={{ my: 3 }} />
          <FundWalletTextValueContainer
            text="You Pay"
            onBlurHandler={handleAddComma}
            onFocusHandler={handleFocus}
            onchange={handleAmountChange}
            currency={base?.code?.toUpperCase()}
            amount={cryptoDetails?.amount}
            icon={base?.image}
          />
          <Divider sx={{ my: 3 }} />
          <Stack direction={"row"} justifyContent={"center"}>
            <ButtonContainedMain
              onclick={handleNextContent}
              sx={{ width: "150px" }}
            >
              Continue
            </ButtonContainedMain>
          </Stack>
        </Box>
      </ModalContainer>
    </>
  );
};

export const CryptoSecondStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  open: boolean;
  coinList: any[];
  walletList: any[];
  navigate: () => void;
  setCryptoDetails: React.Dispatch<React.SetStateAction<CryptoStateType>>;
}> = ({
  onclick,
  onclose,
  open,
  navigate,
  walletList,
  coinList,
  setCryptoDetails,
}) => {
  const theme = useTheme();
  const handleNextContent = () => {
    onclose();
    onclick();
  };

  // handle provider selection
  const handleAddressSelection = async (obj: any) => {
    console.log(walletList)
    if (hasValues(obj) && walletList?.length > 0) {
      // find selected coin wallet Addresses
      const selectedCoin = walletList?.find(
        (wallet) => wallet.name === obj?.name || wallet.code === obj?.code
      );
      setCryptoDetails((prev) => ({ ...prev, cryptoWallet: selectedCoin }));
    }
  };
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ color: theme.palette.text.primary }}
        >
          <Typography>Crypto Address</Typography>
          <span onClick={navigate} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Typography variant="caption">
          Copy this address or Sacn the QR code below to ass to your Kumowallet
        </Typography>
        <Divider sx={{ my: 3 }} />
        <InputLabel
          sx={{
            color: theme.palette.secondary.main,
          }}
        >
          Select Coin
        </InputLabel>
        <FundWalletTextSelectContainer
          placeholder="Select Coin"
          text="Select Coin"
          value={""}
          data={coinList}
          onclick={handleAddressSelection}
        />
        <Divider sx={{ my: 3 }} />
        <Stack direction={"row"} justifyContent={"center"}>
          <ButtonContainedMain
            onclick={handleNextContent}
            sx={{ width: "150px" }}
          >
            Continue
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};

export const CryptoThirdStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  navigate: () => void;
  submit: () => void;
  open: boolean;
  cryptoDetails: CryptoStateType;
}> = ({ onclick, onclose, open, navigate, cryptoDetails, submit }) => {
  const theme = useTheme();
  const handleNextContent = () => {
    onclose();
    onclick();
  };
  return (
    <>
      <ModalContainer
        sx={{}}
        size={"sm"}
        openModal={open}
        fullWidth={true}
        handleModal={() => {}}
      >
        <Box
          sx={{
            background: theme.palette.common.white,
            padding: 4,
            borderRadius: "20px",
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ color: theme.palette.text.primary }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Crypto Address{" "}
            </Typography>
            <span onClick={navigate} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </span>
          </Stack>
          <Typography variant="body2">
            Copy this address or Sacn the QR code below to ass to your
            Kumowallet
          </Typography>
          <Divider sx={{ my: 3 }} />
          <QrCodeContainer
            text="Naira Deposit Address"
            address={cryptoDetails?.cryptoWallet?.walletAddress}
          />
          <QrCodeContainer
            text="Crypto Deposit Address"
            address={cryptoDetails?.cryptoWallet?.cryptoAddress}
          />
          <Divider sx={{ my: 3 }} />
          <Stack direction={"row"} justifyContent={"center"}>
            <ButtonContainedMain onclick={submit} sx={{ width: "150px" }}>
              Continue
            </ButtonContainedMain>
          </Stack>
        </Box>
      </ModalContainer>
    </>
  );
};
