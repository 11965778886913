import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { FC, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useNotificationService } from "../UIElements/notification";
import ModalContainer from "../UIElements/modal";
import { TransactionDetailsProps } from "../../data/transactionData";
import { CloseIcon } from "../UIElements/svgIcons";
import { parseDate } from "../../utils/basic";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ButtonContainedMain } from "../UIElements/button";
import useResponsive from "../../hooks/useResponsive";

const TransactionDetailsContent: FC<TransactionDetailsProps> = ({
  open,
  onClose,
  onclick,
  data,
}) => {
  const theme = useTheme();
  const modalRef = useRef(null);
  const isSmall = useResponsive("down", "sm", "xl");

  const handleSaveAsPDF = async () => {
    if (modalRef.current) {
      const canvas = await html2canvas(modalRef.current, {
        scale: 2, // Increase scale for better resolution
        useCORS: true // To handle cross-origin images
      });
  
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // Set page size to A4
  
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
  
      // Calculate the width and height of the image to maintain aspect ratio
      const imgWidth = canvas.width * 0.264583; // Convert px to mm
      const imgHeight = canvas.height * 0.264583; // Convert px to mm
  
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = (pdfHeight - imgHeight * ratio) / 2;
  
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save('transaction-details.pdf');
    }
  };
  

  const handleSaveAsImage = async () => {
    if (modalRef.current) {
      const canvas = await html2canvas(modalRef.current);
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "transaction-details.png";
      link.click();
    }
  };

  return (
    <ModalContainer
      sx={{}}
      openModal={open}
      handleModal={() => {}}
      size="md"
      fullWidth={true}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: isSmall ? 3 : 4,
        }}
      >
        <Stack direction="row" justifyContent={"space-between"} sx={{ my: 2 }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary, fontWeight: 600 }}
          >
            Transaction Receipt
          </Typography>
          <span style={{ cursor: "pointer" }} onClick={onClose}>
            <CloseIcon />
          </span>
        </Stack>
        <Box ref={modalRef} sx={{ padding: isSmall ? 2 : 3 }}>
          <Stack direction={"column"} alignItems={"center"}>
            <img
              src="/assets/images/check-icon.png"
              alt=""
              width={"50px"}
              height={"50px"}
            />
            <Typography sx={{ fontWeight: 600 }} variant="h6" color="primary">
              {data?.type}
            </Typography>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <TextLabelContainer
            text={`${data?.first_name} ${data?.last_name}`}
            label="Full Name"
          />
          <TextLabelContainer text={data?.email} label="Email" />
          <TextLabelContainer text={data?.to} label="Address" />
          <TextLabelContainer
            text={data?.base_currency?.name?.toUpperCase()}
            label="Base Currency"
          />
          <TextLabelContainer text={data?.amount_paid} label="Amount" />
          <TextLabelContainer text={data?.status} label="Status" />
          <TextLabelContainer text={data?.fee} label="Fee" />
          <TextLabelContainer text={data?.txRefId} label="Reference Number" />
          <TextLabelContainer text={data?.from} label="From" />

          <TextLabelContainer text={data?.to} label="To" />
          <TextLabelContainer text={parseDate(data?.created_at)} label="Date" />
          <TextLabelContainer text={data?.total_cost} label="Total" />

          <Divider sx={{ my: 2 }} />
          <Stack direction={"row"} justifyContent={"center"}>
            <Typography sx={{ fontWeight: 500, textAlign: 'center' }} variant="body1">
              {data?.description}
            </Typography>
          </Stack>
        </Box>

        <Stack
          direction={"row"}
          justifyContent={"center"}
          sx={{ gap: "20px", p: 4, background: theme.palette.common.white }}
        >
          <ButtonContainedMain sx={{}} onclick={handleSaveAsImage}>
            Download
          </ButtonContainedMain>
          <ButtonContainedMain sx={{}} onclick={handleSaveAsPDF}>
            Share
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};

export default TransactionDetailsContent;

const TextLabelContainer: FC<{ label: string; text: string | number }> = ({
  label,
  text,
}) => {
  const theme = useTheme();
  const textStyle = {
    fontWeight: 400,
    color: theme.palette.common.black,
  };
  const valueStyle = {
    fontWeight: 600,
    color: theme.palette.common.black,
  };
  return (
    <Stack direction={"row"} justifyContent={"space-between"} sx={{ my: 2 }}>
      <Typography variant="body2" sx={textStyle}>
        {label}
      </Typography>
      <Typography variant="body2" sx={valueStyle}>
        {text}
      </Typography>
    </Stack>
  );
};
