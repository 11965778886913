import React, { useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { EmailInput, PasswordInput } from "../UIElements/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { PATH_AUTH, PATH_DASHBOARD } from "../../routes/path";
import { useTheme } from "@mui/material/styles";
import { useNotificationService } from "../UIElements/notification";
import { Link } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import { apiUserLogin } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import { loginSchema } from "../../validate/input";
import { useAuth } from "../../hooks/useAuth";
import { useDetails } from "../../hooks/useDetails";

//
const LoginDetails: React.FC<{ handleVerify: () => void }> = ({handleVerify}) => {
  const theme = useTheme();
  const { showNotification } = useNotificationService();
  const isMedium = useResponsive("down", "md", "xs");
  const navigate = useNavigate();
  const { set_data } = useAuth();
  const { set_user_wallet } = useDetails();
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(loginSchema),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async (values: any): Promise<void> => {
    const { email, password } = values;

    // setIsLoading(true);
    const data = { password: password, name_field: email };
    setIsLoading(true);
    const result = await apiUserLogin(data);
    if ("msg" in result) {
      showNotification(result.msg, "error");
      setIsLoading(false);

      if (result?.msg === "Account not verified") {
        handleVerify();
      }
    } else if (result?.status == 201) {
      showNotification("Login Success", "success");
      set_data(result?.data?.data);
      set_user_wallet(result?.data?.data?.token);
      setIsLoading(false);
      navigate(PATH_DASHBOARD.root);
    } else {
      showNotification("Login Failed", "error");
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: isMedium ? "100%" : "40%" }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <EmailInput
          errors={errors}
          placeholder="Email Address"
          register={register}
        />
        <PasswordInput
          errors={errors}
          placeholder="Password"
          register={register}
        />
        <Stack direction="row">
          <Link
            style={{ color: theme.palette.common.black, marginLeft: "auto" }}
            to={PATH_AUTH.resetPassword}
          >
            <Typography variant="body2">Forgot Password?</Typography>
          </Link>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ my: 3 }}
        >
          <Link style={{ textDecorationLine: "none" }} to={PATH_AUTH.register}>
            <Typography>Create Account</Typography>
          </Link>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            sx={{
              width: "120px",
              borderRadius: "10px",
            }}
          >
            Login
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default LoginDetails;
