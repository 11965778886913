import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ModalContainer from "./UIElements/modal";
import { ButtonContainedMain } from "./UIElements/button";
import { TransactionPropType } from "../interface/transaction";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../routes/path";
import { useDetails } from "../hooks/useDetails";

export const TransactionResult = ({
  children,
  txId, 
  onclose,
  openResult,
}: TransactionPropType) => {
  const theme = useTheme();
  const { refetchData } = useDetails();
  const navigate = useNavigate();

  const handleResult = async () => {
    await refetchData();
    onclose();
    navigate(PATH_DASHBOARD.root);
  };

  return (
    <ModalContainer
      sx={{}}
      openModal={openResult}
      handleModal={() => {}}
      size="sm"
      fullWidth={true}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: "20px",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/images/check-icon.png"
          alt=""
          width={"150px"}
          height={"150px"}
        />
        <Stack
          direction={"column"}
          sx={{ textAlign: "center", color: theme.palette.text.primary, my: 2 }}
        >
          <Typography variant="h3" sx={{ fontWeight: 600 }}>
            Transaction Successful
          </Typography>
        </Stack>

        <Stack direction={"row"} justifyContent={"center"}>
          <ButtonContainedMain onclick={handleResult} sx={{ width: "150px" }}>
            Done
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};

export const TransactionResultFailed = ({
  onClose,
  openResult,
  reason,
}: {
  onClose: () => void;
  openResult: boolean;
  reason: string;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { refetchData } = useDetails();


  const handleResult = async () => {
    await refetchData();
    onClose();
    navigate(PATH_DASHBOARD.root);
  };

  return (
    <ModalContainer
      sx={{}}
      openModal={openResult}
      handleModal={() => {}}
      size="sm"
      fullWidth={true}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: "20px",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/images/failed-icon.png"
          alt=""
          width={"150px"}
          height={"150px"}
        />
        <Stack
          direction={"column"}
          sx={{ textAlign: "center", color: theme.palette.text.primary, my: 2 }}
        >
          <Typography variant="h3" sx={{ fontWeight: 600 }}>
            Transaction Failed
          </Typography>

          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ my: 2, color: theme.palette.text.primary }}
          >
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {reason}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={"row"} justifyContent={"center"}>
          <ButtonContainedMain onclick={handleResult} sx={{ width: "150px" }}>
            Done
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};
