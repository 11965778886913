import React, { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import { useNotificationService } from "../UIElements/notification";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { apiResendAccountCode, apiVerifyAccount } from "../../api/auth";
import { PATH_AUTH } from "../../routes/path";

const EmailVerification: React.FC<{ email: string; prevPage: () => void }> = ({
  email,
  prevPage,
}) => {
  const isMedium = useResponsive("down", "md", "xs");
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotificationService();
  const navigate = useNavigate();
  const location = useLocation();
  // set up validation
  const [data, setData] = useState<{ email: string; code: string }>({
    email: email ?? "",
    code: "",
  });

  const handleOnSubmit = async () => {
    setIsLoading(true);
    const verify_email = await apiVerifyAccount(data);
    console.log(verify_email);
    if ("msg" in verify_email) {
      showNotification(verify_email.msg, "error");
      setIsLoading(false);
    } else if (verify_email.status == 200) {
      showNotification(
        verify_email.data?.data?.message ?? "Resend OTP",
        "success"
      );
      setIsLoading(false);
      if (location.pathname === "/auth/login") {
        prevPage();
      } else {
        navigate(PATH_AUTH.login);
      }
    } else {
      showNotification("An error occured while fetching data", "error");
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    if (data?.email.length >= 4) {
      const result = await apiResendAccountCode(data?.email);
      console.log(result);
      if (result.status == 200) {
        showNotification("Email OTP Sent", "success");
      } else {
        showNotification("Sending Email OTP Failed", "error");
      }
    } else {
      showNotification("Kindly input Email, then try again", "error");
    }
  };
  return (
    <Stack
      direction={isMedium ? "column" : "row"}
      justifyContent={"space-between"}
      sx={{ margin: 3, gap: 4 }}
    >
      <Box sx={{ width: isMedium ? "100%" : "35%" }}>
        <img
          src="/assets/images/kumo-logo.png"
          width="140"
          height="40"
          alt=""
        />
        <Typography
          sx={{
            fontWeight: 300,
            lineHeight: isMedium ? "50px" : "56px",
            fontSize: isMedium ? "40px" : "52px",
            my: 2,
          }}
        >
          Verify your Account
        </Typography>
        <Typography variant="h5">
          Join over 1000 users to enjoy our seamless payment platform.
        </Typography>
      </Box>
      <Box sx={{ width: isMedium ? "100%" : "40%" }}>
        <Box>
          <Stack sx={{ my: 2 }}>
            <InputLabel
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: theme.palette.common.black,
              }}
            >
              Email
            </InputLabel>
            <TextField
              sx={{ width: "100%" }}
              type="email"
              value={data.email}
              onChange={(e) =>
                setData((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </Stack>
          <Stack sx={{ my: 2 }}>
            <InputLabel
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: theme.palette.common.black,
              }}
            >
              OTP Code
            </InputLabel>
            <TextField
              sx={{ width: "100%" }}
              type="email"
              value={data.code}
              onChange={(e) =>
                setData((prev) => ({ ...prev, code: e.target.value }))
              }
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"justify-between"}
            sx={{ my: 3 }}
          >
            <Button
              onClick={prevPage}
              sx={{ color: theme.palette.primary.main, mx: 1 }}
            >
              Back
            </Button>
            <Button
              onClick={handleResend}
              sx={{ color: theme.palette.primary.main, mx: 1 }}
            >
              Resend
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              onClick={handleOnSubmit}
              sx={{
                marginLeft: "auto",
                width: "120px",
                borderRadius: "10px",
              }}
            >
              Submit
            </LoadingButton>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} sx={{ my: 3 }}></Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default EmailVerification;
