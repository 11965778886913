import React, { useState } from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import useResponsive from "../../hooks/useResponsive";
import { ButtonContainedMain } from "../../components/UIElements/button";
import { useNavigate } from "react-router-dom";
import { PATH_AUTH } from "../../routes/path";
import { Helmet } from "react-helmet-async";

const Onboarding: React.FC = () => {
  const isMedium = useResponsive("down", "md", "xs");
  const isSmall = useResponsive("down", "sm", "xs");
  const theme = useTheme();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  let text = "";
  let subText = "";
  if (page === 1) {
    text = "Embrace your Future";
    subText = "Dive into a world where fiat and crypto collide seamlessly !";
  } else if (page === 2) {
    text = "Buy, Sell, Send ans Receive";
    subText = "Trade crypto like a pro and watch your assets soar!";
  } else if (page === 3) {
    text = "Go Global, Stay Local";
    subText =
      "Access your funds anytime, anywhere, with the tao of your Screen";
  } else {
    text = "Welcome to seamless Security";
    subText = "Fro easy, secure payments, activate OTP with one tap.";
  }

  const handleNextContent = () => {
    if (page === 4) {
      navigate(PATH_AUTH.login);
    }
    setPage((prev) => prev + 1);
  };
  const handlePrevContent = () => {
    setPage((prev) => prev - 1);
  };
  return (
    <>
      <Helmet>
        <title>Onboarding</title>
      </Helmet>
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: "36px",
          width: isSmall ? "300px" : "500px",
          marginX: "auto",
          padding: "1.4rem",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ margin: 1, mb: 3 }}
        >
          <img
            src="/assets/images/kumo-logo.png"
            width="140"
            height="40"
            alt=""
          />
        </Stack>
        <img
          width={"100%"}
          height={"auto"}
          src="/assets/images/rocket.png"
          alt=""
        />
        <Typography
          sx={{ fontWeight: 300, lineHeight: "56px", fontSize: "32px", mt: 2 }}
        >
          {text}{" "}
        </Typography>
        <Typography variant="h5">{subText}</Typography>
        <SemiCircle page={page} />
        <Stack
          sx={{ mt: 3 }}
          direction={"row"}
          justifyContent={"space-between"}
        >
          {page !== 1 && <Button onClick={handlePrevContent}>previous</Button>}
          <ButtonContainedMain
            sx={{ marginLeft: "auto" }}
            onclick={handleNextContent}
          >
            {page === 4 ? "Get Started!" : "Next"}
          </ButtonContainedMain>
        </Stack>
      </Box>
    </>
  );
};

export default Onboarding;

const SemiCircle: React.FC<{ page: number }> = ({ page }) => {
  const theme = useTheme();

  const style = {
    borderRadius: "30px",
    height: "6px",
  };
  return (
    <Box sx={{ display: "flex", gap: "3px", my: 2 }}>
      <Box
        sx={{
          ...style,
          width: page === 1 ? "19px" : "6px",
          background:
            page === 1
              ? theme.palette.primary.main
              : theme.palette.primary.light,
        }}
      />
      <Box
        sx={{
          ...style,
          width: page === 2 ? "19px" : "6px ",
          background:
            page === 2
              ? theme.palette.primary.main
              : theme.palette.primary.light,
        }}
      />
      <Box
        sx={{
          ...style,
          width: page === 3 ? "19px" : "6px ",
          background:
            page === 3
              ? theme.palette.primary.main
              : theme.palette.primary.light,
        }}
      />
      <Box
        sx={{
          ...style,
          width: page === 4 ? "19px" : "6px ",
          background:
            page === 4
              ? theme.palette.primary.main
              : theme.palette.primary.light,
        }}
      />
    </Box>
  );
};
