import { useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Theme, useTheme } from "@mui/material/styles";
import {
  FullnamePropType,
  InputValidatePropType,
  PhonenumberPropType,
  SelectProps,
} from "../../interface/input";
import LockIcon from "@mui/icons-material/Lock";
import { VisibilityOff } from "@mui/icons-material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { codes } from "country-calling-code";
import { ProfileIcon } from "./svgIcons";

const styles = {
  input: {
    padding: "15px",
    paddingLeft: "4px",
    fontSize: "14px",
    fontFamily: "Outfit",
    fontWeight: 400,
    borderRadius: "10px",
    color: "#292925",
  },
};

const inputSty = {
  border: "none",
  outline: "none",
  background: "white",
  height: "2.5rem",
  borderRadius: "10px",
  color: "#292925",
  fontSize: "13px",
  padding: "0 14px",
};
const OldPasswordInput = ({
  errors,
  register,
  placeholder,
}: InputValidatePropType) => {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        error={!!errors.old_password}
        helperText={errors.old_password ? errors.old_password.message : ""} // Render error message only when there's an error
        {...register("old_password")}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        autoComplete="old_password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon
                style={{
                  color: !!errors.old_password
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
const NewPasswordInput = ({
  errors,
  register,
  placeholder,
}: InputValidatePropType) => {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Stack
      sx={{
        marginBottom: 3,
      }}
    >
      <TextField
        error={!!errors.new_password}
        helperText={errors.new_password ? errors.new_password.message : ""} // Render error message only when there's an error
        {...register("new_password")}
        type={showPassword ? "text" : "password"}
        autoComplete="new_password"
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon
                style={{
                  color: !!errors.new_password
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

// Normal Input
const PhonenumberMainInput = ({
  type,
  disable,
  value,
  placeholder,
  onChange,
}: PhonenumberPropType) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const getBorderColor = (isFocused: boolean, theme: Theme) => {
    if (isFocused) {
      return `2px solid ${theme.palette.primary.main}`;
    } else {
      return `1px solid ${theme.palette.secondary.light}`;
    }
  };

  return (
    <Box
      sx={{
        color: theme.palette.primary.main,
        marginBottom: 3,
        gap: "4px",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        paddingLeft: "14px",
        height: "3rem",
        border: getBorderColor(isFocused, theme),
        cursor: "pointer",
        background: theme.palette.common.white,
        justifyContent: "space-between",
        "&:hover": {
          border: isFocused ? "" : `1px solid ${theme.palette.common.black}`,
        },
      }}
    >
      <PhoneInTalkIcon />

      <input
        id="phoneNumber"
        type={type}
        value={value}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disable}
        onChange={onChange}
        style={{ ...inputSty, width: "100%", padding: "0 10px" }}
        maxLength={12}
      />
    </Box>
  );
};

const FullNameMainInput = ({
  first_value,
  last_value,
  onChangeFirst,
  onChangeLast,
  disable,
}: FullnamePropType) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const getBorderColor = (isFocused: boolean, theme: Theme) => {
    if (isFocused) {
      return `2px solid ${theme.palette.primary.main}`;
    } else {
      return `1px solid ${theme.palette.secondary.light}`;
    }
  };

  return (
    <Box
      sx={{
        color: theme.palette.primary.main,
        marginBottom: 3,
        gap: "4px",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        paddingLeft: "14px",
        height: "3rem",
        border: getBorderColor(isFocused, theme),
        cursor: "pointer",
        background: theme.palette.common.white,
        justifyContent: "space-between",
        "&:hover": {
          border: isFocused ? "" : `1px solid ${theme.palette.common.black}`,
        },
      }}
    >
      <ProfileIcon />
      <input
        style={{ ...inputSty, width: "35%" }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={first_value}
        name="first_name"
        disabled={disable}
        onChange={onChangeFirst}
      />
      <Divider
        orientation="vertical"
        sx={{
          background: "white",
          width: "1px",
          height: "20px",
          marginRight: "1rem",
        }}
      />
      <input
        style={{ ...inputSty, width: "40%" }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name="last_name"
        value={last_value}
        disabled={disable}
        onChange={onChangeLast}
      />
    </Box>
  );
};

const SelectContainer = ({ value, onChange, selectData }: SelectProps) => {
  const theme = useTheme();
  const handleValue = (e: Event) => {
    // console.log(text, value);
  };

  return (
    <FormControl>
      <Select value={"english"} onChange={onChange}>
        <MenuItem sx={{
          background: theme.palette.common.white
        }} value={"english"}>English</MenuItem>
      </Select>
    </FormControl>
  );
};

export {
  OldPasswordInput,
  NewPasswordInput,
  PhonenumberMainInput,
  SelectContainer,
  FullNameMainInput,
};
