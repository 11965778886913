// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_UTILITIES = "/utilities";
const ROOTS_RECHARGE = "/recharge";
const ROOTS_TRANSACTION = "/transaction";
const ROOTS_KUMOSTAKING = "/kumo-staking";
const ROOTS_PROFILE = "/profile";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  //   changepassword: path(ROOTS_AUTH, "/change-password"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  assets: path(ROOTS_DASHBOARD, "/assets"),
  buySell: path(ROOTS_DASHBOARD, "/buy-sell"),
  buyCrypto: path(ROOTS_DASHBOARD, "/buy-crypto"),
  sellCrypto: path(ROOTS_DASHBOARD, "/sell-crypto"),
  sendReceive: path(ROOTS_DASHBOARD, "/send-receive"),
  sendCrypto: path(ROOTS_DASHBOARD, "/send-crypto"),
  receiveCrypto: path(ROOTS_DASHBOARD, "/receive-crypto"),
  swapCoin: path(ROOTS_DASHBOARD, "/swap-coin"),
  kumoTransfer: path(ROOTS_DASHBOARD, "/kumo-transfer"),
  kumoWallet: path(ROOTS_DASHBOARD, "/kumo-wallet"),
  transferMoney: path(ROOTS_DASHBOARD, "/transfer-money"),
  transferCrypto: path(ROOTS_DASHBOARD, "/transfer-crypto"),
  fundWallet: path(ROOTS_DASHBOARD, "/fund-wallet"),
  fundWalletCrypto: path(ROOTS_DASHBOARD, "/fund-wallet/crypto"),
  fundWalletBankTransfer: path(ROOTS_DASHBOARD, "/fund-wallet/bank-transfer"),
  fundWalletPaypal: path(ROOTS_DASHBOARD, "/fund-wallet/paypal"),
  fundWalletKumoAgent: path(ROOTS_DASHBOARD, "/fund-wallet/kumo-agent"),
};
export const PATH_TRANSACTION = {
  root: ROOTS_TRANSACTION,
};

export const PATH_UTILITIES = {
  root: ROOTS_UTILITIES,
  buyElectricity: path(ROOTS_UTILITIES, "/buy-electricity"),
  cableTv: path(ROOTS_UTILITIES, "/cable-tv"),
};

export const PATH_RECHARGE = {
  root: ROOTS_RECHARGE,
  buyAirtime: path(ROOTS_RECHARGE, "/buy-airtime"),
  buyData: path(ROOTS_RECHARGE, "/buy-data"),
};

export const PATH_KUMOSTAKING = {
  root: ROOTS_KUMOSTAKING,
  history: path(ROOTS_KUMOSTAKING, "/history"),
};

export const PATH_PROFILE = {
  root: ROOTS_PROFILE,
};
