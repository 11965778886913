import React, { useEffect, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../hooks/useResponsive";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import ModalContainer from "./UIElements/modal";
import { SearchInput } from "./UIElements/searchInput";
import { TextIconContainerSelect } from "./UIElements/textContainer";
import {
  InfoValueProps,
  SelectModalType,
  TextLabelType,
  ValuePropType,
  ValueSelectInputPropType,
} from "../interface/textInput";

export const InputContainer = ({ text, amount }: ValuePropType) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xs");
  return (
    <Stack
      direction={"column"}
      sx={{
        gap: "5px",
        background: theme.palette.common.white,
        borderRadius: "20px",
        padding: isMedium ? " 0.7rem" : "1rem",
        my: 3,
        flexGrow: 1,
      }}
    >
      <Typography
        variant={isMedium ? "caption" : "body2"}
        sx={{ color: theme.palette.secondary.main }}
      >
        {text}
      </Typography>
      <input
        value={amount}
        style={{
          color: theme.palette.common.black,
          fontFamily: "Outfit",
          fontSize: isMedium ? "20px" : "24px",
          fontWeight: 700,
          border: 0,
          outline: "none",
        }}
      />
    </Stack>
  );
};

export const TextLabelContainer = ({ text, label }: TextLabelType) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xs");
  return (
    <Stack
      direction={"column"}
      sx={{
        gap: "5px",
        background: theme.palette.common.white,
        borderRadius: "20px",
        padding: isMedium ? " 0.7rem" : "1rem",
        my: 3,
        flexGrow: 1,
      }}
    >
      <Typography
        variant={isMedium ? "caption" : "body2"}
        sx={{ color: theme.palette.secondary.main }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.common.black,
          fontSize: isMedium ? "20px" : "24px",
          fontWeight: 700,
          border: 0,
          outline: "none",
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export const CustomTextSelectContainer = ({
  text,
  modalText,
  data,
  placeHolder,
  value,
  onclick,
}: ValueSelectInputPropType) => {
  const theme = useTheme();
  const isMedium = useResponsive("down", "md", "xl");
  const [select, setSelect] = useState("");
  const [modalContents, setModalContents] = useState(false);
  const [filteredData, setFilteredData] = useState<{
    items_list: any[];
    search_value: string;
  }>({
    items_list: [],
    search_value: "",
  });

  useEffect(() => {
    setFilteredData((prev) => ({ ...prev, items_list: [...data] }));
  }, [data]);
  const handleChange = (details: any) => {
    setSelect(details?.name as string);
    onclick(details);
    handleModalContents();
  };
  const handleModalContents = () => {
    setModalContents((prev) => !prev);
  };

  // handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setFilteredData((prev) => ({ ...prev, search_value: value }));

    if (value.length === 0) {
      setFilteredData((prev) => ({ ...prev, items_list: data })); // Reset to original list if search input is empty
    } else {
      const filteredTokens = filteredData?.items_list?.filter((token) =>
        token?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredData((prev) => ({ ...prev, items_list: filteredTokens }));
    }
  };

  return (
    <Stack
      direction={isMedium ? "column" : "row"}
      justifyContent={"space-between"}
      alignItems={isMedium ? "flex-start" : "center"}
      sx={{
        gap: isMedium ? "10px" : "20px",
        borderRadius: "20px",
        flexGrow: 1,
        paddingY: "1rem",
        paddingLeft: "1rem",
      }}
    >
      <Typography
        variant="body2"
        sx={{ color: theme.palette.secondary.light, minWidth: "150px" }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          width: isMedium ? "85%" : "100%",
          padding: "10px",
          borderRadius: "10px",
          border: `1px solid ${theme.palette.secondary.light} `,
          cursor: "pointer",
          background: theme.palette.common.white,
          justifyContent: "space-between",
          alignItems: "center",
          "&:hover": {
            border: `1px solid ${theme.palette.common.black}`,
          },
          "&:active": {
            border: `2px solid ${theme.palette.primary.main}`,
          },
        }}
        onClick={handleModalContents}
      >
        <Typography
          variant="body2"
          sx={{ color: theme.palette.secondary.main }}
        >
          {select.length >= 2 ? select : placeHolder}
        </Typography>
        <span style={{ cursor: "pointer" }}>
          <KeyboardArrowDownIcon />
        </span>
      </Box>
      <ModalContainer
        openModal={modalContents}
        sx={{}}
        fullWidth={true}
        size="sm"
        handleModal={handleModalContents}
      >
        <Box
          sx={{
            background: theme.palette.common.white,
            padding: 3,
            borderRadius: "20px",
            minHeight: 500,
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, color: theme.palette.text.primary }}
          >
            {modalText}
          </Typography>
          <Divider sx={{ my: 3 }} />
          <SearchInput
            text={"Search..."}
            handler={handleSearchChange}
            width={"100%"}
            value={filteredData?.search_value}
          />
          <Box
            sx={{
              overflowY: "auto",
              height: isMedium ? 350 : 400,
            }}
          >
            {filteredData?.items_list?.map((data, index) => (
              <TextIconContainerSelect
                key={index}
                image={data?.image ?? ""}
                onclick={() => handleChange(data)}
              >
                {data.name}
              </TextIconContainerSelect>
            ))}
          </Box>
        </Box>
      </ModalContainer>
    </Stack>
  );
};

export const UserInfo = ({
  value1,
  value2,
  firstlabel,
  secondlabel,
}: InfoValueProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction="column"
      sx={{
        color: theme.palette.text.primary,
        padding: "1rem",
        borderRadius: "20px",
        my: 3,
        background: theme.palette.primary.light,
      }}
    >
      <Typography variant="body1" sx={{ my: 3 }}>
        {firstlabel}

        <span style={{ fontWeight: 700, marginLeft: 4 }}>{value1}</span>
      </Typography>

      <Typography variant="body1" sx={{ my: 3 }}>
        {secondlabel}

        <span style={{ fontWeight: 700, marginLeft: 4 }}>{value2}</span>
      </Typography>
    </Stack>
  );
};

export const CustomSelectModalContainer = ({
  text,
  data,
  placeholder,
  value,
  handleValue,
}: SelectModalType) => {
  const theme = useTheme();

  const [select, setSelect] = useState("");
  const [modalContents, setModalContents] = useState(false);
  const handleChange = (value: string) => {
    setSelect(value as string);
    handleValue(value);
    handleModalContents();
  };
  const handleModalContents = () => {
    setModalContents((prev) => !prev);
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        gap: "20px",
        borderRadius: "20px",
        flexGrow: 1,
        paddingY: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          padding: "14px",
          borderRadius: "10px",
          border: "1px solid #E0E0E0",
          cursor: "pointer",
          background: theme.palette.common.white,
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={handleModalContents}
      >
        <Typography
          variant="body2"
          sx={{ color: theme.palette.secondary.main }}
        >
          {select.length >= 2 ? select : placeholder}
        </Typography>
        <span style={{ cursor: "pointer" }}>
          <KeyboardArrowDownIcon />
        </span>
      </Box>
      <ModalContainer
        openModal={modalContents}
        sx={{}}
        fullWidth={true}
        size="sm"
        handleModal={handleModalContents}
      >
        <Box
          sx={{
            background: theme.palette.common.white,
            padding: 3,
            borderRadius: "20px",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 500, color: theme.palette.text.primary }}
          >
            {text}{" "}
          </Typography>
          <Divider sx={{ my: 3 }} />
          <SearchInput text={""} handler={() => {}} width={"100%"} value={""} />
          {data?.map((data: any, index: number) => (
            <TextIconContainerSelect
              key={index}
              image=""
              onclick={() => handleChange(data.token)}
            >
              {data.token}
            </TextIconContainerSelect>
          ))}
        </Box>
      </ModalContainer>
    </Stack>
  );
};
