export function mergeCurrencies(baseCurrency: any, currencies: any) {
  // Find the currency in the data array that matches the base currency
  const matchingCurrency = currencies.find(
    (currency: { code: string; name: string }) =>
      currency.code === baseCurrency.code || currency.name === baseCurrency.name
  );

  // If no matching currency is found, return null
  if (!matchingCurrency) {
    return null;
  }

  // Merge properties of the matching currency with the base currency
  const mergedCurrency = { ...baseCurrency, ...matchingCurrency };
  return mergedCurrency;
}
