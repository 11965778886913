import { Grid, Skeleton } from "@mui/material";
import React from "react";
import AccountInfo from "./AccountInfo";
import QuickAction from "./QuickAction";
import TransactionInformation from "../transaction/TransactionInformation";
import useResponsive from "../../hooks/useResponsive";
import { useAuth } from "../../hooks/useAuth";
import { useDetails } from "../../hooks/useDetails";

const DashboardContent = () => {
  const isDesktop = useResponsive("up", "lg", "xl");
  const { base, token } = useAuth();
  const { wallet } = useDetails();

  return (
    <Grid container sx={{ marginTop: "1.5rem" }}>
      <Grid item xs={!isDesktop ? 12 : 8} sx={{ padding: 2 }}>
        <AccountInfo />
        <QuickAction />
      </Grid>
      <Grid item xs={!isDesktop ? 12 : 4} sx={{ paddingX: 3, paddingY: 2 }}>
        <TransactionInformation />
      </Grid>
    </Grid>
  );
};

export default DashboardContent;
