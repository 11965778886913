import { useState } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  ConfirmPasswordInput,
  EmailInput,
  OTPInput,
  PasswordInput,
} from "../UIElements/input";
import { useNavigate } from "react-router-dom";
import { PATH_AUTH } from "../../routes/path";
import { useTheme } from "@mui/material/styles";
import useResponsive from "../../hooks/useResponsive";
import { ResetPasswordPropType, ResetPasswordThreePropType } from "../../interface/auth";
import { useNotificationService } from "../UIElements/notification";
import {
  apiForgotPassword,
  apiResendAccountCode,
  apiResetPassword,
} from "../../api/auth";
import {
  forgotPasswordSchemaOne,
  forgotPasswordSchemaTwo,
  passwordSchema,
} from "../../validate/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

export const ResetPasswordDetailsOne = ({
  pageHandler,
  changeValues,
}: ResetPasswordPropType) => {
  const isMedium = useResponsive("down", "md", "xs");
  const { showNotification } = useNotificationService();
  const [isLoading, setIsLoading] = useState(false);

  // set up validation
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(forgotPasswordSchemaOne),
  });
  const handleOnSubmit = async (value: any) => {
    const { email } = value;
    console.log(email);
    changeValues((prevState) => ({
      ...prevState,
      email: email,
    }));
    setIsLoading(true);

    const result = await apiForgotPassword(email);
    if (result.status == 200) {
      showNotification("OTP Sent", "success");
      setIsLoading(false);
      // next page
      pageHandler();
    } else {
      showNotification("Invalid Email", "error");
      setIsLoading(false);
    }
  };
  return (
    <Box sx={{ width: isMedium ? "100%" : "40%" }}>
      <Typography variant="body1" sx={{ my: 1 }}>
        Enter your email address
      </Typography>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <EmailInput
          errors={errors}
          placeholder="Email Address"
          register={register}
        />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ my: 3 }}
        >
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            sx={{
              marginLeft: "auto",
              width: "160px",
              borderRadius: "10px",
            }}
          >
            Continue
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};

export const ResetPasswordDetailsTwo = ({
  pageHandler,
  values,
  changeValues,
  prevPage,
}: ResetPasswordPropType) => {
  const isMedium = useResponsive("down", "md", "xs");
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotificationService();
  const theme = useTheme();
  // set up validation
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(forgotPasswordSchemaTwo),
  });

  const handleOnSubmit = async (value: any) => {
    const { code } = value;
    changeValues((prevState) => ({
      ...prevState,
      code: code,
    }));
    // next page
    pageHandler();
  };

  const handleResend = async () => {
    if (values?.email.length >= 4) {
      setIsLoading(true);
      const result = await apiResendAccountCode(values?.email);
      if (result.status == 200) {
        showNotification("Email OTP Sent", "success");
        setIsLoading(false);
      } else {
        showNotification("Sending Email OTP Failed", "error");
        setIsLoading(false);
      }
    }
  };
  return (
    <Box sx={{ width: isMedium ? "100%" : "40%" }}>
      <Typography variant="body1" sx={{ my: 1 }}>
        OTP have been sent to{" "}
        <span style={{ fontWeight: 700 }}>{values?.email}</span>
      </Typography>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <OTPInput placeholder="Enter OTP" errors={errors} register={register} />

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"end"}
          sx={{ my: 3 }}
        >
          <Button onClick={handleResend}>Resend</Button>
          <Button
            onClick={prevPage}
            sx={{ color: theme.palette.common.black, mx: 4 }}
          >
            Back
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            sx={{
              marginLeft: "auto",
              width: "160px",
              borderRadius: "10px",
            }}
          >
            Continue
          </LoadingButton>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} sx={{ my: 3 }}></Stack>
      </Box>
    </Box>
  );
};

export const ResetPasswordDetailsThree = ({
  values,
  prevPage,
}: ResetPasswordThreePropType) => {
  const isMedium = useResponsive("down", "md", "xs");
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotificationService();
  const navigate = useNavigate();
  const theme = useTheme();

  // set up validation
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(passwordSchema),
  });

  const handleOnSubmit = async (value: any) => {
    const { password } = value;

    const data = { ...values, password: password };
    setIsLoading(true);
    const result = await apiResetPassword(data);
    if (result.status == 200) {
      showNotification("Password reset success", "success");
      setIsLoading(false);
      // route to login
      navigate(PATH_AUTH.login);
    } else {
      showNotification("Changing Password Failed", "error");
      setIsLoading(false);
    }

    // sent request
  };

  return (
    <Box sx={{ width: isMedium ? "100%" : "40%" }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <PasswordInput
          errors={errors}
          placeholder="Password"
          register={register}
        />
        <ConfirmPasswordInput
          errors={errors}
          placeholder="Re-enter Password"
          register={register}
        />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"end"}
          sx={{ my: 3 }}
        >
          <Button
            onClick={prevPage}
            sx={{ color: theme.palette.common.black, mx: 4 }}
          >
            Back
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            sx={{
              width: "160px",
              borderRadius: "10px",
            }}
          >
            Continue
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};
