import Axios from "../utils/http-request";
import { getRequestConfiguration } from "../utils/headerHelper";
import {
  BuyCryptoVerifierProps,
  GetSwapConversion,
  TransactionHistoryProps,
  CryptoProps,
  TransactionToBaseCurrencyProps,
  SellCryptoVerifierProps,
  SwapCryptoVerifierProps,
  SwapCoinsProps,
  TransferKumoAccountVerifier,
  TransferKumoAccount,
  TransferBank,
  SendCryptoMinMax,
  FundWalletPropType,
  AxiosErrorResponse,
  SendCryptoVerifier,
  SendCryptoProps,
} from "../interface/api_request";
import { AllTransactionsProp } from "../interface/transaction";

export const getAllTransactions = async (
  query: AllTransactionsProp,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/get-transactions?page=${query?.page}&item_per_page=${query?.item_per_page}&component=${query?.component}&q=${query?.q}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const getTransaction = async (id: string, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/get-transaction/${"654232eeb682d475351be874"}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const getWalletBalance = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/get-wallet-balance`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getWalletAddress = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/get-wallet-address`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const getUSDtoNairaConversion = async (token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/get-usd-ngn`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getTransactionHistory = async (
  values: TransactionHistoryProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/users/get-transaction-history?format=${values?.format}&user_id=${values?.user_id}&from=${values?.from}&to=${values?.to}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
    };
  }
};

export const getCryptoToBaseCurrencyForBuy = async (
  values: TransactionToBaseCurrencyProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/get-crypto-to-base-currency-for-buy?amount=${values?.amount}&crypto=${values?.crypto}&currency=${values?.currency}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const getCryptoToBaseCurrencyForSend = async (
  values: TransactionToBaseCurrencyProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/get-crypto-to-base-currency-for-send?amount=${values?.amount}&crypto=${values?.crypto}&currency=${values?.currency}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const sendCryptoMinMaxCheck = async (
  values: SendCryptoMinMax,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/send-crypto-check-min-max?currency_name=${values?.currency_name}&amount=${values?.amount}&rate=${values?.rate}&base_currency_amount=${values?.base_currency_amount}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };}
};


export const sendCryptoVerifier = async (
  values: SendCryptoVerifier,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/send-crypto-verifier`;
    return await Axios.post(uri, values, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };}
};


export const sendCrypto = async (
  values: SendCryptoProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/send-crypto`;
    return await Axios.post(uri, values, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };}
};
 
export const buyCryptoVerifier = async (
  values: BuyCryptoVerifierProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/buy-crypto-verifier`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const buyCryptoCheckMinMax = async (
  values: { currency_name: string; amount: number; rate: number },
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/buy-crypto-check-min-max?currency_name=${values?.currency_name}&amount=${values?.amount}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const buyCrypto = async (values: CryptoProps, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/buy-crypto`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const getCryptoToBaseCurrencyForSell = async (
  values: TransactionToBaseCurrencyProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/get-crypto-to-base-currency-for-sell?amount=${values?.amount}&crypto=${values?.crypto}&currency=${values?.currency}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const sellCryptoCheckMinMax = async (
  values: { currency_name: string; amount: number },
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/sell-crypto-check-min-max?currency_name=${values?.currency_name}&amount=${values?.amount}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const sellCryptoVerifier = async (
  values: SellCryptoVerifierProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/sell-crypto-verifier`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const sellCrypto = async (values: CryptoProps, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/sell-crypto`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const getSwapConversion = async (
  values: GetSwapConversion,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/get-swap-conversion?to_name=${values?.to_name}&from_name=${values?.from_name}&from_amount=${values?.from_amount}`;
    return await Axios.get(uri, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const swapCryptoVerifier = async (
  values: SwapCryptoVerifierProps,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/swap-crypto-verifier`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const swapCoins = async (values: SwapCoinsProps, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/swap-coins`;
    return await Axios.post(uri, values, cfg);
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const transferToKumoAccount = async (
  values: TransferKumoAccount,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/transfer-to-kumo-account`;
    return await Axios.put(uri, values, cfg);
  }catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};

export const transferToKumoAccountVerifier = async (
  values: TransferKumoAccountVerifier,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/transfer-to-kumo-account-verifier`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const transferToBank = async (values: TransferBank, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/transfer-to-bank`;
    return await Axios.post(uri, values, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const transferToBankVerifier = async (
  values: TransferBank,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/transfer-to-bank-verifier`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const transferToBankMinMax = async (
  values: TransferBank,
  token: string
) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/transfer-to-bank-min-max`;
    return await Axios.put(uri, values, cfg);
  } catch (e: any) {
    return {
      data: {},
      status: "error",
      msg: e?.response?.data?.message || e?.message,
      message: e?.response?.data?.msg,
    };
  }
};

export const fundWallet = async (values: FundWalletPropType, token: string) => {
  try {
    const cfg = await getRequestConfiguration(token, null);
    const uri = `/app/transaction/fund-wallet`;
    const response = await Axios.put(uri, values, cfg);
    return response;
  } catch (e: any) {
    const error = e as AxiosErrorResponse;
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error?.message ||
      "An error occurred";
    return {
      data: {},
      status: "error",
      msg: errorMessage,
    };
  }
};
