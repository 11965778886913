import { Box, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import Header from "../../layouts/dashboard/header";
import { PageMainTextSummary } from "../../components/pageMainSummary";
import { TextContainer } from "../../components/UIElements/textContainer";
import { PATH_UTILITIES } from "../../routes/path";
import { Helmet } from "react-helmet-async";

const Utilities: React.FC = () => {
  const theme = useTheme();
  return (
    <>
     <Helmet>
        <title>Utilities</title>
      </Helmet>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            Utilities
          </Typography>
        </Box>
      </Header>
      <Box sx={{ mt: 4 }}>
        <PageMainTextSummary
          referralCode={false}
          code=""
          text="Pay for your Utility services"
        />
      </Box>

      <DashboardGridContainer>
        <Box sx={{}}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
          >
            Make Purchase
          </Typography>
          <TextContainer path={""}>Send SMS </TextContainer>
          <TextContainer path={""}>Buy WAEC Scratch Card</TextContainer>
          <TextContainer path={""}>Buy NECO Scratch Card</TextContainer>
          <TextContainer path={PATH_UTILITIES.buyElectricity}>
            Buy Electricity
          </TextContainer>
          <TextContainer path={PATH_UTILITIES.cableTv}>Cable TV</TextContainer>
        </Box>
      </DashboardGridContainer>
    </>
  );
};

export default Utilities;
