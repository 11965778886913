import React, { FC } from "react";
import ModalContainer from "../../UIElements/modal";
import { Box, Stack, Typography, Divider, InputLabel } from "@mui/material";
import { ButtonContainedMain } from "../../UIElements/button";
import { useTheme } from "@mui/material/styles";

import { CloseIcon } from "../../UIElements/svgIcons";
import { FundWalletInput, FundWalletTextValueContainer } from "./contentStyle";
import { useAuth } from "../../../hooks/useAuth";
import {
  addCommasToNumbers,
  hasValues,
  parseFormattedNumber,
} from "../../../utils/basic";
import { KumoAgentStateType } from "../../../interface/dashboard";

// ----------------------------------

export const KumoAgentFirstStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  navigate: () => void;
  open: boolean;
  kumoAgent: KumoAgentStateType;
  setKumoAgent: React.Dispatch<React.SetStateAction<KumoAgentStateType>>;
}> = ({ onclick, onclose, open, navigate, kumoAgent, setKumoAgent }) => {
  const theme = useTheme();
  const { base } = useAuth();
  const handleNextContent = () => {
    onclose();
    onclick();
  };
  const handleAddComma = () => {
    const plainNumber = kumoAgent?.amount || "";
    const formattedNumber = addCommasToNumbers(+plainNumber);
    setKumoAgent((prev) => ({ ...prev, amount: formattedNumber }));
  };

  const handleFocus = () => {
    if (kumoAgent?.amount) {
      const plainNumber = parseFormattedNumber(kumoAgent?.amount?.toString());
      setKumoAgent((prev) => ({ ...prev, amount: plainNumber }));
    }
  };

  const handleAmountChange = (e: { target: { value: any } }) => {
    const value = e.target.value.replace(/,/g, "");
    setKumoAgent((prev) => ({ ...prev, amount: value }));
  };

  return (
    <>
      <ModalContainer
        sx={{}}
        size={"sm"}
        openModal={open}
        fullWidth={true}
        handleModal={() => {}}
      >
        <Box
          sx={{
            background: theme.palette.common.white,
            padding: 4,
            borderRadius: "20px",
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ color: theme.palette.text.primary }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Fund Wallet{" "}
            </Typography>
            <span onClick={navigate} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </span>
          </Stack>
          <Typography variant="body2">
            Enter the amount to add, click “Continue”, and complete payment to
            update your wallet balance
          </Typography>
          <Divider sx={{ my: 3 }} />
          <FundWalletTextValueContainer
            text="You Pay"
            onBlurHandler={handleAddComma}
            onFocusHandler={handleFocus}
            onchange={handleAmountChange}
            currency={base?.code?.toUpperCase()}
            amount={kumoAgent?.amount}
            icon={base?.image}
          />
          <Divider sx={{ my: 3 }} />
          <Stack direction={"row"} justifyContent={"center"}>
            <ButtonContainedMain
              onclick={handleNextContent}
              sx={{ width: "150px" }}
            >
              Continue
            </ButtonContainedMain>
          </Stack>
        </Box>
      </ModalContainer>
    </>
  );
};

export const KumoAgentSecondStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  open: boolean;
  navigate: () => void;
}> = ({ onclick, onclose, open, navigate }) => {
  const theme = useTheme();
  const handleNextContent = () => {
    onclose();
    onclick();
  };
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ color: theme.palette.text.primary }}
        >
          <Typography sx={{ fontWeight: 700 }}>Kumo Agents</Typography>
          <span onClick={navigate} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          Terms of usage
        </Typography>
        <Typography variant="body2">
          Kindly note that this is a manual process carried out by varified
          paymetn agentsfrom a third party service{" "}
        </Typography>
        <Typography variant="body2">
          Get started in 4 simple steps below:
        </Typography>
        <ul style={{ fontSize: "14px", fontWeight: 400, fontFamily: "Outfit" }}>
          <li>Select any of our agents</li>
          <li>Make a bank transfer to the account number displayed.</li>
          <li>
            After a successful fund transfer, Kindly contact or send proof of
            payment and your username to the Agent. (OPTIONAL)
          </li>
          <li>Click the confirm button to notify agent. </li>
        </ul>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          Agent terms and charges:
        </Typography>
        <ol style={{ fontSize: "14px", fontWeight: 400, fontFamily: "Outfit" }}>
          <li>Charges may be applied to transactions.</li>
          <li>All wallet funding are charged by agents</li>
          <li>Above one million: 1,000 Naira.</li>
          <li>Below one million: 500 Naira</li>
          <li>Below one hundred thousand: 200 Naira</li>
          <li>
            All cash deposit must be made from accounts that matches your
            verified name.
          </li>
          <li>
            Kindly include charges to the amount you are funding. Charges will
            be deducted from the amount deposited.
          </li>
          <li>
            Conduct transactions with only agents available/displayed on the
            platform.
          </li>
        </ol>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          Disclaimer:
        </Typography>
        <Typography variant="body2">
          Any transaction conducted outside the agents available on our platform
          may result to loss of funds
          <br />
          Funds deposited from an unverified source or account that doesn't
          matches your profile may result to loss of fund.
        </Typography>

        <Stack
          direction={"row"}
          justifyContent={"center"}
          sx={{ marginTop: 3 }}
        >
          <ButtonContainedMain
            onclick={handleNextContent}
            sx={{ width: "150px" }}
          >
            Continue
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};

export const KumoAgentThirdStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  open: boolean;
  agentList: any[];
  navigate: () => void;
  setKumoAgent: React.Dispatch<React.SetStateAction<KumoAgentStateType>>;
}> = ({ onclick, onclose, open, agentList, navigate, setKumoAgent }) => {
  const theme = useTheme();
  const handleNextContent = () => {
    onclose();
    onclick();
  };
  const handleAgentClick = (obj: any) => {
    if (hasValues(obj)) {
      setKumoAgent((prev) => ({ ...prev, agentSelected: obj }));
      handleNextContent();
    }
  };
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ color: theme.palette.text.primary }}
        >
          <Typography>Kumo Agents</Typography>
          <span onClick={navigate} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Typography variant="caption">
          Select a preferred agent of your choice{" "}
        </Typography>
        {agentList?.map((agent, id) => (
          <Box
            key={id}
            onClick={() => handleAgentClick(agent)}
            sx={{
              background: "#F7F7FC",
              padding: 3,
              borderRadius: "20px",
              my: 2,
              cursor: "pointer",
            }}
          >
            <Typography variant="body1">
              {agent?.agent_name?.toUpperCase()}
            </Typography>
          </Box>
        ))}

        <Typography variant="caption">
          Kidly note that this option is manual and your wallet will be credited
          when value is received fron your bank, Crediting your wallet might
          take upto 5 miniutes - 2 hours depending on bank network, Kindly
          excercise patience while the process is ongoin.
        </Typography>
      </Box>
    </ModalContainer>
  );
};

export const KumoAgentFourthStep: React.FC<{
  onclick: () => void;
  onclose: () => void;
  open: boolean;
  kumoAgent: KumoAgentStateType;
}> = ({ onclick, onclose, open, kumoAgent }) => {
  const theme = useTheme();

  const handleNextContent = () => {
    onclick();
  };
  const { type_details } = kumoAgent?.agentSelected;
  return (
    <ModalContainer
      sx={{}}
      size={"sm"}
      openModal={open}
      fullWidth={true}
      handleModal={() => {}}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          padding: 4,
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ color: theme.palette.text.primary }}
        >
          <Typography>Kumo Agents</Typography>
          <span onClick={onclose} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </Stack>
        <Stack>
          <Box
            sx={{
              background: "#F7F7FC",
              padding: 3,
              borderRadius: "20px",
              my: 2,
            }}
          >
            <Typography variant="body1">
              {kumoAgent?.agentSelected?.agent_name?.toUpperCase()}
            </Typography>
          </Box>
          <Typography variant="body2">
            Paying the sun of {kumoAgent?.amount} to the account number below
          </Typography>
        </Stack>
        <Divider />
        <FundWalletInput value={type_details?.bank_name} label={"Bank Name"} />
        <FundWalletInput
          value={type_details?.account_name}
          label={"Account Name"}
        />
        <FundWalletInput
          value={type_details?.account_number}
          label={"Account Number"}
        />
        <FundWalletInput
          value={kumoAgent?.agentSelected?.agent_phone}
          label={"Phone Number"}
        />
        <FundWalletInput
          value={kumoAgent?.agentSelected?.recipient_country}
          label={"Country"}
        />
        <FundWalletInput
          value={type_details?.recipient_type}
          label={"Recipient Type"}
        />
        <FundWalletInput
          value={kumoAgent?.agentSelected?.type}
          label={"Bank Type"}
        />

        <Divider />
        <Stack
          direction={"row"}
          justifyContent={"center"}
          sx={{ marginTop: 3 }}
        >
          <ButtonContainedMain
            onclick={handleNextContent}
            sx={{ width: "150px" }}
          >
            Continue
          </ButtonContainedMain>
        </Stack>
      </Box>
    </ModalContainer>
  );
};
