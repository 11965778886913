import { FC, useState } from "react";

//
import { Box, Typography, Stack } from "@mui/material";

import { TextIconContainer } from "../UIElements/textContainer";
import { SearchInput } from "../UIElements/searchInput";
import { useTheme } from "@mui/material/styles";
import { ButtonContainedMain } from "../UIElements/button";
import { QrCodeContainer } from "../qrCodeContainer";
import useResponsive from "../../hooks/useResponsive";
import { useNotificationService } from "../UIElements/notification";

//
const ReceiveCryptoContent: FC<{ tokenList: any[]; walletData: any }> = ({
  tokenList,
  walletData,
}) => {
  const theme = useTheme();
  const isSmall = useResponsive("down", "sm", "xl");
  const { showNotification } = useNotificationService();
  const [currency, setCurrency] = useState<{
    token: any;
    walletObject: any;
    exchange: boolean;
  }>({
    token: "",
    walletObject: {},
    exchange: false,
  });
  const [filteredData, setFilteredData] = useState<{
    coin_list: any[];
    search_value: string;
  }>({
    coin_list: tokenList ?? [],
    search_value: "",
  });
  console.log(walletData, tokenList);

  // handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilteredData((prev) => ({ ...prev, search_value: value }));

    if (value?.length === 0) {
      setFilteredData((prev) => ({ ...prev, coin_list: tokenList })); // Reset to original list if search input is empty
    } else {
      const filteredTokens = filteredData?.coin_list?.filter((token) =>
        token?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredData((prev) => ({ ...prev, coin_list: filteredTokens }));
    }
  };

  const handleCurrency = async (tokenObj: any) => {
    if (!!walletData) {
      showNotification("User has empty wallet address", "error");
      return;
    }
    const address = walletData?.find(
      (token: any) =>
        token?.code === tokenObj?.code || token?.name === tokenObj?.name
    );
    setCurrency(() => ({
      exchange: true,
      token: tokenObj,
      walletObject: address,
    }));
  };
  return (
    <>
      <Box sx={{ mt: isSmall ? 0 : 4 }}>
        {currency?.exchange && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Balance{" "}
            </Typography>
            <QrCodeContainer
              text="Naira Deposit Address"
              address={currency?.walletObject?.walletAddress}
            />
            <QrCodeContainer
              text="Crypto Deposit Address"
              address={currency?.walletObject?.cryptoAddress}
            />
            <Stack direction="row" justifyContent={"center"}>
              <ButtonContainedMain
                sx={{
                  width: "150px",
                  marginX: "auto",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
                onclick={() => {
                  showNotification("This Operation is not available", "error");
                }}
              >
                Share
              </ButtonContainedMain>
            </Stack>
          </Box>
        )}
        {!currency?.exchange && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: theme.palette.text.primary }}
            >
              Select Your Preferred coin
            </Typography>
            <Box sx={{ my: 2 }}>
              <SearchInput
                width="100%"
                text="Search Crypto"
                handler={handleSearchChange}
                value={filteredData?.search_value}
              />
              {filteredData?.coin_list?.map((token, id) => (
                <Box key={id}>
                  <TextIconContainer
                    image={token?.image}
                    onclick={() => handleCurrency(token)}
                  >
                    {token?.name?.toUpperCase()}
                  </TextIconContainer>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ReceiveCryptoContent;
