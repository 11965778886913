import axios from "axios";
let uri;

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    uri = 'https://kumo-api-v2.herokuapp.com/' 
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    uri = 'https://kumo-api-v2.herokuapp.com/' // staging
} else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    uri = 'https://kumo-api-v2.herokuapp.com/' // production
}
const Axios = axios.create({ baseURL: uri + 'v1' });

Axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    console.log(error?.response?.data?.msg);
    console.log(error.response.status);
    if (error.response.status === 401) {
        console.log(window.location.pathname);
        if (window.location.pathname !== '/logout' && window.location.pathname !== '/login') window.location.href = '/logout';
    }
    return Promise.reject(error);
});

export const BaseURL = uri;
export default Axios;

