import { FC, useState } from "react";

//
import { Box, Typography, Stack } from "@mui/material";

import {
  MaxAmountValue,
  TextInputContainer,
  TextValueContainer,
} from "../UIElements/textContainer";
import { useTheme } from "@mui/material/styles";
import { ButtonContainedMain } from "../UIElements/button";
import { PasswordModal } from "../passwordModal";
import { TransactionResult, TransactionResultFailed } from "../transactionResult";
import { CustomTextSelectContainer } from "../textInput";
import CustomTransactionDetailsModal from "../customTransactionDetailsModal";
import { TransactionDetailsPropType } from "../../interface/transaction";
import { WalletBalanceType } from "../../interface/useDetail";
import { useAuth } from "../../hooks/useAuth";
import { useNotificationService } from "../UIElements/notification";
import { ProviderDetailsProps } from "../../interface/cable";
import { VariationSelectedProps } from "../../interface/cable";
import {
  addCommasToNumbers,
  hasValues,
  parseFormattedNumber,
} from "../../utils/basic";
import { getUtilitiesVariation, rechargeData } from "../../api/utilities";
import { verifyPin } from "../../api/users";

//
const BuyDataContent: FC<{
  provider_data: any[];
  baseWallet: WalletBalanceType | undefined;
}> = ({ provider_data, baseWallet }) => {
  const theme = useTheme();
  const { token, base } = useAuth();
  const { showNotification } = useNotificationService();
  const [dataDetails, setDataDetails] = useState<{
    provider: ProviderDetailsProps;
    plans: any[];
    plan_selected: VariationSelectedProps;
    phone_number: string;
    amount: number | string;
  }>({
    provider: {} as ProviderDetailsProps,
    plans: [],
    plan_selected: {} as VariationSelectedProps,
    phone_number: "",
    amount: "",
  });
  const [modalContainer, setModalContainer] = useState({
    modalDetails: false,
    pinModal: false,
    resultModal: false,
    failedModal: false
  });

  // handle provider selection
  const handleProviderSelection = async (obj: ProviderDetailsProps) => {
    const token_key = token ?? "";
    setDataDetails((prev) => ({
      ...prev,
      provider: obj,
    }));
    showNotification("Fetching data plans, kindly hold on", "info");

    if (hasValues(obj)) {
      // update state
      const plans_fetch = await getUtilitiesVariation(
        obj?.serviceID,
        token_key
      );
      const variation =
        plans_fetch?.data?.data?.variations ||
        plans_fetch?.data?.data?.varations ||
        [];
      if (plans_fetch.status !== 200) {
        showNotification(`${obj.name} plans Failed`, "error");
        return;
      } else {
        showNotification("Data plans Fetched", "success");
        setDataDetails((prev) => ({
          ...prev,
          plans: variation,
        }));
      }
    }
  };

  const handlePlansSelection = async (obj: VariationSelectedProps) => {
    if (hasValues(obj)) {
      // update state
      setDataDetails((prev) => ({
        ...prev,
        plan_selected: obj,
        amount: obj.variation_amount,
      }));
    }
  };

  const handleMaxAmount = (num: number) => {
    // setDataDetails((prev) => ({ ...prev, amount: num }));
  };

  // const handleAmountChange = (e: { target: { value: any } }) => {
  //   const value = e.target.value.replace(/,/g, "");
  //   setDataDetails((prev) => ({ ...prev, amount: value }));
  // };

  const handleModal = () => {
    // check if it's a dollar account
    if (base?.code === "usd" || base?.name === "dollars") {
      showNotification(
        "Conversion for dollar account has not been implemented",
        "info"
      );
      return;
    }
    // check amout
    if (
      parseFormattedNumber(dataDetails?.amount?.toString()) !==
      Number(dataDetails?.plan_selected?.variation_amount)
    ) {
      showNotification(
        `Purchase amount is ${dataDetails?.plan_selected?.variation_amount}`,
        "error"
      );
      return;
    }
    // check if amount is more than base amount
    if (
      parseFormattedNumber(dataDetails?.amount?.toString()) >
      (baseWallet?.value ?? 0)
    ) {
      showNotification(`Amount is greater than base amount`, "error");
      return;
    }
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: !prev.modalDetails,
    }));
  };
  const handlePinModal = () =>
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: !prev.modalDetails,
      pinModal: !prev.pinModal,
    }));
 
     // verify pin then send confirmation
  const handleResultModal = async (pin: string[]) => {
    const token_key = token ?? "";
    const pin_value = pin.join("");

    const recharge_data = {
      amount: dataDetails?.amount.toString(),
      service_id: dataDetails?.provider?.serviceID,
      billers_code: dataDetails?.phone_number,
      type: "data",
      variation_code: dataDetails?.plan_selected?.variation_code,
      pin: pin_value
    };

    const pin_verify = await verifyPin(pin_value, token_key);
    if (pin_verify?.status === 200) {
      // sent final request to server
      const data_recharge = await rechargeData(recharge_data, token_key);
      if ("msg" in data_recharge) {
        showNotification(data_recharge.msg, "error");
        setModalContainer((prev) => ({
          ...prev,
          pinModal: !prev.pinModal,
          failedModal: !prev.failedModal,
        }));
      } else if (data_recharge.status == 200) {
        showNotification("Data Recharged", "success");
        setModalContainer((prev) => ({
          ...prev,
          pinModal: !prev.pinModal,
          resultModal: !prev.resultModal,
        }));
      } else {
        showNotification("An error occured while recharging data", "error");
      }
    } else {
      showNotification("Invalid Pin", "error");
      setModalContainer((prev) => ({
        ...prev,
        pinModal: !prev.pinModal,
        failedModal: !prev.failedModal,
      }));
    }
  };
  // const handleAddComma = () => {
  //   const plainNumber = dataDetails?.amount || "";
  //   const formattedNumber = addCommasToNumbers(+plainNumber);
  //   setDataDetails((prev) => ({ ...prev, amount: formattedNumber }));
  // };

  // const handleFocus = () => {
  //   if (dataDetails?.amount) {
  //     const plainNumber = parseFormattedNumber(dataDetails?.amount?.toString());
  //     setDataDetails((prev) => ({ ...prev, amount: plainNumber }));
  //   }
  // };

  // close all open modal
  const closeAllModal = () => {
    setModalContainer((prev) => ({
      ...prev,
      modalDetails: false,
      pinModal: false,
      resultModal: false,
    }));
  };
  return (
    <>
      <BuyDataTransaction
        charges={""}
        open={modalContainer.modalDetails}
        onclick={handlePinModal}
        onClose={closeAllModal}
        data={dataDetails}
      />
      <PasswordModal
        open={modalContainer.pinModal}
        onclick={handleResultModal}
        onClose={closeAllModal}
      />
      <TransactionResult
        txId={1231981819}
        onclose={closeAllModal}
        openResult={modalContainer.resultModal}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          You have <span style={{ fontWeight: 700 }}>successfully</span> bought{" "}
          <span style={{ fontWeight: 700 }}>Data </span>
        </Typography>
      </TransactionResult>
      <TransactionResultFailed
        onClose={closeAllModal}
        openResult={modalContainer?.failedModal}
        reason="Transaction Failed"
      />
      <Box>
        {!!baseWallet && (
          <MaxAmountValue
            onclick={handleMaxAmount}
            currency={base?.code}
            amount={baseWallet?.value}
          />
        )}{" "}
        {/* <TextValueContainer
          text="You Pay"
          currency={base?.code?.toUpperCase()}
          onFocusHandler={handleFocus}
          onBlurHandler={handleAddComma}
          amount={dataDetails?.amount}
          onchange={handleAmountChange}
          icon={base?.image}
        /> */}
        <TextInputContainer
          onclick={(e) =>
            setDataDetails((prev) => ({
              ...prev,
              phone_number: e.target.value,
            }))
          }
          placeholder="Enter phone number here..."
          value={dataDetails?.phone_number}
          text="Phone Number"
        />
        <CustomTextSelectContainer
          modalText="Select Network"
          placeHolder="Select Network"
          text="Select Network"
          data={provider_data}
          value={""}
          onclick={handleProviderSelection}
        />
        <CustomTextSelectContainer
          modalText="Select Plan"
          placeHolder="Select Plan"
          text="Select Plan"
          data={dataDetails?.plans}
          value={""}
          onclick={handlePlansSelection}
        />
        <Stack direction="column" alignItems={"center"}>
          <Typography
            variant="body2"
            sx={{ my: 2, color: theme.palette.secondary.main }}
          >
            Charge = 0
          </Typography>
          <ButtonContainedMain sx={{ marginX: "auto" }} onclick={handleModal}>
            Send
          </ButtonContainedMain>
        </Stack>
      </Box>
    </>
  );
};

export default BuyDataContent;

const BuyDataTransaction = ({
  onClose,
  onclick,
  open,
  data,
}: TransactionDetailsPropType) => {
  const theme = useTheme();
  const { base } = useAuth();
  const textStyle = {
    color: theme.palette.secondary.main,
  };
  const valueStyle = {
    color: theme.palette.common.black,
    fontWeight: 700,
  };
  return (
    <CustomTransactionDetailsModal
      open={open}
      onClose={onClose}
      onclick={onclick}
    >
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Amount
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {base?.symbol} {data?.amount}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Network Provider
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.provider?.name}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Plan
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.plan_selected?.name}
          </Typography>
        </Stack>
        {/* <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Validity
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.validity}
          </Typography>
        </Stack> */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ my: 2 }}
        >
          <Typography variant="body2" sx={textStyle}>
            Phone number
          </Typography>
          <Typography variant="body2" sx={valueStyle}>
            {data?.phone_number}
          </Typography>
        </Stack>
      </Box>
    </CustomTransactionDetailsModal>
  );
};
