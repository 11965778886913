import React, { useContext } from "react";
import {
  AirtimeIcon,
  DashboardIcon,
  KumoIcon,
  ProfileIcon,
  ReferralIcon,
  TransactionIcon,
  UtilityIcon,
} from "../../../components/UIElements/svgIcons";

const NavConfig = () => {
  const content = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <DashboardIcon />,
    },
    { 
      title: "Utilities",
      path: "/utilities",
      icon: <UtilityIcon />,
    },
    {
      title: "Airtime and Data",
      path: "/recharge",
      icon: <AirtimeIcon />,
    },
    {
      title: "Transaction",
      path: "/transaction",
      icon: <TransactionIcon />,
    },
    {
      title: "Referrals",
      path: "/referral",
      icon: <ReferralIcon />,
    },
    {
      title: "Profile",
      path: "/profile",
      icon: <ProfileIcon />,
    },
    {
      title: "Kumo Staking",
      path: "/kumo-staking",
      icon: <KumoIcon />,
    },
  ];

  return content;
};

export default NavConfig;
