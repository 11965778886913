import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardGridContainer from "../../components/dashboard/DashboardGridContainer";
import { useTheme } from "@mui/material/styles";
import Header from "../../layouts/dashboard/header";
import ReceiveCryptoContent from "../../components/dashboard/ReceiveCryptoContent";
import { useNotificationService } from "../../components/UIElements/notification";
import { useAuth } from "../../hooks/useAuth";
import { getAllListedCoinToken } from "../../api/settings";
import { getUserDetails } from "../../api/profile";
import { getWalletAddress } from "../../api/transaction";

const ReceiveCrypto: React.FC = () => {
  const theme = useTheme();
  const { token } = useAuth();
  const { showNotification } = useNotificationService();
  const [walletData, setWalletData] = useState();
  const [tokenList, setTokenList] = useState([]);

  useEffect(() => {
    fetchCrypto();
  }, []);
  const fetchCrypto = async () => {
    const token_key = token ?? "";
    const result = await getAllListedCoinToken(token_key);
  console.log(result);
    const walletResult = await getWalletAddress(token_key);
    if (result?.status === 200 && walletResult?.status === 200) {
      setTokenList(result?.data?.data);
      setWalletData(walletResult?.data?.data);
    } else {
      showNotification("Error while fetching token list", "error");
    }
  };
  return (
    <>
      <Header>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: "#2C393F" }}>
            Receive Crypto
          </Typography>
        </Box>
      </Header>
      <DashboardGridContainer>
        {tokenList?.length >= 1 && (
          <ReceiveCryptoContent tokenList={tokenList} walletData={walletData} />
        )}
      </DashboardGridContainer>
    </>
  );
};

export default ReceiveCrypto;
